import { useEffect, useState } from "react";
import { Box, IconButton, Stack, Typography } from "@mui/material";
import { ArrowBackRounded } from "@mui/icons-material";
import { mediumOptions, styleFilterOptions } from "../../state/constants";
import Button from "../../components/Button";
import { colors } from "../../theme";
import { PrintSetProductFragment } from "../../generated/storefront";
import { useAppState } from "../../state";
import CategoryCard from "./CategoryCard";

type Props = {
  onClose: () => void;
  selectedMediums: string[];
  setSelectedMediums: (mediums: string[]) => void;
  selectedStyles: string[];
  setSelectedStyles: (styles: string[]) => void;
  mediumImagery: { [key: string]: PrintSetProductFragment[] };
  styleImagery: { [key: string]: PrintSetProductFragment[] };
  producePersonalisedPrintSets: () => void;
};

const ArtQuiz = ({
  onClose,
  selectedMediums,
  setSelectedMediums,
  selectedStyles,
  setSelectedStyles,
  mediumImagery,
  styleImagery,
  producePersonalisedPrintSets,
}: Props) => {
  const [step, setStep] = useState(1);
  const { isMobileScreen, navHeight } = useAppState();

  const onClickMedium = (medium: string) => {
    if (selectedMediums.includes(medium)) {
      setSelectedMediums(selectedMediums.filter((m) => m !== medium));
    } else {
      setSelectedMediums([...selectedMediums, medium]);
    }
  };

  const onClickStyles = (style: string) => {
    if (selectedStyles.includes(style)) {
      setSelectedStyles(selectedStyles.filter((s) => s !== style));
    } else {
      setSelectedStyles([...selectedStyles, style]);
    }
  };

  useEffect(() => {
    if (step === 4) {
      producePersonalisedPrintSets();
      onClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step]);

  const backButtonDisabled = step === 1;
  const nextButtonDisabled = (step === 1 && selectedMediums.length === 0) || (step === 2 && selectedStyles.length === 0);
  const colourStyles = ["bold", "monochromatic", "softcolour", "blackandwhite"];
  const styleOptionsToRemove = [
    "photography",
    "brutalist",
    "oriental",
    "escapist",
    "lineart",
    "popart",
    "scandi",
    "digitalpainting",
    "illustrative",
    "cottagecore",
  ];
  const styleOptions = styleFilterOptions.filter(
    (style) => !styleOptionsToRemove.includes(style.value) && !colourStyles.includes(style.value)
  );
  const colourOptions = styleFilterOptions.filter((style) => colourStyles.includes(style.value));

  return (
    <Stack
      gap={2}
      padding={{ xs: 2, md: 5 }}
      position="fixed"
      top={navHeight}
      left={0}
      width="100%"
      height={`calc(100vh - ${navHeight}px)`}
      zIndex={1}
      bgcolor={colors.white}
      overflow="auto"
    >
      <Stack direction="row" justifyContent={{ xs: "center", md: "space-between" }}>
        {!isMobileScreen && (
          <Button secondary onClick={() => setStep(Math.max(1, step - 1))} disabled={backButtonDisabled}>
            Back
          </Button>
        )}
        <Stack direction="row" gap={1} alignItems="center" paddingX={2} paddingY={1} bgcolor={colors.grey02} borderRadius={16}>
          <Typography fontSize={14} component="span">
            Step
          </Typography>
          <Stack
            bgcolor={step === 1 ? colors.black : colors.white}
            width={25}
            height={25}
            borderRadius={25}
            alignItems="center"
            justifyContent="center"
            onClick={() => setStep(1)}
            sx={{ cursor: "pointer" }}
          >
            <Typography fontSize={14} component="span" color={step === 1 ? colors.white : colors.black}>
              1
            </Typography>
          </Stack>
          <Stack
            bgcolor={step === 2 ? colors.black : colors.white}
            width={25}
            height={25}
            borderRadius={25}
            alignItems="center"
            justifyContent="center"
            onClick={() => selectedMediums.length > 0 && setStep(2)}
            sx={{ cursor: selectedMediums.length > 0 ? "pointer" : "default" }}
          >
            <Typography fontSize={14} component="span" color={step === 2 ? colors.white : colors.black}>
              2
            </Typography>
          </Stack>
          <Stack
            bgcolor={step === 3 ? colors.black : colors.white}
            width={25}
            height={25}
            borderRadius={25}
            alignItems="center"
            justifyContent="center"
            onClick={() => selectedStyles.length > 0 && setStep(3)}
            sx={{ cursor: selectedStyles.length > 0 ? "pointer" : "default" }}
          >
            <Typography fontSize={14} component="span" color={step === 3 ? colors.white : colors.black}>
              3
            </Typography>
          </Stack>
        </Stack>

        {!isMobileScreen && (
          <Button secondary onClick={() => setStep(Math.min(4, step + 1))} disabled={nextButtonDisabled}>
            Next
          </Button>
        )}
      </Stack>

      <Stack direction="row" justifyContent="center" paddingBottom={16}>
        {step === 1 && (
          <Stack gap={3} maxWidth={1100}>
            <Typography variant="h2" align="center">
              Which mediums do you like?
            </Typography>
            <Stack gap={{ xs: 2, md: 3 }} direction="row" flexWrap="wrap" justifyContent="center">
              {mediumOptions.map((medium) => (
                <CategoryCard
                  key={medium.value}
                  category={medium}
                  selectedCategories={selectedMediums}
                  imageryObject={mediumImagery}
                  onClick={onClickMedium}
                />
              ))}
            </Stack>
          </Stack>
        )}
        {step === 2 && (
          <Stack gap={3} maxWidth={1100}>
            <Typography variant="h2" align="center">
              Which styles do you like?
            </Typography>
            <Stack gap={{ xs: 2, md: 3 }} direction="row" flexWrap="wrap" justifyContent="center">
              {styleOptions.map((style) => (
                <CategoryCard
                  key={style.value}
                  category={style}
                  selectedCategories={selectedStyles}
                  imageryObject={styleImagery}
                  onClick={onClickStyles}
                />
              ))}
            </Stack>
          </Stack>
        )}
        {step === 3 && (
          <Stack gap={3}>
            <Typography variant="h2" align="center">
              What colour style do you like?
            </Typography>
            <Stack gap={{ xs: 2, md: 3 }} direction="row" flexWrap="wrap" justifyContent="center">
              {colourOptions.map((style) => (
                <CategoryCard
                  key={style.value}
                  category={style}
                  selectedCategories={selectedStyles}
                  imageryObject={styleImagery}
                  onClick={onClickStyles}
                />
              ))}
            </Stack>
          </Stack>
        )}
      </Stack>
      {isMobileScreen && (
        <Box position="fixed" width="100vw" left={0} bottom={0} bgcolor={colors.white} paddingX={2} paddingY={3}>
          <Stack direction="row" gap={1.5}>
            <IconButton
              onClick={() => setStep(Math.max(1, step - 1))}
              disabled={backButtonDisabled}
              color="secondary"
              sx={{ backgroundColor: colors.black }}
            >
              <ArrowBackRounded fontSize="small" />
            </IconButton>
            <Button fullWidth onClick={() => setStep(Math.min(4, step + 1))} disabled={nextButtonDisabled}>
              Next
            </Button>
          </Stack>
        </Box>
      )}
    </Stack>
  );
};

export default ArtQuiz;
