import { useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import { compact } from "lodash";
import { Box, Stack, Typography } from "@mui/material";
import { useGetCollectionQuery, useGetGalleryWallCollectionsQuery, useGetProductByHandleQuery } from "../../generated/storefront";
import { Loader } from "../../components/Loader";
import { Image } from "../../components";
import ProductCard from "../../components/ProductCard";
import { getAvailableSizes, getSizeAndFrame } from "../../helpers/product";
import ScrollCarousel from "../../components/ScrollCarousel";
import CollectionItem from "./CollectionItem";
import Button from "../../components/Button";
import useBasketActions from "../../hooks/useBasketActions";
import { colors } from "../../theme";
import { Frame } from "../../types/product";
import { formattedPrice } from "../../helpers/money";
import { useAppState } from "../../state";
import { Carousel, CarouselItem } from "../../components/SnapCarousel";
import { PlainMockUp } from "../../components/MockUps";
import CollectionCard from "../../components/Card/Collection";

const Collection = () => {
  const { id } = useParams();
  const { fixedNavHeight, isMobileScreen, selectedCountry } = useAppState();
  const { addOrCreateBasket, loading: loadingBasket, error } = useBasketActions();
  const [selectedVariants, setSelectedVariants] = useState<Record<string, { variantId: string; frame: Frame }>>({});
  const { data, loading } = useGetCollectionQuery({
    variables: {
      id: `gid://shopify/Collection/${id}`,
      country: selectedCountry,
    },
  });
  const products = data?.collection?.products?.nodes || [];
  const noOfProductsToShow = Number(data?.collection?.productsToShow?.value || 0);
  const productsToShow = noOfProductsToShow ? products.slice(0, noOfProductsToShow) : [];
  const { data: collectionsData } = useGetGalleryWallCollectionsQuery();
  const { data: frameProductData } = useGetProductByHandleQuery({
    fetchPolicy: "cache-and-network",
    variables: { handle: "frame", country: selectedCountry },
  });
  const frameProduct = useMemo(() => frameProductData?.product, [frameProductData]);

  const setSelectedVariant = (productId: string, variantId: string, frame: Frame) =>
    setSelectedVariants({ ...selectedVariants, [productId]: { variantId, frame } });

  const addAllItemsToBasket = () => {
    const items = Object.keys(selectedVariants).map((productId) => {
      const selectedVariantId = selectedVariants[productId].variantId;
      const product = products.find((p) => p.id === productId);
      if (!product) return null;
      const selectedVariant = product.variants.nodes.find((variant) => variant.id === selectedVariantId);
      if (!selectedVariant) return null;
      const { size } = getSizeAndFrame(selectedVariant.selectedOptions);
      return {
        merchandiseId: selectedVariantId,
        quantity: 1,
        frame: selectedVariants[product.id].frame,
        size,
        mounted: false,
        isGiftCard: false,
      };
    });
    addOrCreateBasket(compact(items));
  };

  useEffect(() => {
    const defaultVariants: Record<string, { variantId: string; frame: Frame }> = {};
    productsToShow.forEach((product) => {
      const defaultVariant = product.variants.nodes.find((variant) => {
        const { size, frame } = getSizeAndFrame(variant.selectedOptions);
        return size === getAvailableSizes(product.variants.nodes)[0] && frame === Frame.Unframed;
      });
      if (defaultVariant) defaultVariants[product.id] = { variantId: defaultVariant.id, frame: Frame.Natural };
    });
    setSelectedVariants(defaultVariants);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products]);

  if (!data || loading)
    return (
      <Box padding={2}>
        <Loader />
      </Box>
    );

  const { collection } = data;
  const galleryWalls = collectionsData?.collections.nodes || [];
  const moreGalleryWalls = galleryWalls.filter((c) => c.id !== collection?.id && !/\d/.test(c.title));

  if (!collection)
    return (
      <Box padding={2}>
        <Typography variant="h2" align="center">
          Collection not found
        </Typography>
      </Box>
    );

  const subtotal = productsToShow.reduce((acc, product) => {
    const variant = product.variants.nodes.find((v) => v.id === selectedVariants[product.id]?.variantId);
    const framePrice = Number(
      frameProduct?.variants.nodes.find(
        (variant) =>
          variant.selectedOptions[0].value === getSizeAndFrame(variant.selectedOptions).size &&
          variant.selectedOptions[1].value === selectedVariants[product.id]?.frame
      )?.price.amount || 0
    );
    return acc + (variant ? Number(variant.priceV2.amount) + framePrice : 0);
  }, 0);
  const currencyCode = productsToShow[0]?.variants.nodes[0].priceV2.currencyCode;
  const discountPct = productsToShow.length > 2 ? 0.2 : 0.15;

  return (
    <>
      <Helmet>
        <title>{collection.title.split(": ").pop()} | GoodMood</title>
        <meta name="description" content={`Buy curated collections of ${collection.title.split(": ").pop()} art prints`} />
      </Helmet>
      <Stack
        direction={{ xs: "column", md: "row" }}
        width="100%"
        height={{ xs: "auto", md: `calc(100vh - ${fixedNavHeight}px)` }}
      >
        {productsToShow.length > 0 ? (
          <>
            <Stack flexBasis={{ xs: "100%", md: "50%" }}>
              {isMobileScreen ? (
                productsToShow.length > 0 && (
                  <Box height="50vh">
                    <Carousel
                      items={[
                        {
                          key: "collection-image",
                          component: <Image src={collection.image?.url} />,
                        },
                        ...productsToShow.map((product) => {
                          const frame = selectedVariants[product.id]?.frame || Frame.Natural;
                          return {
                            key: product.id,
                            component: <PlainMockUp image={product.images.nodes[0]} frame={frame} isMobile />,
                          };
                        }),
                      ]}
                      renderItem={({ item, isSnapPoint }) => {
                        return (
                          <CarouselItem key={item.key} isSnapPoint={isSnapPoint}>
                            {item.component}
                          </CarouselItem>
                        );
                      }}
                    />
                  </Box>
                )
              ) : (
                <Image src={collection.image?.url} alt={collection.image?.altText || collection.title.split(": ").pop()} />
              )}
            </Stack>
            <Stack flexBasis={{ xs: "100%", md: "50%" }}>
              <Box overflow={{ xs: "initial", md: "auto" }} paddingTop={2}>
                <Stack gap={3}>
                  <Typography variant="h2" component="h1" paddingX={{ xs: 2, md: 5 }}>
                    {collection.title.split(": ").pop()}
                  </Typography>
                  <Stack gap={2}>
                    {productsToShow.map((product, index) => (
                      <CollectionItem
                        product={product}
                        setSelectedVariant={setSelectedVariant}
                        lastChild={index === productsToShow.length - 1}
                      />
                    ))}
                  </Stack>
                </Stack>
                <Box
                  paddingY={1.5}
                  paddingX={{ xs: 2, md: 5 }}
                  bgcolor={colors.white}
                  position="sticky"
                  bottom={0}
                  borderTop={`1px solid ${colors.grey10}`}
                >
                  <Stack gap={{ xs: 1, md: 2 }} alignItems="center">
                    {productsToShow.length && (
                      <Stack gap={{ xs: 0.5, md: 1 }} direction="row" alignSelf="start">
                        <Typography
                          variant="h4"
                          alignSelf="start"
                          style={{ textDecoration: "line-through" }}
                          color={colors.grey60}
                        >
                          {formattedPrice(subtotal, currencyCode)}
                        </Typography>
                        <Typography variant="h4" color={colors.red}>
                          {formattedPrice(subtotal * (1 - discountPct), currencyCode)} · {discountPct * 100}% OFF
                        </Typography>
                      </Stack>
                    )}

                    <Button fullWidth onClick={addAllItemsToBasket} loading={loadingBasket}>
                      Add all to basket
                    </Button>
                    {error && <Typography color={colors.error}>{error}</Typography>}
                    <Typography variant="caption" align="center" color="text.secondary" fontSize={{ xs: 12, md: 14 }}>
                      No import fees for UK, EU, AUS & USA
                    </Typography>
                  </Stack>
                </Box>
              </Box>
            </Stack>
          </>
        ) : (
          <Box padding={2} width="100%">
            <Loader />
          </Box>
        )}
      </Stack>

      <Box paddingY={{ xs: 2, md: 5 }}>
        <ScrollCarousel header="Related Products" cta={{ text: "View all", link: `/shop/collections/${collection.handle}` }}>
          {products.map((product) => (
            <ProductCard key={product.id} product={product} />
          ))}
        </ScrollCarousel>
      </Box>

      <Box paddingY={{ xs: 2, md: 5 }}>
        <ScrollCarousel header="More Gallery Walls" cta={{ text: "View all", link: "/gallery-walls" }}>
          {moreGalleryWalls.map((c) => (
            <CollectionCard key={c.id} collection={c} imageHeight={isMobileScreen ? "50vw" : "25vw"} />
          ))}
        </ScrollCarousel>
      </Box>
    </>
  );
};

export default Collection;
