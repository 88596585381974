import { useState } from "react";
import { Link } from "react-router-dom";
import { match } from "ts-pattern";
import { Box, Skeleton, Stack, Typography } from "@mui/material";
import FrameMockUp from "../../components/FrameMockUp";
import bedMockUp from "../../assets/images/mock-ups/bed.webp";
import sofaMockUp from "../../assets/images/mock-ups/sofa.webp";
import { PrintSet } from "./usePrintSets";
import { Frame } from "../../types/product";
import { formattedPrice } from "../../helpers/money";
import { truncatedTextStyles } from "../../state/constants";
import { colors } from "../../theme";
import { CurrencyCode } from "../../generated/storefront";

const PrintSetCard = ({
  printSet,
  selectedFrame,
  indent,
  openInNewWindow,
  imageStyle,
}: {
  printSet: PrintSet;
  selectedFrame: Frame;
  indent?: boolean;
  openInNewWindow?: boolean;
  imageStyle: "bed" | "sofa";
}) => {
  const [imageLoaded, setImageLoaded] = useState(false);

  const currencyCode = printSet.product.priceRange.minVariantPrice.currencyCode;
  const showDecimals = currencyCode === CurrencyCode.Eur;
  const totalMinPrice =
    Number(printSet.product.priceRange.minVariantPrice.amount) +
    Number(printSet.complimentaryProduct.priceRange.minVariantPrice.amount);

  const mockUpImage = match(imageStyle)
    .with("bed", () => bedMockUp)
    .with("sofa", () => sofaMockUp)
    .exhaustive();

  return (
    <Link to={printSet.link} target={openInNewWindow ? "_blank" : ""}>
      <Stack gap={{ xs: 1.5, md: 2 }}>
        <Stack direction="row" position="relative" minHeight={{ xs: "45vw", md: "30vw", xl: "25vw", xxl: "10vw" }} width="100%">
          {!imageLoaded && (
            <Skeleton
              width="100%"
              height="100%"
              animation="wave"
              variant="rectangular"
              sx={{ position: "absolute", bgcolor: colors.grey02 }}
            />
          )}
          <img src={mockUpImage} alt="home mock up" width="100%" height="auto" onLoad={() => setImageLoaded(true)} />
          <Stack
            direction="row"
            gap={{ xs: 1, md: 2 }}
            position="absolute"
            top="30%"
            left="50%"
            width="70%"
            padding={{ xs: 2, md: 5 }}
            sx={{ transform: "translate(-50%, -50%)" }}
          >
            <Box width="50%">
              <FrameMockUp frame={selectedFrame} image={printSet.product.images.nodes[0]} shadow />
            </Box>

            <Box width="50%">
              <FrameMockUp frame={selectedFrame} image={printSet.complimentaryProduct.images.nodes[0]} shadow />
            </Box>
          </Stack>
        </Stack>
        <Stack gap={0.5} paddingX={{ xs: indent ? 2 : 0, md: 0 }}>
          <Typography variant="body2" {...truncatedTextStyles}>
            {printSet.product.title} · {printSet.complimentaryProduct.title}
          </Typography>
          <Stack gap={{ xs: 0.75, md: 1 }} direction="row">
            <Stack direction="row" gap={{ xs: 0.5, md: 0.75 }}>
              <Typography variant="body2">From</Typography>
              <Typography variant="body2" style={{ textDecoration: "line-through" }} color={colors.grey60}>
                {formattedPrice(totalMinPrice, currencyCode, showDecimals)}
              </Typography>
            </Stack>

            <Typography variant="body2" color={colors.red}>
              {formattedPrice(totalMinPrice * (1 - 0.15), currencyCode, showDecimals)} (-15%)
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </Link>
  );
};

export default PrintSetCard;
