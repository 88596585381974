import { ChangeEvent } from "react";
import { FormGroup } from "@mui/material";
import CheckboxButton from "../../../components/Checkbox/CheckboxButton";

type Option = {
  label: string;
  value: string;
};

type Props = {
  name: string;
  options: Option[];
  state: {
    [key: string]: string;
  };
  setState: (state: { [key: string]: string }) => void;
};

const CheckboxGroup = ({ name, options, state, setState }: Props) => {
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = event.target;
    const currentList = state[name] ? state[name].split(",") : [];
    if (checked) {
      setState({ ...state, [name]: [...currentList, value].join(",") });
    } else {
      const filtered = currentList.filter((item: string) => item !== value);
      if (filtered.length === 0) {
        const newState = { ...state };
        delete newState[name];
        setState(newState);
      } else {
        setState({ ...state, [name]: currentList.filter((item: string) => item !== value).join(",") });
      }
    }
  };

  return (
    <FormGroup style={{ gap: 8, flexDirection: "row" }}>
      {options.map((option) => (
        <CheckboxButton
          name={name}
          label={option.label}
          value={option.value}
          onChange={handleChange}
          checked={state[name] ? state[name].includes(option.value) : false}
        />
      ))}
    </FormGroup>
  );
};

export default CheckboxGroup;
