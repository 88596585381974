import { useMemo, useState } from "react";
import {
  CartLineInput,
  useAddItemsToBasketMutation,
  useCreateBasketMutation,
  useGetBasketLazyQuery,
  useGetProductByHandleQuery,
} from "../generated/storefront";
import { trackEvent } from "../helpers/analytics";
import { useAppState } from "../state";
import { trackAddedToCart } from "../services/API/events";
import { Frame, Size } from "../types/product";
import { getIdNumber } from "../helpers/shopify";

type Item =
  | {
      merchandiseId: string;
      quantity: number;
      size: Size;
      frame: Frame;
      mounted: boolean;
      isGiftCard: false;
    }
  | {
      merchandiseId: string;
      quantity: number;
      isGiftCard: true;
    };

const useBasketActions = () => {
  const { selectedCountry, setBasket, setItemAdded } = useAppState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>("");
  const [getBasket] = useGetBasketLazyQuery();
  const [createBasket] = useCreateBasketMutation();
  const [addItemToBasket] = useAddItemsToBasketMutation();
  const { data: mountingProductData } = useGetProductByHandleQuery({
    fetchPolicy: "cache-and-network",
    variables: { handle: "mounting", country: selectedCountry },
  });
  const { data: frameProductData } = useGetProductByHandleQuery({
    fetchPolicy: "cache-and-network",
    variables: { handle: "frame", country: selectedCountry },
  });
  const mountingProduct = useMemo(() => mountingProductData?.product, [mountingProductData]);
  const frameProduct = useMemo(() => frameProductData?.product, [frameProductData]);

  const getLines = (items: Item[]): CartLineInput[] => {
    const lines: CartLineInput[] = [];
    items.forEach((item) => {
      const { merchandiseId, quantity } = item;
      if (item.isGiftCard) {
        return lines.push({
          merchandiseId,
          quantity,
        });
      }
      if (item.frame === Frame.Unframed) {
        return lines.push({
          merchandiseId,
          quantity,
        });
      }

      const mountingVariant = mountingProduct?.variants.nodes.find((variant) => variant.selectedOptions[0].value === item.size);
      const mountedLineItem = mountingVariant
        ? [{ merchandiseId: mountingVariant.id, quantity: item.quantity, attributes: [{ key: "_attribute", value: "yes" }] }]
        : [];
      const frameVariant = frameProduct?.variants.nodes.find((variant) => {
        return variant.selectedOptions[0].value === item.size && variant.selectedOptions[1].value === item.frame;
      });
      const framedLineItem = frameVariant
        ? [{ merchandiseId: frameVariant.id, quantity: item.quantity, attributes: [{ key: "_attribute", value: "yes" }] }]
        : [];

      if (item.mounted) {
        return lines.push(
          {
            merchandiseId,
            quantity,
            attributes: [
              { key: "frame", value: item.frame },
              {
                key: "mounted",
                value: "yes",
              },
              { key: "arrives", value: "framed and ready to hang" },
            ],
          },
          ...framedLineItem,
          ...mountedLineItem
        );
      }

      lines.push(
        {
          merchandiseId,
          quantity,
          attributes: [
            { key: "frame", value: item.frame },
            { key: "arrives", value: "framed and ready to hang" },
          ],
        },
        ...framedLineItem
      );
    });
    return lines;
  };

  const addOrCreateBasket = async (items: Item[]) => {
    trackEvent("AddToCart", {
      content_ids: items.map((item) => getIdNumber(item.merchandiseId)),
      content_type: "product",
    });
    setLoading(true);
    const basketId = localStorage.getItem("basketId");
    const isValidBasket = basketId ? await getBasket({ variables: { basketId } }) : null;
    const lines = getLines(items);

    try {
      if (basketId && isValidBasket?.data?.cart?.id) {
        const { data } = await addItemToBasket({
          variables: { basketId, lines },
        });
        if (data?.cartLinesAdd?.cart) {
          setBasket(data.cartLinesAdd.cart);
          trackAddedToCart({
            basket: data.cartLinesAdd.cart,
            addedItemId: lines[0].merchandiseId,
          });
        }
      } else {
        trackEvent("CreateCart");
        const { data } = await createBasket({
          variables: {
            lines,
            buyerIdentity: {
              countryCode: selectedCountry,
            },
          },
        });
        if (data?.cartCreate?.cart) {
          setBasket(data.cartCreate.cart);
          localStorage.setItem("basketId", data.cartCreate.cart.id);
          trackAddedToCart({
            basket: data.cartCreate.cart,
            addedItemId: lines[0].merchandiseId,
          });
        }
      }
      setLoading(false);
      setItemAdded(true);
    } catch (e) {
      setLoading(false);
      setError("Could not add item to basket");
    }
  };

  return { getBasket, addOrCreateBasket, loading, error };
};

export default useBasketActions;
