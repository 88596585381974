import posthog from "posthog-js";
import ReactPixel from "react-facebook-pixel";
import ReactGA4 from "react-ga4";
// @ts-ignore
import ReactPinterestTag from "react-pinterest-tag";

export const trackEvent = (eventName: string, data?: Record<string, string | string[]>) => {
  if (process.env.REACT_APP_ENVIRONMENT === "production") {
    ReactPixel.track(eventName, data);
    const label = !data || Array.isArray(data) ? "" : (Object.values(data)[0] as string);
    ReactGA4.event({
      category: "User",
      action: eventName,
      label,
    });
    ReactPinterestTag.track(eventName, data);
    posthog.capture(eventName, data);
  }
};
