import { ChangeEvent } from "react";
import { Stack } from "@mui/material";
import Radio from "../../../../components/Radio";

type Option = {
  label: string;
  value: string;
  disabled?: boolean;
};

type Props = {
  name: string;
  options: Option[];
  state: {
    [key: string]: string;
  };
  setState: (state: { [key: string]: string }) => void;
};

const RadioGroup = ({ name, options, state, setState }: Props) => {
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setState({ ...state, [name]: event.target.value });
  };

  return (
    <Stack onChange={handleChange} gap={1.5}>
      {options.map((option) => (
        <Radio
          name={name}
          id={option.value}
          value={option.value}
          label={option.label}
          checked={state[name] ? state[name].includes(option.value) : false}
          disabled={option.disabled}
        />
      ))}
    </Stack>
  );
};

export default RadioGroup;
