import { useCallback, useEffect, useRef, useState } from "react";
import { format, subMonths } from "date-fns";
import { Box, CircularProgress, MenuItem, Select, SelectChangeEvent, Stack, Typography } from "@mui/material";
import { ArrowDropDownRounded } from "@mui/icons-material";
import Checkbox from "../Checkbox";
import { updateProduct } from "../../services/API";
import { styleFilterOptions, subjectFilterOptions } from "../../state/constants";
import { getIdNumber } from "../../helpers/shopify";
import { getPageViews } from "../../services/API/analytics";
import { ProductCardProduct } from ".";

const AdminSection = ({ product }: { product: Omit<ProductCardProduct, "priceRange"> }) => {
  const [updatingProduct, setUpdatingProduct] = useState(false);
  const [pageViews, setPageViews] = useState<number>();
  const [loadingViews, setLoadingViews] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const reference = ref.current;
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
      },
      { threshold: 0.1 }
    );

    if (reference) {
      observer.observe(reference);
    }

    return () => {
      if (reference) {
        observer.unobserve(reference);
      }
    };
  }, []);

  const fetchPageViews = useCallback(async () => {
    if (!product.id || loadingViews || pageViews !== undefined || !isVisible) return;

    setLoadingViews(true);
    const { data } = await getPageViews({
      page: "products",
      id: getIdNumber(product.id),
      handle: product.handle,
      startDate: format(subMonths(new Date(), 1), "yyyy-MM-dd"),
      endDate: format(new Date(), "yyyy-MM-dd"),
    });
    setPageViews(data?.pageViews);
    setLoadingViews(false);
  }, [product, loadingViews, pageViews, isVisible]);

  const handleUpdateTag = async (target: EventTarget & HTMLInputElement, id: string) => {
    setUpdatingProduct(true);
    await updateProduct(id, { [target.name]: target.checked });
    setUpdatingProduct(false);
  };

  const handleUpdateTags = async (event: SelectChangeEvent<string[]>) => {
    setUpdatingProduct(true);
    const gmTags = event.target.value;
    if (typeof gmTags === "string") return;
    await updateProduct(product.id, { gmTags });
    setUpdatingProduct(false);
  };

  useEffect(() => {
    fetchPageViews();
  }, [fetchPageViews]);

  const tags = [...styleFilterOptions, ...subjectFilterOptions, { label: "Studio Favourites", value: "studiofavourites" }].map(
    (tag) => ({ value: `gm.${tag.value}`, label: tag.label })
  );

  return (
    <Stack gap={2} ref={ref}>
      <Stack position="absolute" top={10} right={10}>
        {loadingViews ? (
          <Stack width={18} justifyContent="center">
            <CircularProgress size="small" />
          </Stack>
        ) : (
          <Typography>{pageViews}</Typography>
        )}
      </Stack>
      <Stack gap={1} direction="row" flexWrap="wrap">
        <Checkbox
          name="pick"
          label="Pick"
          onChange={({ target }) => handleUpdateTag(target, product.id)}
          defaultChecked={product.tags.includes("pick")}
          disabled={updatingProduct}
        />
        <Checkbox
          name="favourite"
          label="Favourite"
          onChange={({ target }) => handleUpdateTag(target, product.id)}
          defaultChecked={product.tags.includes("favourite")}
          disabled={updatingProduct}
        />
        <Checkbox
          name="communityFavourite"
          label="Community favourites"
          onChange={({ target }) => handleUpdateTag(target, product.id)}
          defaultChecked={product.tags.includes("community_favourites")}
          disabled={updatingProduct}
        />
      </Stack>

      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
        {product.tags
          .filter((tag) => tag.startsWith("gm."))
          .map((value) => (
            <Checkbox
              name="gmTags"
              key={value}
              onChange={() => {
                // @ts-ignore
                handleUpdateTags({ target: { value: product.tags.filter((tag) => tag !== value) } });
              }}
              defaultChecked={product.tags.includes(value)}
              disabled={updatingProduct}
              label={tags.find((tag) => tag.value === value)?.label}
            />
          ))}
      </Box>

      <Select
        id="tags-select"
        variant="filled"
        multiple
        displayEmpty
        value={product.tags.filter((tag) => tag.startsWith("gm."))}
        name="tags"
        onChange={handleUpdateTags}
        IconComponent={ArrowDropDownRounded}
        renderValue={(selected) => selected.map((s) => tags.find((tag) => tag.value === s)?.label).join(", ")}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: "40vh",
            },
          },
        }}
      >
        {tags.map((tag) => (
          <MenuItem key={tag.value} value={tag.value}>
            <Checkbox
              name="tags"
              label={tag.label}
              defaultChecked={product.tags.includes(tag.value)}
              disabled={updatingProduct}
            />
          </MenuItem>
        ))}
      </Select>
    </Stack>
  );
};

export default AdminSection;
