import { useRef } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Box, Stack } from "@mui/material";
import { media } from "../../helpers/layout";
import { useAppState } from "../../state";
import Button from "../Button";
import IconButton from "../Button/IconButton";
import { Header } from "../Text";
import { Loader } from "../Loader";

type Props = {
  children: JSX.Element[];
  headerComponent?: JSX.Element;
  header?: string;
  loading?: boolean;
  cta?: {
    link: string;
    text: string;
  };
  slideWidth?: string;
  minHeight?: string;
  noPadding?: boolean;
};

const StyledCarousel = styled.ul<{ noPadding?: boolean }>`
  display: flex;
  overflow: auto;
  list-style: none;
  margin: 0;
  padding: 0;
  gap: 16px;
  scroll-behavior: smooth;
  -ms-overflow-style: none;
  scrollbar-width: none;
  padding: ${({ noPadding }) => (noPadding ? "0" : "0 40px")};

  &::-webkit-scrollbar {
    display: none;
  }

  @media ${media.m} {
    gap: 12px;
    padding: ${({ noPadding }) => (noPadding ? "0" : "0 16px")};
  }
`;

const CarouselItem = styled.li<{ slideWidth: string }>`
  ${(p) => `flex: 0 0 ${p.slideWidth}; width: ${p.slideWidth};`}
`;

const ScrollCarousel = ({ header, cta, children, headerComponent, slideWidth, loading, minHeight, noPadding }: Props) => {
  const ref = useRef<HTMLUListElement>(null);
  const appState = useAppState();
  const { isMobileScreen } = appState;
  const width = slideWidth || (isMobileScreen ? "60%" : "25%");

  const goBack = () => {
    const scrollDistance = ref.current ? ref.current?.scrollWidth / children.length : 0;
    ref.current?.scrollBy(-scrollDistance, 0);
  };
  const goForward = () => {
    const scrollDistance = ref.current ? ref.current?.scrollWidth / children.length : 0;
    ref.current?.scrollBy(scrollDistance, 0);
  };

  return (
    <Box minHeight={minHeight}>
      <Box paddingBottom={3} paddingX={noPadding ? {} : { xs: 2, md: 5 }}>
        <Stack direction="row" justifyContent="space-between" alignItems="end">
          <Stack gap={1}>
            {header && <Header type="h2">{header}</Header>}
            {headerComponent}
          </Stack>
          <Stack direction="row" gap={3}>
            {!isMobileScreen && children.length > 3 && (
              <Stack direction="row" gap={2}>
                <IconButton icon="backArrow" aria-label="back" onClick={goBack} disabled={loading} />
                <IconButton icon="forwardArrow" aria-label="forward" onClick={goForward} disabled={loading} />
              </Stack>
            )}
            {cta && (
              <Link to={cta.link}>
                <Button secondary size={isMobileScreen ? "small" : "large"} disabled={loading}>
                  {cta.text}
                </Button>
              </Link>
            )}
          </Stack>
        </Stack>
      </Box>

      <StyledCarousel ref={ref} noPadding={noPadding}>
        {loading ? (
          <Loader />
        ) : (
          children.map((child) => (
            <CarouselItem key={child.key} slideWidth={width}>
              {child}
            </CarouselItem>
          ))
        )}
      </StyledCarousel>
    </Box>
  );
};

export default ScrollCarousel;
