import { useMemo } from "react";
import { Link } from "react-router-dom";
import { match } from "ts-pattern";
import { capitalize } from "lodash";
import { Box, MenuItem, Select, Stack, Typography } from "@mui/material";
import { ArrowDropDownRounded } from "@mui/icons-material";
import FrameMockUp from "../../FrameMockUp";
import {
  BasketFragment,
  BasketLineItemFragment,
  CartLineInput,
  CartLineUpdateInput,
  CountryCode,
  useAddItemsToBasketMutation,
  useGetProductByHandleQuery,
  useUpdateItemInBasketMutation,
} from "../../../generated/storefront";
import { getAvailableSizes, getSizeAndFrame, sizeLabelsForCountry } from "../../../helpers/product";
import { Frame, frames, RectangleSize, Size } from "../../../types/product";
import { formattedPrice } from "../../../helpers/money";
import { useAppState } from "../../../state";
import FrameBadge from "../../FrameBadges/FrameBadge";
import { colors, fonts } from "../../../theme";
import QuantityPicker from "../../QuantityPicker";

const BasketDrawerItem = ({
  item,
  basket,
  disableEdit,
  onClose,
}: {
  item: BasketLineItemFragment;
  basket: BasketFragment;
  disableEdit: boolean;
  onClose: () => void;
}) => {
  const { selectedCountry, setBasket } = useAppState();
  const [addItemToBasket, { loading: adding }] = useAddItemsToBasketMutation();
  const [updateItemInBasket, { loading }] = useUpdateItemInBasketMutation();
  const updating = loading || adding;
  const { data: mountingProductData } = useGetProductByHandleQuery({
    fetchPolicy: "cache-and-network",
    variables: { handle: "mounting", country: selectedCountry },
  });
  const mountingProduct = useMemo(() => mountingProductData?.product, [mountingProductData]);
  const { data: frameProductData } = useGetProductByHandleQuery({
    fetchPolicy: "cache-and-network",
    variables: { handle: "frame", country: selectedCountry },
  });
  const frameProduct = useMemo(() => frameProductData?.product, [frameProductData]);
  const mountedLabel = match(selectedCountry)
    .with(CountryCode.Us, () => "Matted")
    .otherwise(() => "Mounted");
  const unmountedLabel = match(selectedCountry)
    .with(CountryCode.Us, () => "No matt")
    .otherwise(() => "No mount");
  const sizeLabels = sizeLabelsForCountry(selectedCountry);
  const items = basket.lines.nodes || [];

  const getFrame = (item: Pick<BasketLineItemFragment, "merchandise" | "attributes">) => {
    const { frame } = getSizeAndFrame(item.merchandise.selectedOptions);
    const frameColor = item.attributes.find((a) => a.key === "frame")?.value || frame;
    return frameColor as Frame;
  };

  const getSize = (item: Pick<BasketLineItemFragment, "merchandise">) => {
    if (item.merchandise.product.isGiftCard) return RectangleSize.A3;
    const { size } = getSizeAndFrame(item.merchandise.selectedOptions);
    return size;
  };

  const findFrameItemInBasket = (items: Pick<BasketLineItemFragment, "id" | "merchandise" | "attributes" | "quantity">[]) => {
    return items.find(
      (i) =>
        i.merchandise.sku === `GLOBAL-CFP-${getSize(item)}-BACKLOADER` &&
        i.attributes.find((a) => a.key === "_attribute") &&
        i.merchandise.selectedOptions[1].value === getFrame(item)
    );
  };

  const getFrameVariant = (size: Size, frame: Frame) => {
    return frameProduct?.variants.nodes.find(
      (variant) => variant.selectedOptions[0].value === size && variant.selectedOptions[1].value === frame
    );
  };

  const getBasketItemPrice = (item: Pick<BasketLineItemFragment, "merchandise" | "attributes" | "cost">) => {
    if (item.merchandise.product.isGiftCard)
      return formattedPrice(item.cost.subtotalAmount.amount, item.cost.subtotalAmount.currencyCode);
    const mountingVariant = mountingProduct?.variants.nodes.find((variant) => variant.selectedOptions[0].value === getSize(item));
    const frameVariant = getFrameVariant(getSize(item), getFrame(item));
    const framePrice = item.attributes.find((a) => a.key === "frame") ? Number(frameVariant?.price.amount || 0) : 0;
    const mountPrice = item.attributes.find((a) => a.key === "mounted") ? Number(mountingVariant?.price.amount || 0) : 0;
    const price = Number(item.cost.subtotalAmount.amount) + framePrice + mountPrice;
    return formattedPrice(price, item.cost.subtotalAmount.currencyCode);
  };

  const removeItem = async () => {
    const mounted = item?.attributes.find((a) => a.key === "mounted");
    const framed = item?.attributes.find((a) => a.key === "frame");
    const linesToUpdate = [{ id: item.id, quantity: 0 }];
    const size = getSize(item);
    if (mounted) {
      const mountedItem = items.find((i) => i.merchandise.sku === `M-${size}`);
      if (mountedItem) {
        linesToUpdate.push({ id: mountedItem.id, quantity: mountedItem.quantity - item.quantity });
      }
    }
    if (framed) {
      const frameItem = findFrameItemInBasket(items);
      if (frameItem) {
        linesToUpdate.push({ id: frameItem.id, quantity: frameItem.quantity - item.quantity });
      }
    }
    const { data } = await updateItemInBasket({ variables: { basketId: basket.id, lines: linesToUpdate } });
    if (data?.cartLinesUpdate?.cart) {
      if (data.cartLinesUpdate.cart.lines.nodes.length === 0) onClose();
      setBasket(data.cartLinesUpdate.cart);
    }
  };

  const updateQuantity = async (quantity: number) => {
    if (quantity === 0) return removeItem();
    const mounted = item?.attributes.find((a) => a.key === "mounted");
    const framed = item?.attributes.find((a) => a.key === "frame");
    const linesToUpdate = [{ id: item.id, quantity }];
    const size = getSize(item);
    const diff = quantity - item.quantity;

    const frameItem = findFrameItemInBasket(items);
    if (framed && frameItem) {
      linesToUpdate.push({ id: frameItem.id, quantity: frameItem.quantity + diff });
    }
    const mountedItem = items.find((i) => i.merchandise.sku === `M-${size}`);
    if (mounted && mountedItem) {
      linesToUpdate.push({ id: mountedItem.id, quantity: mountedItem.quantity + diff });
    }

    const { data } = await updateItemInBasket({ variables: { basketId: basket.id, lines: linesToUpdate } });
    if (data?.cartLinesUpdate?.cart) {
      if (data.cartLinesUpdate.cart.lines.nodes.length === 0) onClose();
      setBasket(data.cartLinesUpdate.cart);
    }
  };

  const updateSize = async (size: Size) => {
    const merchandiseId = item.merchandise.product.variants.nodes.find((variant) => {
      const { size: variantSize, frame: variantFrame } = getSizeAndFrame(variant.selectedOptions);
      return variantSize === size && variantFrame === Frame.Unframed;
    })?.id;
    if (!merchandiseId) throw new Error("Variant not found");
    const linesToUpdate: CartLineUpdateInput[] = [{ id: item.id, merchandiseId }];
    const linesToAdd: CartLineInput[] = [];
    const mounted = item?.attributes.find((a) => a.key === "mounted");
    const framed = item?.attributes.find((a) => a.key === "frame") || getFrame(item) !== Frame.Unframed;

    if (framed) {
      const exisitingFrameItem = findFrameItemInBasket(items);
      if (exisitingFrameItem) {
        linesToUpdate.push({ id: exisitingFrameItem.id, quantity: exisitingFrameItem.quantity - item.quantity });
      }
      const newFrameVariant = getFrameVariant(size, getFrame(item));
      if (newFrameVariant) {
        linesToAdd.push({
          merchandiseId: newFrameVariant.id,
          quantity: item.quantity,
          attributes: [{ key: "_attribute", value: "yes" }],
        });
      }
    }

    if (mounted) {
      const exisitingMountedItem = items.find((i) => i.merchandise.sku === `M-${getSize(item)}`);
      if (exisitingMountedItem) {
        linesToUpdate.push({ id: exisitingMountedItem.id, quantity: exisitingMountedItem.quantity - item.quantity });
      }
      const newMountingVariant = mountingProduct?.variants.nodes.find((variant) => variant.selectedOptions[0].value === size);
      if (newMountingVariant) {
        linesToAdd.push({
          merchandiseId: newMountingVariant.id,
          quantity: item.quantity,
          attributes: [{ key: "_attribute", value: "yes" }],
        });
      }
    }

    await addItemToBasket({
      variables: { basketId: basket.id, lines: linesToAdd },
    });

    const { data } = await updateItemInBasket({ variables: { basketId: basket.id, lines: linesToUpdate } });
    if (data?.cartLinesUpdate?.cart) {
      if (data.cartLinesUpdate.cart.lines.nodes.length === 0) onClose();
      setBasket(data.cartLinesUpdate.cart);
    }
  };

  const updateFrame = async (frame: Frame) => {
    const merchandiseId = item.merchandise.product.variants.nodes.find((variant) => {
      const { size: variantSize, frame: variantFrame } = getSizeAndFrame(variant.selectedOptions);
      return variantSize === getSize(item) && variantFrame === Frame.Unframed;
    })?.id;
    if (!merchandiseId) throw new Error("Variant not found");
    const frameItem = findFrameItemInBasket(items);
    if (frame === Frame.Unframed) {
      if (!frameItem) throw new Error("Frame item not found");
      const linesToUpdate = [
        { id: item.id, merchandiseId, attributes: [] },
        { id: frameItem.id, quantity: frameItem.quantity - item.quantity },
      ];
      const mounted = item.attributes.find((a) => a.key === "mounted");
      if (mounted) {
        const mountedItem = items.find((i) => i.merchandise.sku === `M-${getSize(item)}`);
        if (mountedItem) {
          linesToUpdate.push({ id: mountedItem.id, quantity: mountedItem.quantity - item.quantity });
        }
      }
      const { data } = await updateItemInBasket({ variables: { basketId: basket.id, lines: linesToUpdate } });
      if (data?.cartLinesUpdate?.cart) {
        if (data.cartLinesUpdate.cart.lines.nodes.length === 0) onClose();
        setBasket(data.cartLinesUpdate.cart);
      }
    } else {
      const frameVariant = getFrameVariant(getSize(item), frame);
      if (!frameVariant) throw new Error("Frame variant not found");
      const mounted = item.attributes.find((a) => a.key === "mounted");
      const linesToAdd = [
        { merchandiseId: frameVariant.id, quantity: item.quantity, attributes: [{ key: "_attribute", value: "yes" }] },
      ];
      await addItemToBasket({
        variables: { basketId: basket.id, lines: linesToAdd },
      });
      const attributes = [
        { key: "frame", value: frame },
        ...(mounted ? [{ key: "mounted", value: "yes" }] : []),
        { key: "arrives", value: "framed and ready to hang" },
      ];
      const linesToUpdate = [
        { id: item.id, merchandiseId, attributes },
        ...(frameItem ? [{ id: frameItem.id, quantity: frameItem.quantity - item.quantity }] : []),
      ];
      const { data } = await updateItemInBasket({ variables: { basketId: basket.id, lines: linesToUpdate } });
      if (data?.cartLinesUpdate?.cart) {
        if (data.cartLinesUpdate.cart.lines.nodes.length === 0) onClose();
        setBasket(data.cartLinesUpdate.cart);
      }
    }
  };

  const updateMounting = async (mount: "mounted" | "unmounted") => {
    const size = getSize(item);
    const mounted = mount === "mounted";
    if (mounted) {
      const mountingVariant = mountingProduct?.variants.nodes.find((variant) => variant.selectedOptions[0].value === size);
      if (!mountingVariant) throw new Error("Mounting variant not found");
      const linesToAdd = [
        { merchandiseId: mountingVariant.id, quantity: item.quantity, attributes: [{ key: "_attribute", value: "yes" }] },
      ];
      await addItemToBasket({
        variables: { basketId: basket.id, lines: linesToAdd },
      });
      const existingAttributes = item.attributes.filter((a) => a.value).map((a) => ({ key: a.key, value: a.value })) as {
        key: string;
        value: string;
      }[];
      const linesToUpdate = [{ id: item.id, attributes: [...existingAttributes, { key: "mounted", value: "yes" }] }];
      const { data } = await updateItemInBasket({ variables: { basketId: basket.id, lines: linesToUpdate } });

      if (data?.cartLinesUpdate?.cart) {
        if (data.cartLinesUpdate.cart.lines.nodes.length === 0) onClose();
        setBasket(data.cartLinesUpdate.cart);
      }
    } else {
      const mountedItem = items.find((i) => i.merchandise.sku === `M-${size}`);
      if (!mountedItem) throw new Error("Mounted item not found");
      const attributes = item.attributes.filter((a) => a.key !== "mounted").map((a) => ({ key: a.key, value: a.value })) as {
        key: string;
        value: string;
      }[];
      const linesToUpdate = [
        { id: item.id, attributes },
        { id: mountedItem.id, quantity: mountedItem.quantity - item.quantity },
      ];
      const { data } = await updateItemInBasket({ variables: { basketId: basket.id, lines: linesToUpdate } });
      if (data?.cartLinesUpdate?.cart) {
        if (data.cartLinesUpdate.cart.lines.nodes.length === 0) onClose();
        setBasket(data.cartLinesUpdate.cart);
      }
    }
  };

  return (
    <Box key={item.id} padding="16px" borderBottom={`1px solid ${colors.grey10}`}>
      <Stack gap={2} direction="row">
        <Link to={`/products/${item.merchandise.product.handle}`} style={{ width: "40%", display: "flex" }}>
          {item.merchandise.product.isGiftCard ? (
            <img
              src={item.merchandise.product.images.nodes[0].src}
              alt={item.merchandise.product.title}
              style={{ width: "100%", height: "auto" }}
            />
          ) : (
            getFrame(item) && (
              <FrameMockUp
                image={item.merchandise.product.images.nodes[0]}
                frame={getFrame(item)}
                size={getSize(item)}
                mounted={Boolean(item.attributes.find((a) => a.key === "mounted"))}
              />
            )
          )}
        </Link>
        <Stack width="65%" gap={1}>
          <Stack direction="row" justifyContent="space-between" gap={2}>
            <Typography>{item.merchandise.product.title}</Typography>
            <Typography fontSize={14}>{getBasketItemPrice(item)}</Typography>
          </Stack>

          <Stack gap={0.5}>
            <Stack direction="row" gap={1} alignItems="center" justifyContent="space-between">
              <Typography fontSize={12} fontFamily={fonts.body} color={colors.grey80}>
                Quantity
              </Typography>
              <QuantityPicker
                updateQuantity={(value: number) => updateQuantity(value)}
                value={item.quantity}
                disabled={updating || disableEdit}
              />
            </Stack>
            {!item.merchandise.product.isGiftCard && (
              <>
                <Select
                  onChange={(event) => updateSize(event.target.value as Size)}
                  value={getSize(item)}
                  disabled={updating || disableEdit}
                  size="small"
                  IconComponent={ArrowDropDownRounded}
                  sx={{
                    border: 0,
                    ".MuiOutlinedInput-input": { padding: "4px 0", paddingRight: "0 !important" },
                    ".MuiSelect-icon": { right: 0, color: colors.grey80 },
                  }}
                >
                  {getAvailableSizes(item.merchandise.product.variants.nodes).map((option) => (
                    <MenuItem key={option} value={option}>
                      <Typography fontSize={12} fontFamily={fonts.body} color={colors.grey80}>
                        {option} ({sizeLabels[option].printMeasurements})
                      </Typography>
                    </MenuItem>
                  ))}
                </Select>

                <Select
                  onChange={(event) => updateFrame(event.target.value as Frame)}
                  value={getFrame(item)}
                  disabled={updating || disableEdit}
                  size="small"
                  fullWidth
                  IconComponent={ArrowDropDownRounded}
                  color="secondary"
                  sx={{
                    border: 0,
                    ".MuiOutlinedInput-input": { padding: "4px 0", paddingRight: "0 !important" },
                    ".MuiSelect-icon": { right: 0, color: colors.grey80 },
                  }}
                >
                  {frames.map((frame) => (
                    <MenuItem key={frame} value={frame}>
                      <Typography fontSize={12} fontFamily={fonts.body} marginRight={0.6} color={colors.grey80}>
                        {capitalize(frame)}
                      </Typography>
                      <FrameBadge frame={frame} size={14} />
                    </MenuItem>
                  ))}
                </Select>
                {getFrame(item) !== Frame.Unframed && (
                  <Select
                    onChange={(event) => updateMounting(event.target.value as "mounted" | "unmounted")}
                    value={item.attributes.find((a) => a.key === "mounted") ? "mounted" : "unmounted"}
                    disabled={updating || disableEdit}
                    size="small"
                    fullWidth
                    IconComponent={ArrowDropDownRounded}
                    sx={{
                      border: 0,
                      ".MuiOutlinedInput-input": { padding: "4px 0", paddingRight: "0 !important" },
                      ".MuiSelect-icon": { right: 0, color: colors.grey80 },
                    }}
                  >
                    <MenuItem key="mounted" value="mounted">
                      <Typography fontSize={12} fontFamily={fonts.body} color={colors.grey80}>
                        {mountedLabel}
                      </Typography>
                    </MenuItem>
                    <MenuItem key="unmounted" value="unmounted">
                      <Typography fontSize={12} fontFamily={fonts.body} color={colors.grey80}>
                        {unmountedLabel}
                      </Typography>
                    </MenuItem>
                  </Select>
                )}
              </>
            )}
          </Stack>
        </Stack>
      </Stack>
    </Box>
  );
};

export default BasketDrawerItem;
