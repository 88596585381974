import { ApolloClient, HttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { Product } from "../generated/storefront";
import { Order } from "../generated/graphql";

const httpLink = new HttpLink({
  uri: "https://goodmoodprints.myshopify.com/api/2025-01/graphql",
});

const storeFrontMiddlewareLink = setContext(() => ({
  headers: {
    "X-Shopify-Storefront-Access-Token": process.env.REACT_APP_SHOPIFY_STOREFRONT_API_ACCESS_TOKEN,
  },
}));

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        products: {
          keyArgs: (args, context) => {
            const { sortKey, reverse, query } = args as any;
            const { country, variantLimit } = context.variables as any;
            return JSON.stringify({ sortKey, reverse, query, country, variantLimit });
          },
          merge(existing, incoming) {
            if (!incoming) return existing;
            if (!existing) return incoming;
            const { nodes, ...rest } = incoming;
            const existingIds = new Set(
              existing.nodes.map((product: Product & { __ref: string }) => product.id || product.__ref)
            );
            const filteredIncoming = nodes.filter(
              (product: Product & { __ref: string }) => !existingIds.has(product.id || product.__ref)
            );
            return { ...rest, nodes: [...existing.nodes, ...filteredIncoming] };
          },
        },
        search: {
          keyArgs: (args, context) => {
            const { sortKey, reverse, query, productFilters } = args as any;
            const { country, variantLimit } = context.variables as any;
            return JSON.stringify({ sortKey, reverse, query, country, variantLimit, productFilters });
          },
          merge(existing, incoming) {
            if (!incoming) return existing;
            if (!existing) return incoming;
            const { nodes, ...rest } = incoming;
            const existingIds = new Set(
              existing.nodes.map((product: Product & { __ref: string }) => product.id || product.__ref)
            );
            const filteredIncoming = nodes.filter(
              (product: Product & { __ref: string }) => !existingIds.has(product.id || product.__ref)
            );
            return { ...rest, nodes: [...existing.nodes, ...filteredIncoming] };
          },
        },
        collections: {
          keyArgs: (args, context) => {
            const { sortKey, reverse, query } = args as any;
            const { country } = context.variables as any;
            return JSON.stringify({ sortKey, reverse, query, country });
          },
          merge(existing, incoming) {
            if (!incoming) return existing;
            if (!existing) return incoming;
            const { nodes, ...rest } = incoming;
            return { ...rest, nodes: [...existing.nodes, ...nodes] };
          },
        },
        collection: {
          keyArgs: (args, context) => {
            const { sortKey, reverse, filters } = args as any;
            const { country, variantLimit } = context.variables as any;
            return JSON.stringify({ args, sortKey, reverse, filters, country, variantLimit });
          },
          merge(existing, incoming) {
            if (!incoming) return existing;
            if (!existing) return incoming;

            const existingProductKey = Object.keys(existing).find((key) => key.startsWith("products"));
            if (!existingProductKey) return incoming;
            const existingProducts = existing[existingProductKey];
            const incomingProductKey = Object.keys(incoming).find((key) => key.startsWith("products"));
            if (!incomingProductKey) return existing;

            const { [incomingProductKey]: incomingProducts, ...restIncoming } = incoming;
            const { nodes, ...rest } = incomingProducts;
            const result = rest;
            result.nodes = [...existingProducts.nodes, ...nodes];

            return { ...restIncoming, [existingProductKey]: result };
          },
        },
        orders: {
          keyArgs: (args) => {
            const { sortKey, reverse, query } = args as any;
            return JSON.stringify({ sortKey, reverse, query });
          },
          merge(existing, incoming) {
            if (!incoming) return existing;
            if (!existing) return incoming;
            const { nodes, ...rest } = incoming;
            const existingIds = new Set(existing.nodes.map((order: Order & { __ref: string }) => order.id || order.__ref));
            const filteredIncoming = nodes.filter(
              (order: Order & { __ref: string }) => !existingIds.has(order.id || order.__ref)
            );
            return { ...rest, nodes: [...existing.nodes, ...filteredIncoming] };
          },
        },
      },
    },
  },
});

export const storefrontClient = new ApolloClient({
  link: storeFrontMiddlewareLink.concat(httpLink),
  cache,
});

export const adminClient = new ApolloClient({
  uri: "/.netlify/functions/graphql",
  cache,
});
