import { SyntheticEvent, useState } from "react";
import { useLocation, Link, useNavigate } from "react-router-dom";
import { compact, debounce } from "lodash";
import { Autocomplete, IconButton, InputAdornment, Stack, TextField, Typography } from "@mui/material";
import { ArrowBackRounded, KeyboardArrowDownRounded, Search } from "@mui/icons-material";
import { DataGrid } from "@mui/x-data-grid";
import { useCreateDraftOrderMutation, useGetCustomersLazyQuery } from "../../../../generated/graphql";
import Button from "../../../../components/Button";
import AddOrUpdateItemModal, { ArtworkItem, CustomItem } from "./AddOrUpdateItemModal";
import { dataGridStyles } from "../../styles";
import { getIdNumber } from "../../../../helpers/shopify";
import { getArtworkLineItems, getCustomLineItems } from "./helpers";
import useBasketActions from "../../../../hooks/useBasketActions";
import { getSizeAndFrame, getSkuCode } from "../../../../helpers/product";

const CreateDraftOrder = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [addOrUpdateItemModalOpen, setAddOrUpdateItemModalOpen] = useState(false);
  const [customerId, setCustomerId] = useState<string>();
  const [createDraftOrder, { loading }] = useCreateDraftOrderMutation();
  const [getCustomers, { data: customersData, loading: loadingCustomers }] = useGetCustomersLazyQuery();
  const customers = customersData?.customers.nodes || [];
  const basketId = localStorage.getItem("basketId");
  const { getBasket } = useBasketActions();

  const onCreateOrder = async (item: ArtworkItem | CustomItem) => {
    const lineItems =
      item.itemType === "artwork" ? getArtworkLineItems([item], state.currency) : getCustomLineItems([item], state.currency);
    try {
      const { data } = await createDraftOrder({
        variables: {
          input: {
            tags: [state.orderType || "B2B"],
            customerId,
            presentmentCurrencyCode: state.currency,
            lineItems,
            customAttributes: [{ key: "name", value: state.name }],
          },
        },
      });
      if (data?.draftOrderCreate.userErrors.length) {
        console.error(data.draftOrderCreate.userErrors);
        alert(`Failed to create order - ${data.draftOrderCreate.userErrors[0].message}`);
        return;
      }
      if (data?.draftOrderCreate.draftOrder?.id) {
        navigate(`/admin/orders/${getIdNumber(data.draftOrderCreate.draftOrder.id, "DraftOrder")}`);
      }
    } catch (error) {
      console.error(error);
      alert("Failed to create order, try again");
    }
  };

  const onPopulateOrderWithBasket = async () => {
    if (!basketId) return alert("No basket found");
    const { data } = await getBasket({ variables: { basketId } });
    if (!data?.cart) return alert("Failed to get basket");

    const lineItems = data.cart.lines.nodes.map((line) => {
      if (line.attributes.find((attr) => attr.key === "_attribute")) return null;
      const frame = line.attributes.find((attr) => attr.key === "frame");
      const mounted = line.attributes.find((attr) => attr.key === "mounted");
      if (frame) {
        const { size } = getSizeAndFrame(line.merchandise.selectedOptions);
        const tradePrice =
          Number(line.merchandise.product.variants.nodes.find((variant) => variant.id === line.merchandise.id)?.priceV2.amount) *
          0.75;
        return {
          title: line.merchandise.product.title,
          sku: getSkuCode(size, true, Boolean(mounted)),
          quantity: line.quantity,
          requiresShipping: true,
          originalUnitPriceWithCurrency: {
            amount: state.orderType === "Sample" ? 0 : tradePrice,
            currencyCode: line.merchandise.product.variants.nodes[0].priceV2.currencyCode,
          },
          customAttributes: [
            { key: "frame", value: frame.value },
            { key: "_commissionValue", value: state.orderType === "Sample" ? "0" : "30" },
            { key: "_commissionType", value: "pct" },
            { key: "_sizing", value: "stretchToPrintArea" },
            { key: "_artworkUrl", value: line.merchandise.product.images.nodes[0].src },
            { key: "_artistId", value: line.merchandise.product.vendor },
            { key: "_artistName", value: line.merchandise.product.artistName?.value },
          ],
        };
      }
      return {
        variantId: line.merchandise.id,
        quantity: line.quantity,
      };
    });

    try {
      const { data } = await createDraftOrder({
        variables: {
          input: {
            tags: [state.orderType || "B2B"],
            customerId,
            presentmentCurrencyCode: state.currency,
            lineItems: compact(lineItems),
            customAttributes: [{ key: "name", value: state.name }],
          },
        },
      });
      if (data?.draftOrderCreate.userErrors.length) {
        console.error(data.draftOrderCreate.userErrors);
        alert(`Failed to create order - ${data.draftOrderCreate.userErrors[0].message}`);
        return;
      }
      if (data?.draftOrderCreate.draftOrder?.id) {
        navigate(`/admin/orders/${getIdNumber(data.draftOrderCreate.draftOrder.id, "DraftOrder")}`);
      }
    } catch (error) {
      console.error(error);
      alert("Failed to create order, try again");
    }
  };

  const onSelectCustomer = (
    _: SyntheticEvent<Element, Event>,
    value: {
      value: string;
      label: string;
    } | null
  ) => {
    setCustomerId(value?.value);
  };

  return (
    <Stack gap={2} maxWidth={1000} padding={{ xs: 2, md: 5 }}>
      <Stack direction="row" alignItems="center" gap={2}>
        <Link to="/admin?tab=orders&orderType=draft_orders">
          <IconButton>
            <ArrowBackRounded color="primary" />
          </IconButton>
        </Link>
        <Typography variant="h2">{state.name}</Typography>
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="space-between" gap={2}>
        <Stack direction="row" alignItems="center" gap={2}>
          <Button secondary onClick={() => setAddOrUpdateItemModalOpen(true)}>
            Add item
          </Button>
          <Button secondary onClick={onPopulateOrderWithBasket}>
            Populate order with basket
          </Button>
        </Stack>
      </Stack>

      <DataGrid
        rows={[]}
        columns={[
          { field: "product", headerName: "Product", flex: 2 },
          { field: "artist", headerName: "Artist", flex: 2 },
          { field: "sku", headerName: "SKU", flex: 3 },
          { field: "quantity", headerName: "Quantity", flex: 1 },
          { field: "price", headerName: "Price", flex: 1 },
          { field: "commission", headerName: "Commission", flex: 1 },
        ]}
        hideFooter
        sx={{ ...dataGridStyles, minHeight: 200 }}
      />

      <Stack gap={0.5}>
        <Typography>Shopify Customer (optional)</Typography>
        <Autocomplete
          loading={loadingCustomers}
          options={
            customers?.map((customer) => ({
              value: customer.id,
              label: customer.displayName + " · " + customer.email,
            })) || []
          }
          popupIcon={<KeyboardArrowDownRounded />}
          onChange={onSelectCustomer}
          renderInput={(params) => (
            <TextField
              {...params}
              style={{ zIndex: 0 }}
              placeholder="Search customer"
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <InputAdornment position="start">
                    <Search fontSize="small" />
                  </InputAdornment>
                ),
              }}
              onChange={debounce(({ target }) => getCustomers({ variables: { limit: 5, query: target.value } }), 1000)}
            />
          )}
        />
      </Stack>

      {addOrUpdateItemModalOpen && (
        <AddOrUpdateItemModal
          currency={state.currency}
          loading={loading}
          handleAddItem={onCreateOrder}
          onClose={() => setAddOrUpdateItemModalOpen(false)}
          isSampleOrder={state.orderType === "Sample"}
        />
      )}
    </Stack>
  );
};

export default CreateDraftOrder;
