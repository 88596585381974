import { useEffect, useState } from "react";
import { SetURLSearchParams } from "react-router-dom";
import { Accordion, AccordionDetails, AccordionSummary, Box, Drawer, Stack, Typography } from "@mui/material";
import { CloseRounded, ExpandMoreRounded } from "@mui/icons-material";
import CheckboxGroup from "../CheckboxGroup";
import Button from "../../../../components/Button";
import { last12Months } from "../../../../state/constants";
import useFilterOptions from "../useFilterOptions";
import { colors } from "../../../../theme";
import RadioGroup from "./RadioGroup";
import { SortBy } from "../../../../types/product";
import ColourBadges from "../../../../components/ColourBadges";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  searchParams: URLSearchParams;
  setSearchParams: SetURLSearchParams;
  hideSortBy?: boolean;
  filterGroupsToHide?: string[];
  availableTags?: string[];
};

const FiltersDrawer: React.FC<Props> = ({
  isOpen,
  onClose,
  searchParams,
  setSearchParams,
  hideSortBy,
  filterGroupsToHide,
  availableTags,
}) => {
  const { mediumOptions, styleOptions, subjectOptions, sizeOptions, orientationOptions, sortByOptions } = useFilterOptions();
  const [state, setState] = useState<{
    [key: string]: string;
  }>(searchParams.toString() ? Object.fromEntries(searchParams) : {});

  const getFilterCount = (name: string) => {
    const count = state[name] ? state[name].split(",").length : 0;
    return count > 0 ? `(${count})` : "";
  };

  const clearFilters = () => {
    const newParams = new URLSearchParams();
    setSearchParams(newParams, { preventScrollReset: true });
    setState({});
    if (window.scrollY > 650) window.scrollTo(0, 650);
  };

  const onConfirm = () => {
    setSearchParams(state);
    if (window.scrollY > 650) window.scrollTo(0, 650);
    onClose();
  };

  useEffect(() => {
    setState(searchParams.toString() ? Object.fromEntries(searchParams) : {});
  }, [searchParams]);

  const filterGroups = [
    {
      name: "orientation",
      label: "Orientation",
      options: orientationOptions,
    },
    {
      name: "medium",
      label: "Medium",
      options: mediumOptions,
    },
    {
      name: "style",
      label: "Style",
      options: availableTags ? styleOptions.filter((option) => availableTags.includes(option.value)) : styleOptions,
    },
    {
      name: "subject",
      label: "Subject",
      options: availableTags ? subjectOptions.filter((option) => availableTags.includes(option.value)) : subjectOptions,
    },
    {
      name: "size",
      label: "Size",
      options: sizeOptions,
    },

    {
      name: "month",
      label: "Month collection",
      options: last12Months,
    },
  ];

  const activeColours = state["colour"] ? state["colour"].split(",") : [];

  const handleColourChange = (colour: string) => {
    const colourState = state["colour"] ? state["colour"].split(",") : [];
    if (activeColours.includes(colour)) {
      const filtered = colourState.filter((item: string) => item !== colour);
      if (filtered.length === 0) {
        const newState = { ...state };
        delete newState["colour"];
        setState(newState);
      } else {
        setState({ ...state, colour: filtered.join(",") });
      }
    } else {
      setState({ ...state, colour: [...colourState, colour].join(",") });
    }
  };

  const sortBy = sortByOptions.map((option) => ({
    ...option,
    disabled:
      (option.value === SortBy.BestSelling || option.value === SortBy.Newest) &&
      Boolean(state["colour"] || state["style"] || state["subject"]),
  }));

  return (
    <Drawer anchor="left" open={isOpen} onClose={onClose}>
      <Box width="90vw" height="100vh" overflow="auto">
        <Stack
          paddingX={2}
          paddingY={2}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          borderBottom={`1px solid ${colors.grey10}`}
        >
          <Typography variant="h3">Filters</Typography>
          <CloseRounded color="primary" onClick={onClose} />
        </Stack>

        <Stack justifyContent="space-between" height="calc(100% - 64px)">
          <Box>
            {!hideSortBy && (
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreRounded />}
                  aria-controls="month-collection-content"
                  id="month-collection-header"
                >
                  <Typography>Sort by</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <RadioGroup name="sort" options={sortBy} state={state} setState={setState} />
                </AccordionDetails>
              </Accordion>
            )}

            {!filterGroupsToHide?.includes("colour") && (
              <Accordion key="colour">
                <AccordionSummary expandIcon={<ExpandMoreRounded />} aria-controls={`colour-content`} id={`colour-header`}>
                  <Typography>Colour {getFilterCount("colour")}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <ColourBadges selectedColours={activeColours} handleColourChange={handleColourChange} />
                </AccordionDetails>
              </Accordion>
            )}

            {filterGroups
              .filter((group) => !filterGroupsToHide?.includes(group.name))
              .map((group) => (
                <Accordion key={group.name}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreRounded />}
                    aria-controls={`${group.name}-content`}
                    id={`${group.name}-header`}
                  >
                    <Typography>
                      {group.label} {getFilterCount(group.name)}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <CheckboxGroup name={group.name} options={group.options} state={state} setState={setState} />
                  </AccordionDetails>
                </Accordion>
              ))}
          </Box>

          <Stack
            gap={1}
            padding={2}
            position="sticky"
            bottom={0}
            bgcolor={colors.white}
            borderTop={`1px solid ${colors.grey10}`}
            justifyContent="flex-end"
          >
            <Button onClick={onConfirm}>Confirm</Button>
            {searchParams.size > 0 && (
              <Button onClick={clearFilters} tertiary size="small">
                Clear
              </Button>
            )}
          </Stack>
        </Stack>
      </Box>
    </Drawer>
  );
};

export default FiltersDrawer;
