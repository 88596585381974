import { colors, fonts } from "../../theme";

export const dataGridStyles = {
  borderRadius: 4,
  borderColor: colors.grey20,
  "--DataGrid-rowBorderColor": colors.grey10,
  "--DataGrid-containerBackground": colors.cardGrey,
  ".MuiDataGrid-cellContent": { fontFamily: fonts.body, fontSize: "14px" },
  ".MuiDataGrid-columnSeparator": { display: "none" },
  ".MuiDataGrid-columnHeaderTitle": { fontSize: "12px" },
  ".MuiDataGrid-overlayWrapperInner": { height: "150px !important" },
  ".MuiDataGrid-rowReorderCellPlaceholder": { opacity: 0 },
  ".MuiDataGrid-row:last-child .MuiDataGrid-cell": { borderBottom: "none" },
};
