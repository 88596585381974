import { match } from "ts-pattern";
import { CountryCode, CurrencyCode } from "../generated/storefront";

export const roundPrice = (price: string | number) => Math.round((Number(price) + Number.EPSILON) * 100) / 100;

export const currencyToLocale = (currency: CurrencyCode) => {
  return match(currency)
    .with(CurrencyCode.Gbp, () => "en-GB")
    .with(CurrencyCode.Usd, () => "en-US")
    .with(CurrencyCode.Eur, () => "en-AT")
    .with(CurrencyCode.Aud, () => "en-AU")
    .otherwise(() => "en-GB");
};

export const getCurrencySymbol = (currency: CurrencyCode) => {
  return new Intl.NumberFormat(currencyToLocale(currency), {
    style: "currency",
    currency,
  })
    .format(0)
    .split("0")[0];
};

export const getCurrencyFromCountryCode = (countryCode: string) => {
  return match(countryCode)
    .with(CountryCode.Gb, () => CurrencyCode.Gbp)
    .with(CountryCode.Us, () => CurrencyCode.Usd)
    .with(CountryCode.Au, () => CurrencyCode.Aud)
    .otherwise(() => CurrencyCode.Gbp);
};

export const formattedPrice = (price: string | number, currency: CurrencyCode, showDecimalPlaces = true) => {
  const money = roundPrice(price);
  const decimalPlaces = showDecimalPlaces ? 2 : 0;
  return new Intl.NumberFormat(currencyToLocale(currency), {
    style: "currency",
    currency,
    minimumFractionDigits: decimalPlaces,
    maximumFractionDigits: decimalPlaces,
  }).format(money);
};
