import styled from "styled-components";
import { Box, Stack } from "@mui/material";
import { colors } from "../../theme";
import { Icon } from "../Icon";
import { Text } from "../Text";
import reviewsLogo from "../../assets/images/reviewsLogo.png";
import reviewsImage from "../../assets/images/reviews.webp";
import { overallRating, reviews } from "./reviewsData";
import { Carousel, CarouselItem } from "../SnapCarousel";

const ReviewsLogo = styled.img`
  height: auto;
  width: 130px;
`;

const Image = styled.img`
  display: flex;
  height: 100%;
  width: 100%;
  object-fit: cover;
`;

type ItemProps = {
  rating: number;
  comment: string;
};

const ReviewItem = ({ rating, comment }: ItemProps) => {
  return (
    <Stack gap={1} width="100%" paddingBottom={5}>
      <Stack gap={0.5} direction="row" justifyContent="center">
        {[
          ...Array(rating)
            .fill("")
            .map((r, i) => <Icon key={i} icon="star" size={19} fill={colors.gold} />),
        ]}
      </Stack>
      <Text align="center" size={16}>
        {comment}
      </Text>
    </Stack>
  );
};

const Reviews = () => {
  return (
    <Stack direction={{ xs: "column-reverse", md: "row" }} height={{ xs: "auto", md: "50vh" }}>
      <Box width={{ xs: "100%", md: "50%" }}>
        <Image src={reviewsImage} alt="computer mock up" />
      </Box>
      <Box padding={5} bgcolor={colors.cream} gap={3} height="100%" width={{ xs: "100%", md: "50%" }}>
        <Stack height="100%" gap={2} justifyContent="center" alignItems="center">
          <Box maxWidth="500px" width="100%">
            <Carousel
              items={reviews}
              dotColor={colors.purple}
              renderItem={({ item, isSnapPoint }) => (
                <CarouselItem key={item.id} isSnapPoint={isSnapPoint}>
                  <ReviewItem rating={item.rating} comment={item.comment} />
                </CarouselItem>
              )}
            />
          </Box>

          <a href="https://www.reviews.co.uk/company-reviews/store/www.goodmoodprints.io" target="_blank" rel="noreferrer">
            <Stack gap={2} alignItems="center">
              <ReviewsLogo src={reviewsLogo} alt="reviews.io logo" />
              <Text align="center" size={14}>
                {overallRating} star rating
              </Text>
            </Stack>
          </a>
        </Stack>
      </Box>
    </Stack>
  );
};

export default Reviews;
