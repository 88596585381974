import { LazyLoadImage } from "react-lazy-load-image-component";
import styled from "styled-components";
import { Box, Stack } from "@mui/material";
import mockWall from "../../assets/images/mock-ups/pdp-wall.webp";
import portraitLifestyle01 from "../../assets/images/mock-ups/portrait.01.webp";
import portraitLifestyle02 from "../../assets/images/mock-ups/portrait.02.webp";
import portraitLifestyle03 from "../../assets/images/mock-ups/portrait.03.webp";
import landscapeLifestyle01 from "../../assets/images/mock-ups/landscape.01.webp";
import landscapeLifestyle02 from "../../assets/images/mock-ups/landscape.02.webp";
import squareLifestyle from "../../assets/images/mock-ups/square.webp";
import mockPlain from "../../assets/images/mock-ups/pdp-plain.webp";
import { Container, Flex } from "../Layout";
import { Frame, Size, TShirtSize } from "../../types/product";
import { useAppState } from "../../state";
import { colors } from "../../theme";
import DynamicFrameMockUp from "../FrameMockUp/DynamicFrameMockUp";
import FrameMockUp from "../FrameMockUp";
import { Image } from "..";

type Props = {
  image: {
    src: string;
    altText?: string | null;
    height?: number | null;
    width?: number | null;
  };
  secondImage?: {
    src: string;
    altText?: string | null;
    height?: number | null;
    width?: number | null;
  };
  frame: Frame;
  size: Size;
  mounted?: boolean;
};

type MockUpProps = {
  frame?: Frame;
  size: Size;
  mounted?: boolean;
  image: {
    src: string;
    altText?: string | null;
    height?: number | null;
    width?: number | null;
  };
  isMobile: boolean;
};

const Background = styled.img`
  position: relative;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const ProductImage = styled.div`
  position: absolute;
  top: 38%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
`;

export const PlainMockUp = ({
  frame = Frame.Unframed,
  mounted = false,
  image,
  isMobile,
}: Omit<MockUpProps, "size"> & { size?: Size }) => {
  return (
    <Container
      margin={isMobile ? "0" : "0 0 4px"}
      width={100}
      style={{ height: isMobile ? "100%" : "80vh" }}
      background={colors.grey05}
      padding={isMobile ? "0 20px 16px" : "20px"}
    >
      <Stack justifyContent="center" alignItems="center" height="100%" position="relative">
        {isMobile ? (
          <DynamicFrameMockUp frame={frame} mounted={mounted} image={image} scale={isMobile ? window.outerHeight / 450 : 1.6} />
        ) : (
          <FrameMockUp frame={frame} mounted={mounted} image={image} tShirtSize={TShirtSize.Large} resolution={800} contain />
        )}
      </Stack>
    </Container>
  );
};

export const WallMockUp = ({ frame = Frame.Natural, size, mounted = false, image, isMobile }: MockUpProps) => {
  if (!image.height || !image.width) return null;
  const isLandscape = image.height < image.width;
  const style = image.height === image.width ? { top: "36%" } : isLandscape ? { top: "36%", left: "50%" } : {};
  return (
    <Container margin={isMobile ? "0" : "0 0 4px"} width={100} style={{ height: isMobile ? "100%" : "700px" }}>
      <Flex justifyContent="center" style={{ height: "100%" }}>
        <Background src={mockWall} alt="mock wall" />
        <ProductImage style={style}>
          <DynamicFrameMockUp frame={frame} mounted={mounted} image={image} size={size} shadow={frame !== Frame.Unframed} />
        </ProductImage>
      </Flex>
    </Container>
  );
};

export const FixedWallMockUp = ({
  index,
  image,
  secondImage,
  setImageLoaded,
}: {
  index?: number;
  image: {
    src: string;
    altText?: string | null;
    height?: number | null;
    width?: number | null;
  };
  secondImage?: {
    src: string;
    altText?: string | null;
    height?: number | null;
    width?: number | null;
  };
  setImageLoaded: (loaded: boolean) => void;
}) => {
  if (!image.height || !image.width) return null;
  const isLandscape = image.height < image.width;

  const getImageWithStyles = () => {
    const n = (index || 0) + 1;
    if (image.height === image.width)
      return {
        mockupImage: squareLifestyle,
        style: { top: "44.5%", left: "51.8%", width: "46.4%" },
      };
    if (isLandscape) {
      if (n % 4 === 0 || n % 4 === 2) {
        return {
          mockupImage: landscapeLifestyle02,
          style: { top: "30.6%", left: "52.8%", width: "56.4%" },
        };
      }
      return {
        mockupImage: landscapeLifestyle01,
        style: { top: "29.8%", left: "48.7%", width: "49.3%" },
      };
    }
    if (n % 4 === 0)
      return {
        mockupImage: portraitLifestyle03,
        style: { top: "43.8%", left: "54.5%", width: "43%" },
      };
    if (n % 4 === 1 || n % 4 === 3)
      return {
        mockupImage: portraitLifestyle01,
        style: { top: "32.4%", left: "50.8%", width: "34.6%" },
      };
    return {
      mockupImage: portraitLifestyle02,
      style: { top: "38.9%", left: "58%", width: "44%" },
    };
  };

  const { mockupImage, style } = getImageWithStyles();
  const resolutionParam = isLandscape ? "&height=500" : "&width=500";

  return (
    <Stack position="relative" alignItems="center" style={{ height: "100%" }}>
      {secondImage ? (
        <Image src={secondImage.src + "&width=500"} alt={secondImage.altText || "interior mockup"} />
      ) : (
        <>
          <Box height="100%" width="100%" position="absolute" zIndex={2} />
          <Background src={mockupImage} alt="mock wall" onLoad={() => setImageLoaded(true)} />
          <ProductImage style={{ ...style, display: "flex", zIndex: 0 }}>
            <Box bgcolor="rgba(0, 0, 0, 0.08)" height="100%" width="100%" position="absolute" />
            <LazyLoadImage
              src={image.src + resolutionParam}
              alt={image.altText || "interior mockup"}
              width="100%"
              height="100%"
              onLoad={() => setImageLoaded(true)}
            />
          </ProductImage>
        </>
      )}
    </Stack>
  );
};

export const AllFramesMockUp = ({
  mounted = false,
  image,
  isMobile,
}: {
  mounted?: boolean;
  image: {
    src: string;
    altText?: string | null;
    height?: number | null;
    width?: number | null;
  };
  isMobile: boolean;
}) => {
  if (!image.height || !image.width) return null;
  const isLandscape = image.height < image.width;
  const width = isLandscape ? "70%" : "60%";
  const maxWidth = isMobile ? (isLandscape ? "360px" : "260px") : isLandscape ? "560px" : "460px";
  return (
    <Container margin={isMobile ? "0" : "0 0 4px"} width={100} style={{ height: isMobile ? "100%" : "700px" }}>
      <Flex justifyContent="center" style={{ height: "100%" }}>
        <Background src={mockPlain} alt="mock plain" />
        <Stack
          gap={{ xs: 2, md: 4 }}
          width={width}
          maxWidth={maxWidth}
          position="absolute"
          top="50%"
          left="50%"
          zIndex={1}
          sx={{ transform: "translate(-50%, -50%)" }}
        >
          <Stack direction="row" gap={{ xs: 2, md: 4 }}>
            <FrameMockUp frame={Frame.Unframed} image={image} />
            <FrameMockUp frame={Frame.Natural} mounted={mounted} image={image} shadow />
          </Stack>
          <Stack direction="row" gap={{ xs: 2, md: 4 }}>
            <FrameMockUp frame={Frame.Black} mounted={mounted} image={image} shadow />
            <FrameMockUp frame={Frame.White} mounted={mounted} image={image} shadow />
          </Stack>
        </Stack>
      </Flex>
    </Container>
  );
};

const MockUps = ({ image, frame, size, mounted = false }: Props) => {
  const appState = useAppState();
  const { isMobileScreen } = appState;

  return (
    <>
      <PlainMockUp image={image} frame={frame} size={size} mounted={mounted} isMobile={isMobileScreen} />
      <WallMockUp image={image} frame={frame} size={size} mounted={mounted} isMobile={isMobileScreen} />
      <AllFramesMockUp image={image} mounted={mounted} isMobile={isMobileScreen} />
    </>
  );
};

export default MockUps;
