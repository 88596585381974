import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { addMonths, endOfMonth, format, isSameDay, startOfMonth, startOfYear, subMonths } from "date-fns";
import { match } from "ts-pattern";
import { isNumber, sortBy } from "lodash";
import { Box, CircularProgress, IconButton, MenuItem, Select, Stack, Typography } from "@mui/material";
import { ArrowBackIosRounded, ArrowDropDownRounded, ArrowForwardIosRounded, IosShareRounded } from "@mui/icons-material";
import Button from "../../../components/Button";
import { Loader } from "../../../components/Loader";
import { formattedPrice } from "../../../helpers/money";
import { colors, fonts } from "../../../theme";
import { CurrencyCode } from "../../../generated/storefront";
import { ProductStatus, useGetArtistOrdersQuery, useGetArtistProductsQuery } from "../../../generated/graphql";
import { User } from "../../../types/user";
import Switch from "../../../components/Switch";
import { filterVendorItemsInOrders } from "../../../helpers/orders";
import { getOrdersTotals } from "../../../helpers/prices";
import ProductGrid from "./ProductGrid";
import { getPageViews } from "../../../services/API/analytics";
import ShareIcons from "../../../components/ShareIcons";
import ShareBar from "../ShareBar";
import { getProductUploads, ProductUpload } from "../../../services/Firebase/pending-products";
import PendingProductGrid from "./PendingProductGrid";

enum TimeRange {
  Month = "month",
  YearToDate = "year-to-date",
  AllTime = "all-time",
}

export enum Status {
  Live = "live",
  Pending = "pending",
  Sold = "sold",
  Archived = "archived",
}

const Dashboard = ({ isMobileScreen, user }: { isMobileScreen: boolean; user: User }) => {
  const [selectedStatus, setSelectedStatus] = useState(Status.Live);
  const [selectedTimeRange, setSelectedTimeRange] = useState(TimeRange.Month);
  const [startDate, setStartDate] = useState(format(startOfMonth(new Date()), "yyyy-MM-dd"));
  const [endDate, setEndDate] = useState("");
  const [month, setMonth] = useState(startOfMonth(new Date()));
  const [timeRangeQuery, setTimeRangeQuery] = useState("");
  const [shareBarOpen, setShareBarOpen] = useState(false);
  const [artistProfilePageViews, setArtistProfilePageViews] = useState<number | undefined>(undefined);
  const [loadingViews, setLoadingViews] = useState(false);
  const [pendingProducts, setPendingProducts] = useState<ProductUpload[]>([]);
  const ref = useRef<HTMLDivElement>(null);
  const {
    data: productData,
    loading: loadingProducts,
    refetch,
  } = useGetArtistProductsQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      query: `vendor:${user.id}`,
      limit: 250,
    },
  });
  const products = sortBy(productData?.products?.nodes, (product) => product.artistPosition?.value);
  const { data, loading, fetchMore } = useGetArtistOrdersQuery({
    variables: {
      query: user.id + timeRangeQuery + " NOT tag:Sample AND NOT tag:B2B",
    },
  });
  const hasMoreOrders = data?.orders?.pageInfo?.hasNextPage;
  const afterCursor = data?.orders?.pageInfo?.endCursor;
  const loadingOrders = hasMoreOrders || loading;
  const artistOrders = useMemo(
    () => (data?.orders ? filterVendorItemsInOrders(data.orders.nodes, user.id) : []),
    [data, user.id]
  );
  const profit = useMemo(() => artistOrders && getOrdersTotals(artistOrders).commissionTotal, [artistOrders]);

  const goToPreviousMonth = () => {
    const prevMonth = subMonths(month, 1);
    setMonth(prevMonth);
  };

  const goToNextMonth = () => {
    const nextMonth = addMonths(month, 1);
    setMonth(nextMonth);
  };

  const onTimeRangeChange = useCallback(async () => {
    const timeRangeQuery = match(selectedTimeRange)
      .with(
        TimeRange.Month,
        () => ` created_at:>=${format(month, "yyyy-MM-dd")} created_at:<=${format(endOfMonth(month), "yyyy-MM-dd")}`
      )
      .with(TimeRange.YearToDate, () => ` created_at:>=${format(startOfYear(new Date()), "yyyy-MM-dd")}`)
      .with(TimeRange.AllTime, () => "")
      .exhaustive();
    setTimeRangeQuery(timeRangeQuery);

    if (selectedTimeRange === TimeRange.Month) {
      setStartDate(format(month, "yyyy-MM-dd"));
      setEndDate(format(addMonths(month, 1), "yyyy-MM-dd"));
    }

    if (selectedTimeRange === TimeRange.YearToDate) {
      setStartDate(format(startOfYear(new Date()), "yyyy-MM-dd"));
      setEndDate("");
    }

    if (selectedTimeRange === TimeRange.AllTime) {
      setStartDate("");
      setEndDate("");
    }

    if (selectedTimeRange !== TimeRange.Month && !isSameDay(month, startOfMonth(new Date()))) {
      setMonth(startOfMonth(new Date()));
    }
  }, [selectedTimeRange, month]);

  const fetchPageViews = useCallback(async () => {
    setLoadingViews(true);
    const { data } = await getPageViews({
      page: "artists",
      id: user.id,
      handle: user.permalink,
      startDate,
      endDate,
    });
    setArtistProfilePageViews(data?.pageViews || 0);
    setLoadingViews(false);
  }, [endDate, startDate, user.id, user.permalink]);

  const getArtistPendingUploads = async () => {
    const productUploads = await getProductUploads(user.id);
    setPendingProducts(productUploads);
  };

  useEffect(() => {
    getArtistPendingUploads();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.id]);

  useEffect(() => {
    fetchPageViews();
  }, [fetchPageViews]);

  useEffect(() => {
    onTimeRangeChange();
  }, [onTimeRangeChange]);

  useEffect(() => {
    if (hasMoreOrders && afterCursor) {
      fetchMore({
        variables: {
          afterCursor,
        },
      });
    }
  }, [afterCursor, fetchMore, hasMoreOrders, user.id, timeRangeQuery]);

  const timeRanges = [
    {
      label: "Month",
      value: "month",
    },
    {
      label: "Year to date",
      value: "year-to-date",
    },
    {
      label: "All time",
      value: "all-time",
    },
  ];

  return (
    <Stack ref={ref} gap={2} paddingTop={2} paddingLeft={{ xs: 2, md: 0 }} paddingRight={{ xs: 2, md: 5 }} paddingBottom={12}>
      <Typography variant="h2" display={{ xs: "none", md: "flex" }}>
        Dashboard
      </Typography>
      <Stack direction="row" justifyContent="space-between">
        <Stack direction="row" gap={1} alignItems="center">
          <IconButton
            onClick={goToPreviousMonth}
            disabled={selectedTimeRange !== TimeRange.Month}
            size="small"
            color="primary"
            style={{ backgroundColor: colors.grey02, fontSize: 12 }}
          >
            <ArrowBackIosRounded fontSize="inherit" />
          </IconButton>
          <Select
            variant="filled"
            renderValue={(selected) =>
              selected === TimeRange.Month ? format(month, "MMMM") : timeRanges.find((o) => o.value === selected)?.label
            }
            onChange={(e) => setSelectedTimeRange(e.target.value as TimeRange)}
            value={selectedTimeRange}
            IconComponent={ArrowDropDownRounded}
            style={{ minWidth: 125 }}
          >
            {timeRanges.map((timeRange) => (
              <MenuItem key={timeRange.value} value={timeRange.value}>
                {timeRange.label}
              </MenuItem>
            ))}
          </Select>
          <IconButton
            onClick={goToNextMonth}
            disabled={selectedTimeRange !== TimeRange.Month || isSameDay(month, startOfMonth(new Date()))}
            size="small"
            color="primary"
            style={{ backgroundColor: colors.grey02, fontSize: 12 }}
          >
            <ArrowForwardIosRounded fontSize="inherit" />
          </IconButton>
        </Stack>
        <Stack direction="row" gap={1} display={{ xs: "none", md: "flex" }}>
          <Link to={`/artists/${user.permalink || user.id}`} target="_blank">
            <Button secondary size="medium">
              View profile
            </Button>
          </Link>
          <Link to="/sell">
            <Button>Upload</Button>
          </Link>
        </Stack>
      </Stack>
      <Stack spacing={3}>
        <Stack direction="row" gap={{ xs: 1, md: 2 }}>
          <Stack
            gap={1}
            justifyContent="space-between"
            height={{ xs: 100, md: 120 }}
            border={{ xs: 0, md: `1px solid ${colors.grey10}` }}
            bgcolor={{ xs: colors.cardGrey, md: colors.white }}
            borderRadius={4}
            padding={2}
            flex={1}
          >
            <Typography fontSize={{ xs: 12, md: 16 }}>Profit</Typography>
            {isNumber(profit) && !loadingOrders ? (
              <Typography fontSize={{ xs: 20, md: 40 }} fontWeight={600} fontFamily={fonts.banner} letterSpacing={-1}>
                {formattedPrice(profit, CurrencyCode.Gbp)}
              </Typography>
            ) : (
              <Stack width={20}>
                <CircularProgress size="small" />
              </Stack>
            )}
          </Stack>

          <Stack
            gap={1}
            justifyContent="space-between"
            height={{ xs: 100, md: 120 }}
            border={{ xs: 0, md: `1px solid ${colors.grey10}` }}
            bgcolor={{ xs: colors.cardGrey, md: colors.white }}
            borderRadius={4}
            padding={2}
            flex={1}
          >
            <Typography fontSize={{ xs: 12, md: 16 }}>Sales</Typography>
            {artistOrders && !loadingOrders ? (
              <Typography fontSize={{ xs: 20, md: 40 }} fontWeight={600} fontFamily={fonts.banner} letterSpacing={-1}>
                {artistOrders.flatMap((order) => order.lineItems.nodes).length}
              </Typography>
            ) : (
              <Stack width={20}>
                <CircularProgress size="small" />
              </Stack>
            )}
          </Stack>

          <Stack
            gap={1}
            justifyContent="space-between"
            height={{ xs: 100, md: 120 }}
            border={{ xs: 0, md: `1px solid ${colors.grey10}` }}
            bgcolor={{ xs: colors.cardGrey, md: colors.white }}
            borderRadius={4}
            padding={2}
            flex={1}
          >
            <Typography fontSize={{ xs: 12, md: 16 }}>Profile views</Typography>
            <Stack direction="row" gap={2} alignItems="center" justifyContent="space-between">
              {artistProfilePageViews !== undefined && !loadingViews ? (
                <Typography fontSize={{ xs: 20, md: 40 }} fontWeight={600} fontFamily={fonts.banner} letterSpacing={-1}>
                  {artistProfilePageViews}
                </Typography>
              ) : (
                <Stack width={20}>
                  <CircularProgress size="small" />
                </Stack>
              )}
              {isMobileScreen ? (
                <IosShareRounded onClick={() => setShareBarOpen(true)} fontSize="small" />
              ) : (
                <ShareIcons
                  text="Check out my shop on GoodMood"
                  pageUrl={`https://www.goodmoodprints.com/artists/${user.permalink || user.id}`}
                  isMobileScreen={isMobileScreen}
                />
              )}
            </Stack>
          </Stack>
        </Stack>

        {loadingProducts ? (
          <Loader />
        ) : products?.length ? (
          <>
            <Box border={{ xs: 0, md: `1px solid ${colors.grey10}` }} borderRadius={{ xs: 0, md: 4 }} overflow="hidden">
              <Box paddingX={{ xs: 0, md: 2 }} paddingTop={{ xs: 0, md: 2 }} paddingBottom={2} maxWidth={{ xs: "none", md: 360 }}>
                <Switch
                  options={[
                    {
                      label: "Live",
                      value: Status.Live,
                    },
                    {
                      label: "Pending",
                      value: Status.Pending,
                    },
                    {
                      label: "Sold",
                      value: Status.Sold,
                    },
                    {
                      label: "Archived",
                      value: Status.Archived,
                    },
                  ]}
                  fullWidth
                  selected={selectedStatus}
                  onChange={(value) => setSelectedStatus(value as Status)}
                />
              </Box>

              {selectedStatus === "pending" ? (
                <PendingProductGrid products={pendingProducts} isMobileScreen={isMobileScreen} />
              ) : (
                <ProductGrid
                  isMobileScreen={isMobileScreen}
                  products={products.filter((product) => {
                    switch (selectedStatus) {
                      case "live":
                        return product.status !== ProductStatus.Archived;
                      case "sold":
                        return artistOrders
                          ?.flatMap((order) => order.lineItems.nodes)
                          .some((lineItem) => lineItem.product?.id === product.id);
                      case "archived":
                        return product.status === ProductStatus.Archived;
                      default:
                        return false;
                    }
                  })}
                  selectedStatus={selectedStatus}
                  refetchProducts={refetch}
                  artistOrders={artistOrders}
                  loading={loadingOrders}
                  startDate={startDate}
                  endDate={endDate}
                />
              )}
            </Box>
            {loadingProducts && <Loader />}
          </>
        ) : (
          <Box padding={4} border={`1px solid ${colors.grey20}`} borderRadius={4}>
            <Stack gap={2} justifyContent="center" alignItems="center" width="100%">
              <Typography variant="h2" align="center">
                Start selling
              </Typography>
              <Stack>
                <Typography align="center">There are no upfront costs to submit your work.</Typography>
                <Typography align="center">Just simply upload your files and sell your way.</Typography>
              </Stack>
              <Link to="/sell">
                <Button>Upload your first piece</Button>
              </Link>
            </Stack>
          </Box>
        )}
      </Stack>
      {shareBarOpen && (
        <ShareBar
          setShareBarOpen={setShareBarOpen}
          pageUrl={`https://www.goodmoodprints.com/artists/${user.permalink || user.id}`}
          text="Check out my shop on GoodMood"
          isMobileScreen={isMobileScreen}
        />
      )}
    </Stack>
  );
};

export default Dashboard;
