import styled from "styled-components";
import { Stack, Typography } from "@mui/material";
import { colors } from "../../theme";
import { media } from "../../helpers/layout";

type Props = {
  options: {
    label: string;
    value: string;
  }[];
  selected: string;
  onChange: (value: string) => void;
  fullWidth?: boolean;
};

const Wrapper = styled.div<{ fullWidth?: boolean }>`
  width: ${(p) => (p.fullWidth ? "100%" : "min-content")};
  overflow: hidden;
  background: ${colors.cardGrey};
  border-radius: 100px;
  position: relative;
  min-width: 180px;
  cursor: pointer;

  @media ${media.m} {
    min-width: 160px;
  }
`;

const SwitchToggle = styled.div<{ width: number; position: number }>`
  position: absolute;
  width: ${({ width }) => `${width}%`};
  height: 100%;
  pointer-events: none;
  ${({ position }) => `left: ${position}%; transform: translateX(-${position}%);`}
  transition: left 0.2s ease-out;
`;

const Toggle = styled.div`
  background: ${colors.cream};
  width: calc(100% - 4px);
  height: calc(100% - 4px);
  margin: 2px;
  border-radius: 100px;
`;

const SwitchItem = styled.div`
  padding: 12px 16px;
  z-index: 1;
  flex: 1;
`;

const Switch = ({ options, selected, onChange, fullWidth }: Props) => {
  const selectedIndex = options.findIndex((o) => o.value === selected);

  return (
    <Wrapper fullWidth={fullWidth}>
      <SwitchToggle width={100 / options.length} position={(selectedIndex / (options.length - 1)) * 100}>
        <Toggle />
      </SwitchToggle>
      <Stack direction="row">
        {options.map(({ label, value }) => (
          <SwitchItem key={value} onClick={() => onChange(value)}>
            <Typography variant="body1" fontSize={12} align="center" sx={{ whiteSpace: "nowrap" }}>
              {label}
            </Typography>
          </SwitchItem>
        ))}
      </Stack>
    </Wrapper>
  );
};

export default Switch;
