import { SyntheticEvent, useMemo, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { match } from "ts-pattern";
import { capitalize, debounce } from "lodash";
import { Autocomplete, Box, IconButton, InputAdornment, MenuItem, Select, Stack, TextField, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { ArrowBackRounded, DeleteRounded, KeyboardArrowDownRounded, Search } from "@mui/icons-material";
import {
  CurrencyCode,
  CustomerFragment,
  DraftOrderStatus,
  MailingAddressInput,
  useCompleteDraftOrderMutation,
  useGetCustomersLazyQuery,
  useGetDraftOrderQuery,
  useSendDraftOrderInvoiceMutation,
  useUpdateDraftOrderMutation,
} from "../../../../generated/graphql";
import Button from "../../../../components/Button";
import { Loader } from "../../../../components/Loader";
import { dataGridStyles } from "../../styles";
import AddOrUpdateItemModal, { ArtworkItem, CustomItem } from "./AddOrUpdateItemModal";
import { formattedPrice } from "../../../../helpers/money";
import { getArtworkLineItems, getCustomLineItems, getExVatPrice, getLineItemInput } from "./helpers";
import { colors, fonts } from "../../../../theme";
import TextLink from "../../../../components/TextLink";
import EditShippingModal from "./EditShippingModal";
import CreateXeroInvoiceModal from "./CreateXeroInvoiceModal";
import EditShippingAddressModal from "./EditShippingAddressModal";
import { sizings } from "../../../../state/constants";

export type CustomAttributes = {
  _commissionValue: string;
  _commissionType: string;
  _artistId?: string;
  _artistName?: string;
  _artworkUrl?: string;
  _sizing?: string;
  frame?: string;
};

type CustomAttributeKeys = keyof CustomAttributes;

enum ShippingMethod {
  Express = "Express",
  Standard = "Standard",
  Budget = "Budget",
}

const Order = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [addOrUpdateItemModalOpen, setAddOrUpdateItemModalOpen] = useState(false);
  const [editShippingModalOpen, setEditShippingModalOpen] = useState(false);
  const [editShippingAddressModalOpen, setEditShippingAddressModalOpen] = useState(false);
  const [createXeroInvoiceModalOpen, setCreateXeroInvoiceModalOpen] = useState(false);
  const [updateDraftOrder, { loading: updating }] = useUpdateDraftOrderMutation();
  const [sendDraftOrderInvoice, { loading: sending }] = useSendDraftOrderInvoiceMutation();
  const [completeDraftOrder, { loading: completing }] = useCompleteDraftOrderMutation();
  const [editItemId, setEditItemId] = useState<string | null>(null);
  const { data, loading, refetch } = useGetDraftOrderQuery({ variables: { id: id ? `gid://shopify/DraftOrder/${id}` : "" } });
  const order = data?.draftOrder;
  const [getCustomers, { data: customersData, loading: loadingCustomers }] = useGetCustomersLazyQuery();
  const customers = customersData?.customers.nodes || [];
  const isSampleOrder = order?.tags.includes("Sample");
  const isComplete = order?.status === DraftOrderStatus.Completed;
  const shippingMethod = useMemo(
    () =>
      order?.customAttributes.find((attr) => attr.key === "_shippingMethod")?.value ||
      (isSampleOrder ? ShippingMethod.Budget : ShippingMethod.Standard),
    [order?.customAttributes, isSampleOrder]
  );

  const rows =
    order?.lineItems.nodes.map((item) => {
      const customAttributes = item.customAttributes.reduce((acc, attr) => {
        acc[attr.key as CustomAttributeKeys] = attr.value;
        return acc;
      }, {} as CustomAttributes);
      const prodigiAttributes = item.customAttributes.filter((attr) => !attr.key.startsWith("_"));
      return {
        id: item.id,
        product: item.variant?.product.title || item.title,
        image: item.variant ? item.variant?.product.images.nodes[0].src : customAttributes._artworkUrl,
        artist: item.variant ? item.variant.product.artistName.value : customAttributes._artistName,
        sku:
          (item.sku || "") +
          (prodigiAttributes.length ? ` (${prodigiAttributes.map((attr) => capitalize(attr.value)).join(", ")})` : ""),
        sizing: sizings.find((sizing) => sizing.value === customAttributes._sizing)?.label || "",
        quantity: item.quantity,
        unitPrice: formattedPrice(
          getExVatPrice(
            item.originalUnitPriceSet.presentmentMoney.amount,
            item.originalUnitPriceSet.presentmentMoney.currencyCode
          ),
          item.originalUnitPriceSet.presentmentMoney.currencyCode
        ),
        totalPrice: formattedPrice(
          getExVatPrice(item.originalTotalSet.presentmentMoney.amount, item.originalTotalSet.presentmentMoney.currencyCode),
          item.originalTotalSet.presentmentMoney.currencyCode
        ),
        commission:
          customAttributes._commissionValue && customAttributes._commissionType
            ? customAttributes._commissionValue + " " + (customAttributes._commissionType === "pct" ? "%" : "£")
            : null,
      };
    }) || [];

  const onAddOrUpdateItem = async (item: ArtworkItem | CustomItem, editItemId?: string | null) => {
    if (!order) return;
    const existingLineItems = order.lineItems.nodes.map((lineItem) => {
      if (lineItem.id === editItemId) {
        const lineItems = match(item)
          .with({ itemType: "artwork" }, (i) => getArtworkLineItems([i], order.presentmentCurrencyCode))
          .with({ itemType: "custom" }, (i) => getCustomLineItems([i], order.presentmentCurrencyCode))
          .exhaustive();
        return lineItems[0];
      } else {
        return getLineItemInput(lineItem);
      }
    });
    const lineItems = editItemId
      ? []
      : match(item)
          .with({ itemType: "artwork" }, (i) => getArtworkLineItems([i], order.presentmentCurrencyCode))
          .with({ itemType: "custom" }, (i) => getCustomLineItems([i], order.presentmentCurrencyCode))
          .exhaustive();

    const response = await updateDraftOrder({
      variables: {
        id: order.id,
        input: {
          lineItems: [...existingLineItems, ...lineItems],
        },
      },
    });
    if (response.data?.draftOrderUpdate?.userErrors.length) {
      console.error(response.data.draftOrderUpdate.userErrors);
      alert("Error adding item - " + response.data.draftOrderUpdate.userErrors[0].message);
    } else {
      setAddOrUpdateItemModalOpen(false);
      await refetch();
    }
  };

  const onRemoveItem = async (lineItemId: string) => {
    if (!order) return;

    const lineItems = order.lineItems.nodes.filter((item) => item.id !== lineItemId).map((item) => getLineItemInput(item));

    await updateDraftOrder({
      variables: {
        id: order.id,
        input: {
          lineItems,
        },
      },
    });
    await refetch();
  };

  const handleShippingUpdate = async (rate: string, title: string) => {
    if (!order) return;
    const taxable = order.presentmentCurrencyCode === CurrencyCode.Gbp;
    const rateIncVat = taxable ? (Number(rate) * 1.2).toFixed(2) : rate;

    await updateDraftOrder({
      variables: {
        id: order.id,
        input: {
          shippingLine: {
            title,
            priceWithCurrency: {
              amount: rateIncVat,
              currencyCode: order.presentmentCurrencyCode,
            },
          },
        },
      },
    });
    await refetch();
  };

  const handleShippingAddressUpdate = async (shippingAddress: MailingAddressInput, email: string) => {
    if (!order) return;
    await updateDraftOrder({
      variables: {
        id: order.id,
        input: {
          shippingAddress,
          customAttributes: [
            ...order.customAttributes.map((attr) => ({
              key: attr.key,
              value: attr.value,
            })),
            {
              key: "_shippingEmail",
              value: email,
            },
          ],
        },
      },
    });
    await refetch();
  };

  const handleShippingMethodUpdate = async (method: ShippingMethod) => {
    if (!order) return;
    await updateDraftOrder({
      variables: {
        id: order.id,
        input: {
          customAttributes: [
            ...order.customAttributes.map((attr) => ({
              key: attr.key,
              value: attr.value,
            })),
            {
              key: "_shippingMethod",
              value: method,
            },
          ],
        },
      },
    });
    await refetch();
  };

  const onSelectCustomer = async (
    _: SyntheticEvent<Element, Event>,
    value: {
      value: string;
      label: string;
    } | null
  ) => {
    if (!order) return;
    const customer = customers.find((customer) => customer.id === value?.value);
    const shippingAddress =
      !order.shippingAddress && customer?.defaultAddress
        ? {
            shippingAddress: {
              address1: customer.defaultAddress.address1,
              address2: customer.defaultAddress.address2,
              city: customer.defaultAddress.city,
              country: customer.defaultAddress.countryCodeV2,
              zip: customer.defaultAddress.zip,
              firstName: customer.firstName,
              lastName: customer.lastName,
            },
          }
        : {};
    await updateDraftOrder({
      variables: {
        id: order.id,
        input: {
          customerId: value?.value,
          ...shippingAddress,
        },
      },
    });
  };

  const onSendInvoiceEmail = async () => {
    if (!order) return;
    await sendDraftOrderInvoice({ variables: { id: order.id } });
  };

  if (loading) {
    return (
      <Stack width="100%" alignItems="center" padding={5}>
        <Loader />
      </Stack>
    );
  }

  if (!order) {
    return (
      <Stack width="100%" alignItems="center" padding={5}>
        <Typography>Order not found</Typography>
      </Stack>
    );
  }

  const onComplete = async () => {
    if (!order) return;
    await completeDraftOrder({
      variables: {
        id: order.id,
      },
    });
    navigate("/admin?tab=orders");
  };

  const orderName = order.customAttributes.find((attr) => attr.key === "name")?.value;
  const itemCount = order.lineItems.nodes.reduce((acc, item) => acc + item.quantity, 0);

  const customerLabel = (customer?: CustomerFragment | null) => {
    if (!customer) return "";

    if (customer.defaultAddress) {
      return customer.displayName + ", " + customer.defaultAddress.formattedArea + ", " + customer.email;
    } else {
      return customer.displayName + ", " + customer.email;
    }
  };

  const onEditItem = (itemId: string) => {
    setEditItemId(itemId);
    setAddOrUpdateItemModalOpen(true);
  };

  return (
    <Stack gap={2} padding={{ xs: 2, md: 5 }}>
      <Stack direction="row" alignItems="center" gap={2} justifyContent="space-between">
        <Stack direction="row" alignItems="center" gap={2}>
          <Link to="/admin?tab=orders&orderType=draft_orders">
            <IconButton>
              <ArrowBackRounded color="primary" />
            </IconButton>
          </Link>
          <Typography variant="h2">{orderName}</Typography>
          <Typography fontFamily={fonts.body} color={colors.grey60}>
            {itemCount} {itemCount === 1 ? "item" : "items"}
          </Typography>
        </Stack>
        {match(order.status)
          .with(DraftOrderStatus.Open, () => (
            <Box bgcolor={colors.blue} px={2} py={0.5} borderRadius={8}>
              <Typography fontFamily={fonts.body}>Open</Typography>
            </Box>
          ))
          .with(DraftOrderStatus.InvoiceSent, () => (
            <Box bgcolor={colors.peach} px={2} py={0.5} borderRadius={8}>
              <Typography fontFamily={fonts.body}>Invoice sent</Typography>
            </Box>
          ))
          .with(DraftOrderStatus.Completed, () => (
            <Box bgcolor={colors.mint} px={2} py={0.5} borderRadius={8}>
              <Typography fontFamily={fonts.body}>Completed</Typography>
            </Box>
          ))
          .exhaustive()}
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="space-between" gap={2}>
        <Stack>
          {!isComplete && (
            <Button secondary onClick={() => setAddOrUpdateItemModalOpen(true)}>
              Add item
            </Button>
          )}
        </Stack>

        {!isSampleOrder && (
          <Stack direction="row" alignItems="center" gap={2}>
            <Button secondary onClick={onSendInvoiceEmail} loading={sending}>
              Send Shopify invoice email
            </Button>
            <Button secondary onClick={() => setCreateXeroInvoiceModalOpen(true)}>
              Create Xero invoice
            </Button>
          </Stack>
        )}
      </Stack>

      <DataGrid
        rows={rows}
        columns={[
          {
            field: "product",
            headerName: "Product",
            flex: 2,
            renderCell: (params) => (
              <Stack direction="row" alignItems="center" gap={1} p={0.5} height="100%">
                {params.row.image ? <img src={params.row.image} height="100%" width="auto" alt={params.row.product} /> : null}
                <Typography fontFamily={fonts.body} fontSize={14}>
                  {params.row.product}
                </Typography>
              </Stack>
            ),
          },
          { field: "artist", headerName: "Artist", flex: 2 },
          { field: "sku", headerName: "SKU", flex: 3 },
          { field: "sizing", headerName: "Sizing", flex: 1 },
          { field: "quantity", headerName: "Quantity", flex: 1 },
          { field: "unitPrice", headerName: "Unit Price (ex. VAT)", flex: 1 },
          { field: "totalPrice", headerName: "Total Price (ex. VAT)", flex: 1 },
          { field: "commission", headerName: "Commission", flex: 1 },
          {
            field: "actions",
            headerName: "",
            flex: 1,
            align: "right",
            renderCell: (params) =>
              !isComplete && (
                <Stack direction="row" gap={2} alignItems="center">
                  <TextLink onClick={() => onEditItem(params.row.id)}>
                    <Typography fontFamily={fonts.body} fontSize={14}>
                      Edit
                    </Typography>
                  </TextLink>
                  <IconButton size="small" onClick={() => onRemoveItem(params.row.id)}>
                    <DeleteRounded color="action" fontSize="small" />
                  </IconButton>
                </Stack>
              ),
          },
        ]}
        hideFooter
        sx={{ ...dataGridStyles, minHeight: 200 }}
      />

      <Stack
        gap={1}
        p={2}
        border={`1px solid ${colors.grey20}`}
        borderRadius={4}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography fontFamily={fonts.body} fontSize={14} fontWeight={700}>
          Subtotal (ex. VAT)
        </Typography>
        <Typography fontFamily={fonts.body} fontSize={14} fontWeight={700}>
          {formattedPrice(
            getExVatPrice(order.subtotalPriceSet.presentmentMoney.amount, order.subtotalPriceSet.presentmentMoney.currencyCode),
            order.subtotalPriceSet.presentmentMoney.currencyCode
          )}
        </Typography>
      </Stack>

      <Stack gap={0.5}>
        <Typography fontFamily={fonts.body} fontSize={14}>
          Shopify Customer (optional)
        </Typography>
        <Autocomplete
          loading={loadingCustomers}
          options={
            customers?.map((customer) => ({
              value: customer.id,
              label: customerLabel(customer),
            })) || []
          }
          onChange={onSelectCustomer}
          popupIcon={<KeyboardArrowDownRounded />}
          defaultValue={{
            label: customerLabel(order.customer),
            value: order.customer?.id || "",
          }}
          disabled={isComplete}
          renderInput={(params) => (
            <TextField
              {...params}
              style={{ zIndex: 0 }}
              placeholder="Search customer"
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <InputAdornment position="start">
                    <Search fontSize="small" />
                  </InputAdornment>
                ),
              }}
              onChange={debounce(({ target }) => getCustomers({ variables: { limit: 5, query: target.value } }), 1000)}
              value={
                order.customer?.displayName +
                ", " +
                order.customer?.defaultAddress.city +
                ", " +
                order.customer?.defaultAddress.countryCodeV2 +
                ", " +
                order.customer?.defaultAddress.zip +
                ", " +
                order.customer?.email
              }
            />
          )}
        />
      </Stack>

      <Stack gap={0.5}>
        <Typography fontFamily={fonts.body} fontSize={14}>
          Shipping Method
        </Typography>
        <Select
          fullWidth
          value={shippingMethod}
          onChange={(e) => handleShippingMethodUpdate(e.target.value as ShippingMethod)}
          IconComponent={KeyboardArrowDownRounded}
          disabled={isComplete}
        >
          <MenuItem value={ShippingMethod.Budget}>{ShippingMethod.Budget}</MenuItem>
          <MenuItem value={ShippingMethod.Standard}>{ShippingMethod.Standard}</MenuItem>
          <MenuItem value={ShippingMethod.Express}>{ShippingMethod.Express}</MenuItem>
        </Select>
      </Stack>

      <Stack
        gap={1}
        p={2}
        border={`1px solid ${colors.grey20}`}
        borderRadius={4}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography fontFamily={fonts.body} fontSize={14} fontWeight={700}>
          Shipping address
        </Typography>
        {isComplete ? (
          <Typography fontFamily={fonts.body} fontSize={14}>
            {order.shippingAddress?.formatted?.join(", ") || "Edit"}
          </Typography>
        ) : (
          <TextLink onClick={() => setEditShippingAddressModalOpen(true)}>
            <Typography fontFamily={fonts.body} fontSize={14}>
              {order.shippingAddress?.formatted?.join(", ") || "Edit"}
            </Typography>
          </TextLink>
        )}
      </Stack>

      <Stack
        gap={1}
        p={2}
        border={`1px solid ${colors.grey20}`}
        borderRadius={4}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography fontFamily={fonts.body} fontSize={14} fontWeight={700}>
          {order.shippingLine?.title || "Shipping"} (ex. VAT)
        </Typography>
        {isComplete ? (
          <Typography fontFamily={fonts.body} fontSize={14}>
            {formattedPrice(
              order.shippingLine?.discountedPriceSet.presentmentMoney.amount || 0,
              order.shippingLine?.discountedPriceSet.presentmentMoney.currencyCode || order.presentmentCurrencyCode
            )}
          </Typography>
        ) : (
          <TextLink onClick={() => setEditShippingModalOpen(true)}>
            <Typography fontFamily={fonts.body} fontSize={14}>
              {formattedPrice(
                getExVatPrice(
                  order.shippingLine?.discountedPriceSet.presentmentMoney.amount || "0",
                  order.shippingLine?.discountedPriceSet.presentmentMoney.currencyCode || order.presentmentCurrencyCode
                ),
                order.shippingLine?.discountedPriceSet.presentmentMoney.currencyCode || order.presentmentCurrencyCode
              )}
            </Typography>
          </TextLink>
        )}
      </Stack>

      <Stack
        gap={1}
        p={2}
        border={`1px solid ${colors.grey20}`}
        borderRadius={4}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography fontFamily={fonts.body} fontSize={14} fontWeight={700}>
          Total (ex. VAT)
        </Typography>
        <Typography fontFamily={fonts.body} fontSize={14} fontWeight={700}>
          {formattedPrice(
            getExVatPrice(order.totalPriceSet.presentmentMoney.amount, order.totalPriceSet.presentmentMoney.currencyCode),
            order.totalPriceSet.presentmentMoney.currencyCode
          )}
        </Typography>
      </Stack>

      {order.status !== DraftOrderStatus.Completed && (
        <Stack direction="row" justifyContent="end" marginTop={2}>
          <Button onClick={onComplete} loading={completing}>
            Complete order
          </Button>
        </Stack>
      )}

      {addOrUpdateItemModalOpen && (
        <AddOrUpdateItemModal
          currency={order.presentmentCurrencyCode}
          order={order}
          editItemId={editItemId}
          handleAddItem={onAddOrUpdateItem}
          loading={updating}
          onClose={() => setAddOrUpdateItemModalOpen(false)}
          isSampleOrder={order.tags.includes("Sample")}
        />
      )}
      {editShippingAddressModalOpen && (
        <EditShippingAddressModal
          shippingEmail={order.email}
          shippingAddress={order.shippingAddress}
          handleShippingAddressUpdate={handleShippingAddressUpdate}
          loading={updating}
          onClose={() => setEditShippingAddressModalOpen(false)}
        />
      )}
      {editShippingModalOpen && (
        <EditShippingModal
          currency={order.presentmentCurrencyCode}
          handleShippingUpdate={handleShippingUpdate}
          loading={updating}
          onClose={() => setEditShippingModalOpen(false)}
        />
      )}
      {createXeroInvoiceModalOpen && (
        <CreateXeroInvoiceModal order={order} onClose={() => setCreateXeroInvoiceModalOpen(false)} />
      )}
    </Stack>
  );
};

export default Order;
