import axios, { AxiosResponse } from "axios";
export * from "./localisation";
export * from "./products";
export * from "./images";

export type PageInfo = {
  hasNextPage: boolean;
  endCursor?: string;
};

export const requestHandler = async (fn: () => Promise<AxiosResponse>): Promise<any> => {
  let retries = 0;
  let result: any;

  const request = async () => {
    retries++;
    result = await fn();
    if (retries > 0) {
      await new Promise((resolve) => setTimeout(resolve, 2000));
    }
  };

  try {
    while (
      retries === 0 ||
      (result.data.errors?.graphQLErrors?.length && result.data.errors.graphQLErrors[0].message === "Throttled" && retries < 5)
    ) {
      await request();
    }
    if (result.data.errors) {
      throw new Error(`Error: ${result.data.errors.graphQLErrors[0].message}`);
    }
    return result.data;
  } catch (error) {
    throw error;
  }
};

type CreatePayoutProps = {
  email: string;
  amount: number;
  month: string;
  invoiceNumber: number;
  currency?: string;
};

export const createPayout = async (payoutProps: CreatePayoutProps) => {
  try {
    await axios.post("/.netlify/functions/createPayout", payoutProps);
  } catch (error) {
    console.log({ error });
    throw error;
  }
};
