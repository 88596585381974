import { endOfMonth, startOfMonth } from "date-fns";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  endAt,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  startAt,
  Timestamp,
  updateDoc,
  where,
} from "firebase/firestore";
import { db } from ".";
import { Invoice, InvoiceInput } from "../../types/invoice";

export const getInvoices = async (month: Date, qs: string = "") => {
  try {
    const invoicesRef = collection(db, "invoices");
    const capitiliasedQs = qs.charAt(0).toUpperCase() + qs.slice(1);
    const searchQuery = [orderBy("artist"), startAt(capitiliasedQs), endAt(capitiliasedQs + "\uf8ff")];
    const whereQuery = [
      where("issueDate", ">=", startOfMonth(month)),
      where("issueDate", "<=", endOfMonth(month)),
      orderBy("issueDate", "desc"),
      orderBy("amount", "desc"),
    ];
    const invoices = query(invoicesRef, ...(qs ? searchQuery : whereQuery), limit(500));
    const documentSnapshots = await getDocs(invoices);
    const docs: Invoice[] = [];
    documentSnapshots.forEach((doc) => {
      docs.push({ id: doc.id, ...doc.data() } as Invoice);
    });
    return docs;
  } catch (error) {
    console.error("Error getting invoices: ", error);
    throw error;
  }
};

export const getInvoiceById = async (invoiceId: string) => {
  try {
    const invoiceRef = doc(db, "invoices", invoiceId);
    const docSnap = await getDoc(invoiceRef);
    return { id: docSnap.id, ...docSnap.data() } as Invoice;
  } catch (error) {
    console.error("Error getting invoice document: ", error);
    throw error;
  }
};

export const getInvoiceNumber = async () => {
  try {
    const invoicesRef = collection(db, "invoices");
    const invoices = query(invoicesRef, orderBy("invoiceNumber", "desc"), limit(1));
    const documentSnapshots = await getDocs(invoices);
    let lastInvoiceNumber = 1;
    documentSnapshots.forEach((d) => (lastInvoiceNumber = d.data().invoiceNumber));
    return lastInvoiceNumber + 1;
  } catch (error) {
    console.error("Error getting invoice number: ", error);
    throw error;
  }
};

export const createOrUpdateInvoice = async (invoice: InvoiceInput) => {
  try {
    const { date, artistId } = invoice;
    const issueDate = endOfMonth(date);
    const invoicesRef = collection(db, "invoices");
    const whereQuery = [where("issueDate", "==", issueDate), where("artistId", "==", artistId)];
    const q = query(invoicesRef, ...whereQuery);
    const querySnapshot = await getDocs(q);
    if (querySnapshot.docs.length > 0) {
      // update existing invoice
      const invoiceRef = doc(db, "invoices", querySnapshot.docs[0].id);
      await updateDoc(invoiceRef, invoice);
      const docSnap = await getDoc(invoiceRef);
      if (docSnap.data()) {
        return { id: docSnap.id, ...docSnap.data(), updatedAt: Timestamp.now() } as Invoice;
      }
    } else {
      // create new invoice
      const invoiceNumber = invoice.invoiceNumber || (await getInvoiceNumber());
      const newInvoiceRef = await addDoc(invoicesRef, {
        ...invoice,
        invoiceNumber,
        issueDate,
        createdAt: Timestamp.now(),
        updatedAt: Timestamp.now(),
      });
      const docSnap = await getDoc(newInvoiceRef);
      if (docSnap.data()) {
        return { id: docSnap.id, ...docSnap.data() } as Invoice;
      }
    }
  } catch (error) {
    console.error("Error adding invoice document: ", error);
    throw error;
  }
};

export const updateInvoice = async (invoiceId: string, invoice: Partial<Invoice>) => {
  try {
    const invoiceRef = doc(db, "invoices", invoiceId);
    await updateDoc(invoiceRef, invoice);
    const docSnap = await getDoc(invoiceRef);
    return { id: docSnap.id, ...docSnap.data() } as Invoice;
  } catch (error) {
    console.error("Error updating invoice document: ", error);
    throw error;
  }
};

export const deleteInvoice = async (invoiceId: string) => {
  try {
    const invoiceRef = doc(db, "invoices", invoiceId);
    await deleteDoc(invoiceRef);
  } catch (error) {
    console.error("Error deleting invoice document: ", error);
    throw error;
  }
};
