import { SyntheticEvent, useCallback, useEffect, useState } from "react";
import { capitalize, debounce } from "lodash";
import { match } from "ts-pattern";
import {
  Autocomplete,
  CircularProgress,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { KeyboardArrowDownRounded, Search } from "@mui/icons-material";
import { Form } from "../../../../components/Layout";
import Modal from "../../../../components/Modal";
import Button from "../../../../components/Button";
import Switch from "../../../../components/Switch";
import {
  AdminProductFragment,
  CurrencyCode,
  DraftOrderFragment,
  useGetAdminProductsLazyQuery,
  useGetProductVariantLazyQuery,
} from "../../../../generated/graphql";
import { sizings, skus } from "../../../../state/constants";
import useAdmin from "../../useAdmin";
import DragAndDrop from "../../../../components/DragAndDrop";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { storage } from "../../../../services/Firebase";
import { ProgressBar, ProgressBarContainer } from "../../../../components/ProgressBar";
import { getCurrencySymbol } from "../../../../helpers/money";
import { getProductDetails } from "../../../../services/API/prodigi";
import { CustomAttributes } from "./DraftOrder";

type BaseItem = {
  itemType: "artwork";
  quantity: number;
  commissionValue: string;
  commissionType: "pct" | "fixed";
  attributes: Record<string, string>;
  sizing: string;
};

export type ExistingProduct = BaseItem & {
  productExists: true;
  variantId: string;
  price?: string;
};

export type CustomProduct = BaseItem & {
  productExists: false;
  price: string;
  sku: string;
  title: string;
  artworkUrl: string;
  artistId: string;
  artistName: string;
};

export type ArtworkItem = ExistingProduct | CustomProduct;

export type CustomItem = {
  itemType: "custom";
  title: string;
  quantity: number;
  price: string;
  commissionValue: string;
  commissionType: "pct" | "fixed";
  artistId: string;
  artistName: string;
};

type ItemState = {
  variantId?: string;
  sku?: string;
  quantity: number;
  price?: string;
  commissionValue?: string;
  commissionType: "pct" | "fixed";
  sizing: string;
  artworkUrl?: string;
  artistId?: string;
  artistName?: string;
  title?: string;
  attributes: Record<string, string>;
};

type Props = {
  currency: CurrencyCode;
  order?: DraftOrderFragment;
  editItemId?: string | null;
  handleAddItem: (item: ArtworkItem | CustomItem, editItemId?: string | null) => Promise<void>;
  onClose: () => void;
  loading?: boolean;
  isSampleOrder: boolean;
};

const AddOrUpdateItemModal = ({ currency, order, editItemId, handleAddItem, onClose, loading, isSampleOrder }: Props) => {
  const [error, setError] = useState<string | null>(null);
  const [progress, setProgress] = useState(0);
  const [uploading, setUploading] = useState(false);
  const { setArtistSearchValue, searchedUsers, loading: loadingUsers } = useAdmin();
  const [getAdminProducts, { loading: loadingProducts }] = useGetAdminProductsLazyQuery();
  const [getAdminProductVariant] = useGetProductVariantLazyQuery();
  const [searchedProducts, setSearchedProducts] = useState<AdminProductFragment[] | null>(null);
  const [itemType, setItemType] = useState<"regular" | "upload" | "custom">("regular");
  const [attributes, setAttributes] = useState<Record<string, string[]>>({});
  const [loadingAttributes, setLoadingAttributes] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState<{
    id: string;
    title: string;
    images: { nodes: { id: string; src: string; height: number; width: number }[] };
    artistName: { value: string };
    vendor: string;
    variants?: { nodes: { id: string; sku: string }[] };
  } | null>(null);
  const [state, setState] = useState<ItemState>({
    quantity: 1,
    commissionValue: isSampleOrder ? "0" : "30",
    commissionType: "pct",
    sizing: "stretchToPrintArea",
    price: isSampleOrder ? "0" : undefined,
    attributes: {},
  });

  const searchProducts = async (search: string) => {
    const { data } = await getAdminProducts({
      variables: {
        query: `title:${search}*`,
        limit: 20,
      },
    });
    if (data?.products?.nodes) {
      setSearchedProducts(data?.products?.nodes);
    }
  };

  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const { variantId, commissionValue, artworkUrl, title, artistId, artistName, sku, price, quantity } = state;

    if (Object.keys(state.attributes).length < Object.keys(attributes).length)
      return setError("Please fill in all required fields");

    match(itemType)
      .with("regular", () => {
        if (!commissionValue || !sku) return setError("Please fill in all required fields");
        if (!selectedProduct) return setError("Please select a product");
        if (variantId) {
          handleAddItem(
            {
              ...state,
              itemType: "artwork",
              productExists: true,
              price: price,
              variantId,
              quantity,
              commissionValue,
            },
            editItemId
          );
        } else {
          if (!commissionValue || !sku) return setError("Please fill in all required fields");
          if (!price) return setError("Please set a price");
          handleAddItem(
            {
              ...state,
              itemType: "artwork",
              productExists: false,
              title: selectedProduct.title,
              artworkUrl: selectedProduct.images?.nodes[0]?.src || "",
              artistId: selectedProduct.vendor,
              artistName: selectedProduct.artistName?.value || "",
              price,
              sku,
              quantity,
              commissionValue,
            },
            editItemId
          );
        }
      })
      .with("upload", () => {
        if (isSampleOrder) {
          if (!state.commissionValue || !state.sku) return setError("Please fill in all required fields");
          if (!state.artworkUrl) return setError("Please upload an artwork");
          handleAddItem(
            {
              ...state,
              itemType: "artwork",
              productExists: false,
              price: "0",
              sku: state.sku,
              quantity: state.quantity,
              commissionValue: "0",
              artworkUrl: state.artworkUrl,
              title: "",
              artistId: "",
              artistName: "",
            },
            editItemId
          );
        } else {
          if (!price || !artworkUrl || !commissionValue || !title || !sku) return setError("Please fill in all required fields");
          handleAddItem(
            {
              ...state,
              itemType: "artwork",
              productExists: false,
              price,
              sku,
              quantity,
              commissionValue,
              artworkUrl,
              title,
              artistId: artistId || "",
              artistName: artistName || "",
            },
            editItemId
          );
        }
      })
      .with("custom", () => {
        if (!quantity || !commissionValue || !price || !artistId || !title) return setError("Please fill in all required fields");
        const artist = searchedUsers?.find((user) => user.id === state.artistId);
        if (!artist) return setError("Artist not found");
        handleAddItem({
          ...state,
          itemType: "custom",
          price,
          quantity,
          commissionValue,
          title,
          artistId,
          artistName: artist.firstName + " " + artist.lastName,
        });
      })
      .exhaustive();
  };

  const onProductChange = (
    _: SyntheticEvent<Element, Event>,
    value: {
      value: string;
      label: string;
    } | null
  ) => {
    const product = searchedProducts?.find((product) => product.id === value?.value);
    if (product) {
      setSelectedProduct(product);
    }
  };
  const onSKUChange = async (_: SyntheticEvent<Element, Event>, value: string | { value: string; label: string } | null) => {
    const sku = typeof value === "string" ? value : value?.value;
    if (sku) {
      const existingSku = selectedProduct?.variants?.nodes.find((variant) => variant.sku === sku);
      if (existingSku) {
        setState((prevState) => ({ ...prevState, sku, variantId: existingSku.id }));
      } else {
        setState((prevState) => ({ ...prevState, sku }));
      }
      setLoadingAttributes(true);
      const details = await getProductDetails(sku);
      const attributes = Object.entries(details.attributes).filter(([_, value]) => value.length > 1);
      setAttributes(Object.fromEntries(attributes));
      setLoadingAttributes(false);
    }
  };

  const handleChange = ({ name, value }: { name: string; value?: string | number | null }) => {
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleArtistChange = (
    _: SyntheticEvent<Element, Event>,
    value: {
      value: string;
      label: string;
    } | null
  ) => {
    if (!value) return;
    setState((prevState) => ({ ...prevState, artistId: value.value, artistName: value.label }));
  };

  const handleAttributeChange = ({ name, value }: { name: string; value: string }) => {
    setState((prevState) => ({ ...prevState, attributes: { ...state.attributes, [name]: value } }));
  };

  const uploadImageToFirebase = (files: File[]) => {
    setUploading(true);
    const file = files[0];
    const artworkReference = new Date().getTime() + file.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    const storageRef = ref(storage, `artwork/${artworkReference}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on("state_changed", (snapshot) => {
      const percent = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      setProgress(percent);
    });

    uploadTask.on("state_changed", {
      complete: function () {
        getDownloadURL(storageRef).then((downloadURL) => {
          console.log("File available at", downloadURL);
          setState((prevState) => ({ ...prevState, artworkUrl: downloadURL }));
          setUploading(false);
        });
      },
    });
  };

  const setEditItemState = useCallback(async () => {
    const editItem = order?.lineItems.nodes.find((item) => item.id === editItemId);
    if (!editItem) return;
    const editItemCustomAttributes = editItem.customAttributes.reduce((acc, attribute) => {
      acc[attribute.key as keyof CustomAttributes] = attribute.value;
      return acc;
    }, {} as CustomAttributes);
    const variantId = editItem.variant?.id;
    const { _artistId, _artistName, _artworkUrl, _commissionType, _commissionValue, _sizing, frame, ...attributes } =
      editItemCustomAttributes;

    const editItemState = {
      variantId,
      price: editItem.priceOverride ? editItem.priceOverride.amount : editItem.originalUnitPriceSet.presentmentMoney.amount,
      sku: editItem.sku || "",
      quantity: editItem.quantity,
      title: editItem.title,
      commissionType: _commissionType as "pct" | "fixed",
      commissionValue: _commissionValue,
      sizing: _sizing || "stretchToPrintArea",
      attributes: {
        ...attributes,
        ...(frame ? { color: frame } : {}),
      },
    };
    if (variantId) {
      setState({ ...editItemState });
      const { data } = await getAdminProductVariant({ variables: { variantId } });
      if (!data?.productVariant) return;
      setSelectedProduct(data?.productVariant?.product);
    } else {
      setState({
        ...editItemState,
        artistId: _artistId,
        artistName: _artistName,
        title: editItem.title,
        artworkUrl: _artworkUrl,
      });
      if (!_artworkUrl || !_artistId || !_artistName) return;
      setSelectedProduct({
        id: "custom",
        title: editItem.title,
        images: { nodes: [{ id: "", src: _artworkUrl, height: 100, width: 100 }] },
        artistName: { value: _artistName },
        vendor: _artistId,
      });
    }
  }, [order, editItemId, getAdminProductVariant]);

  useEffect(() => {
    setEditItemState();
  }, [setEditItemState]);

  return (
    <Modal size="medium" height="auto" onClose={onClose}>
      <Form onSubmit={onSubmit}>
        <Switch
          options={[
            { label: "Regular", value: "regular" },
            { label: "Upload", value: "upload" },
            { label: "Custom", value: "custom" },
          ]}
          selected={itemType}
          onChange={(value) => setItemType(value as "regular" | "upload" | "custom")}
        />
        {match(itemType)
          .with("regular", () => (
            <Stack gap={0.5}>
              <Typography>Artwork</Typography>
              <Autocomplete
                loading={loadingProducts}
                options={
                  searchedProducts?.map((product) => ({
                    value: product.id,
                    label: product.title + " · " + (product.artistName?.value || ""),
                  })) || []
                }
                value={selectedProduct ? { value: selectedProduct.id, label: selectedProduct.title } : null}
                onChange={onProductChange}
                popupIcon={<KeyboardArrowDownRounded />}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    style={{ zIndex: 0 }}
                    placeholder="Search artwork"
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <InputAdornment position="start">
                          <Search fontSize="small" />
                        </InputAdornment>
                      ),
                    }}
                    onChange={debounce(({ target }) => searchProducts(target.value), 1000)}
                  />
                )}
              />
            </Stack>
          ))
          .with("upload", () => (
            <Stack gap={2}>
              {uploading ? (
                <ProgressBarContainer>
                  <ProgressBar
                    role="progressbar"
                    aria-valuenow={50}
                    aria-valuemin={0}
                    aria-valuemax={100}
                    width={progress}
                  ></ProgressBar>
                </ProgressBarContainer>
              ) : (
                <Stack direction="row" gap={2}>
                  {state.artworkUrl && <img src={state.artworkUrl} alt="Uploaded artwork" width="auto" height="120" />}
                  <DragAndDrop
                    onImageDrop={uploadImageToFirebase}
                    label={
                      <Stack gap={1}>
                        <Typography fontSize={18} align="center" margin="16px 0 0">
                          Upload artwork
                        </Typography>
                      </Stack>
                    }
                  />
                </Stack>
              )}
            </Stack>
          ))
          .otherwise(() => null)}

        {(itemType === "custom" || itemType === "upload") && (
          <Stack direction="row" gap={2}>
            <Stack gap={0.5} flex={1}>
              <Typography>Title</Typography>
              <OutlinedInput
                placeholder="Title"
                size="small"
                onChange={(event) => handleChange({ name: "title", value: event.target.value })}
                value={state.title || ""}
              />
            </Stack>
            <Stack gap={0.5} flex={1}>
              <Typography>Artist</Typography>
              <Autocomplete
                loading={loadingUsers}
                popupIcon={<KeyboardArrowDownRounded />}
                options={searchedUsers?.map((user) => ({ value: user.id, label: user.firstName + " " + user.lastName })) || []}
                onChange={handleArtistChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    style={{ zIndex: 0 }}
                    placeholder="Search artist"
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <InputAdornment position="start">
                          <Search fontSize="small" />
                        </InputAdornment>
                      ),
                    }}
                    onChange={(event) => setArtistSearchValue(event.target.value)}
                  />
                )}
              />
            </Stack>
          </Stack>
        )}

        <Stack direction="row" gap={2}>
          {itemType !== "custom" && (
            <Stack gap={0.5} flex={2}>
              <Typography>SKU</Typography>
              <Autocomplete
                options={skus.map((sku) => ({ value: sku, label: sku }))}
                onChange={onSKUChange}
                freeSolo
                value={state.sku ? { value: state.sku, label: state.sku } : null}
                popupIcon={<KeyboardArrowDownRounded />}
                renderInput={(params) => <TextField {...params} size="small" style={{ zIndex: 0 }} placeholder="Search SKU" />}
              />
            </Stack>
          )}

          {itemType !== "custom" && (
            <Stack gap={0.5} flex={2}>
              <Typography>Prodigi sizing</Typography>
              <Autocomplete
                options={sizings}
                onChange={(_, value) => handleChange({ name: "sizing", value: value?.value || "stretchToPrintArea" })}
                value={state.sizing ? sizings.find((s) => s.value === state.sizing) : null}
                popupIcon={<KeyboardArrowDownRounded />}
                renderInput={(params) => <TextField {...params} size="small" style={{ zIndex: 0 }} placeholder="Search SKU" />}
              />
            </Stack>
          )}

          <Stack gap={0.5} flex={1}>
            <Typography>Quantity</Typography>
            <OutlinedInput
              value={state.quantity || 1}
              onChange={(e) => handleChange({ name: "quantity", value: Number(e.target.value) })}
              type="number"
              placeholder="Quantity"
              size="small"
            />
          </Stack>
        </Stack>

        <Stack direction="row" gap={2}>
          {loadingAttributes ? (
            <CircularProgress size={20} />
          ) : (
            Object.keys(attributes).map((attribute) => (
              <Stack gap={0.5} flex={2} key={attribute}>
                <Typography>{capitalize(attribute)}</Typography>
                <Select
                  value={state.attributes[attribute] || ""}
                  onChange={(e) => handleAttributeChange({ name: attribute, value: e.target.value })}
                  placeholder={attribute}
                  renderValue={(selected) => (selected ? capitalize(selected) : capitalize(attribute))}
                  IconComponent={KeyboardArrowDownRounded}
                >
                  {attributes[attribute].map((value) => (
                    <MenuItem key={value} value={value}>
                      {capitalize(value)}
                    </MenuItem>
                  ))}
                </Select>
              </Stack>
            ))
          )}
        </Stack>

        {!isSampleOrder && (
          <Stack direction="row" gap={2}>
            <Stack gap={0.5} flex={1}>
              <Typography>Price (ex. VAT)</Typography>
              <OutlinedInput
                value={state.price || ""}
                onChange={(e) => handleChange({ name: "price", value: e.target.value })}
                placeholder="Price"
                size="small"
                startAdornment={<Typography>{getCurrencySymbol(currency)}</Typography>}
              />
            </Stack>

            <Stack gap={0.5} flex={2}>
              <Typography>Commission</Typography>
              <Stack direction="row" gap={1} alignItems="center">
                <OutlinedInput
                  value={state.commissionValue}
                  onChange={(e) => handleChange({ name: "commissionValue", value: e.target.value })}
                  size="small"
                />
                <Select
                  value={state.commissionType || "pct"}
                  onChange={(e) => handleChange({ name: "commissionType", value: e.target.value })}
                  IconComponent={KeyboardArrowDownRounded}
                >
                  <MenuItem value="pct">%</MenuItem>
                  <MenuItem value="fixed">£</MenuItem>
                </Select>
              </Stack>
            </Stack>
          </Stack>
        )}

        {error && <Typography color="error">{error}</Typography>}

        <Stack direction="row" alignItems="center" justifyContent="end" gap={2} paddingTop={1}>
          <Button secondary onClick={onClose}>
            Cancel
          </Button>
          <Button type="submit" loading={loading}>
            {editItemId ? "Update" : "Add"}
          </Button>
        </Stack>
      </Form>
    </Modal>
  );
};

export default AddOrUpdateItemModal;
