import { useState } from "react";
import { Autocomplete, Box, OutlinedInput, Stack, TextField, Typography } from "@mui/material";
import momentData from "moment-timezone/data/meta/latest.json";
import { Form } from "../../../../components/Layout";
import Modal from "../../../../components/Modal";
import Button from "../../../../components/Button";
import { CountryCode, MailingAddress, MailingAddressInput } from "../../../../generated/graphql";
import { KeyboardArrowDownRounded } from "@mui/icons-material";

type Props = {
  shippingEmail?: string | null;
  shippingAddress?: MailingAddress | null;
  handleShippingAddressUpdate: (address: MailingAddressInput, email: string) => void;
  onClose: () => void;
  loading?: boolean;
};

const EditShippingAddressModal = ({ shippingEmail, shippingAddress, handleShippingAddressUpdate, onClose, loading }: Props) => {
  const [error, setError] = useState<string | null>(null);
  const [firstName, setFirstName] = useState<string | null>(shippingAddress?.firstName || null);
  const [lastName, setLastName] = useState<string | null>(shippingAddress?.lastName || null);
  const [company, setCompany] = useState<string | null>(shippingAddress?.company || null);
  const [address1, setAddress1] = useState<string | null>(shippingAddress?.address1 || null);
  const [address2, setAddress2] = useState<string | null>(shippingAddress?.address2 || null);
  const [city, setCity] = useState<string | null>(shippingAddress?.city || null);
  const [countryCode, setCountryCode] = useState<CountryCode | null>(shippingAddress?.countryCodeV2 || null);
  const [zip, setZip] = useState<string | null>(shippingAddress?.zip || null);
  const [email, setEmail] = useState<string | null>(shippingEmail || null);
  const [phone, setPhone] = useState<string | null>(shippingAddress?.phone || null);
  const countries = momentData.countries as unknown as Record<CountryCode, { abbr: CountryCode; name: string }>;

  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);
    if (!firstName || !address1 || !city || !countryCode || !zip || !email) {
      setError("Please fill all required fields");
      return;
    }
    handleShippingAddressUpdate({ company, firstName, lastName, address1, address2, city, countryCode, zip, phone }, email || "");
    onClose();
  };

  return (
    <Modal size="small" onClose={onClose}>
      <Box paddingTop={3}>
        <Form onSubmit={onSubmit}>
          <Stack gap={1}>
            <Typography>Shipping address</Typography>
            <Stack direction="row" gap={1}>
              <OutlinedInput
                placeholder="First name*"
                size="small"
                onChange={(event) => setFirstName(event.target.value)}
                value={firstName}
                fullWidth
              />
              <OutlinedInput
                placeholder="Last name"
                size="small"
                onChange={(event) => setLastName(event.target.value)}
                value={lastName}
                fullWidth
              />
            </Stack>
            <OutlinedInput
              placeholder="Company"
              size="small"
              onChange={(event) => setCompany(event.target.value)}
              value={company}
            />
            <OutlinedInput
              placeholder="Line 1*"
              size="small"
              onChange={(event) => setAddress1(event.target.value)}
              value={address1}
            />
            <OutlinedInput
              placeholder="Line 2"
              size="small"
              onChange={(event) => setAddress2(event.target.value)}
              value={address2}
            />
            <Stack direction="row" gap={1}>
              <OutlinedInput placeholder="City*" size="small" onChange={(event) => setCity(event.target.value)} value={city} />
              <Autocomplete
                placeholder="Country*"
                fullWidth
                value={{ value: countryCode, label: countryCode ? countries[countryCode]?.name : "" }}
                onChange={(_, value) => setCountryCode(value?.value || null)}
                options={Object.values(countries).map((c) => ({ value: c.abbr, label: c.name }))}
                renderInput={(params) => <TextField {...params} />}
                popupIcon={<KeyboardArrowDownRounded />}
              />
              <OutlinedInput
                placeholder="Postal code*"
                size="small"
                onChange={(event) => setZip(event.target.value)}
                value={zip}
              />
            </Stack>
            <Stack direction="row" gap={1}>
              <OutlinedInput
                placeholder="Email*"
                size="small"
                onChange={(event) => setEmail(event.target.value)}
                value={email}
                fullWidth
              />
              <OutlinedInput
                placeholder="Phone number"
                size="small"
                onChange={(event) => setPhone(event.target.value)}
                value={phone}
                fullWidth
              />
            </Stack>
          </Stack>

          {error && <Typography color="error">{error}</Typography>}

          <Stack direction="row" alignItems="center" justifyContent="end" gap={2} paddingTop={1}>
            <Button secondary onClick={onClose}>
              Cancel
            </Button>
            <Button loading={loading}>Update</Button>
          </Stack>
        </Form>
      </Box>
    </Modal>
  );
};

export default EditShippingAddressModal;
