import { Suspense, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { format } from "date-fns";
import { Box, Stack, Typography } from "@mui/material";
import Button from "../../components/Button";
import { colors } from "../../theme";
import howItWorks from "../../assets/images/howItWorks.webp";
import howItWorks2 from "../../assets/images/howItWorks2.webp";
import placeholder from "../../assets/images/placeholder.webp";
import backgroundImage from "../../assets/images/backgroundImage.webp";
import wallBuilderBannerWebp from "../../assets/images/wallBuilderBanner.webp";
import { Text, Header } from "../../components/Text";
import ProductCard from "../../components/ProductCard";
import ScrollCarousel from "../../components/ScrollCarousel";
import { ArtistButton } from "./styles";
import { getUsers } from "../../services/Firebase";
import { User } from "../../types/user";
// import RollingTextBanner from "../../components/RollingTextBanner";
import { Icon } from "../../components/Icon";
import {
  CollectionSortKeys,
  useGetCollectionByHandleQuery,
  useGetGalleryWallCollectionsQuery,
  useGetHomePageProductsQuery,
  useGetPrintSetCollectionsQuery,
} from "../../generated/storefront";
import { getThisMonthsDate } from "../../helpers/time";
import CollectionCard from "../../components/Card/Collection";
import LazyLoadImage from "../../components/Image/LazyLoadImage";
import PrintSetCard from "../PrintSets/PrintSetCard";
import { Frame } from "../../types/product";
import { getIdNumber } from "../../helpers/shopify";
import FeaturedCollections from "../Shop/FeaturedCollections";
import { useAppState } from "../../state";
import { Loader } from "../../components/Loader";

const Home = () => {
  const { isMobileScreen, selectedCountry, toggleGalleryWallBuilderIsOpen } = useAppState();
  const [artists, setArtists] = useState<User[]>();
  const { data: collectionsData } = useGetGalleryWallCollectionsQuery({ variables: { limit: 20 } });
  const galleryWalls = useMemo(() => collectionsData?.collections?.nodes || [], [collectionsData]);
  const { data, refetch } = useGetHomePageProductsQuery({
    variables: {
      country: selectedCountry,
      picksQuery: `tag:pick product_type:${getThisMonthsDate()}`,
    },
  });
  const { data: trendingData, refetch: refetchTrending } = useGetCollectionByHandleQuery({
    variables: {
      handle: "trending",
      country: selectedCountry,
    },
  });
  const { data: printSetsData } = useGetPrintSetCollectionsQuery({
    variables: {
      sortKey: CollectionSortKeys.Title,
      limit: 12,
      country: selectedCountry,
    },
  });

  const mostPopular = useMemo(() => data?.mostPopular?.nodes || [], [data]);
  const ourPicks = useMemo(() => data?.picks?.nodes || [], [data]);
  const communityFavourites = useMemo(() => data?.communityFavourites?.nodes || [], [data]);
  const trending = useMemo(() => trendingData?.collection?.products?.nodes || [], [trendingData]);
  const printSets = useMemo(() => printSetsData?.collections?.nodes || [], [printSetsData]);

  const getArtists = async () => {
    const { users } = await getUsers({
      filters: { hasProducts: true },
      orderBy: { field: "salesCount", direction: "desc" },
      limit: 10,
    });
    setArtists(users);
  };

  useEffect(() => {
    if (!artists) {
      getArtists();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [artists]);

  useEffect(() => {
    refetch({
      country: selectedCountry,
    });
    refetchTrending({
      country: selectedCountry,
    });
  }, [selectedCountry, refetch, refetchTrending]);

  return (
    <>
      <Helmet>
        <title>GoodMood</title>
        <meta
          name="description"
          content="Support independent artists and fill your space with work that inspires, entertains and resonates with you"
        />
      </Helmet>
      <Stack direction={{ xs: "column", md: "row-reverse" }} height={{ xs: "auto", md: "60vh" }}>
        <Link to="/shop" style={{ display: "flex", width: isMobileScreen ? "100%" : "50%" }}>
          <img
            src="https://cdn.shopify.com/s/files/1/0619/3417/4422/files/desktop.webp"
            srcSet="https://cdn.shopify.com/s/files/1/0619/3417/4422/files/desktop.webp?width=400 400w, https://cdn.shopify.com/s/files/1/0619/3417/4422/files/desktop.webp?width=800 800w, https://cdn.shopify.com/s/files/1/0619/3417/4422/files/desktop.webp?width=1200 1200w, https://cdn.shopify.com/s/files/1/0619/3417/4422/files/desktop.webp?width=1600 1600w"
            sizes="50vw"
            width="100%"
            height="100%"
            alt="living room with artwork"
            style={{
              height: isMobileScreen ? "35vh" : "60vh",
              position: "relative",
              objectFit: "cover",
              objectPosition: "top",
            }}
          />
        </Link>
        <Stack
          padding={{ xs: "40px 16px 0", md: "24px 40px" }}
          width={{ xs: "100%", md: "50%" }}
          position="relative"
          justifyContent="flex-end"
        >
          <Stack gap={2} alignItems={{ xs: "center", md: "start" }} justifyContent="space-between">
            <Stack gap={{ xs: 2, md: 2 }} alignItems={{ xs: "stretch", md: "start" }} width="100%">
              <Typography
                fontSize={{ xs: 54, md: "6vw" }}
                lineHeight={1}
                component="h1"
                letterSpacing={-2}
                textAlign={{ xs: "center", md: "left" }}
              >
                Unique Prints
              </Typography>
              <Stack width={{ xs: "100%", md: "100%" }}>
                {isMobileScreen ? (
                  <Typography fontSize={{ xs: 16, md: 18 }} align={isMobileScreen ? "center" : "left"}>
                    Fill your space with art that resonates
                  </Typography>
                ) : (
                  <Typography fontSize={{ xs: 16, md: 18 }} align={isMobileScreen ? "center" : "left"}>
                    Support independent artists and fill your space with art that inspires, entertains and resonates
                  </Typography>
                )}
              </Stack>

              <Link to="/shop">
                <Button size="large" fullWidth={isMobileScreen}>
                  Shop the collection
                </Button>
              </Link>
            </Stack>

            <a href="https://www.reviews.co.uk/company-reviews/store/www.goodmoodprints.io" target="_blank" rel="noreferrer">
              <Stack gap={1} direction="row" alignItems="center" style={{ transition: "padding-bottom 0.2s ease" }}>
                <Typography whiteSpace="nowrap" fontSize={{ xs: 14, md: 16 }}>
                  4.9 / 5 · Excellent
                </Typography>
                <Stack direction="row" gap={0.5} justifyContent={{ xs: "center", md: "start" }}>
                  {[
                    ...Array(5)
                      .fill("")
                      .map((r, i) => <Icon key={i} icon="star" size={16} fill={colors.gold} />),
                  ]}
                </Stack>
              </Stack>
            </a>
          </Stack>
        </Stack>
      </Stack>

      <Stack gap={{ xs: 5, md: 8 }} paddingTop={{ xs: 5, md: 5 }} paddingBottom={{ xs: 5, md: 8 }}>
        {/* TRENDING */}
        <Suspense fallback={<Loader />}>
          <ScrollCarousel
            header="Trending"
            minHeight={isMobileScreen ? "85vw" : "35vw"}
            cta={{
              link: "/shop/collections/trending",
              text: "Shop all",
            }}
          >
            {trending?.map((product) => <ProductCard frame={Frame.Natural} product={product} key={`trending-${product.id}`} />) ||
              []}
          </ScrollCarousel>
        </Suspense>

        {/* OUR PICKS */}
        <Suspense fallback={<Loader />}>
          <ScrollCarousel
            header={`${format(new Date(), "MMMM")}'s Picks`}
            minHeight={isMobileScreen ? "85vw" : "35vw"}
            cta={{
              link: "/shop?month=" + getThisMonthsDate(),
              text: "Shop all",
            }}
          >
            {ourPicks?.map((product) => <ProductCard frame={Frame.Natural} product={product} key={`picks-${product.id}`} />) ||
              []}
          </ScrollCarousel>
        </Suspense>

        {/* GALLERY WALLS */}
        <Suspense fallback={<Loader />}>
          <ScrollCarousel
            header="Gallery Walls"
            minHeight={isMobileScreen ? "60%" : "450px"}
            cta={{
              link: "/gallery-walls",
              text: "View all",
            }}
            slideWidth={isMobileScreen ? "60%" : "450px"}
          >
            {galleryWalls.map((collection) => (
              <CollectionCard key={collection.id} imageHeight={isMobileScreen ? "60vw" : "450px"} collection={collection} />
            )) || []}
          </ScrollCarousel>
        </Suspense>

        {/* PRINT SETS */}
        <Suspense fallback={<Loader />}>
          <ScrollCarousel
            header="Print Sets"
            minHeight={isMobileScreen ? "60%" : "450px"}
            cta={{
              link: "/print-sets",
              text: "View all",
            }}
            slideWidth={isMobileScreen ? "60%" : "450px"}
          >
            {printSets.map((printSet) => (
              <PrintSetCard
                key={printSet.id}
                printSet={{
                  link: `/print-set?products=${getIdNumber(printSet.products.nodes[0].id)},${getIdNumber(
                    printSet.products.nodes[1].id
                  )}`,
                  product: printSet.products.nodes[0],
                  complimentaryProduct: printSet.products.nodes[1],
                }}
                selectedFrame={Frame.Black}
                imageStyle="sofa"
              />
            )) || []}
          </ScrollCarousel>
        </Suspense>

        {/* COMMUNITY PICKS */}
        <Suspense fallback={<Loader />}>
          <ScrollCarousel
            header="Community Picks"
            minHeight={isMobileScreen ? "85vw" : "35vw"}
            cta={{
              link: "/shop/collections/community-favourites",
              text: "Shop all",
            }}
          >
            {communityFavourites?.map((product) => (
              <ProductCard frame={Frame.Natural} product={product} key={`community-picks-${product.id}`} />
            )) || []}
          </ScrollCarousel>
        </Suspense>

        {/* MOST POPULAR */}
        <Suspense fallback={<Loader />}>
          <ScrollCarousel
            header="Most Popular"
            minHeight={isMobileScreen ? "85vw" : "35vw"}
            cta={{
              link: "/shop",
              text: "Shop all",
            }}
          >
            {mostPopular?.map((product) => (
              <ProductCard frame={Frame.Natural} product={product} key={`popular-${product.id}`} />
            )) || []}
          </ScrollCarousel>
        </Suspense>
      </Stack>

      {!isMobileScreen && (
        <Box
          paddingX={{ xs: 2, md: 5 }}
          paddingY={{ xs: 5, md: 8 }}
          width="100%"
          sx={{ backgroundImage: `url(${backgroundImage})`, backgroundSize: "cover" }}
        >
          <Box border={`3px solid ${colors.white}`}>
            <Box bgcolor={colors.beige}>
              <Stack direction={{ xs: "column-reverse", md: "row" }}>
                <Stack
                  gap={2}
                  width={{ xs: "100%", md: "50%" }}
                  alignItems="start"
                  justifyContent="end"
                  padding={{ xs: 3, md: 5 }}
                >
                  <Typography variant="h2" fontSize={{ xs: 32, md: 48 }}>
                    Gallery Wall Builder
                  </Typography>
                  <Typography>Visualise pieces in your own space, or mix and match with ours</Typography>

                  <Button secondary onClick={toggleGalleryWallBuilderIsOpen}>
                    Start building
                  </Button>
                </Stack>
                <Stack width={{ xs: "100%", md: "50%" }}>
                  <img
                    src={wallBuilderBannerWebp}
                    alt="gallery wall interior"
                    width="100%"
                    height="100%"
                    style={{ objectFit: "cover" }}
                  />
                </Stack>
              </Stack>
            </Box>
          </Box>
        </Box>
      )}

      {/* THE ARTISTS */}
      <Box bgcolor={colors.white} paddingTop={{ xs: 0, md: 8 }} width="100%">
        <Suspense fallback={<Loader />}>
          <ScrollCarousel
            header="Artists"
            minHeight={isMobileScreen ? "95%" : "450px"}
            cta={{
              link: "/artists",
              text: "View all",
            }}
            slideWidth={isMobileScreen ? "95%" : "450px"}
          >
            {artists?.map((artist) => (
              <div key={artist.id} style={{ height: "100%" }}>
                <Link to={`/artists/${artist.permalink || artist.id}`}>
                  <Box height={{ xs: 300, md: 500 }} overflow="hidden" position="relative">
                    <LazyLoadImage
                      src={artist.image || placeholder}
                      alt={artist.firstName}
                      width="100%"
                      height="100%"
                      style={{ objectFit: "cover" }}
                    />
                    <ArtistButton>
                      <Button secondary>
                        <>
                          {artist.firstName} {artist.lastName}
                        </>
                      </Button>
                    </ArtistButton>
                  </Box>
                </Link>
              </div>
            )) || []}
          </ScrollCarousel>
        </Suspense>
      </Box>

      {/* SHOP BY */}
      <Stack gap={4} paddingTop={{ xs: 5, md: 8 }} paddingBottom={{ xs: 3, md: 6 }}>
        <Typography variant="h2" paddingX={{ xs: 2, md: 5 }}>
          Shop by
        </Typography>
        <Suspense fallback={<Loader />}>
          <FeaturedCollections collectionCategory="Featured" isMobileScreen={isMobileScreen} />
        </Suspense>
      </Stack>

      <Box bgcolor={colors.beige} paddingX={{ xs: 2, md: 5 }} paddingY={{ xs: 5, md: 8 }} width="100%">
        <Box paddingBottom={4}>
          <Header type="h2">How It Works</Header>
        </Box>
        <Stack gap={3} rowGap={6} direction={{ xs: "column", md: "row" }}>
          <Stack width={{ xs: "100%", md: "50%" }}>
            <img src={howItWorks} alt="how it works" width="100%" height="auto" />
            <Header type="h3" margin="24px 0 12px">
              Artists create and upload, risk free
            </Header>
            <Text size={18} margin="0 0 16px">
              Artists upload their work, set their price and we take care of the rest. Printing, packing, sending - we've got it.
              There's no costs and no complications to uploading your work, so you can share as much as you want.
            </Text>
            <Link to="/upload">
              <Button secondary>Upload and sell</Button>
            </Link>
          </Stack>
          <Stack width={{ xs: "100%", md: "50%" }}>
            <img src={howItWorks2} alt="how it works" width="100%" height="auto" />
            <Header type="h3" margin="24px 0 12px">
              We launch monthly collections
            </Header>
            <Text size={18} margin="0 0 16px">
              We review every submission and showcase our favourite new pieces on our homepage, in monthly collections - think of
              them as rolling pop-up exhibitions on our platform, which keeps the site fresh for our audience.
            </Text>
            <Link to="/shop">
              <Button secondary>Shop now</Button>
            </Link>
          </Stack>
        </Stack>
      </Box>
      {/* <RollingTextBanner
        text="zero effort · experiment more · risk free · waste nothing · get paid · "
        background={colors.purple}
        color={colors.black}
        padding={12}
        size={isMobileScreen ? 12 : 16}
      /> */}
    </>
  );
};

export default Home;
