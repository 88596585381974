import { useEffect, useState } from "react";
import { getUsers, searchArtists } from "../../../services/Firebase";
import { Medium } from "../../../types/product";
import { User } from "../../../types/user";
import {
  ProductSortKeys,
  SearchArticleFragment,
  SearchProductFragment,
  useGetProductsForProductCardLazyQuery,
  useSearchLazyQuery,
} from "../../../generated/storefront";
import { useAppState } from "../../../state";
import { productSearchFilter } from "../../../helpers/product";

export const useSearch = () => {
  const mediumValues = Object.values(Medium);
  const { selectedCountry } = useAppState();
  const [artists, setArtists] = useState<User[]>([]);
  const [defaultArtists, setDefaultArtists] = useState<User[]>([]);
  const [mediums, setMediums] = useState<Medium[]>(mediumValues);
  const [products, setProducts] = useState<SearchProductFragment[]>();
  const [articles, setArticles] = useState<SearchArticleFragment[]>([]);
  const [loadingArtists, setLoadingArtists] = useState(false);
  const [loadingProducts, setLoadingProducts] = useState(false);
  const [search, { loading }] = useSearchLazyQuery();
  const [getProducts] = useGetProductsForProductCardLazyQuery();
  const [searchQs, setSearchQs] = useState("");

  const fetchArtists = async () => {
    const { users } = await getUsers({
      filters: { hasProducts: true },
      orderBy: { field: "salesCount", direction: "desc" },
      limit: 3,
    });
    setDefaultArtists(users);
  };

  const searchItems = async (query: string, artistIds: string[]) => {
    setLoadingProducts(true);
    const { data: artistProductsData } = await getProducts({
      variables: {
        limit: 6,
        query: `vendor:${artistIds.join(",")}`,
        sortKey: ProductSortKeys.BestSelling,
        country: selectedCountry,
      },
    });
    const { data: favouriteProductsData } = await search({
      variables: {
        limit: 6,
        query,
        country: selectedCountry,
        productFilters: { tag: "favourite" },
      },
      fetchPolicy: "no-cache",
    });
    const favouriteProductsResult = favouriteProductsData?.search.nodes || [];
    const favouriteProducts = (favouriteProductsResult?.filter(productSearchFilter) as SearchProductFragment[]) || [];

    const searchProducts = [
      ...favouriteProducts,
      ...(artistProductsData?.products.nodes.filter((node) => !favouriteProducts.find((product) => product.id === node.id)) ||
        []),
    ];

    setProducts(searchProducts);
    const searchedArticles =
      (favouriteProductsResult?.filter((node) => node?.__typename === "Article") as SearchArticleFragment[]) || [];
    setArticles(searchedArticles);
    setLoadingProducts(false);
  };

  const onSearchChange = async (value: string) => {
    setSearchQs(value);
    if (value) {
      setLoadingArtists(true);
      const searchedArtists = await searchArtists(value);
      setArtists(searchedArtists);
      setLoadingArtists(false);
      const searchedMediums = value ? mediums.filter((medium) => medium.match(value)) : [];
      setMediums(searchedMediums.length ? searchedMediums : mediums);
      await searchItems(
        value,
        searchedArtists.map((artist) => artist.id)
      );
    } else {
      setArtists([]);
    }
  };

  useEffect(() => {
    if (!artists.length) {
      fetchArtists();
    }
  }, [artists]);

  return {
    onSearchChange,
    defaultArtists,
    artists,
    mediums,
    products,
    articles,
    loadingArtists,
    loadingProducts: loading || loadingProducts,
    searchQs,
  };
};
