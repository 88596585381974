import { Link, useNavigate } from "react-router-dom";
import { subMonths } from "date-fns";
import { Box, Grid, Stack, Typography } from "@mui/material";
import { useGetCollectionsByTypeQuery } from "../../../generated/storefront";
import { colors, fonts } from "../../../theme";
import { getMonthsDate, getThisMonthsDate } from "../../../helpers/time";
import ColourBadges from "../../ColourBadges";

const ShopMegaMenu = ({ closeMegaMenu }: { closeMegaMenu: boolean }) => {
  const navigate = useNavigate();
  const { data: featuredCollectionData } = useGetCollectionsByTypeQuery({
    variables: { type: `title:featured` },
  });
  const { data: roomCollectionData } = useGetCollectionsByTypeQuery({
    variables: { type: `title:by room` },
  });
  const collections = featuredCollectionData?.collections.nodes || [];
  const roomCollections = roomCollectionData?.collections.nodes || [];

  const orientationOptions = [
    {
      label: "Square",
      value: "square",
      width: 52,
      height: 52,
    },
    {
      label: "Portrait",
      value: "portrait",
      width: 52,
      height: 72,
    },
    {
      label: "Landscape",
      value: "landscape",
      width: 72,
      height: 52,
    },
  ];

  return (
    <Box
      paddingY={3.2}
      position="fixed"
      className={`shop-mega-menu` + (closeMegaMenu ? " close" : "")}
      display="none"
      top={86}
      left="50%"
      width={1200}
      maxWidth="100vw"
      maxHeight={640}
      sx={{ transform: "translate(-50%, 0)", zIndex: 1000 }}
    >
      <Box bgcolor={colors.white} borderRadius="0 0 24px 24px" overflow="hidden" width="100%">
        <Stack direction="row" height="100%">
          <Stack padding={2} gap={2} bgcolor={colors.grey02}>
            <Typography fontFamily={fonts.brand} fontSize={10} color={colors.grey40} textTransform="uppercase">
              Popular
            </Typography>
            <Link to="/shop">
              <Typography>All Art Prints</Typography>
            </Link>
            <Link to="/shop/collections/community-favourites">
              <Typography>Community Favourites</Typography>
            </Link>
            <Link
              to={`/shop?month=${getThisMonthsDate()},${getMonthsDate(subMonths(new Date(), 1))},${getMonthsDate(
                subMonths(new Date(), 2)
              )},${getMonthsDate(subMonths(new Date(), 3))},${getMonthsDate(subMonths(new Date(), 4))}`}
            >
              <Typography>Trending</Typography>
            </Link>
            <Link to={"/shop?month=" + getThisMonthsDate()}>
              <Typography>New Arrivals</Typography>
            </Link>
            <Link to="/products/gift-card">
              <Typography>Gift Card</Typography>
            </Link>
            <Link to="/gift-guide">
              <Typography>Gift Guide</Typography>
            </Link>
            <Link to="/gallery-wall-builder">
              <Typography>Gallery Wall Builder</Typography>
            </Link>
          </Stack>
          <Stack flex={1} gap={2} padding={2} justifyContent="space-around" overflow="auto">
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography fontFamily={fonts.brand} fontSize={10} color={colors.grey40} textTransform="uppercase">
                  By Collection
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography fontFamily={fonts.brand} fontSize={10} color={colors.grey40} textTransform="uppercase">
                  By Room
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography fontFamily={fonts.brand} fontSize={10} color={colors.grey40} textTransform="uppercase">
                  By Colour
                </Typography>
              </Grid>

              <Grid item xs={6}>
                <Box gap={1.5} height="100%" sx={{ columnCount: 2 }}>
                  {collections.map((collection) => (
                    <Link key={collection.handle} to={`/shop/collections/${collection.handle}`}>
                      <Stack direction="row" alignItems="center" gap={1.5} paddingBottom={1}>
                        <img
                          src={collection.image?.url + "&width=400"}
                          alt={collection.image?.altText || collection.title.split(": ").pop()}
                          width={56}
                          height={56}
                          style={{ borderRadius: 5, objectFit: "cover" }}
                        />
                        <Typography>{collection.title.split(": ").pop()}</Typography>
                      </Stack>
                    </Link>
                  ))}
                </Box>
              </Grid>
              <Grid item xs={3}>
                <Stack gap={1.5}>
                  {roomCollections.map((collection) => (
                    <Link key={collection.handle} to={`/shop/collections/${collection.handle}`}>
                      <Stack direction="row" alignItems="center" gap={1.5}>
                        <img
                          src={collection.image?.url + "&width=400"}
                          alt={collection.image?.altText || collection.title.split(": ").pop()}
                          width={56}
                          height={56}
                          style={{ borderRadius: 5, objectFit: "cover" }}
                        />
                        <Typography>{collection.title.split(": ").pop()}</Typography>
                      </Stack>
                    </Link>
                  ))}
                </Stack>
              </Grid>

              <Grid item xs={3}>
                <ColourBadges selectedColours={[]} handleColourChange={(colour) => navigate(`/shop?colour=${colour}`)} />
                <Stack gap={1.5} paddingTop={4}>
                  <Typography
                    fontFamily={fonts.brand}
                    fontSize={10}
                    color={colors.grey40}
                    textTransform="uppercase"
                    flexBasis={"33%"}
                  >
                    By Orientation
                  </Typography>

                  <Stack gap={1.5}>
                    {orientationOptions.map((orientation) => (
                      <Link
                        key={orientation.label}
                        to={{
                          pathname: "/shop",
                          search: `?orientation=${orientation.value}`,
                        }}
                        style={{ display: "flex" }}
                      >
                        <Stack direction="row" alignItems="center" gap={1.5} width="100%">
                          <Box
                            width={orientation.width}
                            height={orientation.height}
                            bgcolor={colors.beige}
                            sx={{ border: `1px solid ${colors.grey10}` }}
                          />
                          <Typography>{orientation.label}</Typography>
                        </Stack>
                      </Link>
                    ))}
                  </Stack>
                </Stack>
              </Grid>
            </Grid>
          </Stack>
        </Stack>
      </Box>
    </Box>
  );
};

export default ShopMegaMenu;
