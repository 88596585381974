import { useState } from "react";
import { Box, OutlinedInput, Stack, Typography } from "@mui/material";
import { Form } from "../../../../components/Layout";
import Modal from "../../../../components/Modal";
import Button from "../../../../components/Button";
import { CurrencyCode } from "../../../../generated/graphql";
import { getCurrencySymbol } from "../../../../helpers/money";

type Props = {
  currency: CurrencyCode;
  handleShippingUpdate: (rate: string, title: string) => void;
  onClose: () => void;
  loading?: boolean;
};

const EditShippingModal = ({ currency, handleShippingUpdate, onClose, loading }: Props) => {
  const [error, setError] = useState<string | null>(null);
  const [shippingName, setShippingName] = useState<string | null>(null);
  const [shippingRate, setShippingRate] = useState<string | null>(null);

  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (!shippingRate) return setError("Please enter a shipping rate");
    handleShippingUpdate(shippingRate, shippingName || "Shipping");
    onClose();
  };

  return (
    <Modal size="small" onClose={onClose}>
      <Box paddingTop={3}>
        <Form onSubmit={onSubmit}>
          <Stack gap={1}>
            <Typography>Shipping rate</Typography>
            <OutlinedInput
              placeholder="Name"
              size="small"
              onChange={(event) => setShippingName(event.target.value)}
              value={shippingName}
            />
            <OutlinedInput
              placeholder="Shipping amount (ex. VAT)"
              size="small"
              onChange={(event) => setShippingRate(event.target.value)}
              type="number"
              value={shippingRate}
              startAdornment={<Typography>{getCurrencySymbol(currency)}</Typography>}
            />
          </Stack>

          {error && <Typography color="error">{error}</Typography>}

          <Stack direction="row" alignItems="center" justifyContent="end" gap={2} paddingTop={1}>
            <Button secondary onClick={onClose}>
              Cancel
            </Button>
            <Button loading={loading}>Add</Button>
          </Stack>
        </Form>
      </Box>
    </Modal>
  );
};

export default EditShippingModal;
