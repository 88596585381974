import { collection, getDocs, limit, query, where, orderBy } from "firebase/firestore";
import { UploadInput } from "../../types/product";
import { db } from ".";

export type ProductUpload = UploadInput & { id: string; artistId: string; productType?: string; imageId: string };

export const getProductUploads = async (artistId: string) => {
  try {
    const productUploadsRef = collection(db, "product_uploads");
    const productUploads = query(
      productUploadsRef,
      ...[where("artistId", "==", artistId), where("status", "==", "pending")],
      orderBy("createdAt", "desc"),
      limit(100)
    );
    const documentSnapshots = await getDocs(productUploads);
    const docs: ProductUpload[] = [];
    documentSnapshots.forEach((doc) => {
      docs.push({ id: doc.id, ...doc.data() } as ProductUpload);
    });
    return docs;
  } catch (error) {
    console.error("Error getting productUploads: ", error);
    throw error;
  }
};
