import { CircularProgress, IconButton, Stack, Typography } from "@mui/material";
import TextLink from "../../../../components/TextLink";
import { getProductFirebaseImageUrl } from "../../../../services/API";
import { getIdNumber } from "../../../../helpers/shopify";
import { useState } from "react";
import { fonts } from "../../../../theme";
import { ContentCopyRounded } from "@mui/icons-material";

type Props = {
  id: string;
};

const FirebaseActions = ({ id }: Props) => {
  const [copying, setCopying] = useState(false);

  const openFirebaseUrl = async () => {
    const firebaseUrl = await getProductFirebaseImageUrl(getIdNumber(id));
    window.open(firebaseUrl, "_blank");
  };

  const copyFirebaseUrl = async () => {
    setCopying(true);
    const firebaseUrl = await getProductFirebaseImageUrl(getIdNumber(id));
    navigator.clipboard.writeText(firebaseUrl);
    setCopying(false);
  };

  return (
    <Stack direction="row" alignItems="center" gap={0.5}>
      <TextLink onClick={openFirebaseUrl}>
        <Typography fontSize={14} fontFamily={fonts.body}>
          Open
        </Typography>
      </TextLink>
      <IconButton onClick={copyFirebaseUrl} disabled={copying}>
        {copying ? <CircularProgress size={16} /> : <ContentCopyRounded color="primary" sx={{ fontSize: 16 }} />}
      </IconButton>
    </Stack>
  );
};
export default FirebaseActions;
