import { FilledInput, InputAdornment } from "@mui/material";
import { CloseRounded, Search as SearchIcon } from "@mui/icons-material";

type Props = {
  inputValue: string;
  setInputValue: (value: string) => void;
};

const Search = ({ inputValue, setInputValue }: Props) => {
  return (
    <FilledInput
      placeholder="Search"
      onChange={(event) => setInputValue(event.target.value)}
      value={inputValue}
      sx={{ input: { paddingX: 1 } }}
      startAdornment={
        <InputAdornment position="start">
          <SearchIcon fontSize="small" />
        </InputAdornment>
      }
      endAdornment={
        <InputAdornment position="end">
          <CloseRounded
            color="action"
            fontSize="small"
            onClick={() => setInputValue("")}
            style={{ cursor: "pointer", visibility: inputValue ? "visible" : "hidden" }}
          />
        </InputAdornment>
      }
    />
  );
};

export default Search;
