import { useEffect, useState } from "react";
import { useToggle } from "react-use";
import { useSearchParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Badge, Stack, Typography } from "@mui/material";
import AutoResizeText from "../../components/AutoResizeText";
import Button from "../../components/Button";
import FrameBadges from "../../components/FrameBadges";
import { Frame } from "../../types/product";
import ArtQuiz from "./ArtQuiz";
import usePrintSets from "./usePrintSets";
import { useAppState } from "../../state";
import Switch from "../../components/Switch";
import PrintSetGrid from "./PrintSetGrid";
import BottomSheet from "../../components/BottomSheet";
import FrameBadge from "../../components/FrameBadges/FrameBadge";
import { colors } from "../../theme";
import { FiltersButton } from "../Shop/styles";
import { Icon } from "../../components/Icon";
import FiltersDrawer from "../Shop/Filters/Drawer";
import FiltersDesktopDrawer from "../Shop/Filters/DesktopDrawer";

const PrintSets = () => {
  const {
    selectedMediums,
    setSelectedMediums,
    selectedStyles,
    setSelectedStyles,
    producePersonalisedPrintSets,
    mediumImagery,
    styleImagery,
    printSets,
    personalisedPrintSets,
    fetchMore,
    hasMore,
    loading,
    fetchMorePersonalised,
    hasMorePersonalised,
    loadingPersonalised,
    clearFilters,
  } = usePrintSets();
  const [searchParams, setSearchParams] = useSearchParams();
  const artQuizParam = searchParams.get("art-quiz");
  const { isMobileScreen, navHeight } = useAppState();
  const [selectedImageStyle, setSelectedImageStyle] = useState<"sofa" | "bed">("sofa");
  const [showArtQuiz, setShowArtQuiz] = useState(false);
  const [selectedFrame, setSelectedFrame] = useState(Frame.Black);
  const [openFrameBar, setOpenFrameBar] = useState(false);
  const [showFilters, toggleShowFilters] = useToggle(false);
  let filterCount = 0;
  const filters = ["medium", "style", "subject", "orientation"];
  searchParams.forEach((value, key) => filters.includes(key) && value && filterCount++);

  const openArtQuiz = () => {
    setShowArtQuiz(true);
    window.scrollTo(0, 0);
  };

  const closeArtQuiz = () => {
    setShowArtQuiz(false);
    searchParams.delete("art-quiz");
    setSearchParams(searchParams);
  };

  useEffect(() => {
    if (artQuizParam === "true") {
      openArtQuiz();
    } else {
      closeArtQuiz();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [artQuizParam]);

  return (
    <>
      <Helmet>
        <title>Print Sets | GoodMood</title>
        <meta
          name="description"
          content="Transform your space with our carefully curated print sets. Personalise them with your preferences and make them uniquely yours"
        />
      </Helmet>
      <Stack gap={{ xs: 1 }} paddingTop={2} paddingBottom={{ xs: 3, md: 5 }} position="relative">
        <Stack gap={{ xs: 1, md: 2 }} paddingX={{ xs: 2, md: 5 }} alignItems="center" width="100%">
          <AutoResizeText text="Print Sets" align="center" maxSize={isMobileScreen ? 50 : 85} />
          <Typography width={{ xs: "100%", md: "50%" }} align="center" fontSize={{ xs: 14, md: 20 }}>
            Transform your space effortlessly with our curated print sets, or customise them with filters to match your unique
            style
          </Typography>
        </Stack>

        <Stack
          position="sticky"
          top={`${navHeight}px`}
          zIndex={1}
          bgcolor={colors.white}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          gap={1.5}
          paddingX={{ xs: 2, md: 5 }}
          paddingY={{ xs: 1, md: 2 }}
        >
          {isMobileScreen ? (
            <Badge badgeContent={filterCount} color="secondary">
              <FiltersButton onClick={toggleShowFilters}>
                <Stack direction="row" alignItems="center" gap={1}>
                  <Icon icon="filters" fill={colors.black} size={20} />
                </Stack>
              </FiltersButton>
            </Badge>
          ) : (
            <Stack direction="row" alignItems="center" gap={2} minWidth={255}>
              <FiltersButton onClick={toggleShowFilters}>
                <Stack direction="row" alignItems="center" gap={1}>
                  <Typography fontSize={12}>Filters{filterCount > 0 ? ` (${filterCount})` : ""}</Typography>
                  <Icon icon="filters" fill={colors.black} size={20} />
                </Stack>
              </FiltersButton>
              {filterCount > 0 && (
                <Button onClick={clearFilters} tertiary size="small" style={{ fontSize: 12 }}>
                  Clear
                </Button>
              )}
            </Stack>
          )}

          <Switch
            options={[
              { label: "Sofa", value: "sofa" },
              { label: "Bed", value: "bed" },
            ]}
            fullWidth={isMobileScreen}
            onChange={(value) => setSelectedImageStyle(value as "sofa" | "bed")}
            selected={selectedImageStyle}
          />

          {isMobileScreen ? (
            <Stack
              onClick={() => setOpenFrameBar(true)}
              component="button"
              position="relative"
              bgcolor={colors.white}
              border={`2px solid ${colors.cardGrey}`}
              borderRadius={100}
              padding={0.25}
            >
              <FrameBadge frame={selectedFrame} size={30} />
            </Stack>
          ) : (
            <Stack direction="row" alignItems="center" justifyContent="flex-end" minWidth={255}>
              <FrameBadges selectedFrame={selectedFrame} setSelectedFrame={setSelectedFrame} size="medium" />
            </Stack>
          )}
        </Stack>

        {showArtQuiz && (
          <ArtQuiz
            selectedMediums={selectedMediums}
            setSelectedMediums={setSelectedMediums}
            selectedStyles={selectedStyles}
            setSelectedStyles={setSelectedStyles}
            mediumImagery={mediumImagery}
            styleImagery={styleImagery}
            producePersonalisedPrintSets={producePersonalisedPrintSets}
            onClose={closeArtQuiz}
          />
        )}

        {personalisedPrintSets ? (
          <PrintSetGrid
            printSets={personalisedPrintSets}
            selectedFrame={selectedFrame}
            loading={loadingPersonalised}
            fetchMore={fetchMorePersonalised}
            hasMore={hasMorePersonalised}
            imageStyle={selectedImageStyle}
          />
        ) : (
          <PrintSetGrid
            printSets={printSets}
            selectedFrame={selectedFrame}
            loading={loading}
            fetchMore={fetchMore}
            hasMore={hasMore}
            imageStyle={selectedImageStyle}
          />
        )}

        {isMobileScreen ? (
          <FiltersDrawer
            isOpen={showFilters}
            onClose={toggleShowFilters}
            searchParams={searchParams}
            setSearchParams={setSearchParams}
            hideSortBy
            filterGroupsToHide={["size", "month"]}
          />
        ) : (
          <FiltersDesktopDrawer
            isOpen={showFilters}
            onClose={toggleShowFilters}
            filterCount={filterCount}
            searchParams={searchParams}
            setSearchParams={setSearchParams}
            filterGroupsToHide={["size", "month"]}
          />
        )}

        {isMobileScreen && (
          <BottomSheet open={openFrameBar} onClose={() => setOpenFrameBar(false)}>
            <Typography>Frame colour</Typography>
            <FrameBadges
              selectedFrame={selectedFrame}
              setSelectedFrame={(frame: Frame) => {
                setSelectedFrame(frame);
                setOpenFrameBar(false);
              }}
              size="large"
              showLabels
            />
          </BottomSheet>
        )}
      </Stack>
    </>
  );
};

export default PrintSets;
