import { Route, Routes } from "react-router";
import { ApolloProvider } from "@apollo/client";
import AuthGuard from "./components/AuthGuard";
import Invoice from "./pages/Admin/Tabs/Invoices/Invoice";
import Admin from "./pages/Admin";
import { adminClient } from "./helpers/apolloClients";
import Nav from "./components/Nav";
import CreateDraftOrder from "./pages/Admin/Tabs/Orders/CreateDraftOrder";
import DraftOrder from "./pages/Admin/Tabs/Orders/DraftOrder";

const AdminApp = () => {
  return (
    <ApolloProvider client={adminClient}>
      <Nav />
      <Routes>
        <Route path="/orders/create" element={<AuthGuard type="admin" element={CreateDraftOrder} />} />
        <Route path="/orders/:id" element={<AuthGuard type="admin" element={DraftOrder} />} />
        <Route path="/invoices/:id" element={<AuthGuard type="admin" element={Invoice} />} />
        <Route path="/" element={<AuthGuard type="admin" element={Admin} />} />
      </Routes>
    </ApolloProvider>
  );
};

export default AdminApp;
