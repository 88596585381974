import { useEffect, useState } from "react";
import { format } from "date-fns";
import { Stack } from "@mui/material";
import Button from "../../../../components/Button";
import {
  CountryCode,
  CurrencyCode,
  ProductSortKeys,
  useGetAdminProductsLazyQuery,
  useGetProductsForGmcLazyQuery,
  useGetProductsForTagsLazyQuery,
} from "../../../../generated/graphql";
import { getIdNumber } from "../../../../helpers/shopify";
import { sizeLabelsForCountry } from "../../../../helpers/product";
import { addTagsToProducts, removeFrameOptionFromProducts } from "../../../../helpers/scripts";
import { Size } from "../../../../types/product";
import { bestsellingIds } from "./constants";

const Extras = () => {
  const [getProductsForGmc, { data, fetchMore, loading }] = useGetProductsForGmcLazyQuery();
  const [getProducts] = useGetAdminProductsLazyQuery();
  const [getProductsForTags] = useGetProductsForTagsLazyQuery();
  const [generating, setGenerating] = useState(false);
  const countryCode = CountryCode.Gb;
  const currencyCode = CurrencyCode.Gbp;
  const products = data?.products.nodes || [];
  const hasMoreProducts = data?.products.pageInfo.hasNextPage;
  const endCursor = data?.products.pageInfo.endCursor;

  useEffect(() => {
    if (hasMoreProducts && endCursor) {
      fetchMore({
        variables: {
          afterCursor: endCursor,
        },
      });
    }
  }, [endCursor, fetchMore, hasMoreProducts]);

  useEffect(() => {
    if (products.length && !hasMoreProducts && !loading && !generating) {
      generateCsvOfProducts();
    }
  });

  const getAllProducts = async () => {
    const thisMonth = format(new Date(), "yyyy-MM");
    try {
      const result = await getProductsForGmc({
        fetchPolicy: "network-only",
        variables: {
          limit: 250,
          query: `tag:favourite status:ACTIVE product_type:${thisMonth}`,
          // query: `tag:favourite status:ACTIVE id:${gmcIds.join(" OR ")}`,
          sortKey: ProductSortKeys.CreatedAt,
          // reverse: true,
          country: countryCode,
        },
      });
      console.log("getProductsForGmc", result);
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  const generateCsvOfProducts = async () => {
    setGenerating(true);
    const csvPromises = products.map(async (product) => {
      const { id, title, descriptionHtml, images, variants } = product;
      const idNumber = getIdNumber(id);
      const sizeLables = sizeLabelsForCountry(countryCode);
      const productImages = images.nodes.map((image) => image.src);
      productImages.shift();
      const additionalImages = productImages.join(",");
      const description = descriptionHtml
        ?.replace(/<[^>]*>?/gm, "")
        .toString()
        .replace(/"/g, '""');
      const productDescription =
        "Premium fine art print, professionally printed on high-quality giclée paper for exceptional color accuracy and longevity. Our museum-grade prints ensure rich, vibrant details and a luxurious matte finish, making them perfect for home or office decor. Printed using archival inks to prevent fading, each piece is crafted to last a lifetime. Choose from our expertly handmade frames, designed to complement your artwork with a sleek, timeless finish. Elevate your space with timeless art, beautifully framed and made to impress. " +
        description;

      const variantRows = variants.nodes.map((variant) => {
        const variantIdNumber = getIdNumber(variant.id, "ProductVariant");
        const price = variant.contextualPricing.price.amount;
        const variantSize = variant.selectedOptions.find((option) => option.name === "size")?.value as Size | undefined;
        if (!variantSize) return "";
        const sizeLabel = sizeLables[variantSize].printMeasurements;
        if (!product.artistName) console.warn("No artist name found for product:", product);
        let productTitle = `${title} | ${product.artistName?.value} | Art Print | ${sizeLabel}`;
        const collectionLabel = product.collections.nodes[0]?.title.split(": ").pop() || "";
        if (collectionLabel && productTitle.length + collectionLabel.length <= 150) {
          productTitle = productTitle + ` | ${collectionLabel}`;
        }
        const bestseller = bestsellingIds.includes(Number(idNumber));
        return `${variantIdNumber},${idNumber},"${productTitle}","${productDescription}",in_stock,"https://www.goodmoodprints.com/products/${idNumber}","${
          images.nodes[0].src
        }","${additionalImages}",${price} ${currencyCode},no,GoodMood Prints,new,0.01 kg,500044,${collectionLabel},${
          bestseller ? "Bestseller" : ""
        }\n`;
      });

      const variantRowsString = variantRows.join("");
      return variantRowsString;
    });
    const csv = (await Promise.all(csvPromises)).join("");
    const csvString = `id,item_group_id,title,description,availability,link,image link,additional images,price,identifier exists,brand,condition,shipping weight,google category id,custom_label_01,custom_label_02\n${csv}`;
    const blob = new Blob([csvString], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "products.csv";
    a.click();
  };

  const removeFrameOption = async () => {
    let hasMoreProducts = true;
    let cursor = "";
    let count = 0;

    while (hasMoreProducts) {
      console.log("count", count);
      const { data } = await getProducts({
        fetchPolicy: "no-cache",
        variables: {
          limit: 200,
          query: `status:ACTIVE`,
          afterCursor: cursor,
        },
      });
      const products = data?.products?.nodes || [];
      hasMoreProducts = data?.products.pageInfo.hasNextPage || false;
      cursor = data?.products.pageInfo.endCursor || "";
      count += 1;
      console.log("products", products);
      const result = await removeFrameOptionFromProducts(products);
      console.log("result", result);
      const filteredResults = result.filter((res) => res);
      if (filteredResults.length > 0) {
        await new Promise((resolve) => setTimeout(resolve, 5000));
      }
    }
  };

  const tagProducts = async () => {
    let hasMoreProducts = true;
    let cursor = "eyJsYXN0X2lkIjoxNDgxMDU4NTU5NjI4MiwibGFzdF92YWx1ZSI6MTQ4MTA1ODU1OTYyODJ9";
    let count = 0;

    while (hasMoreProducts) {
      console.log("count", count);
      const { data } = await getProductsForTags({
        fetchPolicy: "no-cache",
        variables: {
          limit: 5,
          query: `status:ACTIVE tag:favourite`,
          afterCursor: cursor,
        },
      });
      if (!data?.products?.nodes.length) {
        console.log("No more products");
        break;
      }
      const products = data.products.nodes || [];
      hasMoreProducts = data.products.pageInfo.hasNextPage || false;
      cursor = data.products.pageInfo.endCursor || "";
      count += 1;
      console.log("data", data);
      const result = await addTagsToProducts(products);
      console.log("result", result);
      const filteredResults = result.filter((res) => res);
      if (filteredResults.length > 0) {
        await new Promise((resolve) => setTimeout(resolve, 60000));
        console.log("waited 60 seconds");
      }
    }
  };

  return (
    <Stack gap={2}>
      <h1>Extras</h1>
      <Stack gap={2} direction="row">
        <Button secondary size="medium" onClick={getAllProducts}>
          Download CSV of products
        </Button>

        <Button secondary size="medium" onClick={removeFrameOption}>
          Remove frame option
        </Button>

        <Button secondary size="medium" onClick={tagProducts}>
          Tag products
        </Button>
      </Stack>
    </Stack>
  );
};

export default Extras;
