import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { format } from "date-fns";
import { match } from "ts-pattern";
import { Box, Typography } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { DraftOrderStatus, useGetDraftOrdersQuery } from "../../../../generated/graphql";
import { dataGridStyles } from "../../styles";
import TextLink from "../../../../components/TextLink";
import { colors, fonts } from "../../../../theme";
import { formattedPrice } from "../../../../helpers/money";
import { getIdNumber } from "../../../../helpers/shopify";

const defaultPageSettings = {
  pageSize: 25,
  page: 0,
};

type Props = {
  timeRangeQuery: string;
};

const DraftOrders = ({ timeRangeQuery }: Props) => {
  const navigate = useNavigate();
  const [paginationModel, setPaginationModel] = useState(defaultPageSettings);
  const { data, loading, fetchMore } = useGetDraftOrdersQuery({
    variables: {
      limit: 100,
      query: timeRangeQuery,
      reverse: true,
    },
  });
  const hasMoreOrders = data?.draftOrders?.pageInfo?.hasNextPage;
  const afterCursor = data?.draftOrders?.pageInfo?.endCursor;
  const loadingOrders = hasMoreOrders || loading;
  const orders = data?.draftOrders?.nodes || [];

  const columns: GridColDef[] = [
    { field: "name", headerName: "Name", flex: 1.5 },
    { field: "date", headerName: "Date", flex: 1 },
    { field: "customer", headerName: "Customer", flex: 1 },
    { field: "total", headerName: "Total", flex: 1 },
    { field: "itemQty", headerName: "Item Qty", flex: 1 },
    {
      field: "orderLink",
      headerName: "Shopify Order Link",
      flex: 1,
      renderCell: (params) => (
        <TextLink
          openInNewTab
          href={`https://goodmoodprints.myshopify.com/admin/draft_orders/${getIdNumber(params.row.id, "DraftOrder")}`}
        >
          <Typography fontSize={14} fontFamily={fonts.body}>
            View
          </Typography>
        </TextLink>
      ),
    },
    {
      field: "country",
      headerName: "Country",
      flex: 1,
    },
    {
      field: "type",
      headerName: "Type",
      flex: 1,
      renderCell: (params) =>
        params.row.type ? (
          <Box
            display="flex"
            alignItems="center"
            bgcolor={params.row.type === "B2B" ? colors.blue : colors.peach}
            px={1}
            py={0.5}
            borderRadius={8}
          >
            <Typography fontSize={14} fontFamily={fonts.body}>
              {params.row.type}
            </Typography>
          </Box>
        ) : null,
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      renderCell: (params) =>
        match(params.row.status)
          .with(DraftOrderStatus.Open, () => (
            <TextLink>
              <Typography fontSize={14} fontFamily={fonts.body}>
                Edit
              </Typography>
            </TextLink>
          ))
          .with(DraftOrderStatus.InvoiceSent, () => (
            <Box bgcolor={colors.peach} px={1} py={0.5} borderRadius={8}>
              <Typography fontSize={14} fontFamily={fonts.body}>
                Invoice sent
              </Typography>
            </Box>
          ))
          .with(DraftOrderStatus.Completed, () => (
            <Box bgcolor={colors.mint} px={1} py={0.5} borderRadius={8}>
              <Typography fontSize={14} fontFamily={fonts.body}>
                Completed
              </Typography>
            </Box>
          ))
          .exhaustive(),
    },
  ];

  const formattedOrders = (orders || []).map(
    ({
      id,
      name,
      customer,
      lineItems,
      lineItemsSubtotalPrice,
      createdAt,
      shippingAddress,
      tags,
      status,
      order,
      customAttributes,
    }) => {
      const refName = customAttributes.find((attr) => attr.key === "name")?.value || name;
      const date = format(new Date(createdAt), "dd MMM yyyy");
      return {
        id,
        name: refName,
        date,
        customer: customer?.displayName || "Guest",
        total: formattedPrice(
          lineItemsSubtotalPrice.presentmentMoney.amount,
          lineItemsSubtotalPrice.presentmentMoney.currencyCode
        ),
        itemQty: lineItems.nodes.length,
        country: shippingAddress?.country || "",
        type: tags.find((tag) => tag === "B2B") ? "B2B" : tags.find((tag) => tag === "Sample") ? "Sample" : "",
        status,
        orderId: order?.id,
      };
    }
  );

  useEffect(() => {
    if (hasMoreOrders && afterCursor) {
      fetchMore({
        variables: {
          query: timeRangeQuery,
          afterCursor,
        },
      });
    }
  }, [afterCursor, fetchMore, hasMoreOrders, timeRangeQuery]);

  return (
    <DataGrid
      loading={loadingOrders}
      rows={formattedOrders}
      columns={columns}
      pageSizeOptions={[]}
      pagination
      rowCount={orders?.length}
      rowHeight={50}
      paginationModel={paginationModel}
      disableRowSelectionOnClick
      onRowClick={({ row }) => navigate(`/admin/orders/${getIdNumber(row.id, "DraftOrder")}`)}
      onPaginationModelChange={setPaginationModel}
      style={{ height: loadingOrders ? "70vh" : "auto", minHeight: "70vh", width: "100%" }}
      sx={dataGridStyles}
    />
  );
};

export default DraftOrders;
