import { FormEvent, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { match } from "ts-pattern";
import { format } from "date-fns";
import { MenuItem, OutlinedInput, Select, Stack, Typography } from "@mui/material";
import { KeyboardArrowDownRounded } from "@mui/icons-material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DateRange, DateRangePicker } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { formattedPrice } from "../../../../helpers/money";
import TextLink from "../../../../components/TextLink";
import { getIdNumber } from "../../../../helpers/shopify";
import { getOrderItemTotals } from "../../../../helpers/prices";
import { CurrencyCode } from "../../../../generated/storefront";
import { useGetAdminOrdersQuery } from "../../../../generated/graphql";
import Button from "../../../../components/Button";
import { fonts } from "../../../../theme";
import Switch from "../../../../components/Switch";
import MonthPicker from "../../../../components/MonthPicker";
import { dataGridStyles } from "../../styles";
import Modal from "../../../../components/Modal";
import { Form } from "../../../../components/Layout";
import DraftOrders from "./DraftOrders";
import Tabs from "../../../../components/Tabs";
import { currencies } from "../../../../state/constants";

const defaultPageSettings = {
  pageSize: 25,
  page: 0,
};

type Props = {
  selectedTimeRange: "month" | "range";
  setSelectedTimeRange: (value: "month" | "range") => void;
  month: Date;
  setMonth: (value: Date) => void;
  dateRange: DateRange<string>;
  setDateRange: (value: DateRange<string>) => void;
  timeRangeQuery: string;
  goToPreviousMonth: () => void;
  goToNextMonth: () => void;
};

const Orders = ({
  selectedTimeRange,
  setSelectedTimeRange,
  month,
  setMonth,
  dateRange,
  setDateRange,
  timeRangeQuery,
  goToPreviousMonth,
  goToNextMonth,
}: Props) => {
  const navigate = useNavigate();
  const [newOrderName, setNewOrderName] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const orderType = searchParams.get("orderType") as "orders" | "draft_orders" | null;
  const [selectedOrderType, setSelectedOrderType] = useState<"orders" | "draft_orders">(orderType || "orders");
  const [createOrderModalOpen, setCreateOrderModalOpen] = useState(false);
  const [paginationModel, setPaginationModel] = useState(defaultPageSettings);
  const [createOrderType, setCreateOrderType] = useState<"B2B" | "Sample">("B2B");
  const [currency, setCurrency] = useState(CurrencyCode.Gbp);
  const { data, loading, fetchMore } = useGetAdminOrdersQuery({
    variables: {
      limit: 250,
      query: timeRangeQuery + " NOT tag:Sample AND NOT tag:B2B",
    },
  });
  const hasMoreOrders = data?.orders?.pageInfo?.hasNextPage;
  const afterCursor = data?.orders?.pageInfo?.endCursor;
  const loadingOrders = hasMoreOrders || loading;
  const orders = data?.orders?.nodes || [];

  const columns: GridColDef[] = [
    { field: "orderId", headerName: "Order ID", flex: 1 },
    { field: "date", headerName: "Date", flex: 1 },
    { field: "customer", headerName: "Customer", flex: 1 },
    { field: "total", headerName: "Total", flex: 1 },
    { field: "discountedTotal", headerName: "Discounted Total", flex: 1 },
    { field: "discountCode", headerName: "Discount Code", flex: 1 },
    { field: "itemQty", headerName: "Item Qty" },
    {
      field: "orderLink",
      headerName: "Shopify Order Link",
      renderCell: (params) => (
        <TextLink openInNewTab href={`https://goodmoodprints.myshopify.com/admin/orders/${getIdNumber(params.row.id, "Order")}`}>
          <Typography fontSize={14} fontFamily={fonts.body}>
            View
          </Typography>
        </TextLink>
      ),
    },
  ];

  const onOrderNameSubmit = async (event: FormEvent) => {
    event.preventDefault();
    navigate("/admin/orders/create", { state: { name: newOrderName, orderType: createOrderType, currency } });
  };

  const formattedOrders = (orders || []).map(({ id, name, customer, discountCode, lineItems, createdAt, commission }) => {
    const date = format(new Date(createdAt), "dd MMM yyyy");
    const commissionValues = commission ? JSON.parse(commission.value) : {};
    const { orderTotal, discountTotal } = getOrderItemTotals(
      lineItems.nodes.map((i) => ({
        ...i,
        commissionValue: commissionValues[getIdNumber(i.id, "LineItem")],
        orderCreatedAt: createdAt,
      }))
    );
    return {
      id,
      orderId: name,
      date,
      customer: customer.firstName + " " + customer.lastName,
      total: formattedPrice(orderTotal, CurrencyCode.Gbp),
      discountedTotal: formattedPrice(orderTotal - discountTotal, CurrencyCode.Gbp),
      discountCode,
      itemQty: lineItems.nodes.length,
    };
  });

  useEffect(() => {
    if (hasMoreOrders && afterCursor) {
      fetchMore({
        variables: {
          afterCursor,
        },
      });
    }
  }, [afterCursor, fetchMore, hasMoreOrders]);

  return (
    <Stack gap={2} alignItems="start">
      <Tabs
        tabs={[
          { label: "Orders", value: "orders" },
          { label: "Draft Orders", value: "draft_orders" },
        ]}
        selectedTab={selectedOrderType}
        onTabChange={(value) => {
          setSelectedOrderType(value as "orders" | "draft_orders");
          searchParams.set("orderType", value);
          setSearchParams(searchParams);
        }}
      />
      <Stack direction="row" alignItems="center" justifyContent="space-between" gap={2} width="100%">
        <Switch
          options={[
            { label: "Month", value: "month" },
            { label: "Range", value: "range" },
          ]}
          selected={selectedTimeRange}
          onChange={(value) => setSelectedTimeRange(value as "month" | "range")}
        />
        <Stack direction="row" gap={1} alignItems="center">
          {match(selectedTimeRange)
            .with("month", () => (
              <MonthPicker
                month={month}
                setMonth={setMonth}
                loading={loadingOrders}
                goToNextMonth={goToNextMonth}
                goToPreviousMonth={goToPreviousMonth}
              />
            ))
            .with("range", () => (
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateRangePicker
                  value={dateRange}
                  onChange={setDateRange}
                  localeText={{ start: "Start", end: "End" }}
                  disableFuture
                />
              </LocalizationProvider>
            ))
            .exhaustive()}
        </Stack>
        <Stack direction="row" alignItems="center" justifyContent="end" flexGrow={1}>
          <Button size="small" secondary onClick={() => setCreateOrderModalOpen(true)}>
            Create order
          </Button>
        </Stack>
      </Stack>
      {match(selectedOrderType)
        .with("orders", () => (
          <DataGrid
            loading={loadingOrders}
            rows={formattedOrders}
            columns={columns}
            pageSizeOptions={[]}
            pagination
            rowCount={orders?.length}
            rowHeight={50}
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
            style={{ height: loadingOrders ? "70vh" : "auto", minHeight: "70vh", width: "100%" }}
            sx={dataGridStyles}
          />
        ))
        .with("draft_orders", () => <DraftOrders timeRangeQuery={timeRangeQuery} />)
        .exhaustive()}
      {createOrderModalOpen && (
        <Modal size="small" onClose={() => setCreateOrderModalOpen(false)}>
          <Form onSubmit={onOrderNameSubmit}>
            <Typography variant="h2">Order</Typography>
            <Switch
              options={[
                {
                  label: "B2B",
                  value: "B2B",
                },
                {
                  label: "Sample",
                  value: "Sample",
                },
              ]}
              selected={createOrderType}
              onChange={(value) => setCreateOrderType(value as "B2B" | "Sample")}
              fullWidth
            />
            <OutlinedInput placeholder="Name" id="name" name="name" onChange={(e) => setNewOrderName(e.target.value)} />

            <Stack gap={0.5}>
              <Typography>Currency</Typography>
              <Select
                value={currency}
                onChange={(e) => setCurrency(e.target.value as CurrencyCode)}
                placeholder="Currency"
                renderValue={(selected) => currencies.find((o) => o.value === selected)?.label || "Currency"}
                IconComponent={KeyboardArrowDownRounded}
              >
                {currencies.map((currency) => (
                  <MenuItem key={currency.value} value={currency.value}>
                    {currency.label}
                  </MenuItem>
                ))}
              </Select>
            </Stack>

            <Button disabled={!newOrderName} loading={loading}>
              Create
            </Button>
          </Form>
        </Modal>
      )}
    </Stack>
  );
};

export default Orders;
