import { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { Chip, Grid, Stack, Typography } from "@mui/material";
import { User } from "../../../../types/user";
import { Loader } from "../../../Loader";
import SearchProductCard from "../SearchProductCard";
import { CountryCode, useGetCollectionByHandleQuery } from "../../../../generated/storefront";
import { allFilterOptions, styleFilterOptions } from "../../../../state/constants";
import ArtistCard from "../../../Card/Artist";
import { colors } from "../../../../theme";

type Props = {
  artists: User[];
  isMobile?: boolean;
};

const TrendingSearchTab = ({ artists, isMobile = false }: Props) => {
  const [tags, setTags] = useState<{ label: string; value: string }[]>();
  const [loadingTags, setLoadingTags] = useState(false);
  const { data, loading } = useGetCollectionByHandleQuery({
    variables: {
      handle: "trending",
      country: CountryCode.Gb,
    },
  });
  const trendingProducts = useMemo(() => data?.collection?.products?.nodes || [], [data]);

  const getTop5MostCommonTags = (tags: string[]) => {
    const counts: Record<string, number> = {};
    tags.forEach((tag) => {
      counts[tag] = (counts[tag] || 0) + 1;
    });
    const entries = Object.entries(counts);
    entries.sort((a, b) => b[1] - a[1]);
    return entries.slice(0, 5).map((entry) => entry[0]);
  };

  useEffect(() => {
    if (tags || loadingTags) return;
    setLoadingTags(true);
    const trendingTags = trendingProducts
      .map((product) => product.tags)
      .flat()
      .filter((tag) => tag.startsWith("gm."));
    const tagsToDisplay = allFilterOptions.filter((option) => trendingTags.includes(`gm.${option.value}`));
    const top5Tags = getTop5MostCommonTags(tagsToDisplay.map((tag) => tag.value));
    setTags(allFilterOptions.filter((option) => top5Tags.includes(option.value)));
    setLoadingTags(false);
  }, [tags, trendingProducts, loadingTags]);

  if (loadingTags || loading)
    return (
      <Stack gap={3}>
        <Loader />
      </Stack>
    );

  return (
    <Stack gap={3}>
      <Stack gap={1} width="100%" direction="row" flexWrap="wrap">
        {tags?.map((tag) => (
          <Link
            to={{
              pathname: "/shop",
              search: styleFilterOptions.find((o) => o.value === tag.value) ? `?style=${tag.value}` : `?subject=${tag.value}`,
            }}
            key={tag.value}
          >
            <Chip
              label={tag.label}
              color="info"
              clickable
              sx={{ color: colors.darkPurple, backgroundColor: colors.purple20, fontSize: 14, padding: "8px 16px" }}
            />
          </Link>
        ))}
      </Stack>

      <Stack gap={1} width="100%">
        <Typography variant="h4">Artwork</Typography>
        <Grid container spacing={1}>
          {trendingProducts.slice(0, 3).map((product) => (
            <Grid item xs={12 / 3} key={product.id}>
              <SearchProductCard product={product} />
            </Grid>
          ))}
        </Grid>
      </Stack>

      <Stack gap={1} width="100%">
        <Typography variant="h4">Artists</Typography>
        <Grid container spacing={1} rowSpacing={2}>
          {artists.map((artist) => (
            <Grid item xs={12 / 3} key={artist.id}>
              <ArtistCard artist={artist} size="small" imageHeight={isMobile ? "30vw" : "150px"} borderRadius={12} />
            </Grid>
          ))}
        </Grid>
      </Stack>
    </Stack>
  );
};

export default TrendingSearchTab;
