import axios from "axios";

type ProductDetails = {
  sku: string;
  description: string;
  attributes: Record<string, string[]>;
};

export const getProductDetails = async (sku: string): Promise<ProductDetails> => {
  try {
    const { data } = await axios.post("/.netlify/functions/getProductDetails", { sku });
    return data;
  } catch (error) {
    console.error(`Error fetching product details for ${sku}`);
    throw error;
  }
};
