import { Box, Stack } from "@mui/material";
import { Text } from "../Text";
import Button from "../Button";
import { colors } from "../../theme";
import { useAppState } from "../../state";
import { Icon } from "../Icon";

type Props = {
  onClose: () => void;
};

const CookieBanner = ({ onClose }: Props) => {
  const { isMobileScreen } = useAppState();
  const padding = isMobileScreen ? "8px" : "12px";
  return (
    <Box
      bgcolor={colors.white}
      boxShadow="2px 2px 10px rgba(0,0,0,0.08)"
      width={isMobileScreen ? "90%" : 350}
      paddingX={{ xs: 2, md: 3 }}
      paddingY={2}
      position="fixed"
      bottom={20}
      right={20}
      borderRadius={2}
      zIndex={10}
    >
      <Stack gap={2}>
        <Text size={isMobileScreen ? 16 : 14}>This website uses cookies to ensure you receive the best possible experience</Text>
        <Stack direction="row" gap={1}>
          <Button secondary onClick={onClose} size="small" fullWidth>
            Close
          </Button>
          <Button onClick={onClose} size="small" fullWidth>
            Got it
          </Button>
        </Stack>
      </Stack>
      <button
        onClick={onClose}
        style={{
          display: "flex",
          background: "none",
          border: 0,
          cursor: "pointer",
          position: "absolute",
          padding: 0,
          top: padding,
          right: padding,
        }}
      >
        <Icon icon="close" fill={colors.grey60} size={12} />
      </button>
    </Box>
  );
};

export default CookieBanner;
