import React, { useState, useRef, useEffect, PropsWithChildren } from "react";
// @ts-ignore
import { Markup } from "react-render-markup";
import styled from "styled-components";
import { Stack, Typography } from "@mui/material";
import { TypographyProps } from "@mui/material/Typography";

const ExpandableText = styled(Typography)<{ expanded: boolean }>`
  line-height: 1.5;
  max-height: ${({ expanded }) => (expanded ? "none" : "calc(1.8em * 2)")};
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: ${({ expanded }) => (expanded ? "unset" : "2")};
  -webkit-box-orient: vertical;
`;

const ReadMoreText: React.FC<PropsWithChildren<TypographyProps & { markup?: boolean }>> = ({ children, markup, ...props }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isTruncated, setIsTruncated] = useState(false);
  const paragraphRef = useRef<HTMLParagraphElement>(null);

  useEffect(() => {
    const checkOverflow = () => {
      const paragraphElement = paragraphRef.current;
      if (paragraphElement) {
        const lineHeight = parseInt(window.getComputedStyle(paragraphElement).lineHeight, 10);
        const maxHeight = lineHeight * 2;
        if (paragraphElement.scrollHeight > maxHeight) {
          setIsTruncated(true);
        } else {
          setIsTruncated(false);
        }
      }
    };

    checkOverflow();
    window.addEventListener("resize", checkOverflow);

    return () => {
      window.removeEventListener("resize", checkOverflow);
    };
  }, []);

  return (
    <Stack
      alignItems={props.align || "center"}
      justifyContent="center"
      gap={0.5}
      width="100%"
      sx={{ cursor: isTruncated ? "pointer" : "initial" }}
    >
      <ExpandableText ref={paragraphRef} expanded={isExpanded} onClick={() => setIsExpanded(!isExpanded)} {...props}>
        {markup ? <Markup markup={children} style={{ margin: 0 }} /> : children}
      </ExpandableText>
    </Stack>
  );
};

export default ReadMoreText;
