import { Box, Stack, Typography } from "@mui/material";
import { StarRounded } from "@mui/icons-material";
import artist1 from "../../assets/images/artist-section/artist_01.png";
import artist2 from "../../assets/images/artist-section/artist_02.png";
import artist3 from "../../assets/images/artist-section/artist_03.png";
import { colors } from "../../theme";
import { useAppState } from "../../state";

const ArtistSection = () => {
  const { isMobileScreen } = useAppState();
  const height = isMobileScreen ? "40vh" : "60vh";

  return (
    <Stack paddingX={{ xs: 2, md: 5 }} direction={{ xs: "column", md: "row" }} gap={6}>
      <Box width={{ xs: "100%", md: "30%" }}>
        <Stack gap={1}>
          <Typography variant="h2">By Artists, For Artists</Typography>
          <Typography fontSize={14} color={colors.grey60}>
            At GoodMood we're here to help you find something that is uniquely yours, whilst supporting a sustainable creator
            economy
          </Typography>
        </Stack>
        <Box marginTop={5}>
          <Stack gap={3}>
            <Stack gap={1.5} direction="row">
              <StarRounded />
              <Stack gap={0.2}>
                <Typography>Artist first</Typography>
                <Typography fontSize={14} color={colors.grey60}>
                  Empowering independent creators and showcasing the faces behind their work
                </Typography>
              </Stack>
            </Stack>

            <Stack gap={1.5} direction="row">
              <StarRounded />
              <Stack gap={0.2}>
                <Typography>We believe in fair and proper pay</Typography>
                <Typography fontSize={14} color={colors.grey60}>
                  Unlike other platforms, our artists keep 70% of the profits, on average 3x more than anywhere else
                </Typography>
              </Stack>
            </Stack>

            <Stack gap={1.5} direction="row">
              <StarRounded />
              <Stack gap={0.2}>
                <Typography>We dig up hidden gems</Typography>
                <Typography fontSize={14} color={colors.grey60}>
                  Our monthly drops are open to anyone. This enables us to unearth hidden gems you won't find anywhere else
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </Box>
      </Box>

      <Box width={{ xs: "100%", md: "70%" }}>
        <Stack
          gap={{ xs: 1.5, md: 2 }}
          overflow="auto"
          direction="row"
          marginRight={{ xs: -2, md: -5 }}
          marginLeft={{ xs: -2, md: 0 }}
          paddingRight={{ xs: 2, md: 5 }}
          paddingLeft={{ xs: 2, md: 0 }}
        >
          <img src={artist1} alt="artist close-up" width="auto" height="auto" style={{ height }} />
          <img src={artist2} alt="artist close-up" width="auto" height="auto" style={{ height }} />
          <img src={artist3} alt="artist close-up" width="auto" height="auto" style={{ height }} />
        </Stack>
      </Box>
    </Stack>
  );
};

export default ArtistSection;
