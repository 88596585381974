import { Dispatch, SetStateAction } from "react";
import { Header, Text } from "../../components/Text";
import TextLink from "../../components/TextLink";
import { Tab } from ".";

type Props = {
  setSelectedTab: Dispatch<SetStateAction<Tab>>;
};

const TermsOfUse = ({ setSelectedTab }: Props) => {
  return (
    <>
      <Text size={18}>PLEASE READ THESE TERMS AND CONDITIONS CAREFULLY BEFORE USING THIS SITE</Text>
      <Header type="h2" margin="16px 0 0">
        WEBSITE TERMS OF USE
      </Header>
      <Text size={18}>
        goodmoodprints.com (“our site”) is a site where artists can submit designs for application by us to products, which we
        sell to buyers on our site.
      </Text>
      <Text size={18}>
        These terms of use (together with the documents referred to in them) tell you the terms of use on which you may browse our
        website (our site), whether as a guest or as a registered user. Use of our site includes accessing, browsing, or
        registering to use our site.
      </Text>
      <Text size={18}>
        By browsing our site, you confirm that you accept these terms of use and that you agree to comply with them.
      </Text>
      <Text size={18}>If you do not agree to these terms of use, you must not use our site.</Text>

      <Header type="h2" margin="16px 0 0">
        OTHER APPLICABLE TERMS
      </Header>
      <Text size={18}>These terms of use refer to the following additional terms, which also apply to your use of our site:</Text>
      <Text size={18}>
        Our{" "}
        <TextLink openInNewTab to="/privacy">
          Privacy Policy
        </TextLink>
        , which sets out the terms on which we process any personal data we collect from you, or that you provide to us. By using
        our site, you consent to such processing and you warrant that all data provided by you is accurate.
      </Text>
      <Text size={18}>
        Our acceptable use policy, which sets out the permitted uses and prohibited uses of our site. When using our site, you
        must comply with this acceptable use policy.
      </Text>
      <Text size={18}>The following terms may also apply to you: </Text>
      <Text size={18}>
        If you purchase garments using our site, our <TextLink onClick={() => setSelectedTab(2)}>Terms for Buyers</TextLink> will
        apply to you.
      </Text>
      <Text size={18}>
        If you use our site to sell garments to buyers, our{" "}
        <TextLink onClick={() => setSelectedTab(1)}>Terms for Artists</TextLink> will apply to you.
      </Text>

      <Header type="h2" margin="16px 0 0">
        INFORMATION ABOUT US
      </Header>
      <Text size={18}>
        goodmoodprints.com is a site operated by GoodMood Prints Ltd (we or us). We are a limited company registered in England
        and Wales under company number 14180872 and our registered office is Avins Farm, College Road, Ardingly, West Sussex, RH17
        6SH.
      </Text>

      <Header type="h2" margin="16px 0 0">
        CHANGES TO THESE TERMS
      </Header>
      <Text size={18}>We may revise these terms of use at any time by amending this page.</Text>
      <Text size={18}>
        Please check this page from time to time to take notice of any changes we make, as they are binding on you.
      </Text>

      <Header type="h2" margin="16px 0 0">
        CHANGES TO OUR SITE
      </Header>
      <Text size={18}>
        We may update our site from time to time, and may change the content at any time. However, please note that any of the
        content on our site may be out of date at any given time, and we are under no obligation to update it.
      </Text>

      <Header type="h2" margin="16px 0 0">
        ACCESSING OUR SITE
      </Header>
      <Text size={18}>Our site is made available free of charge.</Text>
      <Text size={18}>
        We do not guarantee that our site, or any content on it, will always be available or be uninterrupted. Access to our site
        is permitted on a temporary basis only. We may suspend, withdraw, discontinue or change all or any part of our site
        without notice. We will not be liable to you if for any reason our site is unavailable at any time or for any period.
      </Text>
      <Text size={18}>
        You are responsible for making all arrangements necessary for you to have access to our site. You are also responsible for
        ensuring that all persons who access our site through your internet connection are aware of these terms of use and other
        applicable terms and conditions, and that they comply with them.
      </Text>
      <Text size={18}>
        Our site is directed to people residing in the United Kingdom. We do not represent that content available on or through
        our site is appropriate or available in other locations. We may limit the availability of our site or any service or
        product described on our site to any person or geographic area at any time. If you choose to access our site from outside
        the United Kingdom, you do so at your own risk.
      </Text>

      <Header type="h2" margin="16px 0 0">
        YOUR ACCOUNT AND PASSWORD
      </Header>
      <Text size={18}>
        If you choose, or you are provided with, a user identification code, password or any other piece of information as part of
        our security procedures, you must treat such information as confidential. You must not disclose it to any third party.
      </Text>
      <Text size={18}>
        We have the right to disable any user identification code or password, whether chosen by you or allocated by us, at any
        time, if in our reasonable opinion you have failed to comply with any of the provisions of these terms of use.
      </Text>
      <Text size={18}>
        If you know or suspect that anyone other than you knows your user identification code or password, you must promptly
        notify us at <TextLink href="mailto:hello@goodmoodprints.io">hello@goodmoodprints.io</TextLink>
      </Text>

      <Header type="h2" margin="16px 0 0">
        INTELLECTUAL PROPERTY RIGHTS
      </Header>
      <Text size={18}>
        We are the owner or the licensee of all intellectual property rights in our site, and in the material published on it.
        Those works are protected by copyright laws and treaties around the world. All such rights are reserved. The intellectual
        property rights in the branding and designs of creators on Everpress belong to the relevant creator.
      </Text>
      <Text size={18}>
        You may print off one copy, and may download extracts, of any page(s) from our site for your personal use, and you may
        draw the attention of others within your organisation to content posted on our site.
      </Text>
      <Text size={18}>
        You must not modify the paper or digital copies of any materials you have printed off or downloaded in any way, and you
        must not use any illustrations, photographs, video or audio sequences or any graphics separately from any accompanying
        text.
      </Text>
      <Text size={18}>
        Our status (and that of any identified contributors) as the authors of content on our site must always be acknowledged.
      </Text>
      <Text size={18}>
        You must not use any part of the content on our site for commercial purposes without obtaining a licence to do so from us
        or from our licensors (as the case may be).
      </Text>
      <Text size={18}>
        If you print off, copy or download any part of our site in breach of these terms of use, your right to use our site will
        cease immediately and you must, at our option, return or destroy any copies of the materials you have made.
      </Text>

      <Header type="h2" margin="16px 0 0">
        NO RELIANCE ON INFORMATION
      </Header>
      <Text size={18}>
        The content on our site is provided for general information only. It is not intended to amount to advice on which you
        should rely. You must obtain professional or specialist advice before taking, or refraining from, any action on the basis
        of the content on our site.
      </Text>
      <Text size={18}>
        Although we make reasonable efforts to update the information on our site, we make no representations, warranties or
        guarantees, whether express or implied, that the content on our site is accurate, complete or up-to-date.
      </Text>

      <Header type="h2" margin="16px 0 0">
        LIMITATION OF OUR LIABILITY
      </Header>
      <Text size={18}>
        Nothing in these terms of use excludes or limits our liability for death or personal injury arising from our negligence,
        or our fraud or fraudulent misrepresentation, or any other liability that cannot be excluded or limited by English law.
      </Text>
      <Text size={18}>
        To the extent permitted by law, we exclude all conditions, warranties, representations or other terms which may apply to
        our site or any content on it, whether express or implied.
      </Text>
      <Text size={18}>
        We will not be liable to any user for any loss or damage, whether in contract, tort (including negligence), breach of
        statutory duty, or otherwise, even if foreseeable, arising under or in connection with: use of, or inability to use, our
        site; or use of or reliance on any content displayed on our site.
      </Text>
      <Text size={18}>
        If you are a business user, please note that in particular, we will not be liable for: loss of profits, sales, business,
        or revenue; business interruption; loss of anticipated savings; loss of business opportunity, goodwill or reputation; or
        any indirect or consequential loss or damage.
      </Text>
      <Text size={18}>
        If you are a consumer user, please note that we only provide our site for domestic and private use. You agree not to use
        our site for any commercial or business purposes, and we have no liability to you for any loss of profit, loss of
        business, business interruption, or loss of business opportunity.
      </Text>
      <Text size={18}>
        We will not be liable for any loss or damage caused by a virus, distributed denial-of-service attack, or other
        technologically harmful material that may infect your computer equipment, computer programs, data or other proprietary
        material due to your use of our site or to your downloading of any content on it, or on any website linked to it. We
        assume no responsibility for the content of websites linked on our site. Such links should not be interpreted as
        endorsement by us of those linked websites. We will not be liable for any loss or damage that may arise from your use of
        them.
      </Text>
      <Text size={18}>
        Different limitations and exclusions of liability will apply to liability arising as a result of: the supply of any goods
        by us to you, which will be set out in our <TextLink onClick={() => setSelectedTab(2)}>Terms for Buyers</TextLink> or you
        subscribing to any services through our site, which will be set out in our{" "}
        <TextLink onClick={() => setSelectedTab(1)}>Terms for Artists</TextLink>
      </Text>

      <Header type="h2" margin="16px 0 0">
        HOW WE MAY USE YOUR PERSONAL INFORMATION
      </Header>
      <Text size={18}>
        We will only use your personal information as set out in our{" "}
        <TextLink openInNewTab to="/privacy">
          Privacy Policy
        </TextLink>
      </Text>

      <Header type="h2" margin="16px 0 0">
        VIRUSES
      </Header>
      <Text size={18}>We do not guarantee that our site will be secure or free from bugs or viruses.</Text>
      <Text size={18}>
        You are responsible for configuring your information technology, computer programmes and platform in order to access our
        site. You should use your own virus protection software.
      </Text>
      <Text size={18}>
        You must not misuse our site by knowingly introducing viruses, Trojans, worms, logic bombs or other material which is
        malicious or technologically harmful.
      </Text>
      <Text size={18}>
        You must not attempt to gain unauthorised access to our site, the server on which our site is stored, or any server,
        computer or database connected to our site. You must not attack our site via a denial-of-service attack or a distributed
        denial-of service attack. By breaching this provision, you would commit a criminal offence under the Computer Misuse Act
        1990. We will report any such breach to the relevant law enforcement authorities, and we will co-operate with those
        authorities by disclosing your identity to them. In the event of such a breach, your right to use our site will cease
        immediately.
      </Text>

      <Header type="h2" margin="16px 0 0">
        LINKING TO OUR SITE
      </Header>
      <Text size={18}>
        You may link to our site, provided you do so in a way that is fair and legal and does not damage our reputation or take
        advantage of it.
      </Text>
      <Text size={18}>
        You must not establish a link in such a way as to suggest any form of association, approval or endorsement on our part
        where none exists.
      </Text>
      <Text size={18}>
        You must not establish a link to our site in any website that is not owned by you. Our site must not be framed on any
        other site. We reserve the right to withdraw linking permission without notice.
      </Text>
      <Text size={18}>
        The website in which you are linking must comply in all respects with the content standards set out in our{" "}
        <TextLink onClick={() => setSelectedTab(4)}>Acceptable Use Policy</TextLink>
      </Text>
      <Text size={18}>
        If you wish to make any use of content on our site other than that set out above, please contact{" "}
        <TextLink href="mailto:hello@goodmoodprints.io">hello@goodmoodprints.io</TextLink>
      </Text>

      <Header type="h2" margin="16px 0 0">
        THIRD PARTY LINKS AND RESOURCES IN OUR SITE
      </Header>
      <Text size={18}>
        Where our site contains links to other sites and resources provided by third parties, these links are provided for your
        information only.
      </Text>
      <Text size={18}>
        We have no control over the contents of those sites or resources. You are advised to check the terms that apply to your
        use of any third party sites you enter, in particular any privacy policy that will apply to you when you use the site in
        question.
      </Text>

      <Header type="h2" margin="16px 0 0">
        APPLICABLE LAW
      </Header>
      <Text size={18}>
        If you are a consumer, please note that these terms of use, their subject matter and formation, are governed by English
        law. You and we both agree that the courts of England and Wales will have non-exclusive jurisdiction. However, if you are
        a resident of Northern Ireland you may also bring proceedings in Northern Ireland, and if you are resident of Scotland,
        you may also bring proceedings in Scotland.
      </Text>
      <Text size={18}>
        If you are a business, these terms of use, their subject matter and formation (and any non-contractual disputes or claims)
        are governed by English law. We both agree to the exclusive jurisdiction of the courts of England and Wales.
      </Text>

      <Header type="h2" margin="16px 0 0">
        TRADE MARKS
      </Header>
      <Text size={18}>GoodMood is a UK registered trade mark of GoodMood Prints Ltd.</Text>

      <Header type="h2" margin="16px 0 0">
        CONTACT US
      </Header>
      <Text size={18}>
        To contact us, please email <TextLink href="mailto:hello@goodmoodprints.io">hello@goodmoodprints.io</TextLink>. Thank you
        for visiting our site.
      </Text>
    </>
  );
};

export default TermsOfUse;
