import axios from "axios";
import { addMonths, format } from "date-fns";
import { CurrencyCode, DraftOrderFragment } from "../../generated/graphql";
import { getExVatPrice } from "../../pages/Admin/Tabs/Orders/helpers";

type Invoice = {
  type: string;
  contact: {
    contactID: string;
  };
  date: string;
  dueDate: string;
  lineAmountTypes: string;
  lineItems: {
    description: string;
    quantity: number;
    unitAmount: number;
    accountCode: string;
    taxType?: string;
    lineAmount: number;
  }[];
  reference: string;
  currencyCode: string;
  currencyRate: number;
  status: string;
  sentToContact: boolean;
  expectedPaymentDate: string;
  plannedPaymentDate: string;
};

enum TypeEnum {
  ACCREC = "ACCREC",
}

enum LineAmountTypes {
  Inclusive = "Inclusive",
  Exclusive = "Exclusive",
  NoTax = "NoTax",
}

enum StatusEnum {
  DRAFT = "DRAFT",
}

export const createXeroInvoice = async (order: DraftOrderFragment, xeroContactId: string) => {
  const todayDate = format(new Date(), "yyyy-MM-dd");
  const dueDate = format(addMonths(new Date(order.createdAt), 1), "yyyy-MM-dd");
  const taxable = order.presentmentCurrencyCode === CurrencyCode.Gbp;
  const taxType = taxable ? "OUTPUT2" : "NONE";
  const invoiceInput: Invoice = {
    type: TypeEnum.ACCREC,
    contact: {
      contactID: xeroContactId,
    },
    date: todayDate,
    dueDate: dueDate,
    lineAmountTypes: taxable ? LineAmountTypes.Exclusive : LineAmountTypes.NoTax,
    lineItems: [
      ...order.lineItems.nodes.map((lineItem) => ({
        description: lineItem.title,
        quantity: lineItem.quantity,
        unitAmount: Number(
          getExVatPrice(
            lineItem.originalUnitPriceSet.presentmentMoney.amount,
            lineItem.originalUnitPriceSet.presentmentMoney.currencyCode
          )
        ),
        lineAmount: Number(
          getExVatPrice(
            lineItem.originalTotalSet.presentmentMoney.amount,
            lineItem.originalTotalSet.presentmentMoney.currencyCode
          )
        ),
        accountCode: "200",
        taxType,
      })),
      ...(order.shippingLine
        ? [
            {
              description: order.shippingLine.title,
              quantity: 1,
              unitAmount: Number(
                getExVatPrice(
                  order.shippingLine.discountedPriceSet.presentmentMoney.amount,
                  order.shippingLine.discountedPriceSet.presentmentMoney.currencyCode
                )
              ),
              lineAmount: Number(
                getExVatPrice(
                  order.shippingLine.discountedPriceSet.presentmentMoney.amount,
                  order.shippingLine.discountedPriceSet.presentmentMoney.currencyCode
                )
              ),
              accountCode: "200",
              taxType,
            },
          ]
        : []),
    ],
    reference: order.customAttributes.find((attr) => attr.key === "name")?.value || order.name,
    currencyCode: order.presentmentCurrencyCode,
    currencyRate: 1,
    status: StatusEnum.DRAFT,
    sentToContact: false,
    expectedPaymentDate: dueDate,
    plannedPaymentDate: dueDate,
  };
  try {
    return await axios.post("/.netlify/functions/createXeroInvoice", invoiceInput);
  } catch (error) {
    console.error(error);
    return error;
  }
};

export type XeroContact = {
  contactID: string;
  name: string;
  emailAddress: string;
};

export const searchXeroContacts = async (searchValue: string): Promise<XeroContact[]> => {
  try {
    const contacts = await axios.post(`/.netlify/functions/getXeroContacts?searchTerm=${searchValue}`);
    return contacts.data as XeroContact[];
  } catch (error) {
    console.error(error);
    throw error;
  }
};
