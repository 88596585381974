import { Box, Stack } from "@mui/material";
import { colors } from "../../theme";

type Props = {
  children: React.ReactNode;
  open: boolean;
  onClose: () => void;
};

const BottomSheet = ({ children, open, onClose }: Props) => {
  return (
    <Stack sx={{ opacity: open ? 1 : 0 }}>
      {open && (
        <Box
          position="fixed"
          height="100vh"
          bottom={0}
          left={0}
          right={0}
          zIndex={100}
          bgcolor="rgba(0,0,0,0.4)"
          onClick={onClose}
          sx={{ transition: "background-color 0.3s" }}
        ></Box>
      )}
      <Stack
        bgcolor={colors.white}
        paddingY={3}
        paddingX={2}
        borderRadius="32px 32px 0 0"
        position="fixed"
        bottom={0}
        width="100%"
        alignItems="flex-start"
        gap={2}
        zIndex={101}
        sx={{ transform: open ? "translateY(0)" : "translateY(100%)", transition: "transform 0.3s" }}
      >
        {children}
      </Stack>
    </Stack>
  );
};

export default BottomSheet;
