import styled from "styled-components";
import { colors } from "../../theme";

export const ProgressBarContainer = styled.div`
  overflow: hidden;
  background-color: ${colors.grey05};
  border-color: ${colors.grey20};
  box-shadow: inset 0 1px 2px rgb(0 0 0 / 10%);
  height: 157px;
  width: 100%;
  border-radius: 8px;
`;

export const ProgressBar = styled.div<{ width: number }>`
  height: 100%;
  width: ${(p) => `${p.width}%`};
  background-color: ${colors.purple};
  border-radius: 8px;
`;
