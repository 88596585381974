import React from "react";
import { useSnapCarousel } from "react-snap-carousel";
import styled from "styled-components";
import { colors } from "../../theme";

const StyledCarousel = styled.div`
  position: relative;
  height: 100%;
`;

const CarouselList = styled.ul`
  position: relative;
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  scroll-snap-type: x mandatory;
  height: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const CarouselListItem = styled.li<{ itemSnapPoint: boolean }>`
  width: 100%;
  height: 100%;
  flex-shrink: 0;
  ${({ itemSnapPoint }) => itemSnapPoint && "scroll-snap-align: start;"}
`;

const Dots = styled.ul`
  display: flex;
  justify-content: center;
  list-style: none;
  position: absolute;
  z-index: 1;
  bottom: 12px;
  gap: 8px;
  width: 100%;
  padding: 4px 0;
  margin: 0;
`;

const Dot = styled.div<{ active: boolean; dotColor?: string }>`
  width: 8px;
  height: 8px;
  background-color: ${(p) => (p.active ? colors.black : p.dotColor || colors.white)};
  border-radius: 100px;
  cursor: pointer;
`;

interface CarouselProps<T> {
  readonly items: T[];
  readonly renderItem: (props: CarouselRenderItemProps<T>) => React.ReactElement<CarouselItemProps>;
  readonly dotColor?: string;
}

interface CarouselRenderItemProps<T> {
  readonly item: T;
  readonly isSnapPoint: boolean;
}

export const Carousel = <T extends any>({ items, renderItem, dotColor }: CarouselProps<T>) => {
  const { scrollRef, pages, activePageIndex, goTo, snapPointIndexes } = useSnapCarousel();

  return (
    <StyledCarousel>
      <CarouselList ref={scrollRef}>
        {items.map((item, i) =>
          renderItem({
            item,
            isSnapPoint: snapPointIndexes.has(i),
          })
        )}
      </CarouselList>
      <Dots>
        {Array.from(Array(pages.length).keys()).map((index, key) => (
          <li key={key}>
            <Dot active={activePageIndex === index} onClick={() => goTo(index)} dotColor={dotColor} />
          </li>
        ))}
      </Dots>
    </StyledCarousel>
  );
};

interface CarouselItemProps {
  readonly isSnapPoint: boolean;
  readonly children?: React.ReactNode;
}

export const CarouselItem = ({ isSnapPoint, children }: CarouselItemProps) => (
  <CarouselListItem itemSnapPoint={isSnapPoint}>{children}</CarouselListItem>
);
