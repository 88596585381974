import { PropsWithChildren } from "react";
import { Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import styled from "styled-components";
import { Stack } from "@mui/material";
import { media } from "../../helpers/layout";
import { useAppState } from "../../state";
import { colors, fonts } from "../../theme";
import { Container } from "../Layout";
import AutoResizeText from "../AutoResizeText";

export type Props = {
  header: string;
  background?: string;
  image?: string;
  webpImage?: string;
  imagePosition?: "left" | "right" | "top" | "bottom";
  imageLink?: string;
};

const Wrapper = styled.div<{ background?: string }>`
  display: flex;
  background-color: ${(p) => p.background || colors.white};
  height: 70vh;

  > * {
    width: 50%;
  }
  @media ${media.m} {
    flex-direction: column-reverse;
    height: auto;

    > * {
      width: 100%;
    }
  }
`;

export const Content = styled.div`
  font-size: calc(0.5vw + 10px);
  line-height: 1.5;
  font-family: ${fonts.brand};
  margin: 0;

  @media ${media.m} {
    font-size: 16px;
  }
`;

const StyledImage = styled(LazyLoadImage)<{ imagePosition?: "left" | "right" | "top" | "bottom" }>`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: ${(p) => p.imagePosition || "center"};

  @media ${media.m} {
    max-height: 40vh;
  }
`;

const HeaderContainer = styled(Container)`
  justify-content: flex-end;

  @media ${media.m} {
    min-height: 200px;
  }
`;

const PageHeader = ({ background, header, children, image, webpImage, imagePosition, imageLink }: PropsWithChildren<Props>) => {
  const appState = useAppState();
  const { isMobileScreen } = appState;
  return (
    <Wrapper background={background}>
      <HeaderContainer background={background} padding={isMobileScreen ? "32px 24px" : "56px 40px"}>
        <Stack gap={1}>
          <AutoResizeText text={header} maxSize={70} />
          <Content>{children}</Content>
        </Stack>
      </HeaderContainer>
      {image &&
        (imageLink ? (
          <Link to={imageLink}>
            <StyledImage src={webpImage || image} imagePosition={imagePosition} />
          </Link>
        ) : (
          <div>
            <StyledImage src={webpImage || image} imagePosition={imagePosition} />
          </div>
        ))}
    </Wrapper>
  );
};

export default PageHeader;
