import { useCallback, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { compact, isEmpty } from "lodash";
import {
  CollectionSortKeys,
  PrintSetProductFragment,
  ProductCollectionSortKeys,
  useGetComplimentaryProductsLazyQuery,
  useGetPrintSetCollectionsQuery,
  useGetProductsForChosenStyleLazyQuery,
} from "../../generated/storefront";
import { mediumOptions, styleFilterOptions } from "../../state/constants";
import { getIdNumber } from "../../helpers/shopify";
import { useAppState } from "../../state";

export type PrintSet = {
  link: string;
  product: PrintSetProductFragment;
  complimentaryProduct: PrintSetProductFragment;
};

const usePrintSets = () => {
  const { selectedCountry } = useAppState();
  const [mediumImagery, setMediumImagery] = useState<{ [key: string]: PrintSetProductFragment[] }>({});
  const [styleImagery, setStyleImagery] = useState<{ [key: string]: PrintSetProductFragment[] }>({});
  const [selectedMediums, setSelectedMediums] = useState<string[]>([]);
  const [selectedStyles, setSelectedStyles] = useState<string[]>([]);
  const [selectedOrientations, setSelectedOrientations] = useState<string[]>([]);
  const [printSets, setPrintSets] = useState<PrintSet[]>([]);
  const [personalisedPrintSets, setPersonalisedPrintSets] = useState<PrintSet[]>();
  const [loadingPersonalised, setLoadingPersonalised] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const orientation = searchParams.get("orientation");
  const medium = searchParams.get("medium");
  const style = searchParams.get("style");
  const subject = searchParams.get("subject");
  const [getProductsForChosenStyle] = useGetProductsForChosenStyleLazyQuery();
  const [getComplimentaryProducts] = useGetComplimentaryProductsLazyQuery();
  const {
    data,
    loading,
    fetchMore: fetchMorePrintSets,
  } = useGetPrintSetCollectionsQuery({
    variables: {
      sortKey: CollectionSortKeys.Title,
      limit: 12,
      country: selectedCountry,
    },
  });
  const fetchMore = () => {
    fetchMorePrintSets({
      variables: {
        afterCursor: data?.collections?.pageInfo.endCursor,
      },
    });
  };

  const filters = [
    ...selectedMediums.map((m) => ({ productMetafield: { namespace: "custom", key: "medium", value: m } })),
    ...selectedOrientations.map((o) => ({ productMetafield: { namespace: "custom", key: "orientation", value: o } })),
    ...selectedStyles.flatMap((s) => [{ tag: s }, { tag: `gm.${s}` }]),
  ];

  const [getPersonalisedPrintSets, { data: personalisedPrintSetsData, fetchMore: fetchMorePersonalisedPrintSets }] =
    useGetProductsForChosenStyleLazyQuery({
      variables: {
        filters,
        sortKey: ProductCollectionSortKeys.Relevance,
        limit: 12,
        country: selectedCountry,
      },
    });

  const getMediumImagery = useCallback(async () => {
    const imagery: { [key: string]: PrintSetProductFragment[] } = {};
    const promises = mediumOptions.map(async (medium) => {
      const { data } = await getProductsForChosenStyle({
        variables: {
          filters: [{ productMetafield: { namespace: "custom", key: "medium", value: medium.value } }],
          sortKey: ProductCollectionSortKeys.BestSelling,
          limit: 6,
        },
      });
      const products = data?.collection?.products.nodes || [];
      const uniqueArtistProducts = products.filter(
        (product, index, self) => index === self.findIndex((p) => p.vendor === product.vendor)
      );
      const remainingProducts = products.filter((product) => !uniqueArtistProducts.includes(product));
      const producstToDisplay =
        uniqueArtistProducts.length < 3 ? [...uniqueArtistProducts, ...remainingProducts] : uniqueArtistProducts;
      imagery[medium.value] = producstToDisplay.slice(0, 3);
    });
    await Promise.all(promises);
    setMediumImagery(imagery);
  }, [getProductsForChosenStyle]);

  const getStyleImagery = useCallback(async () => {
    const imagery: { [key: string]: PrintSetProductFragment[] } = {};
    const promises = styleFilterOptions.map(async (style) => {
      const { data } = await getProductsForChosenStyle({
        variables: {
          filters: [{ tag: style.value }, { tag: `gm.${style.value}` }],
          sortKey: ProductCollectionSortKeys.BestSelling,
          limit: 6,
        },
      });
      const products = data?.collection?.products.nodes || [];
      const uniqueArtistProducts = products.filter(
        (product, index, self) => index === self.findIndex((p) => p.vendor === product.vendor)
      );
      const remainingProducts = products.filter((product) => !uniqueArtistProducts.includes(product));
      const producstToDisplay =
        uniqueArtistProducts.length < 3 ? [...uniqueArtistProducts, ...remainingProducts] : uniqueArtistProducts;
      imagery[style.value] = producstToDisplay.slice(0, 3);
    });
    await Promise.all(promises);
    setStyleImagery(imagery);
  }, [getProductsForChosenStyle]);

  const applyComplimentaryProducts = async (products: PrintSetProductFragment[]) => {
    const incompletePrintSetProducts = products.filter((product) => printSets.every((p) => p.product.id !== product.id));
    const promises = incompletePrintSetProducts.map(async (product) => {
      const { data } = await getComplimentaryProducts({
        variables: {
          productId: product.id,
          country: selectedCountry,
        },
      });
      const complimentaryProducts = (data?.productRecommendations || []).filter(
        (p) => p.tags.includes("favourite") && !products.some((prod) => prod.id === p.id)
      );
      const complimentaryProduct = complimentaryProducts.find(
        (p) =>
          (selectedStyles.length ? selectedStyles.some((s) => p.tags.includes(`gm.${s}`)) : true) &&
          (p.medium && selectedMediums.length ? selectedMediums.includes(p.medium.value) : true) &&
          (p.orientation && selectedOrientations.length ? selectedOrientations.includes(p.orientation.value) : true)
      );
      if (!complimentaryProduct) return null;
      return {
        link: `/print-set?products=${getIdNumber(product.id)},${getIdNumber(complimentaryProduct.id)}`,
        product,
        complimentaryProduct,
      };
    });
    return await Promise.all(promises);
  };

  const producePersonalisedPrintSets = async (loadMore = false) => {
    setLoadingPersonalised(true);
    if (loadMore) {
      const { data } = await fetchMorePersonalisedPrintSets({
        variables: {
          afterCursor: personalisedPrintSetsData?.collection?.products.pageInfo.endCursor,
        },
      });
      const printCombinations = await applyComplimentaryProducts(data?.collection?.products.nodes || []);
      setPersonalisedPrintSets((prev) => [...(prev || []), ...compact(printCombinations)]);
    } else {
      const { data } = await getPersonalisedPrintSets();
      const printCombinations = await applyComplimentaryProducts(data?.collection?.products.nodes || []);
      setPersonalisedPrintSets(compact(printCombinations));
    }
    setLoadingPersonalised(false);
  };

  const clearFilters = () => {
    const newParams = new URLSearchParams();
    setSearchParams(newParams, { preventScrollReset: true });
    if (window.scrollY > 650) window.scrollTo(0, 650);
    setPersonalisedPrintSets(undefined);
  };

  useEffect(() => {
    if (orientation) {
      const orientations = orientation.split(",");
      setSelectedOrientations(orientations);
    }
    if (medium) {
      const mediums = medium.split(",");
      setSelectedMediums(mediums);
    }
    if (style || subject) {
      const styles = style?.split(",") || [];
      const subjects = subject?.split(",") || [];
      setSelectedStyles([...styles, ...subjects]);
    }
  }, [orientation, medium, style, subject]);

  useEffect(() => {
    if (selectedMediums.length || selectedStyles.length || selectedOrientations.length) {
      producePersonalisedPrintSets();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMediums, selectedStyles, selectedOrientations]);

  useEffect(() => {
    if (isEmpty(mediumImagery)) {
      getMediumImagery();
    }
    if (isEmpty(styleImagery)) {
      getStyleImagery();
    }
  }, [mediumImagery, getMediumImagery, styleImagery, getStyleImagery]);

  useEffect(() => {
    if (data?.collections) {
      const defaultPrintSets = data.collections.nodes.map((collection) => {
        const products = collection.products.nodes;
        const product = products[0];
        const complimentaryProduct = products[1];
        return {
          link: `/print-set?products=${getIdNumber(product.id)},${getIdNumber(complimentaryProduct.id)}`,
          product,
          complimentaryProduct,
        };
      });
      setPrintSets(defaultPrintSets);
    }
  }, [data]);

  return {
    printSets,
    personalisedPrintSets,
    getMediumImagery,
    producePersonalisedPrintSets,
    selectedMediums,
    setSelectedMediums,
    selectedStyles,
    setSelectedStyles,
    mediumImagery,
    styleImagery,
    loading,
    loadingPersonalised,
    hasMore: data?.collections?.pageInfo.hasNextPage || false,
    hasMorePersonalised: personalisedPrintSetsData?.collection?.products.pageInfo.hasNextPage || false,
    fetchMore,
    fetchMorePersonalised: () => producePersonalisedPrintSets(true),
    clearFilters,
  };
};

export default usePrintSets;
