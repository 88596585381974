import { Box, Stack, Typography } from "@mui/material";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { colors } from "../../../theme";
import { ProductUpload } from "../../../services/Firebase/pending-products";

type Props = {
  products: ProductUpload[];
  isMobileScreen: boolean;
};

const PendingProductGrid = ({ products, isMobileScreen }: Props) => {
  const columns = [
    {
      field: "piece",
      headerName: "Piece",
      flex: 1,
      renderCell: (params: any) => (
        <Stack direction="row" gap={1} alignItems="center" height="100%">
          {params.row.image ? (
            <img src={params.row.image} alt={params.row.title} width="40px" height="auto" />
          ) : (
            <Box width={40} height="auto" bgcolor={colors.grey10} />
          )}
          <Typography fontSize={14}>{params.row.title}</Typography>
        </Stack>
      ),
    },
  ];

  const rows = products.map((product) => ({
    id: product.id,
    title: product.title,
    image: product.imageSrc,
  }));

  return isMobileScreen ? (
    <Stack>
      {products.map((product) => (
        <Stack
          key={product.id}
          direction="row"
          alignItems="center"
          gap={2}
          borderBottom={`1px solid ${colors.cardGrey}`}
          paddingX={1.5}
          paddingY={2}
        >
          {product.imageSrc ? (
            <img src={product.imageSrc} alt={product.title} width="40px" height="auto" />
          ) : (
            <Box width={40} height="auto" bgcolor={colors.grey10} />
          )}
          <Typography fontSize={14}>{product.title}</Typography>
        </Stack>
      ))}
    </Stack>
  ) : (
    <DataGridPro
      rows={rows}
      columns={columns}
      rowHeight={75}
      columnHeaderHeight={36}
      disableColumnSorting
      disableColumnMenu
      disableColumnFilter
      disableRowSelectionOnClick
      hideFooter
      sx={{
        border: 0,
        borderTop: `1px solid ${colors.grey10}`,
        borderRadius: 0,
        "--DataGrid-rowBorderColor": colors.grey10,
        "--DataGrid-containerBackground": colors.cardGrey,
        ".MuiDataGrid-columnSeparator": { display: "none" },
        ".MuiDataGrid-columnHeaderTitle": { fontSize: "12px" },
        ".MuiDataGrid-overlayWrapperInner": { height: "150px !important" },
        ".MuiDataGrid-rowReorderCellPlaceholder": { opacity: 0 },
      }}
    />
  );
};

export default PendingProductGrid;
