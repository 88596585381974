import React from "react";
import ReactDOM from "react-dom/client";
import { RouterProvider, ScrollRestoration, createBrowserRouter } from "react-router-dom";
import ReactPixel from "react-facebook-pixel";
import ReactPinterestTag from "react-pinterest-tag";
import ReactGA4 from "react-ga4";
import { PostHogProvider } from "posthog-js/react";
// import Hotjar from "@hotjar/browser";
import { ApolloProvider } from "@apollo/client";
import { ThemeProvider } from "@mui/material";
import reportWebVitals from "./reportWebVitals";
import { AuthStateProvider } from "./state/auth";
import { AppStateProvider } from "./state";
import { storefrontClient } from "./helpers/apolloClients";
import AdminApp from "./AdminApp";
import App from "./App";
import "./index.css";
import ArtistApp from "./ArtistApp";
import { theme } from "./theme";
import ErrorBoundary from "./components/ErrorBoundary";

const options = {
  autoConfig: true,
  debug: false,
};

// const siteId = 4961930;
// const hotjarVersion = 6;

if (process.env.REACT_APP_ENVIRONMENT === "production") {
  // Hotjar.init(siteId, hotjarVersion);
  ReactPixel.init("930828141415050", {}, options);
  ReactPixel.pageView();
  ReactGA4.initialize("G-3VHSZJF34B");
  ReactGA4.send("pageview");
  ReactPinterestTag.init("2612915723950");
  ReactPinterestTag.pageView();
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

const root = ReactDOM.createRoot(document.getElementById("root"));

const posthogOptions = {
  api_host: "https://eu.posthog.com",
};

const router = createBrowserRouter([
  {
    path: "/*",
    element: (
      <ApolloProvider client={storefrontClient}>
        <ErrorBoundary>
          <App />
        </ErrorBoundary>
        <ScrollRestoration />
      </ApolloProvider>
    ),
  },
  {
    path: "/admin/*",
    element: <AdminApp />,
  },
  {
    path: "/profile/*",
    element: (
      <ErrorBoundary>
        <ArtistApp />
      </ErrorBoundary>
    ),
  },
]);

root.render(
  <React.StrictMode>
    <PostHogProvider apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY} options={posthogOptions}>
      <AuthStateProvider>
        <ThemeProvider theme={theme}>
          <AppStateProvider>
            <RouterProvider router={router} />
          </AppStateProvider>
        </ThemeProvider>
      </AuthStateProvider>
    </PostHogProvider>
  </React.StrictMode>
);
