import { useState } from "react";
import { Link } from "react-router-dom";
import { Box, Skeleton, Stack, Typography } from "@mui/material";
import { colors } from "../../theme";
import { CollectionCardFragment } from "../../generated/storefront";
import { getIdNumber } from "../../helpers/shopify";
import LazyLoadImage from "../Image/LazyLoadImage";

type Props = {
  collection: CollectionCardFragment;
  imageHeight?: string;
  borderRadius?: number;
  size?: "small" | "medium";
};

const CollectionCard = ({ collection, imageHeight, borderRadius, size = "medium" }: Props) => {
  const [imageLoaded, setImageLoaded] = useState(false);

  return (
    <Link to={`/collections/${getIdNumber(collection.id, "Collection")}`} key={collection.id}>
      <Stack gap={size === "small" ? 1.25 : 2}>
        <Box height={imageHeight} width="100%" position="relative" display="flex">
          {collection.image ? (
            <>
              {!imageLoaded && (
                <Skeleton
                  width="100%"
                  height="100%"
                  animation="wave"
                  variant="rectangular"
                  sx={{ position: "absolute", bgcolor: colors.grey02 }}
                  style={{ borderRadius }}
                />
              )}
              <LazyLoadImage
                src={collection.image?.url + "&width=600"}
                alt={collection.image?.altText || collection.title}
                width="100%"
                height="100%"
                style={{ display: "flex", borderRadius, objectFit: "cover" }}
                onLoad={() => setImageLoaded(true)}
              />
            </>
          ) : (
            <Box bgcolor={colors.grey02} width="100%" height={300} style={{ borderRadius }} />
          )}
        </Box>
        <Stack gap={0.5}>
          <Typography variant="body2" fontSize={size === "small" ? 12 : 16}>
            {collection.title.split(": ").pop()}
          </Typography>
          {collection.productsToShow && (
            <Typography variant="body2" fontSize={size === "small" ? 12 : 16} color={colors.error}>
              {Number(collection.productsToShow.value) > 2 ? "20% OFF" : "15% OFF"}
            </Typography>
          )}
        </Stack>
      </Stack>
    </Link>
  );
};

export default CollectionCard;
