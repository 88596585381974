import { useEffect, useState } from "react";
import { useDebounce, useToggle } from "react-use";
import { format } from "date-fns";
import { compact } from "lodash";
import { match } from "ts-pattern";
import { IconButton, Stack, Typography } from "@mui/material";
import { ContentCopyRounded } from "@mui/icons-material";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import { getInvoices } from "../../../../services/Firebase/invoices";
import { Invoice, InvoiceStatus } from "../../../../types/invoice";
import { formattedPrice } from "../../../../helpers/money";
import Button from "../../../../components/Button";
import TextLink from "../../../../components/TextLink";
import StatusPill from "./StatusPill";
import { getUserById } from "../../../../services/Firebase";
import { getInvoiceSubtotal } from "../../../../helpers/prices";
import Search from "../../Search";
import CreateInvoiceModal from "./CreateInvoiceModal";
import { CurrencyCode } from "../../../../generated/storefront";
import InvoiceActionButtons from "./InvoiceActionButtons";
import { createPayout } from "../../../../services/API";
import { markInvoiceAsPaid, sendInvoiceEmail } from "../../helpers/invoices";
import MonthPicker from "../../../../components/MonthPicker";
import { dataGridStyles } from "../../styles";
import { fonts } from "../../../../theme";

export type InvoiceRow = Invoice & {
  invoiceReference: string;
  payByBank?: boolean;
  email?: string;
  paypalEmail?: string;
  currency?: CurrencyCode;
};

type Props = {
  month: Date;
  setMonth: (value: Date) => void;
  goToPreviousMonth: () => void;
  goToNextMonth: () => void;
};

const Invoices = ({ month, setMonth, goToPreviousMonth, goToNextMonth }: Props) => {
  const [loading, setLoading] = useState(false);
  const [loadingPayment, setLoadingPayment] = useState(false);
  const [invoices, setInvoices] = useState<Invoice[]>();
  const [rows, setRows] = useState<InvoiceRow[]>([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [createInvoiceModalOpen, toggleCreateInvoiceModalOpen] = useToggle(false);
  const [rowsToShow, setRowsToShow] = useState<InvoiceRow[]>([]);
  const disablePayOutstandingInvoices = rows.every((r) => r.status !== InvoiceStatus.APPROVED);

  const fetchInvoices = async () => {
    setLoading(true);
    const result = await getInvoices(month);
    setInvoices(result);
  };

  useEffect(() => {
    if (month) {
      fetchInvoices();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [month]);

  const columns: GridColDef[] = [
    {
      field: "invoiceReference",
      headerName: "Invoice Reference",
      renderCell: ({ row }: { row: InvoiceRow }) => (
        <TextLink to={`/admin/invoices/${row.id}`} openInNewTab>
          <Typography fontSize={14} fontFamily={fonts.body}>
            {row.invoiceReference}
          </Typography>
        </TextLink>
      ),
    },
    { field: "artist", headerName: "Artist" },
    {
      field: "email",
      headerName: "Email",
      width: 220,
      renderCell: ({ row }) => (
        <Stack
          direction="row"
          alignItems="center"
          onClick={() => navigator.clipboard.writeText(row.email)}
          sx={{ cursor: "pointer" }}
        >
          <Typography fontSize={14} fontFamily={fonts.body}>
            {row.email}
          </Typography>
          <IconButton>
            <ContentCopyRounded color="primary" sx={{ fontSize: 16 }} />
          </IconButton>
        </Stack>
      ),
    },
    {
      field: "paypalEmail",
      headerName: "Paypal Email",
      width: 220,
      renderCell: ({ row }) => (
        <Stack
          direction="row"
          alignItems="center"
          onClick={() => navigator.clipboard.writeText(row.paypalEmail)}
          sx={{ cursor: "pointer" }}
        >
          <Typography fontSize={14} fontFamily={fonts.body}>
            {row.paypalEmail}
          </Typography>
          <IconButton>
            <ContentCopyRounded color="primary" sx={{ fontSize: 16 }} />
          </IconButton>
        </Stack>
      ),
    },
    { field: "currency", headerName: "Currency" },
    { field: "vatRegistered", headerName: "VAT/GST Registered" },
    { field: "formattedAmount", headerName: "Amount" },
    {
      field: "status",
      headerName: "Status",
      renderCell: ({ row }) => (row.status ? <StatusPill status={row.status} /> : null),
    },
    {
      field: "pdf",
      headerName: "PDF",
      renderCell: (params) =>
        params.row.pdfUrl && (
          <TextLink href={params.row.pdfUrl} openInNewTab>
            <Typography fontSize={14} fontFamily={fonts.body}>
              Open
            </Typography>
          </TextLink>
        ),
    },
    {
      field: "paymentMethod",
      headerName: "Payment",
      description:
        "Payment Method: AUTO = amount is less than £40 (GBP), £150 (other currencies), MANUAL = amount is equal to or greater than £40 (GBP), £150 (other currencies), BANK = override when artist prefers bank transfer",
    },
    {
      field: "action",
      headerName: "Actions",
      renderCell: ({ row }: { row: InvoiceRow }) => <InvoiceActionButtons row={row} fetchInvoices={fetchInvoices} />,
      width: 340,
    },
  ];

  const getRows = async (invoices: Invoice[]) => {
    const invoicePromises = invoices.map(async (invoice) => {
      const artist = await getUserById(invoice.artistId);
      if (!artist) return null;
      return {
        ...invoice,
        id: invoice.id,
        invoiceReference: `GM${invoice.invoiceNumber}`,
        email: artist.email,
        paypalEmail: artist.paypalEmail,
        currency: artist.currency,
        vatRegistered: artist.vatRegistered?.vatNumber ? "Yes" : "No",
        formattedAmount: formattedPrice(getInvoiceSubtotal(invoice), CurrencyCode.Gbp),
        payByBank: artist.payByBank,
        paymentMethod: match(artist)
          .when(
            ({ payByBank }) => payByBank === true,
            () => "BANK" as const
          )
          .when(
            ({ id }) => id === "WXHMzdTh9rZNhpXOoFj5",
            () => "MANUAL" as const
          )
          .when(
            ({ currency }) => getInvoiceSubtotal(invoice) < (currency === "GBP" ? 40 : 150),
            () => "AUTO" as const
          )
          .otherwise(() => "MANUAL" as const),
      };
    });
    const rowsToBeSet = await Promise.all(invoicePromises);
    setRows(compact(rowsToBeSet));
    setLoading(false);
  };

  const onPay = async (row: InvoiceRow) => {
    if (!row.paypalEmail) {
      return alert("No paypal email address found for this artist.");
    }
    try {
      await createPayout({
        email: row.paypalEmail,
        amount: getInvoiceSubtotal(row),
        month: format(row.issueDate.toDate(), "MMMM yyyy"),
        invoiceNumber: row.invoiceNumber,
        currency: row.currency,
      });
      await markInvoiceAsPaid(row.id);
      await sendInvoiceEmail(row);
    } catch (error: any) {
      console.error(error);
      alert(`Something went wrong paying ${row.paypalEmail} - ${error?.message}`);
    }
  };

  const payOutstandingInvoices = async () => {
    try {
      setLoadingPayment(true);
      await fetchInvoices();
      const unpaidInvoices = rows.filter((r) => r.status === InvoiceStatus.APPROVED && !r.payByBank);
      const promises = unpaidInvoices.map(async (row) => {
        await onPay(row);
      });
      await Promise.allSettled(promises);
      await fetchInvoices();
      setLoadingPayment(false);
    } catch (error) {
      console.error(error);
      setLoadingPayment(false);
      alert("Something went wrong, please try again.");
    }
  };

  useEffect(() => {
    if (invoices) {
      getRows(invoices);
    }
  }, [invoices]);

  useDebounce(
    () => {
      if (searchQuery) {
        const filteredRows = rows.filter(
          (r) =>
            r.artist.toLowerCase().includes(searchQuery.toLowerCase()) ||
            r.invoiceNumber.toString().includes(searchQuery) ||
            r.id.includes(searchQuery) ||
            r.email?.includes(searchQuery) ||
            r.paypalEmail?.includes(searchQuery)
        );
        setRowsToShow(filteredRows);
      } else {
        setRowsToShow(rows);
      }
    },
    500,
    [rows, searchQuery]
  );

  return (
    <>
      <Stack gap={2}>
        <Stack direction="row" spacing={2} justifyContent="space-between">
          <MonthPicker
            month={month}
            setMonth={setMonth}
            loading={loading}
            goToNextMonth={goToNextMonth}
            goToPreviousMonth={goToPreviousMonth}
          />

          <Stack direction="row" gap={2}>
            <Button
              size="medium"
              onClick={payOutstandingInvoices}
              loading={loadingPayment}
              disabled={disablePayOutstandingInvoices}
              secondary
            >
              Pay outstanding invoices
            </Button>
            <Button size="medium" onClick={toggleCreateInvoiceModalOpen} secondary>
              Create new draft
            </Button>
            <Search inputValue={searchQuery} setInputValue={setSearchQuery} />
          </Stack>
        </Stack>

        <DataGrid
          loading={loading}
          rows={rowsToShow}
          rowHeight={80}
          columns={columns}
          pageSizeOptions={[]}
          pagination
          slots={{ toolbar: GridToolbar }}
          style={{ height: loading ? "70vh" : "auto", minHeight: "70vh" }}
          sx={dataGridStyles}
        />
      </Stack>

      {createInvoiceModalOpen && <CreateInvoiceModal onClose={toggleCreateInvoiceModalOpen} refetch={fetchInvoices} />}
    </>
  );
};

export default Invoices;
