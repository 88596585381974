import styled from "styled-components";
import { Stack } from "@mui/material";
import { colourFilters } from "../../state/constants";
import { colors } from "../../theme";

const BadgeButton = styled.button<{ selected: boolean; hex?: string; url?: string }>(({ selected, hex, url }) => ({
  position: "relative",
  background: "none",
  padding: 0,
  margin: 0,
  border: selected ? `2px solid ${colors.grey02}` : "0",
  backgroundColor: hex || "transparent",
  backgroundImage: url ? `url(${url})` : "",
  backgroundPosition: "center",
  borderRadius: "100%",
  cursor: "pointer",
  width: 32,
  height: 32,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",

  "&::after": {
    content: '""',
    position: "absolute",
    width: "calc(100% + 4px)",
    height: "calc(100% + 4px)",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "100%",
    border: `2px solid ${selected ? colors.black : "transparent"}`,
  },

  "&:focus": {
    outline: "none",
  },

  "@media (hover: hover)": {
    "&:hover": {
      border: `2px solid ${colors.grey02}`,
    },

    "&:hover::after": {
      borderColor: colors.black,
    },
  },
}));

const ColourBadges = ({
  selectedColours,
  handleColourChange,
}: {
  selectedColours: string[];
  handleColourChange: (colour: string) => void;
}) => {
  return (
    <Stack direction="row" flexWrap="wrap" gap={1.5}>
      {colourFilters.map((colour) => (
        <BadgeButton
          selected={selectedColours.includes(colour.value)}
          hex={colour.hex}
          url={colour.url}
          onClick={() => handleColourChange(colour.value)}
        />
      ))}
    </Stack>
  );
};

export default ColourBadges;
