import { ArrowBackIosRounded, ArrowDropDownRounded, ArrowForwardIosRounded } from "@mui/icons-material";
import { IconButton, MenuItem, Select, Stack } from "@mui/material";
import { addMonths, format, isSameDay, parseISO, startOfMonth } from "date-fns";
import { last12Months } from "../../state/constants";
import { colors } from "../../theme";
import { getNextMonthsDate } from "../../helpers/time";

type Props = {
  month: Date;
  setMonth: (value: Date) => void;
  loading: boolean;
  goToPreviousMonth: () => void;
  goToNextMonth: () => void;
  showNextMonth?: boolean;
};

const MonthPicker = ({ month, setMonth, loading, goToPreviousMonth, goToNextMonth, showNextMonth }: Props) => {
  return (
    <Stack direction="row" alignItems="center" gap={1}>
      <IconButton
        onClick={goToPreviousMonth}
        disabled={loading}
        size="small"
        color="primary"
        style={{ backgroundColor: colors.grey02, fontSize: 12 }}
      >
        <ArrowBackIosRounded fontSize="inherit" />
      </IconButton>
      <Select
        variant="filled"
        size="small"
        renderValue={() => format(month, "MMMM yyyy")}
        onChange={(e) => setMonth(parseISO(`${e.target.value}-01`))}
        value={format(month, "yyyy-MM")}
        style={{ minWidth: 125 }}
        disabled={loading}
        IconComponent={ArrowDropDownRounded}
      >
        {showNextMonth && <MenuItem value={getNextMonthsDate()}>{format(addMonths(new Date(), 1), "MMMM yyyy")}</MenuItem>}
        {last12Months.map((month) => (
          <MenuItem key={month.value} value={month.value}>
            {month.label}
          </MenuItem>
        ))}
      </Select>
      <IconButton
        onClick={goToNextMonth}
        disabled={loading || isSameDay(month, startOfMonth(new Date()))}
        size="small"
        color="primary"
        style={{ backgroundColor: colors.grey02, fontSize: 12 }}
      >
        <ArrowForwardIosRounded fontSize="inherit" />
      </IconButton>
    </Stack>
  );
};

export default MonthPicker;
