import axios from "axios";
import { UploadInput } from "../../types/product";
import { escapeQuotes } from "../../helpers/product";

export const getProductFirebaseImageUrl = async (id: string): Promise<string> => {
  try {
    const { data } = await axios.post(`/.netlify/functions/getProductFirebaseImageUrl?id=${id}`);
    return data.imageUrl;
  } catch (error) {
    console.error(`Error getting product firebase image url: ${error}`);
    return "";
  }
};

export const createProduct = async (product: UploadInput, artistId: string): Promise<{ id: string }> => {
  try {
    const { data, status } = await axios.post("/.netlify/functions/createProduct", {
      ...product,
      title: escapeQuotes(product.title),
      description: escapeQuotes(product.description),
      artistId,
    });
    if (data.errors?.length) {
      throw new Error(`Error: ${data.errors[0].message}`);
    }
    if (status === 202) {
      return { id: "", ...product };
    }
    const createdProduct = data.productCreate;
    if (!createdProduct) throw new Error("Something went wrong, please try again");
    if (createdProduct.userErrors.length) {
      throw new Error(`Error: ${createdProduct.userErrors[0].message}`);
    }
    return createdProduct.product;
  } catch (error) {
    console.error(`Error creating product: ${error}`);
    throw error;
  }
};

export const updateProduct = async (id: string, product: Partial<UploadInput>): Promise<{ id: string }> => {
  try {
    const { data } = await axios.post("/.netlify/functions/updateProduct", {
      ...product,
      id,
      ...(product.title ? { title: escapeQuotes(product.title) } : {}),
      ...(product.description ? { description: escapeQuotes(product.description) } : {}),
    });
    if (data.errors?.length) {
      throw new Error(`Error: ${data.errors[0].message}`);
    }
    const updatedProduct = data.productUpdate;
    if (!updatedProduct) throw new Error("Something went wrong, please try again");
    if (updatedProduct.userErrors.length) {
      throw new Error(`Error: ${updatedProduct.userErrors[0].message}`);
    }
    return updatedProduct.product;
  } catch (error) {
    console.error(`Error updating product: ${error}`);
    throw error;
  }
};

export const generateTags = async (imageSrc: string): Promise<string[]> => {
  try {
    // const tokenResponse = await axios.post("/.netlify/functions/getToken");
    // const { token } = await tokenResponse.data;
    // if (!token) {
    //   throw new Error("Failed to retrieve token");
    // }
    // const headers = {
    //   "Content-Type": "application/json",
    //   Authorization: `Bearer ${token}`,
    // };

    const { data } = await axios.post("/.netlify/functions/generateProductTags", { imageSrc });
    return data.tags;
  } catch (error) {
    console.error(`Error generating product tags: ${error}`);
    throw error;
  }
};
