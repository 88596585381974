import { Helmet } from "react-helmet";
import { Box, Stack, Typography } from "@mui/material";
import { colors } from "../../theme";
import PageHeader from "../../components/PageHeader";
import tradeWebp from "../../assets/images/tradeHero.webp";
import trade01 from "../../assets/images/trade-page/trade_01.png";
import trade02 from "../../assets/images/trade-page/trade_02.png";
import trade03 from "../../assets/images/trade-page/trade_03.png";
import trade04 from "../../assets/images/trade-page/trade_04.png";
import trade05 from "../../assets/images/trade-page/trade_05.png";
import trade06 from "../../assets/images/trade-page/trade_06.png";
import trade07 from "../../assets/images/trade-page/trade_07.png";
import trade08 from "../../assets/images/trade-page/trade_08.png";
import trade09 from "../../assets/images/trade-page/trade_09.png";
import trade10 from "../../assets/images/trade-page/trade_10.png";
import trade11 from "../../assets/images/trade-page/trade_11.png";
import trade12 from "../../assets/images/trade-page/trade_12.png";
import trade13 from "../../assets/images/trade-page/trade_13.png";
import trade14 from "../../assets/images/trade-page/trade_14.png";
import { Image } from "../../components";
import Button from "../../components/Button";
import TextLink from "../../components/TextLink";

const Trade = () => {
  return (
    <>
      <Helmet>
        <title>Trade | GoodMood</title>
        <meta name="description" content="Transform your space with GoodMood" />
      </Helmet>
      <PageHeader header="Trade" background={colors.beige} image={tradeWebp} webpImage={tradeWebp}>
        At GoodMood, we believe in the transformative power of art to elevate spaces into vibrant, inspiring environments. If
        you've got a project in mind - get in touch, we'd love to help
      </PageHeader>
      <Stack gap={9} padding={{ xs: 2, md: 5 }}>
        <Box>
          <Typography variant="h2" marginBottom={3} marginTop={3}>
            Why choose GoodMood?
          </Typography>

          <Stack gap={3} direction={{ xs: "column", md: "row" }}>
            <Stack gap={2} flex={1}>
              <Box height={{ xs: "70vw", md: "50vw" }}>
                <Image src={trade01} alt="artis and print" />
              </Box>
              <Stack gap={1} justifyContent="center">
                <Typography variant="h3">Count on our expertise for commercial projects</Typography>
                <Typography variant="body2" lineHeight="150%">
                  GoodMood has extensive experience in delivering art solutions for large-scale commercial projects in the UK, US,
                  EU, Middle East and beyond. Our know-how and commitment to quality ensures your vision is flawlessly executed.
                </Typography>
              </Stack>
            </Stack>
            <Stack gap={2} flex={1}>
              <Box height={{ xs: "70vw", md: "50vw" }}>
                <Image src={trade02} alt="office" />
              </Box>
              <Stack gap={1} justifyContent="center">
                <Typography variant="h3">An exceptional priority service: we're here to help</Typography>
                <Typography variant="body2" lineHeight="150%">
                  We'll provide each account with a dedicated account manager. Our dedicated team is always ready to assist,
                  provide personalised solutions and prompt responses. Enjoy seamless support every step of the way.
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </Box>

        <Box>
          <Stack gap={3} direction="row" overflow={{ xs: "auto", md: "initial" }}>
            <Stack gap={2} flex={1}>
              <Box height={{ xs: "70vw", md: "20vw" }} width={{ xs: "80vw", md: "auto" }}>
                <Image src={trade03} alt="cafe" />
              </Box>

              <Stack gap={1} justifyContent="center">
                <Typography variant="h3">Something for every space</Typography>
                <Typography variant="body2" lineHeight="150%">
                  Our curated collection of artwork offers a diverse range of styles, from contemporary to traditional, ensuring
                  there's something for every workspace aesthetic.
                </Typography>
              </Stack>
            </Stack>

            <Stack gap={2} flex={1}>
              <Box height={{ xs: "70vw", md: "20vw" }} width={{ xs: "80vw", md: "auto" }}>
                <Image src={trade04} alt="artist" />
              </Box>
              <Stack gap={1} justifyContent="center">
                <Typography variant="h3">Support independent artists</Typography>
                <Typography variant="body2" lineHeight="150%">
                  We are a global community of creatives, with over 400 artists from 40+ countries. Purchasing from GoodMood
                  directly puts money in artists pockets and we pride ourselves on paying our Artists better than alternative
                  print websites. We can help curate art from GoodMood artists in your local area.
                </Typography>
              </Stack>
            </Stack>

            <Stack gap={2} flex={1}>
              <Box height={{ xs: "70vw", md: "20vw" }} width={{ xs: "80vw", md: "auto" }}>
                <Image src={trade05} alt="office" />
              </Box>

              <Stack gap={1} justifyContent="canvas in home">
                <Typography variant="h3">Our global, local network</Typography>
                <Typography variant="body2" lineHeight="150%">
                  We have a global network of printers and framers. This means we can produce and deliver locally, within 5 days,
                  in the UK, EU, US & Australia. It also means it avoids international customs and importantly dramatically
                  reduces our carbon footprint.
                </Typography>
              </Stack>
            </Stack>

            <Stack gap={2} flex={1}>
              <Box height={{ xs: "70vw", md: "20vw" }} width={{ xs: "80vw", md: "auto" }}>
                <Image src={trade06} alt="print in home" />
              </Box>
              <Stack gap={1} justifyContent="center">
                <Typography variant="h3">Pricing, VAT invoicing & payment terms</Typography>
                <Typography variant="body2" lineHeight="150%">
                  For trade accounts we can provide trade discount pricing, VAT invoices and can discuss payment terms for repeat
                  customers. We can review pricing on larger orders on a case by case basis.
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </Box>

        <Box>
          <Typography variant="h2" marginBottom={3}>
            Our extended offering
          </Typography>
          <Stack gap={3} direction="row" overflow={{ xs: "auto", md: "initial" }}>
            <Stack gap={2} flex={1}>
              <Box height={{ xs: "70vw", md: "20vw" }} width={{ xs: "80vw", md: "auto" }}>
                <Image src={trade07} alt="living room" />
              </Box>
              <Stack gap={1} justifyContent="center">
                <Typography variant="h3">Curation services</Typography>
                <Typography variant="body2" lineHeight="150%">
                  In addition to self service, you can choose bespoke. Hotel, office, restaurant, residential home, care home or
                  anything else, we can assist you choose the right product at every step.
                </Typography>
              </Stack>
            </Stack>

            <Stack gap={2} flex={1}>
              <Box height={{ xs: "70vw", md: "20vw" }} width={{ xs: "80vw", md: "auto" }}>
                <Image src={trade08} alt="commission" />
              </Box>
              <Stack gap={1} justifyContent="center">
                <Typography variant="h3">Commissions & art sourcing</Typography>
                <Typography variant="body2" lineHeight="150%">
                  If you need local artists, or custom commissions, we can arrange this too
                </Typography>
              </Stack>
            </Stack>

            <Stack gap={2} flex={1}>
              <Box height={{ xs: "70vw", md: "20vw" }} width={{ xs: "80vw", md: "auto" }}>
                <Image src={trade09} alt="canvas" />
              </Box>
              <Stack gap={1} justifyContent="center">
                <Typography variant="h3">Canvas</Typography>
                <Typography variant="body2" lineHeight="150%">
                  We can provide rolled, stretched and framed canvas in sizes up to 3m.
                </Typography>
              </Stack>
            </Stack>

            <Stack gap={2} flex={1}>
              <Box height={{ xs: "70vw", md: "20vw" }} width={{ xs: "80vw", md: "auto" }}>
                <Image src={trade10} alt="box frames" />
              </Box>
              <Stack gap={1} justifyContent="center">
                <Typography variant="h3">Box frames</Typography>
                <Typography variant="body2" lineHeight="150%">
                  We can provide premium box frames
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </Box>
      </Stack>

      <Stack gap={3} paddingX={5} paddingY={7} alignItems="center">
        <Typography variant="h2" component="h3" textAlign="center">
          We're here to help
        </Typography>
        <Typography variant="body2" textAlign="center" width={{ xs: "100%", md: "70%" }}>
          If you've got any questions, need help or want to speak with one of our team, reach out{" "}
          <TextLink href="mailto:hello@goodmoodprints.io">hello@goodmoodprints.io</TextLink> - we'll usually respond within 1 hour
        </Typography>
        <a href="mailto:hello@goodmoodprints.io">
          <Button size="medium" secondary>
            Email us
          </Button>
        </a>
      </Stack>

      <Stack direction={{ xs: "column", md: "row" }}>
        <Box flex={1}>
          <Image src={trade11} alt="trade" />
        </Box>
        <Box bgcolor={colors.beige} flex={1}>
          <Stack
            gap={3}
            padding={5}
            width={{ xs: "100%", md: "70%" }}
            height="100%"
            margin="auto"
            justifyContent="center"
            alignItems="center"
          >
            <Typography variant="h2">Tried and tested</Typography>
            <Typography align="center">
              "Working with GoodMood has been a game-changer. We always have custom requests and we've never dealt with a company
              so attentive.
            </Typography>
            <Typography align="center">
              Their expertise and attention to detail made the entire process seamless. The quality of their prints and frames is
              outstanding, and our clients couldn't be happier with the results. GoodMood is now our go-to partner for all art
              needs!"
            </Typography>
            <Typography>Interior Designer</Typography>
          </Stack>
        </Box>
      </Stack>

      <Box padding={{ xs: 2, md: 5 }}>
        <Typography variant="h2" marginBottom={3} marginTop={3}>
          Useful trade features
        </Typography>
        <Stack gap={3} direction={{ xs: "column", md: "row" }}>
          <Stack gap={2} flex={1}>
            <Box height={{ xs: "70vw", md: "20vw" }} bgcolor={colors.grey05} padding={3}>
              <Image src={trade12} alt="gallery wall builder" objectFit="contain" />
            </Box>
            <Stack gap={1} justifyContent="center">
              <Typography variant="h3">Gallery Wall Builder</Typography>
              <Typography variant="body2" lineHeight="150%">
                Upload backgrounds and visualise pieces in your space, your clients space or use our pre-made interiors to test
                out combinations
              </Typography>
            </Stack>
          </Stack>

          <Stack gap={2} flex={1}>
            <Box height={{ xs: "70vw", md: "20vw" }} bgcolor={colors.grey05} padding={3}>
              <Image src={trade13} alt="wishlists" objectFit="contain" />
            </Box>
            <Stack gap={1} justifyContent="center">
              <Typography variant="h3">Multiple & Collaborative Wishlists</Typography>
              <Typography variant="body2" lineHeight="150%">
                Create multiple wishlists for multiple projects. Make them collaborative with colleagues or clients{" "}
              </Typography>
            </Stack>
          </Stack>

          <Stack gap={2} flex={1}>
            <Box height={{ xs: "70vw", md: "20vw" }} bgcolor={colors.grey05} padding={3}>
              <Image src={trade14} alt="basket" objectFit="contain" />
            </Box>
            <Stack gap={1} justifyContent="center">
              <Typography variant="h3">Copy your Basket</Typography>
              <Typography variant="body2" lineHeight="150%">
                Copy your basket and send it to us - we can then build out an invoice for you
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </Box>
    </>
  );
};

export default Trade;
