import { useEffect, useState } from "react";
import { Autocomplete, Box, InputAdornment, Stack, TextField, Typography } from "@mui/material";
import { KeyboardArrowDownRounded, Search } from "@mui/icons-material";
import { Form } from "../../../../components/Layout";
import Modal from "../../../../components/Modal";
import Button from "../../../../components/Button";
import { DraftOrderFragment } from "../../../../generated/graphql";
import { createXeroInvoice, searchXeroContacts, XeroContact } from "../../../../services/API/xero";

type Props = {
  order: DraftOrderFragment;
  onClose: () => void;
};

const CreateXeroInvoiceModal = ({ order, onClose }: Props) => {
  const [error, setError] = useState<string | null>(null);
  const [xeroContactId, setXeroContactId] = useState<string>();
  const [loading, setLoading] = useState(false);
  const [loadingContacts, setLoadingContacts] = useState(false);
  const [customerSearchValue, setCustomerSearchValue] = useState<string>();
  const [searchedContacts, setSearchedContacts] = useState<XeroContact[]>();

  const onSubmit = async (e: React.FormEvent) => {
    setLoading(true);
    e.preventDefault();
    if (!xeroContactId) return setError("Please choose a customer");
    await createXeroInvoice(order, xeroContactId);
    setLoading(false);
    onClose();
  };

  const onSearchXeroContacts = async (customerSearchValue: string) => {
    setLoadingContacts(true);
    const contacts = await searchXeroContacts(customerSearchValue);
    if (contacts instanceof Error) return setError("Failed to search for contacts");
    setSearchedContacts(contacts);
    setLoadingContacts(false);
  };

  useEffect(() => {
    onSearchXeroContacts(customerSearchValue || "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerSearchValue]);

  return (
    <Modal size="small" onClose={onClose}>
      <Box paddingTop={3}>
        <Form onSubmit={onSubmit}>
          <Stack gap={1}>
            <Typography>Xero contacts</Typography>
            <Autocomplete
              loading={loadingContacts}
              options={searchedContacts?.map((contact) => ({ value: contact.contactID, label: contact.name })) || []}
              onChange={(_, value) => setXeroContactId(value?.value)}
              popupIcon={<KeyboardArrowDownRounded />}
              renderInput={(params) => (
                <TextField
                  {...params}
                  style={{ zIndex: 0 }}
                  placeholder="Search xero contacts"
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search fontSize="small" />
                      </InputAdornment>
                    ),
                  }}
                  onChange={(event) => setCustomerSearchValue(event.target.value)}
                />
              )}
            />
          </Stack>

          {error && <Typography color="error">{error}</Typography>}

          <Stack direction="row" alignItems="center" justifyContent="end" gap={2} paddingTop={1}>
            <Button secondary onClick={onClose}>
              Cancel
            </Button>
            <Button loading={loading}>Create</Button>
          </Stack>
        </Form>
      </Box>
    </Modal>
  );
};

export default CreateXeroInvoiceModal;
