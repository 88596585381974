import { useSearchParams } from "react-router-dom";
import { capitalize } from "lodash";
import { MenuItem, Select, SelectChangeEvent, Stack, Typography } from "@mui/material";
import { StyledCheckbox } from "../Checkbox";

type Option = {
  label: string;
  value: string;
};

type Props = {
  name: string;
  options: Option[];
  width?: string;
};

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: "40vh",
    },
  },
};

const CheckboxSelect = ({ name, options, width }: Props) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const selectedOptionsParam = searchParams.get(name);
  const selectedOptions = selectedOptionsParam
    ? options.filter((option) => selectedOptionsParam.split(",").includes(option.value))
    : [];

  const handleChange = (event: SelectChangeEvent<string[]>) => {
    const { value } = event.target;
    if (typeof value === "string") return;
    if (value.length > 0) {
      searchParams.set(name, value.join(","));
    } else {
      searchParams.delete(name);
    }
    setSearchParams(searchParams, { preventScrollReset: true });
    if (window.scrollY > 650) window.scrollTo(0, 650);
  };

  return (
    <Select
      id={`multiple-checkbox-${name}`}
      variant="filled"
      multiple
      displayEmpty
      // @ts-ignore
      value={selectedOptions.map((option) => option.value)}
      name={name}
      onChange={handleChange}
      renderValue={(selected) => (selected.length === 0 ? capitalize(name) : `${capitalize(name)} (${selected.length})`)}
      MenuProps={MenuProps}
      sx={{ width }}
    >
      {options.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          <Stack direction="row" gap={1}>
            <StyledCheckbox name={name} checked={selectedOptions.findIndex((item) => item.value === option.value) >= 0} />
            <Typography fontSize={14}>{option.label}</Typography>
          </Stack>
        </MenuItem>
      ))}
    </Select>
  );
};

export default CheckboxSelect;
