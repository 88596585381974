import { useEffect, useMemo, useState } from "react";
import { Grid, Stack, Typography } from "@mui/material";
import { Text } from "../../components/Text";
import { UploadState } from "../../types/product";
import { Container } from "../../components/Layout";
import Button from "../../components/Button";
import ErrorMessage from "../../components/Form/ErrorMessage";
import Range from "../../components/Form/Range";
import { colors, fonts } from "../../theme";
import NumberInput from "../../components/Form/NumberInput";
import { getPrices } from "../../helpers/prices";
import Accordion from "../../components/Accordion";
import { useAppState } from "../../state";
import { getSizesToSell, sizeLabelsForCountry } from "../../helpers/product";
import Checkbox from "../../components/Checkbox";

type Props = {
  state: UploadState;
  setState: (state: Partial<UploadState>) => void;
  onStepChange: (step: number) => void;
  stepComplete: boolean;
};

const GridItem = ({ children, ...props }: any) => (
  <Grid
    item
    padding={1.5}
    style={{ outline: `1px solid ${colors.grey05}`, textOverflow: "ellipsis", overflow: "hidden" }}
    {...props}
  >
    {children}
  </Grid>
);

const StepThree = ({ state, setState, onStepChange, stepComplete }: Props) => {
  const [goal, setGoal] = useState(10);
  const [estimatedEarning, setEstimatedEarning] = useState(0);
  const { errors, countryOfUser } = useAppState();
  const a3Pct = 0.2;
  const a2Pct = 0.3;
  const a1Pct = 0.1;
  const a3FramedPct = 0.11;
  const a2FramedPct = 0.21;
  const a1FramedPct = 0.08;
  const commissionPct = useMemo(() => (state.exclusiveToGoodMood ? 0.3 : 0.2), [state.exclusiveToGoodMood]);
  const prices = useMemo(() => getPrices(state.price), [state.price]);
  const sum = useMemo(
    () =>
      a3Pct * prices.A3 +
      a2Pct * prices.A2 +
      a1Pct * prices.A1 +
      a3FramedPct * prices.A3 +
      a2FramedPct * prices.A2 +
      a1FramedPct * prices.A1,
    [prices]
  );

  const handlePriceChange = (event: any) => {
    const { name, value } = event.target;
    setState({ [name]: Number(value) });
  };

  const handleExclusivityChange = (event: any) => {
    const { checked } = event.target;
    setState({ exclusiveToGoodMood: checked });
  };

  const setEstimatedEarningValue = (goalValue?: number) => {
    const earning = sum * (goalValue || goal) * commissionPct;
    setEstimatedEarning(Number(earning.toFixed(0)));
  };

  const setGoalValue = (earning: number) => {
    const goalValue = earning / sum / commissionPct;
    setGoal(Number(goalValue.toFixed(0)));
  };

  const handleGoalChange = (event: any) => {
    const { value } = event.target;
    setGoal(Number(value));
    setEstimatedEarningValue(Number(value));
  };

  const handleEarningChange = (event: any) => {
    const { value } = event.target;
    setEstimatedEarning(Number(value));
    setGoalValue(Number(value));
  };

  useEffect(() => {
    setEstimatedEarningValue();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prices]);

  const sizesToSell = getSizesToSell(state.maxSize, state.ratio);
  const columnCount = sizesToSell.length + 2;
  const columnWidth = 12 / columnCount;
  const firstColumnWidth = columnWidth * 2;
  const sizeLabels = sizeLabelsForCountry(countryOfUser);

  return (
    <>
      <Stack paddingX={3} paddingY={2} gap={3}>
        <Typography variant="h3" component="h1">
          Set price
        </Typography>
        <Range
          min={30}
          max={80}
          step={5}
          name="price"
          label={`Set price for ${sizeLabels[sizesToSell[0]].shortHand} print (recommend £40)`}
          onChange={handlePriceChange}
          value={state.price}
          valueLabel={`£${state.price}`}
        />
        <Checkbox
          label="This piece is exclusive to GoodMood (30% commission, instead of 20%)"
          onChange={handleExclusivityChange}
          defaultChecked={state.exclusiveToGoodMood}
        />
        <Grid container>
          <Grid item xs={firstColumnWidth} padding={1.5}></Grid>
          {sizesToSell.map((size) => (
            <GridItem key={size} xs={columnWidth} bgcolor={colors.grey02}>
              {sizeLabels[size].shortHand}
            </GridItem>
          ))}
          <GridItem item xs={firstColumnWidth} bgcolor={colors.grey02}>
            Price (£)
          </GridItem>
          {sizesToSell.map((size) => (
            <GridItem key={size} xs={columnWidth}>
              {prices[size]}
            </GridItem>
          ))}
          <GridItem bgcolor={colors.grey02} xs={firstColumnWidth}>
            You get (£)
          </GridItem>
          {sizesToSell.map((size) => (
            <GridItem key={size} xs={columnWidth}>
              {prices[size] * commissionPct}
            </GridItem>
          ))}
        </Grid>

        <Typography fontSize={14} color={colors.grey40} fontFamily={fonts.body}>
          *Please note commission is calculated from the sale price so may vary depending on discounts and/or currency conversions
        </Typography>

        <Stack gap={1}>
          <Stack direction="row" gap={1}>
            <NumberInput label="Sales goal" name="goal" onChange={handleGoalChange} value={goal || ""} />
            <NumberInput
              label="Estimated earnings"
              name="earning"
              onChange={handleEarningChange}
              value={estimatedEarning || ""}
            />
          </Stack>
          <Typography fontSize={14} color={colors.grey40} fontFamily={fonts.body}>
            This is an estimate based on average sales.
          </Typography>
        </Stack>
      </Stack>

      <Accordion
        items={[
          {
            label: "More info on artist commision",
            content: (
              <Stack gap={2} paddingX={{ xs: 3, md: 4 }} paddingBottom={4}>
                <Text>
                  We pay you according to how you set your prices and how many pieces you sell in the month. You are paid 30% (or
                  20% if not exclusive to GoodMood) of the print sale price of each item ie. you are paid the same commission if
                  the print is framed or unframed. You can play with your pricing above to see how much commission you will
                  receive.
                </Text>
                <Text>
                  We've calculated the commission structure so that when you factor in all of the costs, in nearly all cases the
                  artists takes over 60% of the profit from each sale. Our costs include VAT/GST/taxes, printing & framing,
                  packaging, fulfilment, payment processing, customer service, refunds, web hosting & development and marketing.
                </Text>
                <Text>
                  When selling on GoodMood you also need to participate in discounts. So if a customer uses a 10% discount code
                  for their order, both our sales revenue and your commission on that sale will be reduced 10%. We don’t regularly
                  run promotions on our site.
                </Text>
              </Stack>
            ),
          },
          {
            label: "More info on getting paid",
            content: (
              <Stack gap={2} paddingX={{ xs: 3, md: 4 }} paddingBottom={4}>
                <Text>
                  At the end of each month, we will pay you your share of the profit within 10 working days. We currently only pay
                  artists using Paypal. Please make sure you upload your Paypal email to your GoodMood Profile. We currently only
                  pay in GBP.
                </Text>
              </Stack>
            ),
          },
        ]}
      />

      <Container padding="24px" width={100}>
        {errors?.upload && <ErrorMessage errorMessage={errors.upload} />}
        <Stack alignSelf="end">
          <Button secondary onClick={() => onStepChange(4)} disabled={!stepComplete} size="medium">
            Next
          </Button>
        </Stack>
      </Container>
    </>
  );
};

export default StepThree;
