import { Helmet } from "react-helmet";
import { colors } from "../../theme";
import PageHeader from "../../components/PageHeader";
import faqsImage from "../../assets/images/faqs.webp";
import Tabs from "../../components/Tabs";
import { useState } from "react";
import { Flex, Section } from "../../components/Layout";
import Artist from "./Artist";
import Buyer from "./Buyer";
import PrintAndFrames from "./PrintAndFrames";

const FAQS = () => {
  const [selectedTab, setSelectedTab] = useState<number>(1);

  const onTabChange = (tab: number | string) => {
    setSelectedTab(tab as number);
  };

  return (
    <>
      <Helmet>
        <title>FAQs | GoodMood</title>
        <meta name="description" content="Find answers to the most frequently asked questions about GoodMood Prints" />
      </Helmet>
      <PageHeader header="FAQs" background={colors.blue} image={faqsImage} webpImage={faqsImage}>
        Below is a list of questions we get asked most. If you can't find the answer to your question, then please contact us and
        we'll do our best to help.
      </PageHeader>
      <Section>
        <Flex justifyContent="center">
          <Tabs
            onTabChange={onTabChange}
            selectedTab={selectedTab}
            tabs={[
              { value: 1, label: "For Artists" },
              { value: 2, label: "For Buyers" },
              { value: 3, label: "Prints & Frames" },
            ]}
          />
        </Flex>
      </Section>

      {selectedTab === 1 && <Artist />}
      {selectedTab === 2 && <Buyer />}
      {selectedTab === 3 && <PrintAndFrames />}
    </>
  );
};

export default FAQS;
