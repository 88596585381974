import { useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { match } from "ts-pattern";
import { useAppState } from "../../state";
import { uniqBy } from "lodash";
import {
  ProductCardProductFragment,
  ProductCollectionSortKeys,
  ProductSortKeys,
  SearchSortKeys,
  useFilterShopProductsQuery,
  useGetOurShopProductsQuery,
  useGetProductsForProductCardQuery,
} from "../../generated/storefront";
import { Medium, Size, SortBy } from "../../types/product";
import { getThisMonthsDate } from "../../helpers/time";

export const useShop = ({ handle, variantLimit }: { handle?: string; variantLimit?: number }) => {
  const { selectedCountry } = useAppState();
  const [searchParams, setSearchParams] = useSearchParams();
  const [collectionTitle, setCollectionTitle] = useState("Shop");
  const [collectionCategory, setCollectionCategory] = useState<string | null>(null);
  const sortBy = searchParams.get("sort");
  const month = searchParams.get("month");
  const medium = searchParams.get("medium");
  const size = searchParams.get("size");
  const style = searchParams.get("style");
  const subject = searchParams.get("subject");
  const orientation = searchParams.get("orientation");
  const colour = searchParams.get("colour");
  const useSearchQuery = Boolean(style || subject || colour);

  const sortKey = match(sortBy)
    .with(SortBy.BestSelling, () => ProductCollectionSortKeys.BestSelling)
    .with(SortBy.PriceHighToLow, () => ProductCollectionSortKeys.Price)
    .with(SortBy.PriceLowToHigh, () => ProductCollectionSortKeys.Price)
    .with(SortBy.Newest, () => ProductCollectionSortKeys.Created)
    .otherwise(() => ProductCollectionSortKeys.BestSelling);

  const reverse = sortBy === SortBy.PriceHighToLow || sortBy === SortBy.Newest;
  const mediums = (medium?.split(",") || []) as Medium[];
  const styles = style?.split(",") || [];
  const subjects = subject?.split(",") || [];
  const sizes = (size?.split(",") || []) as Size[];
  const orientations = orientation?.split(",") || [];
  const colours = colour?.split(",") || [];
  const months = month?.split(",") || [];

  const filters = [
    ...months.map((m) => ({ productType: m })),
    ...sizes.map((s) => ({ variantOption: { name: "size", value: s } })),
    ...orientations.map((o) => ({ productMetafield: { namespace: "custom", key: "orientation", value: o } })),
    ...mediums.map((m) => ({ productMetafield: { namespace: "custom", key: "medium", value: m } })),
  ];

  const {
    data,
    loading,
    refetch,
    fetchMore: fetchMoreProducts,
  } = useGetOurShopProductsQuery({
    variables: {
      handle,
      sortKey,
      reverse,
      filters,
      limit: 24,
      variantLimit,
      country: selectedCountry,
    },
    skip: useSearchQuery,
  });

  const searchSortKey = match(sortBy)
    .with(SortBy.BestSelling, () => SearchSortKeys.Relevance)
    .with(SortBy.PriceHighToLow, () => SearchSortKeys.Price)
    .with(SortBy.PriceLowToHigh, () => SearchSortKeys.Price)
    .with(SortBy.Newest, () => SearchSortKeys.Relevance)
    .otherwise(() => SearchSortKeys.Relevance);

  const gmStyles = styles.map((style) => `gm.${style}`);
  const gmSubjects = subjects.map((subject) => `gm.${subject}`);
  const searchQueryArray = [
    ...(colours.length ? [`${colours.join(" OR ")}`] : []),
    ...(styles.length ? [`tag:${gmStyles.join(" OR ")}`] : []),
    ...(subjects.length ? [`tag:${gmSubjects.join(" OR ")}`] : []),
    "tag:favourite",
  ];

  const {
    data: filteredData,
    loading: loadingFilteredData,
    refetch: refectFilteredData,
    fetchMore: fetchMoreFilteredProducts,
  } = useFilterShopProductsQuery({
    variables: {
      query: searchQueryArray.join(" "),
      sortKey: searchSortKey,
      reverse: sortBy === SortBy.PriceHighToLow,
      productFilters: filters,
      limit: 24,
      variantLimit,
      country: selectedCountry,
    },
    skip: !useSearchQuery,
  });

  const { data: ourPicksData } = useGetProductsForProductCardQuery({
    variables: {
      sortKey: ProductSortKeys.BestSelling,
      query: `tag:pick product_type:${getThisMonthsDate()}`,
      country: selectedCountry,
      limit: 20,
    },
  });

  const ourPicksProducts = useMemo(() => ourPicksData?.products.nodes || [], [ourPicksData]);
  const products = useMemo(
    () =>
      useSearchQuery
        ? filteredData?.search.nodes.filter((node) => node.__typename === "Product") || []
        : data?.collection?.products.nodes || [],
    [data, filteredData, useSearchQuery]
  ) as ProductCardProductFragment[];
  const afterCursor = useMemo(
    () => (useSearchQuery ? filteredData?.search.pageInfo.endCursor : data?.collection?.products.pageInfo.endCursor),
    [data, filteredData, useSearchQuery]
  );
  const hasMore = useMemo(
    () =>
      useSearchQuery
        ? filteredData?.search.pageInfo.hasNextPage || false
        : data?.collection?.products.pageInfo.hasNextPage || false,
    [data, filteredData, useSearchQuery]
  );
  const fetchMore = useMemo(
    () => (useSearchQuery ? fetchMoreFilteredProducts : fetchMoreProducts),
    [fetchMoreFilteredProducts, fetchMoreProducts, useSearchQuery]
  );

  useEffect(() => {
    if (data?.collection?.handle === "our-shop") return setCollectionCategory("Featured");
    if (data?.collection?.title.includes(":")) {
      const category = data.collection.title.split(": ")[0];
      if (category.includes(". ")) return setCollectionCategory(category.split(". ")[1]);
      setCollectionCategory(category);
    }
  }, [data?.collection]);

  const loadMore = async () => {
    fetchMore({
      variables: {
        afterCursor,
      },
    });
  };

  const clearFilters = () => {
    const newParams = new URLSearchParams();
    setSearchParams(newParams, { preventScrollReset: true });
    if (window.scrollY > 650) window.scrollTo(0, 650);
  };

  useEffect(() => {
    if (data?.collection) {
      setCollectionTitle(data.collection.title.split(": ").pop() || "");
    }
  }, [data]);

  return {
    collectionTitle,
    collectionDescription: data?.collection?.description,
    collectionDescriptionHtml: data?.collection?.descriptionHtml,
    collectionCategory,
    products: months.length === 1 ? uniqBy([...ourPicksProducts, ...products], (product) => product.id) : products,
    loadMore,
    refetch: useSearchQuery ? refectFilteredData : refetch,
    clearFilters,
    loading: loading || loadingFilteredData,
    hasMore,
  };
};
