import { SyntheticEvent, useContext, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { uniq } from "lodash";
import { Stack, Typography } from "@mui/material";
import Button from "../../components/Button";
import IconButton from "../../components/Button/IconButton";
import { Container, Flex, Margin, Row } from "../../components/Layout";
import { Header, Text } from "../../components/Text";
import { getIdNumber } from "../../helpers/shopify";
import { createProduct, uploadImageToShopify } from "../../services/API";
import StepFour from "./StepFour";
import StepOne from "./StepOne";
import StepThree from "./StepThree";
import StepTwo from "./StepTwo";
import LoginModal from "./loginModal";
import { BackButton, Content, ImageContainer, ImageSection, ProductImage, Step, Steps, StepsContainer } from "./styles";
import { defaultUploadState, useAppState } from "../../state";
import AuthContext from "../../state/auth";
import { Frame, Orientation, Ratio, tShirtSizeNumbers } from "../../types/product";
import { colors } from "../../theme";
import { WallMockUp } from "../../components/MockUps";
import { getUserById, updateUser } from "../../services/Firebase/users";
import { EventType } from "../../types/email";
import useFirebaseImage from "../../hooks/useFirebaseImage";
import { getDimensionsForCanvas, getDimensionsForMockUpTemplate, tShirtSizeToSize } from "../../helpers/product";
import { validate } from "../../helpers/formValidation";
import { imageZoom } from "./helpers";
import { triggerEvent } from "../../services/Klaviyo";
import { getNextMonth } from "../../helpers/time";

const Upload = () => {
  const { navHeight, uploadState, setUploadState, isMobileScreen, setError } = useAppState();
  const { user, isAdmin } = useContext(AuthContext);
  const [productId, setProductId] = useState("");
  const [productImage, setProductImage] = useState("");
  const [loading, setLoading] = useState<boolean>(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [artistId, setArtistId] = useState<string | undefined>();
  const [step, setStep] = useState(1);
  const { confirmedRgb, maxSize, ratio } = uploadState;
  const { uploadImage, uploading, progress, imageUploadError, imageUploadWarning, isLandscape, sizeOfUpload } = useFirebaseImage(
    setUploadState,
    maxSize,
    uploadState.ratio
  );
  const { width, height } = getDimensionsForCanvas(Ratio.Rectangle);
  const { width: squareWidth } = getDimensionsForCanvas(Ratio.Square);
  const { width: containerWidth, height: containerHeight } = getDimensionsForMockUpTemplate(uploadState.ratio);
  const uploadedImageRef = useRef<HTMLImageElement>(null);
  const size = tShirtSizeToSize(maxSize, ratio);

  const onStepChange = (step: number) => {
    setStep(step);
    window.scrollTo(0, 0);
  };

  const goBackToBeginning = () => {
    window.location.reload();
  };

  const onSubmit = async () => {
    setError({ upload: "" });
    const artist = isAdmin && artistId ? await getUserById(artistId) : user;
    if (!artist) {
      setError({ upload: "Not logged in, please login and try again" });
      throw Error("No logged in user");
    }
    try {
      setLoading(true);
      const { exclusiveToGoodMood, ...state } = uploadState;
      const validation = validate(state);
      if (!validation.error) {
        const imageSrc = await uploadImageToShopify(validation.state.imageName, validation.state.imageFile);
        if (!imageSrc) return setError({ upload: "Error uploading image" });
        const { title, description, price, medium, imageName, maxSize, campaign, keywords, ratio, gmTags } = validation.state;
        const imageHeight = uploadState.image.height;
        const imageWidth = uploadState.image.width;
        const orientation =
          imageHeight === imageWidth
            ? Orientation.Square
            : imageHeight > imageWidth
            ? Orientation.Portrait
            : Orientation.Landscape;
        const product = await createProduct(
          {
            title,
            description,
            price,
            medium,
            exclusiveToGoodMood,
            imageName,
            imageSrc,
            maxSize,
            campaign,
            keywords,
            ratio,
            orientation,
            gmTags: gmTags.map((tag) => `gm.${tag}`),
          },
          artist.id
        );
        if (product.id) {
          const id = getIdNumber(product.id);
          setProductId(id);
        } else {
          setProductId("pending");
        }
        setProductImage(imageSrc);
        const mediums = uniq([...(artist.mediums || []), validation.state.medium]);
        if (!artist.hasProducts) {
          await updateUser(artist.id, { hasProducts: true, mediums, lastUploaded: new Date() });
          await triggerEvent({ email: artist.email, type: EventType.ArtistFirstUpload });
        } else {
          await updateUser(artist.id, { mediums, lastUploaded: new Date() });
        }
        await triggerEvent({ email: artist.email, type: EventType.ArtistUpload });
        setLoading(false);
        onStepChange(4);
        const { ratio: defaultRatio, maxSize: defaultMaxSize, ...rest } = defaultUploadState;
        setUploadState(rest);
      } else {
        setError({ upload: Object.values(validation.errors).join(", ") });
        setLoading(false);
      }
    } catch (error: any) {
      const errorMessage = error.response?.data?.message || error.message || JSON.stringify(error);
      setError({ upload: errorMessage });
      setLoading(false);
    }
  };

  const createImageZoom = (event: SyntheticEvent<HTMLImageElement, Event>) => {
    imageZoom(event.target as HTMLImageElement, "zoomedUploadedImage", maxSize, ratio, isLandscape);
  };

  useEffect(() => {
    setModalOpen(step === 3 && !user && !artistId);
  }, [step, user, artistId]);

  useEffect(() => {
    if (uploadedImageRef.current) {
      imageZoom(uploadedImageRef.current, "zoomedUploadedImage", maxSize, ratio, isLandscape);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [maxSize]);

  const reuploadRequired = Boolean(sizeOfUpload && tShirtSizeNumbers[sizeOfUpload] < tShirtSizeNumbers[maxSize]);
  const stepOneComplete =
    isAdmin || Boolean(!isAdmin && uploadState.image.src && !uploading && !reuploadRequired && confirmedRgb);
  const stepTwoComplete =
    isAdmin ||
    Boolean(stepOneComplete && uploadState.title && uploadState.description && uploadState.medium && uploadState.paperType);
  const stepThreeComplete = isAdmin || Boolean(!isAdmin && stepOneComplete && stepTwoComplete && uploadState.price);

  return (
    <>
      <Helmet>
        <title>Upload | GoodMood</title>
        <meta name="description" content="Upload your art to GoodMood" />
      </Helmet>
      {isMobileScreen ? (
        <Container padding="24px">
          <Flex direction="column" alignItems="center">
            <Typography variant="h4" component="h1" align="center">
              Use a desktop to upload and start selling.
            </Typography>
            <Margin margin={24} />
            <Link
              to={{
                pathname: "/shop",
                search: "?collection=true",
              }}
            >
              <Button secondary>Explore the collection</Button>
            </Link>
          </Flex>
        </Container>
      ) : (
        <Row nowrap>
          <Stack width="66%">
            <ImageSection>
              {step !== 1 && step !== 4 && (
                <BackButton>
                  <IconButton onClick={() => onStepChange(step - 1)} icon="backArrow" variation="light" />
                </BackButton>
              )}
              <Flex direction="column" fullWidth>
                <Container
                  background={colors.grey02}
                  width={100}
                  margin="0 0 4px"
                  padding="0 0 24px"
                  style={{ overflow: "hidden" }}
                >
                  <Flex justifyContent="center" alignItems="center" style={{ height: "80vh" }}>
                    <ImageContainer width={containerWidth} height={containerHeight} isLandscape={isLandscape}>
                      {uploadState.image.src && !productImage ? (
                        <div id="zoomedUploadedImage" className="img-zoom-result"></div>
                      ) : null}
                      {(uploadState.qualityCheckImageSrc || productImage) && (
                        <ProductImage
                          ref={uploadedImageRef}
                          onLoad={createImageZoom}
                          src={uploadState.qualityCheckImageSrc || productImage}
                        />
                      )}
                      <canvas id="qualityCheckCanvas" style={{ visibility: "hidden", position: "absolute" }}></canvas>
                      <canvas id="canvasLandscape" width={height} height={width} style={{ visibility: "hidden" }}></canvas>
                      <canvas id="canvasPortrait" width={width} height={height} style={{ visibility: "hidden" }}></canvas>
                      <canvas
                        id="canvasSquare"
                        width={squareWidth}
                        height={squareWidth}
                        style={{ visibility: "hidden" }}
                      ></canvas>
                    </ImageContainer>
                  </Flex>
                  {uploadState.image.src && uploadState.qualityCheckImageSrc && !productImage && (
                    <Text align="center">Hover over piece to view print quality at each size</Text>
                  )}
                </Container>
                {uploadState.image.src && !productId && (
                  <WallMockUp image={uploadState.image} frame={Frame.Natural} size={size} isMobile={isMobileScreen} />
                )}
              </Flex>
            </ImageSection>
          </Stack>
          <Stack width="34%" minWidth={450}>
            <StepsContainer sticky={false} navHeight={navHeight}>
              {productId && productImage && !loading ? (
                <Container padding="24px">
                  <Flex gap={16} direction="column">
                    <Header type="h2">You're all done!</Header>
                    <Text size={16}>
                      Please allow up to 24 hours for it to appear on your profile. If we love your work, we'll feature it in the{" "}
                      {getNextMonth()} collection
                    </Text>
                    <Button fullWidth onClick={goBackToBeginning}>
                      Upload another piece
                    </Button>
                    <Link to="/profile">
                      <Button fullWidth secondary>
                        Go to dashboard
                      </Button>
                    </Link>
                  </Flex>
                </Container>
              ) : (
                <>
                  <Steps>
                    <Text size={14} as="span">
                      Step
                    </Text>
                    <Step selected={step === 1} onClick={() => onStepChange(1)}>
                      1
                    </Step>
                    <Step selected={step === 2} onClick={() => onStepChange(2)} disabled={!stepOneComplete}>
                      2
                    </Step>
                    <Step selected={step === 3} onClick={() => onStepChange(3)} disabled={!stepTwoComplete}>
                      3
                    </Step>
                    <Step selected={step === 4} onClick={() => onStepChange(4)} disabled={!stepThreeComplete}>
                      4
                    </Step>
                  </Steps>
                  <Content>
                    {step === 1 && (
                      <StepOne
                        state={uploadState}
                        setState={setUploadState}
                        onStepChange={onStepChange}
                        imageUploaded={Boolean(uploadState.image.src && !uploading)}
                        uploadImage={uploadImage}
                        uploading={uploading}
                        progress={progress}
                        uploadError={imageUploadError}
                        uploadWarning={imageUploadWarning}
                        reuploadRequired={reuploadRequired}
                        stepComplete={stepOneComplete}
                        setConfirmedRgb={(value: boolean) => setUploadState({ confirmedRgb: value })}
                      />
                    )}
                    {step === 2 && (
                      <StepTwo
                        state={uploadState}
                        setState={setUploadState}
                        setArtistId={setArtistId}
                        onStepChange={onStepChange}
                        stepComplete={stepTwoComplete}
                      />
                    )}
                    {step === 3 && (
                      <StepThree
                        state={uploadState}
                        setState={setUploadState}
                        onStepChange={onStepChange}
                        stepComplete={stepThreeComplete}
                      />
                    )}
                    {step === 4 && <StepFour state={uploadState} onSubmit={onSubmit} loading={loading} />}
                  </Content>
                </>
              )}
            </StepsContainer>
          </Stack>
        </Row>
      )}

      {modalOpen && <LoginModal setModalOpen={setModalOpen} />}
    </>
  );
};

export default Upload;
