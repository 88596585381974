import { format, startOfMonth, subMonths } from "date-fns";
import { Medium, MediumNames } from "../types/product";
import blackandwhite from "../assets/images/blackandwhite.png";
import multi from "../assets/images/multi.png";

export const deliveryCountries = [
  "Australia",
  "Austria",
  "Belgium",
  "Canada",
  "China",
  "Croatia",
  "Cyprus",
  "Czechia",
  "Denmark",
  "Estonia",
  "Finland",
  "France",
  "Germany",
  "Greece",
  "Guernsey",
  "Hong Kong SAR",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Ireland",
  "Isle of Man",
  "Israel",
  "Italy",
  "Japan",
  "Jersey",
  "Jordan",
  "Kazakhstan",
  "Latvia",
  "Lebanon",
  "Lithuania",
  "Luxembourg",
  "Malaysia",
  "Malta",
  "Mexico",
  "Netherlands",
  "New Zealand",
  "Norway",
  "Oman",
  "Philippines",
  "Poland",
  "Portugal",
  "Saudi Arabia",
  "Slovakia",
  "Slovenia",
  "Spain",
  "Sweden",
  "Switzerland",
  "Taiwan",
  "Tanzania",
  "Turkey",
  "United Arab Emirates",
  "United Kingdom",
  "United States",
  "Vatican City",
  "Vietnam",
];

export const freeDeliveryCountries = [
  {
    countryCode: "GB",
    name: "United Kingdom",
    minOrderValue: 200,
  },
  {
    countryCode: "IM",
    name: "Isle of Man",
    minOrderValue: 200,
  },
  {
    countryCode: "GG",
    name: "Guernsey",
    minOrderValue: 200,
  },
  {
    countryCode: "US",
    name: "United States",
    minOrderValue: 250,
  },
  {
    countryCode: "AT",
    name: "Austria",
    minOrderValue: 250,
  },
  {
    countryCode: "BE",
    name: "Belgium",
    minOrderValue: 250,
  },
  {
    countryCode: "HR",
    name: "Croatia",
    minOrderValue: 250,
  },
  {
    countryCode: "DK",
    name: "Denmark",
    minOrderValue: 250,
  },
  {
    countryCode: "EE",
    name: "Estonia",
    minOrderValue: 250,
  },
  {
    countryCode: "FI",
    name: "Finland",
    minOrderValue: 250,
  },
  {
    countryCode: "FR",
    name: "France",
    minOrderValue: 250,
  },
  {
    countryCode: "DE",
    name: "Germany",
    minOrderValue: 250,
  },
  {
    countryCode: "GR",
    name: "Greece",
    minOrderValue: 250,
  },
  {
    countryCode: "HU",
    name: "Hungary",
    minOrderValue: 250,
  },
  {
    countryCode: "IS",
    name: "Iceland",
    minOrderValue: 250,
  },
  {
    countryCode: "IT",
    name: "Italy",
    minOrderValue: 250,
  },
  {
    countryCode: "LV",
    name: "Latvia",
    minOrderValue: 250,
  },
  {
    countryCode: "LT",
    name: "Lithuania",
    minOrderValue: 250,
  },
  {
    countryCode: "LU",
    name: "Luxembourg",
    minOrderValue: 250,
  },
  {
    countryCode: "NL",
    name: "Netherlands",
    minOrderValue: 250,
  },
  {
    countryCode: "PL",
    name: "Poland",
    minOrderValue: 250,
  },
  {
    countryCode: "PT",
    name: "Portugal",
    minOrderValue: 250,
  },
  {
    countryCode: "IE",
    name: "Republic of Ireland",
    minOrderValue: 250,
  },
  {
    countryCode: "SK",
    name: "Slovakia",
    minOrderValue: 250,
  },
  {
    countryCode: "SI",
    name: "Slovenia",
    minOrderValue: 250,
  },
  {
    countryCode: "ES",
    name: "Spain",
    minOrderValue: 250,
  },
  {
    countryCode: "SE",
    name: "Sweden",
    minOrderValue: 250,
  },
  {
    countryCode: "CH",
    name: "Switzerland",
    minOrderValue: 250,
  },
];

const subMonthsFromToday = (months: number) => {
  const monthStart = startOfMonth(new Date());
  return {
    label: format(subMonths(monthStart, months), "MMMM yyyy"),
    value: format(subMonths(monthStart, months), "yyyy-MM"),
  };
};

export const last12Months = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((monthsToSubtract) =>
  subMonthsFromToday(monthsToSubtract)
);

export const giftCardId = "gid://shopify/Product/7925959131350";

const mediums = Object.values(Medium);
export const mediumOptions = mediums.map((c) => ({ value: c, label: MediumNames[c] }));

export const orientationOptions = [
  {
    label: "Portrait",
    value: "portrait",
  },
  {
    label: "Landscape",
    value: "landscape",
  },
  {
    label: "Square",
    value: "square",
  },
];

export const styleFilterOptions = [
  { label: "Abstract", value: "abstract", collectionHandle: "abstract" },
  { label: "Black & White", value: "blackandwhite", collectionHandle: "black-white-2" },
  { label: "Bold", value: "bold", collectionHandle: "bold" },
  { label: "Brutalist", value: "brutalist", collectionHandle: "brutalist" },
  { label: "Collage", value: "collage", collectionHandle: "collage" },
  { label: "Cottagecore", value: "cottagecore", collectionHandle: "cottagecore" },
  { label: "Digital Painting", value: "digitalpainting", collectionHandle: "digital-painting" },
  { label: "Electric", value: "electric", collectionHandle: "electric" },
  { label: "Escapist", value: "escapist", collectionHandle: "escapist" },
  { label: "Geometric", value: "geometric", collectionHandle: "geometric" },
  { label: "Illustrative", value: "illustrative", collectionHandle: "illustrative" },
  { label: "Line Art", value: "lineart", collectionHandle: "line-art" },
  { label: "Minimalist", value: "minimalist", collectionHandle: "minimalist" },
  { label: "Monochromatic", value: "monochromatic", collectionHandle: "monochromatic" },
  { label: "Oriental", value: "oriental", collectionHandle: "oriental" },
  { label: "Photography", value: "photography", collectionHandle: "photography" },
  { label: "Playful", value: "playful", collectionHandle: "playful" },
  { label: "Pop Art", value: "popart", collectionHandle: "pop-art" },
  { label: "Scandi", value: "scandi", collectionHandle: "scandi" },
  { label: "Soft Colour", value: "softcolour", collectionHandle: "soft-colour" },
  { label: "Surrealism", value: "surrealism", collectionHandle: "surrealism" },
  { label: "Swiss Design", value: "swissdesign", collectionHandle: "swiss-design" },
  { label: "Typographic", value: "typographic", collectionHandle: "typographic" },
  { label: "Whimsical", value: "whimsical", collectionHandle: "whimsical" },
];

export const subjectFilterOptions = [
  {
    label: "Animals",
    value: "animals",
    collectionHandle: "animals",
  },
  {
    label: "Architecture",
    value: "architecture",
    collectionHandle: "architecture",
  },
  {
    label: "Beach",
    value: "beach",
    collectionHandle: "beach",
  },
  {
    label: "Botanical",
    value: "botanical",
    collectionHandle: "botanical",
  },
  {
    label: "Floral",
    value: "floral",
    collectionHandle: "floral",
  },
  {
    label: "Food & Drink",
    value: "foodanddrink",
    collectionHandle: "food-drink",
  },
  {
    label: "Landscapes",
    value: "landscapes",
    collectionHandle: "landscapes",
  },
  {
    label: "Matchboxes",
    value: "matchboxes",
    collectionHandle: "matchboxes",
  },
  {
    label: "Music",
    value: "music",
    collectionHandle: "music",
  },
  {
    label: "Nude",
    value: "nude",
    collectionHandle: "nude",
  },
  {
    label: "People",
    value: "people",
    collectionHandle: "people",
  },
  {
    label: "Sports",
    value: "sports",
    collectionHandle: "sports",
  },
  {
    label: "Still Life",
    value: "stilllife",
    collectionHandle: "still-life",
  },
  {
    label: "Travel",
    value: "travel",
    collectionHandle: "travel",
  },
  {
    label: "Urban",
    value: "urban",
    collectionHandle: "urban",
  },
];

export const allFilterOptions = [...styleFilterOptions, ...subjectFilterOptions];

export const colourFilters = [
  {
    label: "Red",
    value: "red",
    hex: "#DE1A21",
  },
  {
    label: "Orange",
    value: "orange",
    hex: "#FA5F00",
  },
  {
    label: "Yellow",
    value: "yellow",
    hex: "#FFDE69",
  },
  {
    label: "Green",
    value: "green",
    hex: "#6CC24A",
  },
  {
    label: "Blue",
    value: "blue",
    hex: "#3A7DC9",
  },
  {
    label: "Purple",
    value: "purple",
    hex: "#800080",
  },
  {
    label: "Pink",
    value: "pink",
    hex: "#FFC0CB",
  },
  {
    label: "Brown",
    value: "brown",
    hex: "#9A4F21",
  },
  {
    label: "Beige",
    value: "beige",
    hex: "#F5F5DC",
  },
  {
    label: "Grey",
    value: "grey",
    hex: "#BEBEBE",
  },
  {
    label: "Black",
    value: "black",
    hex: "#000000",
  },
  {
    label: "White & Black",
    value: "black and white",
    url: blackandwhite,
  },
  {
    label: "Multi",
    value: "multi",
    url: multi,
  },
];

export const scrollbarStyles = {
  "-ms-overflow-style": "none",
  "scrollbar-width": "none",
  "&::-webkit-scrollbar": {
    display: "none",
  },
};

export const truncatedTextStyles = {
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
};

export const truncateThreeLinesStyles = {
  overflow: "hidden",
  display: "-webkit-box",
  "-webkit-line-clamp": "3",
  "-webkit-box-orient": "vertical",
};

export const currencies = [
  {
    value: "GBP",
    label: "GBP",
  },
  {
    value: "USD",
    label: "USD",
  },
  {
    value: "EUR",
    label: "EUR",
  },
  {
    value: "AUD",
    label: "AUD",
  },
];

export const sizings = [
  {
    value: "stretchToPrintArea",
    label: "Stretch",
  },
  {
    value: "fitPrintArea",
    label: "Fit",
  },
  {
    value: "fillPrintArea",
    label: "Fill",
  },
];

export const skus = [
  "GLOBAL-FAP-100X50",
  "GLOBAL-FAP-10X10",
  "GLOBAL-FAP-10X12",
  "GLOBAL-FAP-10X20",
  "GLOBAL-FAP-10X30",
  "GLOBAL-FAP-11X14",
  "GLOBAL-FAP-6X4",
  "GLOBAL-FAP-6X6",
  "GLOBAL-FAP-6X8",
  "GLOBAL-FAP-73X35",
  "GLOBAL-FAP-80X58",
  "GLOBAL-FAP-87X39",
  "GLOBAL-FAP-8X10",
  "GLOBAL-FAP-8X12",
  "GLOBAL-FAP-8X16",
  "GLOBAL-FAP-8X42",
  "GLOBAL-FAP-8X8",
  "GLOBAL-FAP-8_5X11",
  "GLOBAL-FAP-9X11",
  "GLOBAL-FAP-11X17",
  "GLOBAL-FAP-12X12",
  "GLOBAL-FAP-12X15",
  "GLOBAL-FAP-12X16",
  "GLOBAL-FAP-12X18",
  "GLOBAL-FAP-12X24",
  "GLOBAL-FAP-12X36",
  "GLOBAL-FAP-13X16",
  "GLOBAL-FAP-14X14",
  "GLOBAL-FAP-14X24",
  "GLOBAL-FAP-14X44",
  "GLOBAL-FAP-14_7X29_5",
  "GLOBAL-FAP-15X20",
  "GLOBAL-FAP-16X16",
  "GLOBAL-FAP-16X20",
  "GLOBAL-FAP-16X24",
  "GLOBAL-FAP-16X32",
  "GLOBAL-FAP-16X36",
  "GLOBAL-FAP-17X35",
  "GLOBAL-FAP-18X18",
  "GLOBAL-FAP-18X24",
  "GLOBAL-FAP-18X36",
  "GLOBAL-FAP-19X14",
  "GLOBAL-FAP-19_75X27_5",
  "GLOBAL-FAP-20X20",
  "GLOBAL-FAP-20X24",
  "GLOBAL-FAP-20X25",
  "GLOBAL-FAP-20X27",
  "GLOBAL-FAP-20X28",
  "GLOBAL-FAP-20X30",
  "GLOBAL-FAP-20X36",
  "GLOBAL-FAP-20X40",
  "GLOBAL-FAP-22X30",
  "GLOBAL-FAP-22X40",
  "GLOBAL-FAP-22X45",
  "GLOBAL-FAP-22X50",
  "GLOBAL-FAP-24X24",
  "GLOBAL-FAP-24X28",
  "GLOBAL-FAP-24X30",
  "GLOBAL-FAP-24X32",
  "GLOBAL-FAP-24X36",
  "GLOBAL-FAP-9X12",
  "GLOBAL-FAP-9X18",
  "GLOBAL-FAP-A0",
  "GLOBAL-FAP-A1",
  "GLOBAL-FAP-A2",
  "GLOBAL-FAP-A3",
  "GLOBAL-FAP-A4",
  "GLOBAL-FAP-A5",
  "GLOBAL-FAP-IK",
  "GLOBAL-HGE-10X10",
  "GLOBAL-HGE-10X12",
  "GLOBAL-HGE-10X20",
  "GLOBAL-HGE-10X30",
  "GLOBAL-FAP-24X40",
  "GLOBAL-FAP-24X47",
  "GLOBAL-FAP-25X45",
  "GLOBAL-FAP-25X50",
  "GLOBAL-FAP-26X36",
  "GLOBAL-FAP-26X38",
  "GLOBAL-FAP-27X35",
  "GLOBAL-FAP-27X41",
  "GLOBAL-FAP-27X60",
  "GLOBAL-FAP-28X18",
  "GLOBAL-FAP-28X28",
  "GLOBAL-FAP-28X36",
  "GLOBAL-FAP-28X40",
  "GLOBAL-FAP-28X43",
  "GLOBAL-FAP-28X45",
  "GLOBAL-FAP-28X48",
  "GLOBAL-FAP-28X50",
  "GLOBAL-FAP-28X55",
  "GLOBAL-FAP-28X63",
  "GLOBAL-FAP-28_25X40_25",
  "GLOBAL-FAP-30X30",
  "GLOBAL-FAP-30X32",
  "GLOBAL-FAP-30X34",
  "GLOBAL-FAP-30X40",
  "GLOBAL-FAP-30X45",
  "GLOBAL-FAP-30X60",
  "GLOBAL-FAP-31X50",
  "GLOBAL-FAP-31X63",
  "GLOBAL-FAP-31X71",
  "GLOBAL-FAP-31_5X35",
  "GLOBAL-FAP-32X32",
  "GLOBAL-FAP-32X40",
  "GLOBAL-FAP-32X44",
  "GLOBAL-FAP-32X47",
  "GLOBAL-FAP-32X48",
  "GLOBAL-FAP-32X70",
  "GLOBAL-HGE-116X19",
  "GLOBAL-HGE-11X14",
  "GLOBAL-HGE-11X17",
  "GLOBAL-HGE-12X12",
  "GLOBAL-HGE-12X16",
  "GLOBAL-HGE-12X18",
  "GLOBAL-HGE-12X24",
  "GLOBAL-HGE-12X36",
  "GLOBAL-FAP-33X60",
  "GLOBAL-FAP-34_5X48",
  "GLOBAL-FAP-35X44",
  "GLOBAL-FAP-35X51",
  "GLOBAL-FAP-35X53",
  "GLOBAL-FAP-35X70",
  "GLOBAL-FAP-35X73",
  "GLOBAL-FAP-36X36",
  "GLOBAL-FAP-36X48",
  "GLOBAL-FAP-36X55",
  "GLOBAL-FAP-37X60",
  "GLOBAL-FAP-39X52",
  "GLOBAL-FAP-39X70",
  "GLOBAL-FAP-39X79",
  "GLOBAL-FAP-39X87",
  "GLOBAL-FAP-40X40",
  "GLOBAL-FAP-40X43",
  "GLOBAL-FAP-40X48",
  "GLOBAL-FAP-40X50",
  "GLOBAL-FAP-40X53",
  "GLOBAL-FAP-40X55",
  "GLOBAL-FAP-40X60",
  "GLOBAL-FAP-40X80",
  "GLOBAL-FAP-41X79",
  "GLOBAL-FAP-41X83",
  "GLOBAL-FAP-42X42",
  "GLOBAL-FAP-42X56",
  "GLOBAL-FAP-43X87",
  "GLOBAL-FAP-44X50",
  "GLOBAL-FAP-44X64",
  "GLOBAL-FAP-44X67",
  "GLOBAL-FAP-44X70",
  "GLOBAL-FAP-45X45",
  "GLOBAL-FAP-47X70",
  "GLOBAL-FAP-4X6",
  "GLOBAL-FAP-50X50",
  "GLOBAL-FAP-56X70",
  "GLOBAL-FAP-58X68",
  "GLOBAL-FAP-5X7",

  "GLOBAL-CFP-10X20",
  "GLOBAL-CFP-10X30",
  "GLOBAL-CFP-11X11",
  "GLOBAL-CFP-11X14",
  "GLOBAL-CFP-11X14-BACKLOADER",
  "GLOBAL-CFP-11X17",
  "GLOBAL-CFP-12X12",
  "GLOBAL-CFP-12X12-BACKLOADER",
  "GLOBAL-CFP-12X15",
  "GLOBAL-CFP-12X16",
  "GLOBAL-CFP-12X16-BACKLOADER",
  "GLOBAL-CFP-12X18",
  "GLOBAL-CFP-12X24",
  "GLOBAL-CFP-12X36",
  "GLOBAL-CFP-13X19",
  "GLOBAL-CFP-14X14",
  "GLOBAL-CFP-14X24",
  "GLOBAL-CFP-15X20",
  "GLOBAL-CFP-16X16",
  "GLOBAL-CFP-16X20",
  "GLOBAL-CFP-16X20-BACKLOADER",
  "GLOBAL-CFP-16X24",
  "GLOBAL-CFP-16X36",
  "GLOBAL-CFP-17X35",
  "GLOBAL-CFP-18X18",
  "GLOBAL-CFP-18X24",
  "GLOBAL-CFP-18X24-BACKLOADER",
  "GLOBAL-CFP-18X36",
  "GLOBAL-CFP-20X20",
  "GLOBAL-CFP-20X20-BACKLOADER",
  "GLOBAL-CFP-20X24",
  "GLOBAL-CFP-20X28",
  "GLOBAL-CFP-20X28-BACKLOADER",
  "GLOBAL-CFP-20X30",
  "GLOBAL-CFP-20X36",
  "GLOBAL-CFP-20X40",
  "GLOBAL-CFP-22X28",
  "GLOBAL-CFP-22X40",
  "GLOBAL-CFP-24X24",
  "GLOBAL-CFP-24X30",
  "GLOBAL-CFP-24X32",
  "GLOBAL-CFP-24X32-BACKLOADER",
  "GLOBAL-CFP-24X36",
  "GLOBAL-CFP-24X36-BACKLOADER",
  "GLOBAL-CFP-28X28",
  "GLOBAL-CFP-28X28-BACKLOADER",
  "GLOBAL-CFP-28X40",
  "GLOBAL-CFP-28X40-BACKLOADER",
  "GLOBAL-CFP-30X30",
  "GLOBAL-CFP-30X32",
  "GLOBAL-CFP-30X34",
  "GLOBAL-CFP-30X40",
  "GLOBAL-CFP-30X40-BACKLOADER",
  "GLOBAL-CFP-30X45",
  "GLOBAL-CFP-32X40",
  "GLOBAL-CFP-36X36",
  "GLOBAL-CFP-40X40",
  "GLOBAL-CFP-5X7",
  "GLOBAL-CFP-6X8",
  "GLOBAL-CFP-6X8-BACKLOADER",
  "GLOBAL-CFP-8X10",
  "GLOBAL-CFP-8X12",
  "GLOBAL-CFP-8X16",
  "GLOBAL-CFP-8X8",
  "GLOBAL-CFP-9X12",
  "GLOBAL-CFP-9X18",
  "GLOBAL-CFP-A0",
  "GLOBAL-CFP-A0-BACKLOADER",
  "GLOBAL-CFP-A1",
  "GLOBAL-CFP-A1-BACKLOADER",
  "GLOBAL-CFP-A2",
  "GLOBAL-CFP-A2-BACKLOADER",
  "GLOBAL-CFP-A3",
  "GLOBAL-CFP-A3-BACKLOADER",
  "GLOBAL-CFP-A4",
  "GLOBAL-CFP-A4-BACKLOADER",
  "GLOBAL-CFP-A5",

  "GLOBAL-CFPM-10X20",
  "GLOBAL-CFPM-10X30",
  "GLOBAL-CFPM-11X14",
  "GLOBAL-CFPM-11X17",
  "GLOBAL-CFPM-12X12",
  "GLOBAL-CFPM-12X16",
  "GLOBAL-CFPM-12X18",
  "GLOBAL-CFPM-12X24",
  "GLOBAL-CFPM-12X36",
  "GLOBAL-CFPM-16X16",
  "GLOBAL-CFPM-16X20",
  "GLOBAL-CFPM-16X24",
  "GLOBAL-CFPM-18X24",
  "GLOBAL-CFPM-20X20",
  "GLOBAL-CFPM-20X28",
  "GLOBAL-CFPM-20X30",
  "GLOBAL-CFPM-20X40",
  "GLOBAL-CFPM-24X24",
  "GLOBAL-CFPM-24X32",
  "GLOBAL-CFPM-24X36",
  "GLOBAL-CFPM-28X28",
  "GLOBAL-CFPM-28X40",
  "GLOBAL-CFPM-30X30",
  "GLOBAL-CFPM-30X40",
  "GLOBAL-CFPM-36X36",
  "GLOBAL-CFPM-5X7",
  "GLOBAL-CFPM-6X8",
  "GLOBAL-CFPM-8X10",
  "GLOBAL-CFPM-8X12",
  "GLOBAL-CFPM-8X16",
  "GLOBAL-CFPM-8X8",
  "GLOBAL-CFPM-A1",
  "GLOBAL-CFPM-A2",
  "GLOBAL-CFPM-A3",
  "GLOBAL-CFPM-A4",
  "GLOBAL-CFPM-A5",

  "GLOBAL-BOX-10X10",
  "GLOBAL-BOX-10X20",
  "GLOBAL-BOX-11X14",
  "GLOBAL-BOX-12X12",
  "GLOBAL-BOX-12X16",
  "GLOBAL-BOX-12X18",
  "GLOBAL-BOX-12X24",
  "GLOBAL-BOX-16X16",
  "GLOBAL-BOX-16X20",
  "GLOBAL-BOX-16X24",
  "GLOBAL-BOX-16X32",
  "GLOBAL-BOX-18X24",
  "GLOBAL-BOX-20X20",
  "GLOBAL-BOX-20X28",
  "GLOBAL-BOX-20X30",
  "GLOBAL-BOX-20X40",
  "GLOBAL-BOX-24X24",
  "GLOBAL-BOX-24X32",
  "GLOBAL-BOX-24X36",
  "GLOBAL-BOX-28X28",
  "GLOBAL-BOX-28X40",
  "GLOBAL-BOX-28X42",
  "GLOBAL-BOX-305X305",
  "GLOBAL-BOX-305X406",
  "GLOBAL-BOX-30X30",
  "GLOBAL-BOX-30X40",
  "GLOBAL-BOX-32X32",
  "GLOBAL-BOX-32X48",
  "GLOBAL-BOX-36X36",
  "GLOBAL-BOX-36X48",
  "GLOBAL-BOX-36X55",
  "GLOBAL-BOX-406X406",
  "GLOBAL-BOX-40X40",
  "GLOBAL-BOX-40X52",
  "GLOBAL-BOX-40X60",
  "GLOBAL-BOX-42X56",
  "GLOBAL-BOX-457X610",
  "GLOBAL-BOX-45X60",
  "GLOBAL-BOX-4X6",
  "GLOBAL-BOX-50X50",
  "GLOBAL-BOX-5X7",
  "GLOBAL-BOX-610X610",
  "GLOBAL-BOX-610X813",
  "GLOBAL-BOX-6X6",
  "GLOBAL-BOX-6X8",
  "GLOBAL-BOX-8X10",
  "GLOBAL-BOX-8X12",
  "GLOBAL-BOX-8X8",
  "GLOBAL-BOX-9X12",
  "GLOBAL-BOX-A1",
  "GLOBAL-BOX-A2",
  "GLOBAL-BOX-A3",
  "GLOBAL-BOX-A4",

  "GLOBAL-BOXM-10X10",
  "GLOBAL-BOXM-10X20",
  "GLOBAL-BOXM-11X14",
  "GLOBAL-BOXM-12X12",
  "GLOBAL-BOXM-12X16",
  "GLOBAL-BOXM-12X18",
  "GLOBAL-BOXM-12X24",
  "GLOBAL-BOXM-16X16",
  "GLOBAL-BOXM-16X20",
  "GLOBAL-BOXM-16X24",
  "GLOBAL-BOXM-16X32",
  "GLOBAL-BOXM-18X24",
  "GLOBAL-BOXM-20X20",
  "GLOBAL-BOXM-20X28",
  "GLOBAL-BOXM-20X30",
  "GLOBAL-BOXM-20X40",
  "GLOBAL-BOXM-24X24",
  "GLOBAL-BOXM-24X32",
  "GLOBAL-BOXM-24X36",
  "GLOBAL-BOXM-28X28",
  "GLOBAL-BOXM-28X40",
  "GLOBAL-BOXM-28X42",
  "GLOBAL-BOXM-305X305",
  "GLOBAL-BOXM-305X406",
  "GLOBAL-BOXM-30X30",
  "GLOBAL-BOXM-30X40",
  "GLOBAL-BOXM-32X32",
  "GLOBAL-BOXM-32X48",
  "GLOBAL-BOXM-36X36",
  "GLOBAL-BOXM-36X48",
  "GLOBAL-BOXM-36X55",
  "GLOBAL-BOXM-406X406",
  "GLOBAL-BOXM-40X40",
  "GLOBAL-BOXM-40X52",
  "GLOBAL-BOXM-40X60",
  "GLOBAL-BOXM-42X56",
  "GLOBAL-BOXM-457X610",
  "GLOBAL-BOXM-45X60",
  "GLOBAL-BOXM-4X6",
  "GLOBAL-BOXM-50X50",
  "GLOBAL-BOXM-5X7",
  "GLOBAL-BOXM-610X610",
  "GLOBAL-BOXM-610X813",
  "GLOBAL-BOXM-6X6",
  "GLOBAL-BOXM-6X8",
  "GLOBAL-BOXM-8X10",
  "GLOBAL-BOXM-8X12",
  "GLOBAL-BOXM-8X8",
  "GLOBAL-BOXM-9X12",
  "GLOBAL-BOXM-A1",
  "GLOBAL-BOXM-A2",
  "GLOBAL-BOXM-A3",
  "GLOBAL-BOXM-A4",

  "GLOBAL-CAN-4x6",
  "GLOBAL-CAN-8x11",
  "GLOBAL-CAN-A5",
  "GLOBAL-CAN-6x6",
  "GLOBAL-CAN-6x8",
  "GLOBAL-CAN-8x10",
  "GLOBAL-CAN-8x8",
  "GLOBAL-CAN-10x10",
  "GLOBAL-CAN-8x12",
  "GLOBAL-CAN-A4",
  "GLOBAL-CAN-10x12",
  "GLOBAL-CAN-8x16",
  "GLOBAL-CAN-10x16",
  "GLOBAL-CAN-11x14",
  "GLOBAL-CAN-12x12",
  "GLOBAL-CAN-8X20",
  "GLOBAL-CAN-12x16",
  "GLOBAL-CAN-13x16",
  "GLOBAL-CAN-8X24",
  "GLOBAL-CAN-A3",
  "GLOBAL-CAN-10x20",
  "GLOBAL-CAN-12x18",
  "GLOBAL-CAN-10x24",
  "GLOBAL-CAN-12x20",
  "GLOBAL-CAN-16x16",
  "GLOBAL-CAN-18x18",
  "GLOBAL-CAN-12x24",
  "GLOBAL-CAN-16x20",
  "GLOBAL-CAN-16x24",
  "GLOBAL-CAN-20x20",
  "GLOBAL-CAN-A2",
  "GLOBAL-CAN-18x24",
  "GLOBAL-CAN-10x30",
  "GLOBAL-CAN-20x24",
  "GLOBAL-CAN-20x28",
  "GLOBAL-CAN-20x30",
  "GLOBAL-CAN-24x24",
  "GLOBAL-CAN-16x36",
  "GLOBAL-CAN-24x32",
  "GLOBAL-CAN-18x36",
  "GLOBAL-CAN-16x32",
  "GLOBAL-CAN-A1",
  "GLOBAL-CAN-24x36",
  "GLOBAL-CAN-20x32",
  "GLOBAL-CAN-15x30",
  "GLOBAL-CAN-18x26",
  "GLOBAL-CAN-28x28",
  "GLOBAL-CAN-30x30",
  "GLOBAL-CAN-24x40",
  "GLOBAL-CAN-28x32",
  "GLOBAL-CAN-14x24",
  "GLOBAL-CAN-24x48",
  "GLOBAL-CAN-28x42",
  "GLOBAL-CAN-32x32",
  "GLOBAL-CAN-8x32",
  "GLOBAL-CAN-21X28",
  "GLOBAL-CAN-28x36",
  "GLOBAL-CAN-12x32",
  "GLOBAL-CAN-20x60",
  "GLOBAL-CAN-20x40",
  "GLOBAL-CAN-26x26",
  "GLOBAL-CAN-26x32",
  "GLOBAL-CAN-28x40",
  "GLOBAL-CAN-36x36",
  "GLOBAL-CAN-12x36",
  "GLOBAL-CAN-24x30",
  "GLOBAL-CAN-24x47",
  "GLOBAL-CAN-36x40",
  "GLOBAL-CAN-38x38",
  "GLOBAL-CAN-30x40",
  "GLOBAL-CAN-32x48",
  "GLOBAL-CAN-16x48",
  "GLOBAL-CAN-32x40",
  "GLOBAL-CAN-26x40",
  "GLOBAL-CAN-28x43",
  "GLOBAL-CAN-28x48",
  "GLOBAL-CAN-36x48",
  "GLOBAL-CAN-30x60",
  "GLOBAL-CAN-36x50",
  "GLOBAL-CAN-30x45",
  "GLOBAL-CAN-15x38",
  "GLOBAL-CAN-40x40",
  "GLOBAL-CAN-40x48",
  "GLOBAL-CAN-32x44",
  "GLOBAL-CAN-40x50",
  "GLOBAL-CAN-28x55",
  "GLOBAL-CAN-35x44",
  "GLOBAL-CAN-28x63",
  "GLOBAL-CAN-35x51",
  "GLOBAL-CAN-40x55",
  "GLOBAL-CAN-35x53",
  "GLOBAL-CAN-48x48",
  "GLOBAL-CAN-A0",
  "GLOBAL-CAN-24x72",
  "GLOBAL-CAN-31x63",
  "GLOBAL-CAN-45x60",
  "GLOBAL-CAN-40x60",
  "GLOBAL-CAN-39x79",
  "GLOBAL-CAN-41x83",
  "GLOBAL-CAN-43x87",
  "GLOBAL-CAN-50x50",
  "GLOBAL-CAN-54x68",
  "GLOBAL-CAN-55x55",
  "GLOBAL-CAN-40x98",
  "GLOBAL-CAN-ROL-SC-4x6",
  "GLOBAL-CAN-ROL-SC-6x6",
  "GLOBAL-CAN-ROL-SC-6x8",
  "GLOBAL-CAN-ROL-SC-A5",
  "GLOBAL-CAN-ROL-SC-10x10",
  "GLOBAL-CAN-ROL-SC-10x12",
  "GLOBAL-CAN-ROL-SC-10x20",
  "GLOBAL-CAN-ROL-SC-11x14",
  "GLOBAL-CAN-ROL-SC-12x12",
  "GLOBAL-CAN-ROL-SC-12x16",
  "GLOBAL-CAN-ROL-SC-12x18",
  "GLOBAL-CAN-ROL-SC-8x10",
  "GLOBAL-CAN-ROL-SC-8x12",
  "GLOBAL-CAN-ROL-SC-8x16",
  "GLOBAL-CAN-ROL-SC-8x8",
  "GLOBAL-CAN-ROL-SC-A3",
  "GLOBAL-CAN-ROL-SC-A4",
  "GLOBAL-CAN-ROL-SC-12x24",
  "GLOBAL-CAN-ROL-SC-16x16",
  "GLOBAL-CAN-ROL-SC-16x20",
  "GLOBAL-CAN-ROL-SC-A2",
  "GLOBAL-CAN-ROL-SC-16x24",
  "GLOBAL-CAN-ROL-SC-18x24",
  "GLOBAL-CAN-ROL-SC-20x20",
  "GLOBAL-CAN-ROL-SC-16x32",
  "GLOBAL-CAN-ROL-SC-20x24",
  "GLOBAL-CAN-ROL-SC-20x28",
  "GLOBAL-CAN-ROL-SC-20x30",
  "GLOBAL-CAN-ROL-SC-21x27",
  "GLOBAL-CAN-ROL-SC-24x24",
  "GLOBAL-CAN-ROL-SC-20x40",
  "GLOBAL-CAN-ROL-SC-24x32",
  "GLOBAL-CAN-ROL-SC-24x36",
  "GLOBAL-CAN-ROL-SC-28x28",
  "GLOBAL-CAN-ROL-SC-A1",
  "GLOBAL-CAN-ROL-SC-27x37",
  "GLOBAL-CAN-ROL-SC-28x36",
  "GLOBAL-CAN-ROL-SC-30x30",
  "GLOBAL-CAN-ROL-SC-32x32",
  "GLOBAL-CAN-ROL-SC-24x47",
  "GLOBAL-CAN-ROL-SC-28x40",
  "GLOBAL-CAN-ROL-SC-30x40",
  "GLOBAL-CAN-ROL-SC-32x40",
  "GLOBAL-CAN-ROL-SC-36x36",
  "GLOBAL-CAN-ROL-SC-32x44",
  "GLOBAL-CAN-ROL-SC-16x36",
  "GLOBAL-CAN-ROL-SC-24x34",
  "GLOBAL-CAN-ROL-SC-28x55",
  "GLOBAL-CAN-ROL-SC-32x48",
  "GLOBAL-CAN-ROL-SC-A0",
  "GLOBAL-CAN-ROL-SC-24x30",
  "GLOBAL-CAN-ROL-SC-40x40",
  "GLOBAL-CAN-ROL-SC-28x63",
  "GLOBAL-CAN-ROL-SC-30x60",
  "GLOBAL-CAN-ROL-SC-35x51",
  "GLOBAL-CAN-ROL-SC-36x48",
  "GLOBAL-CAN-ROL-SC-31x63",
  "GLOBAL-CAN-ROL-SC-35x53",
  "GLOBAL-CAN-ROL-SC-40x48",
  "GLOBAL-CAN-ROL-SC-40x50",
  "GLOBAL-CAN-ROL-SC-40x55",
  "GLOBAL-CAN-ROL-SC-40x60",
  "GLOBAL-CAN-ROL-SC-24x48",
  "GLOBAL-CAN-ROL-SC-34x44",
  "GLOBAL-CAN-ROL-SC-39x79",
  "GLOBAL-CAN-ROL-SC-41x83",
  "GLOBAL-CAN-ROL-SC-43x87",
  "GLOBAL-CAN-ROL-SC-44x64",
  "GLOBAL-CAN-ROL-SC-45x60",
  "GLOBAL-CAN-ROL-SC-48x66",
  "GLOBAL-CAN-ROL-SC-54x74",
  "GLOBAL-CAN-ROL-SC-58x79",
  "GLOBAL-FRA-CAN-4x6",
  "GLOBAL-FRA-CAN-10x10",
  "GLOBAL-FRA-CAN-6x6",
  "GLOBAL-FRA-CAN-6x8",
  "GLOBAL-FRA-CAN-A5",
  "GLOBAL-FRA-CAN-8x10",
  "GLOBAL-FRA-CAN-8x8",
  "GLOBAL-FRA-CAN-10x12",
  "GLOBAL-FRA-CAN-8x12",
  "GLOBAL-FRA-CAN-A4",
  "GLOBAL-FRA-CAN-11x14",
  "GLOBAL-FRA-CAN-12x12",
  "GLOBAL-FRA-CAN-8x16",
  "GLOBAL-FRA-CAN-12x16",
  "GLOBAL-FRA-CAN-10x20",
  "GLOBAL-FRA-CAN-12x18",
  "GLOBAL-FRA-CAN-16x16",
  "GLOBAL-FRA-CAN-16x20",
  "GLOBAL-FRA-CAN-A3",
  "GLOBAL-FRA-CAN-12x24",
  "GLOBAL-FRA-CAN-12x36",
  "GLOBAL-FRA-CAN-16x24",
  "GLOBAL-FRA-CAN-20x20",
  "GLOBAL-FRA-CAN-18x24",
  "GLOBAL-FRA-CAN-20x28",
  "GLOBAL-FRA-CAN-18x18",
  "GLOBAL-FRA-CAN-17x21",
  "GLOBAL-FRA-CAN-A2",
  "GLOBAL-FRA-CAN-20x30",
  "GLOBAL-FRA-CAN-20x24",
  "GLOBAL-FRA-CAN-24x24",
  "GLOBAL-FRA-CAN-20x40",
  "GLOBAL-FRA-CAN-24x30",
  "GLOBAL-FRA-CAN-24x32",
  "GLOBAL-FRA-CAN-16x32",
  "GLOBAL-FRA-CAN-16x36",
  "GLOBAL-FRA-CAN-17X35",
  "GLOBAL-FRA-CAN-24x36",
  "GLOBAL-FRA-CAN-28x28",
  "GLOBAL-FRA-CAN-30x30",
  "GLOBAL-FRA-CAN-20X36",
  "GLOBAL-FRA-CAN-A1",
  "GLOBAL-FRA-CAN-28x36",
  "GLOBAL-FRA-CAN-26x32",
  "GLOBAL-FRA-CAN-22X40",
  "GLOBAL-FRA-CAN-28x40",
  "GLOBAL-FRA-CAN-24x40",
  "GLOBAL-FRA-CAN-32x32",
  "GLOBAL-FRA-CAN-20x60",
  "GLOBAL-FRA-CAN-30x40",
  "GLOBAL-FRA-CAN-32x40",
  "GLOBAL-FRA-CAN-24x47",
  "GLOBAL-FRA-CAN-24x48",
  "GLOBAL-FRA-CAN-A0",
  "GLOBAL-FRA-CAN-36x48",
  "GLOBAL-FRA-CAN-28x43",
  "GLOBAL-FRA-CAN-36x36",
  "GLOBAL-FRA-CAN-36x50",
  "GLOBAL-FRA-CAN-30x60",
  "GLOBAL-FRA-CAN-32x48",
  "GLOBAL-FRA-CAN-40x40",
  "GLOBAL-FRA-CAN-40x48",
  "GLOBAL-FRA-CAN-28x48",
  "GLOBAL-FRA-CAN-30x45",
  "GLOBAL-FRA-CAN-32x44",
  "GLOBAL-FRA-CAN-40x50",
  "GLOBAL-FRA-CAN-28x55",
  "GLOBAL-FRA-CAN-40x55",
  "GLOBAL-FRA-CAN-28x63",
  "GLOBAL-FRA-CAN-35x51",
  "GLOBAL-FRA-CAN-35x53",
  "GLOBAL-FRA-CAN-40x60",
  "GLOBAL-FRA-CAN-31x63",
  "GLOBAL-FRA-CAN-41x51",
  "GLOBAL-FRA-CAN-39x79",
  "GLOBAL-FRA-CAN-41x83",
  "GLOBAL-FRA-CAN-54x68",
  "GLOBAL-FRA-CAN-43x87",
  "GLOBAL-FRA-CAN-40x98",

  "CAN-38MM-SC-72X40",
];
