import { useState } from "react";
import { Link } from "react-router-dom";
import { Stack, Typography } from "@mui/material";
import Modal from "../Modal";
import { colors } from "../../theme";
import { Form } from "../Layout";
import Input from "../Form/Input";
import Button from "../Button";
import { identifyCustomer } from "../../services/API/events";
import { addContact } from "../../services/Klaviyo";
import newsletterImage from "../../assets/images/newsletter.webp";

const NewsletterModal = ({ setModalOpen }: { setModalOpen: (open: boolean) => void }) => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [showEmailInput, setShowEmailInput] = useState(false);
  // const [copied, setCopied] = useState(false);
  const [error, setError] = useState(false);

  const onInputChange = (event: any) => {
    setError(false);
    setEmail(event.target.value);
  };

  const onSubmit = async (event: any) => {
    setLoading(true);
    event.preventDefault();
    try {
      await addContact({ email });
      identifyCustomer({ email });
      setLoading(false);
      setSuccess(true);
    } catch (error) {
      console.log(error);
      setError(true);
      setLoading(false);
    }
  };

  // const onCopy = async () => {
  //   try {
  //     await navigator.clipboard.writeText("GM15");
  //     setCopied(true);
  //     setTimeout(() => setModalOpen(false), 2000);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  return (
    <Modal
      onClose={() => setModalOpen(false)}
      image={newsletterImage}
      imageOnClick={() => setShowEmailInput(true)}
      showImageOnMobile
      size="medium"
    >
      {success ? (
        <Stack gap={3} alignItems="center">
          <Stack gap={1} alignItems="center">
            <Typography variant="h2" fontSize={{ xs: 32, md: 44 }}>
              Sent!
            </Typography>
            <Typography variant="body2" color={colors.grey60} align="center">
              Check your email to discover your mystery discount code
            </Typography>
          </Stack>
          <Link to="/shop" style={{ width: "100%" }}>
            <Button onClick={() => setModalOpen(false)} loading={loading} size="large" fullWidth>
              Shop now
              {/* <Stack direction="row" alignItems="center" justifyContent="center" gap={1}>
              {copied ? (
                <>
                  Copied <Check />
                </>
              ) : (
                <>
                  Copy <ContentCopy fontSize="small" />
                </>
              )}
            </Stack> */}
            </Button>
          </Link>
        </Stack>
      ) : (
        <Stack gap={2} alignItems="center">
          <Stack gap={0.5} alignItems="center">
            <Typography align="center" fontSize={14} color={colors.grey60} textTransform="uppercase">
              {showEmailInput ? "Enter email to recieve" : "You've got a"}
            </Typography>
            <Typography variant="h2" align="center" textTransform="uppercase">
              Mystery Discount
            </Typography>
          </Stack>

          {showEmailInput ? (
            <Form>
              <Input name="email" id="email" onChange={onInputChange} placeholder="Email address" autoFocus />
              <Button onClick={onSubmit} loading={loading} size="large" disabled={!email} fullWidth>
                Send
              </Button>

              {error && (
                <Typography color="error" margin="4px 0" fontSize={14} align="center">
                  Cannot subsribe email, please try again
                </Typography>
              )}
            </Form>
          ) : (
            <>
              <Button onClick={() => setShowEmailInput(true)} size="large" fullWidth>
                Claim
              </Button>
              <Typography
                fontSize={12}
                color={colors.grey60}
                align="center"
                onClick={() => setModalOpen(false)}
                style={{ cursor: "pointer" }}
              >
                I'm not interested
              </Typography>
            </>
          )}
        </Stack>
      )}
    </Modal>
  );
};

export default NewsletterModal;
