import { Dispatch, SetStateAction } from "react";
import { Header, Text } from "../../components/Text";
import TextLink from "../../components/TextLink";
import { Tab } from ".";

type Props = {
  setSelectedTab: Dispatch<SetStateAction<Tab>>;
};

const ArtistTerms = ({ setSelectedTab }: Props) => {
  return (
    <>
      <Text size={18}>
        goodmoodprints.com (“our site”) is a website where artists can upload their designs, to then be sold by us to buyers on
        our site. This page (together with the documents expressly referred to in it) tells you information about us and the legal
        terms and conditions (Terms) on which you license to us your design, and we then sell products using your designs to
        buyers on our site. The products bearing your designs are sold by us, GoodMood Prints Ltd, to consumers using our site. We
        sell the products as GoodMood on our own behalf, and not as your agent. The contract for sale of the products bearing your
        design is between us and the buyer. We are GoodMood Prints Ltd, a limited company registered in England and Wales under
        company number 14180872, whose registered address is Avins Farm, College Road, Ardingly, West Sussex, RH17 6SH.
      </Text>
      <Text size={18}>
        These Terms will apply to any contract between us for the licensing by you to us of your designs (Contract). Please read
        these Terms carefully and make sure that you understand them, before registering on our site. Please note that by
        registering on our site, you agree to be bound by these Terms and the other documents expressly referred to in them.
      </Text>
      <Text size={18}>Please be aware that clause 11 puts limits on our liability to you.</Text>
      <Text size={18}>
        Please check the box marked "I agree to the terms and conditions" when submitting your registration if you accept these
        Terms. If you refuse to accept these Terms, you will not be able to register on our site. You should print a copy of these
        Terms or save them to your computer for future reference. Please note that these Terms, and any Contract between us, are
        only in the English language.
      </Text>
      <Header type="h2" margin="16px 0 0">
        1. INFORMATION ABOUT US
      </Header>
      <Text size={18}>
        1.1 We operate our site. We are GoodMood Prints Ltd, a limited company registered in England and Wales under company
        number 14180872 and with our registered office and main trading address at Avins Farm, College Road, Ardingly, West
        Sussex, RH17 6SH.
      </Text>
      <Text size={18}>1.2 To contact us, please see our homepage where you will find our contact details.</Text>
      <Header type="h2" margin="16px 0 0">
        2. INTERPRETATION
      </Header>
      <Text size={18}>2.1 Definitions. In these Terms, the following definitions apply:</Text>
      <Text size={18}>Brand Elements: any brand, image, trade mark, or graphic work which you include in a Design.</Text>
      <Text size={18}>
        Business Day: a day (other than a Saturday, Sunday or public holiday) when banks in London are open for business.
      </Text>
      <Text size={18}>Buyer: a consumer making a purchase of products from our site.</Text>
      <Text size={18}>Buyer Contract: the contract between us and a Buyer for supply of products under the Buyer Terms.</Text>
      <Text size={18}>
        Buyer Terms: the terms applicable to sales of products by us to buyers, as set out{" "}
        <TextLink onClick={() => setSelectedTab(2)}>here</TextLink>.
      </Text>
      <Text size={18}>Collection: calendar month log period featuring specific Products.</Text>
      <Text size={18}>
        Products: the prints and framed prints on which the Design has been, or will be, reproduced and that you wish for us to
        promote for sale on our site.
      </Text>
      <Text size={18}>Commencement Date: has the meaning set out in clause 3.3.</Text>
      <Text size={18}>
        Contract: the contract between you and us for the provision of the Platform and the licensing to us by you of your Design
        in accordance with these Terms.
      </Text>
      <Text size={18}>
        Artist Fee: the amount payable to you by us in relation to each sale of a Product by us, having the meaning set out in
        clause 8.
      </Text>
      <Text size={18}>
        Design: the drawing, painting, design, logo, artwork, photograph, illustration or other image, including any Brand
        Elements, which you upload via our site and licence to us for reproduction on the Products.
      </Text>
      <Text size={18}>
        Intellectual Property Rights: all patents, rights to inventions, utility models, copyright and related rights, trade
        marks, service marks, trade, business and domain names, rights in trade dress or get-up, rights in goodwill or to sue for
        passing off, unfair competition rights, rights in designs, rights in computer software, database right, topography rights,
        moral rights, rights in confidential information (including know-how and trade secrets) and any other intellectual
        property rights, in each case whether registered or unregistered and including all applications for and renewals or
        extensions of such rights, and all similar or equivalent rights or forms of protection in any part of the world.
      </Text>
      <Text size={18}>
        Purchase Price: the purchase price for the Products stated on our site, including VAT/GST (if applicable).
      </Text>
      <Text size={18}>Registration: your registration to use the Platform as set out in your Registration Form.</Text>
      <Text size={18}>
        Registration Form: the online form available on our site, which you complete and submit to us when you register to use the
        Platform.
      </Text>
      <Text size={18}>Terms: these terms and conditions as amended from time to time in accordance with clause 9.</Text>
      <Text size={18}>
        Platform: The creation and management tools available on our website www.goodmoodprints.com which allows Artists to upload
        their Design to our website for production on our Products for sale by GoodMood.
      </Text>
      <Text size={18}>
        Work: the drawing, design, logo, artwork, photograph, illustration or other image owned by you, which you upload via our
        site and licence to us for reproduction on the Products.
      </Text>
      <Text size={18}>2.2 Construction. In these Terms, the following rules apply:</Text>
      <Text size={18}>
        2.2.1 a person includes a natural person, corporate or unincorporated body (whether or not having separate legal
        personality);
      </Text>
      <Text size={18}>2.2.2 references to the Platform includes the content of the site (Content).</Text>
      <Text size={18}>2.2.3 a reference to a party includes its personal representatives, successors or permitted assigns;</Text>
      <Text size={18}>
        2.2.4 a reference to a statute or statutory provision is a reference to such statute or statutory provision as amended or
        re-enacted. A reference to a statute or statutory provision includes any subordinate legislation made under that statute
        or statutory provision, as amended or re-enacted;
      </Text>
      <Text size={18}>
        2.2.5 any phrase introduced by the terms including, include, in particular or any similar expression, shall be construed
        as illustrative and shall not limit the sense of the words preceding those terms; and
      </Text>
      <Text size={18}>2.2.6 a reference to writing or written includes email.</Text>
      <Header type="h2" margin="16px 0 0">
        3. BASIS OF CONTRACT
      </Header>
      <Text size={18}>
        3.1 The Registration Form constitutes your offer to subscribe to the Platform in accordance with these Terms.
      </Text>
      <Text size={18}>3.2 By registering on our site, you confirm that:</Text>
      <Text size={18}>
        3.2.1 you are legally capable of entering into binding contracts and, if you are entering into this Contract on behalf of
        a business entity of any kind, that you are duly authorised by the legal entity on whose behalf you are registering to
        bind it to this Contract; and
      </Text>
      <Text size={18}>3.2.2 if you are an individual, you are at least 18 years old;</Text>
      <Text size={18}>
        3.2.2 you agree to be bound by our website <TextLink onClick={() => setSelectedTab(3)}>Terms of Use</TextLink>,{" "}
        <TextLink onClick={() => setSelectedTab(4)}>Acceptable Use Policy</TextLink> and{" "}
        <TextLink openInNewTab to="/privacy">
          Privacy Policy
        </TextLink>
        , all of which apply to your use of our site.
      </Text>
      <Text size={18}>
        3.3 Your registration for the Platform shall be deemed accepted, at the point at which you have registered an account, for
        which you must have checked the box to agree to our terms and conditions, as outlined in the Contract.
      </Text>
      <Text size={18}>
        3.4 The Contract constitutes the entire agreement between you and us, and you acknowledge that you have not relied on any
        statement, promise or representation (whether made or given by us or on our behalf) which is not set out in the Contract.
      </Text>
      <Text size={18}>
        3.5 Any descriptive matter or advertising issued by us or displayed on our site is for the sole purpose of giving an
        approximate idea of the Platform. They shall not form part of the Contract or have any contractual force.
      </Text>
      <Text size={18}>
        3.6 These Terms apply to the Contract to the exclusion of any other terms that you may seek to impose or incorporate, or
        which are implied by trade, custom, practice or course of dealing.
      </Text>
      <Header type="h2" margin="16px 0 0">
        4. YOUR OBLIGATIONS
      </Header>
      <Text size={18}>4.1 You acknowledge and agree that:</Text>
      <Text size={18}>
        4.1.1 we have sole and complete discretion as to the look, feel and content of our site, and of any offers for Products
        posted on our site, as well as in respect of the inclusion, omission, positioning, location and all other aspects of
        presentation of the offers and the Products they relate to, and that we have the right in our sole discretion to remove
        any offer or Product or any information about you from our site, at any time;
      </Text>
      <Text size={18}>
        4.1.2 we do not offer you exclusivity of presence on our site, and that third parties may offer for sale via our site
        Products that are the same as, are similar to, or compete with, the Products;
      </Text>
      <Text size={18}>
        4.1.3 we do not warrant or guarantee that any offers posted on our site in relation to the Products will be error-free,
        nor that the posting of an offer on our site will result in the sale of any Products to our customers, or will result in
        any Artist Fee becoming payable to you.
      </Text>
      <Text size={18}>4.2 You warrant that:</Text>
      <Text size={18}>
        4.2.1 you are the sole legal and beneficial owner of, and own all the rights and interests in, the Intellectual Property
        Rights in the Design and Brand Elements;
      </Text>
      <Text size={18}>
        4.2.2 the Design and each Brand Element is your original work and have not been copied wholly or substantially from any
        other source without a licence permitting their reproduction on Products to be sold by you;
      </Text>
      <Text size={18}>
        4.2.3 our provision of the Platform in respect of the Design and our sale of any Products bearing the Design will not
        infringe the Intellectual Property Rights of any third party; and
      </Text>
      <Text size={18}>
        4.2.4 where sales of the Products are purported to support any third party good cause (whether or not a registered
        charity) (Good Cause), you will pay the stated proceeds to such Good Cause immediately on receipt of the Artist Fee, and
        you will only describe the Good Cause as a charity if it qualifies as such under the Charities Act 2011.
      </Text>
      <Text size={18}>
        4.3 You agree to provide true, accurate, current and complete information about yourself and / or your business as
        prompted by the Platform registration process (Registration Details). You further agree that, in providing such
        Registration Details, you will not knowingly omit or misrepresent any material facts or information, and that you will
        promptly enter corrected or updated Registration Details via the Platform, or otherwise advise us promptly in writing of
        any such changes or updates. You also agree to update your Registration Details (including, but not limited to, your
        current email address) as soon as they change.
      </Text>
      <Text size={18}>4.4 You will:</Text>
      <Text size={18}>
        4.4.1 co-operate with us in all matters relating to the Platform, and to the promotion and offering for sale by us of the
        Products on our site;
      </Text>
      <Text size={18}>
        4.4.2 provide us with such information and materials as we may reasonably require in order to post and offer for sale the
        Products on our site and to supply the Platform to you, and ensure that such information is complete and accurate in all
        material respects; and
      </Text>
      <Text size={18}>
        4.4.3 permit and cooperate with all activities undertaken by us (or on our behalf) to promote, sell or market the
        Products, whether directly via our site or through print publications or other media, whether or not owned or operated by
        us.
      </Text>
      <Text size={18}>
        4.5 When you register to use the Platform, you will need to enter a unique email address and password to create and access
        your account. You agree that you will not allow another person to use your User ID to access or use the Platform under any
        circumstances. You agree that you are solely and entirely responsible for your User ID and for any charges, damages,
        liabilities or losses incurred or suffered as a result of your failure to keep it secure. We are not liable for any
        charges, damages, liabilities or losses caused by or related to the theft of your User ID, your disclosure of your User
        ID, or you allowing another person to access and use the Platform using your User ID.
      </Text>
      <Text size={18}>
        4.6 You are solely and entirely responsible for any and all use of your account. You agree to immediately notify us of any
        unauthorised use of your account or any other breach of security known to you.
      </Text>
      <Text size={18}>
        4.7 You acknowledge that the complete privacy of your data and messages transmitted over the internet while using the
        Platform cannot be guaranteed.
      </Text>
      <Text size={18}>4.8 You shall indemnify us against any liability incurred by us in respect of:</Text>
      <Text size={18}>4.8.1 any breach by you of any of the provisions of this clause 4; and</Text>
      <Text size={18}>
        4.8.2 any liabilities, costs (including legal costs), claims, demands, fines, damages, losses (whether direct, indirect or
        consequential) and expenses arising out of or in connection with that liability or failure, or paid or agreed to be paid
        by us in relation to the defence or settlement thereof, except to the extent the liability or failure arises as a result
        of our own action or omission.
      </Text>
      <Header type="h2" margin="16px 0 0">
        5. YOUR LICENCE TO US
      </Header>
      <Text size={18}>
        5.1 You hereby grant to us a non-exclusive licence on a worldwide basis to do the following, subject to, and in accordance
        with, the terms of this agreement:
      </Text>
      <Text size={18}>5.1.1 reproduce the Design on the Products for sale by us (as principal);</Text>
      <Text size={18}>5.1.2 sell the Products to Buyers via our site; and</Text>
      <Text size={18}>
        5.1.3 reproduce the Design and / or any Product in any advertising or promotional material relating to the Product,
        including (but not limited to) our site.
      </Text>
      <Header type="h2" margin="16px 0 0">
        6. HOW THE SERVICE WORKS
      </Header>
      <Text size={18}>
        6.1 Once we have accepted your Registration as described in clause 3.3 above, you will be able to upload the Design to our
        site. In order to do this, the Design must:
      </Text>
      <Text size={18}>
        6.1.1 comply in all respects with the standards set out in our{" "}
        <TextLink onClick={() => setSelectedTab(4)}>Acceptable Use Policy</TextLink>;
      </Text>
      <Text size={18}>6.1.2 comply in all respects with the terms of this agreement; and</Text>
      <Text size={18}>
        6.1.3 be in either PNG or JPG format, and in each case adheres to the following dimensions: For selling up to A1: 9933px
        by 7016px, up to A2: 7016px by 4961px, at A3: 4961px by 3508px. For selling up to 70cmx70cm (28x28 inches): 8268px by
        8268px, up to 50cmx50cm (20x20 inches): 5906px by 5906px, at 30cmx30cm (12x12 inches): 3543px by 3543px
      </Text>
      <Text size={18}>
        6.2 Once you have successfully uploaded the Design to our site in compliance with clause 6.1 above, and we have received
        all of the relevant information regarding the offer and the Products it relates to from you, we may offer the Products for
        sale by us to Buyers. Any Buyers who wish to do so will be able to purchase the Products from our site by placing an order
        through our site, and our payment processors will take payment of the Purchase Price for the Products stated to Buyers on
        our site, including VAT/GST (where VAT/GST is applicable) (the Purchase Price).
      </Text>
      <Text size={18}>
        6.3 We will pay you an Artist Fee in relation to all Products we sell to Buyers, in accordance with clause 8 below.
      </Text>
      <Text size={18}>
        6.4 We will have absolute discretion over whether we promote the Products, and where and if they live on our website, and
        the targeting and composition of any promotion. For example, such promotions may comprise a depiction of the Products,
        your name, the Design, any Brand Elements and/or any title you give the Design, and you authorise us to include those in
        any promotion.
      </Text>
      <Text size={18}>
        6.8 In the rare occurrence that there is a failure of a delivery of a Product to a Buyer, and the Product is returned to
        us, you authorise us to destroy it.
      </Text>
      <Header type="h2" margin="16px 0 0">
        7. SUPPLY OF THE SERVICE
      </Header>
      <Text size={18}>
        7.1 Although we aim to offer you the best service possible, we make no promise that the Platform will meet your
        requirements, and we make no warranties or representations that any Products offered for sale on our site will be
        purchased by any of the third parties who use our site.
      </Text>
      <Text size={18}>
        7.2 We cannot guarantee that the Platform will be fault free. If a fault occurs in the Platform you should report it to us
        immediately by contacting us at <TextLink href="mailto:hello@goodmoodprints.io">hello@goodmoodprints.io</TextLink> and we
        will do our best to correct the fault as soon as we reasonably can.
      </Text>
      <Text size={18}>
        7.3 Your access to the Platform may occasionally be restricted to allow for repairs, maintenance or the introduction of
        new facilities or services. We will attempt to restore the service as soon as we reasonably can.
      </Text>
      <Text size={18}>
        7.4 We reserve the right at any time to make any changes to the Platform which are necessary to comply with any applicable
        laws or regulations, or which do not materially affect the nature or quality of the Platform.
      </Text>
      <Text size={18}>
        7.5 The Service does not include the provision of computer or other necessary equipment to access the Platform. To use the
        Platform you will require internet connectivity and appropriate telecommunication links. We will not be liable for any
        telephone or other costs that you may incur.
      </Text>
      <Text size={18}>7.6 Registration for the Platform is conducted in the English language only.</Text>
      <Text size={18}>
        7.7 We may share information about your Registration and Products with third parties, including as set out in our{" "}
        <TextLink openInNewTab to="/privacy">
          Privacy Policy
        </TextLink>
        .
      </Text>
      <Header type="h2" margin="16px 0 0">
        8. ARTIST FEE AND PAYMENT
      </Header>
      <Text size={18}>
        8.1 Subject to any amendment brought into effect in accordance with clause 9, the Artist Fee payable to you in relation to
        each sale of a Product via our site shall be as outlined in part of the sell journey on the Platform and described in this
        clause 8.
      </Text>
      <Text size={18}>
        8.2 Artist Fee will be calculated from the Purchase Price we have received on your behalf from Buyers. The Artist Fee is
        30% (or 20% if not exclusive to GoodMood) of the Purchase Price for the print only. If a discount is used for the
        purchase, the Artist Fee will be 30% (or 20% if not exclusive to GoodMood) of the discounted print price (the discounted
        print price is equal to the Purchase Price for the print only minus the discount amount applied to that Purchase Price for
        print only).
      </Text>
      <Text size={18}>
        8.3 You acknowledge that you will not receive any Artist Fee in respect of orders for Products cancelled by Buyers under
        applicable consumer laws, including the 14-day right of cancellation under the UK's Consumer Contracts Regulations 2013
        and in respect of any defective Products returned by Buyers.
      </Text>
      <Text size={18}>
        8.4 We will make a single payment to you in arrears for your Artist Fee, within 14 (fourteen) days of the end of each
        calendar month.
      </Text>
      <Text size={18}>
        8.5 You must ensure that at all times your correct PayPal details are notified to us, and you must inform us in writing of
        any changes immediately. You shall reimburse any charges or administrative costs we incur as a consequence of any error in
        the PayPal details you provide to us, or your failure to notify us of any changes. We will not be able to arrange payment
        until you have provided your PayPal address.
      </Text>
      <Text size={18}>
        8.6 In the event of any dispute as to the amount of Artist Fee payable by us to you, the same shall be referred to our
        external accountants for settlement and their decision, except in the case of manifest error, shall be final and binding
        on both parties.
      </Text>
      <Text size={18}>
        8.7 If you are registered for VAT/GST, you must accurately specify your VAT/GST number in your account details section of
        your profile. If you become VAT/GST registered, you must notify us of this, together with your VAT/GST number, by email to{" "}
        <TextLink href="mailto:hello@goodmoodprints.io">hello@goodmoodprints.io</TextLink>. If you are registered for VAT/GST,
        VAT/GST will be added to the Artist Fee where payable.
      </Text>
      <Text size={18}>
        8.8 If you are registered for VAT/GST, and we have obtained and validated your VAT/GST registration number, the following
        self-billing terms will apply:
      </Text>
      <Text size={18}>
        8.8.1 We (GoodMood Prints Ltd) agree to do the following: (i) issue self-billed invoices for all supplies and services
        supplied to us by you until the end of the Contract term; (ii) complete self-billed invoices showing your name, address
        and VAT/GST registration number, together with all details which constitute a full VAT/GST invoice; (iii) make a new
        self-billing agreement in the event that your VAT/GST registration number changes.
      </Text>
      <Text size={18}>
        8.8.2 You agree to do the following: (i) to accept invoices raised by us on your behalf until the end of the Contract
        term; (ii) not to raise sales invoices for the Artist Fee covered by the Contract; (iii) notify us immediately if you: (a)
        change your VAT/GST Number; (b) cease to be VAT/GST registered; or (c) sell your business as a whole or in part.
      </Text>
      <Text size={18}>
        8.9 In the event you are found to have breached this Contract and we terminate your account, you will not be paid any due
        Artist Fees.
      </Text>
      <Text size={18}>
        8.10 Any previous sales for Products bearing your designs that were made in conditions where you are found to have
        breached this Contract, all Artist Fees paid as a result of sales during this period must be returned to GoodMood Prints
        Ltd.
      </Text>
      <Header type="h2" margin="16px 0 0">
        9. OUR RIGHT TO VARY THESE TERMS
      </Header>
      <Text size={18}>
        9.1 We may make changes to these Terms immediately if we are subject to a legal or regulatory obligation which obliges us
        to change them in a manner which does not permit us to give advance notice, or to address an unforeseen and imminent
        danger related to defending the Platform or our site from fraud, malware, spam, data breaches or technical security risks.
      </Text>
      <Text size={18}>
        9.2 We will provide at least 15 days' advance notice to you by email for any other changes to these Terms. You may
        terminate your use of the Platform and the Contract at any time prior to or following such changes coming into effect.
      </Text>
      <Text size={18}>
        9.3 Any new listed Products after the effective date of any change to these Terms will be subject to that change. If you
        do not accept any change, you must not use the Platform after the effective date of the change.
      </Text>
      <Header type="h2" margin="16px 0 0">
        10. INTELLECTUAL PROPERTY RIGHTS
      </Header>
      <Text size={18}>
        10.1 We own all Intellectual Property Rights in or arising out of or in connection with the Platform, the Content and our
        site.
      </Text>
      <Text size={18}>
        10.2 You hereby grant to us a non-exclusive, perpetual and irrevocable worldwide licence to include the information you
        provide to us (the Information) on our site, and to use such information in our own editorial content or promotional
        materials in any medium, including on our site. The Information includes the Design, any title of your Products, your
        name, brand, likeness, promotional material and any Brand Elements.
      </Text>
      <Text size={18}>
        10.3 You acknowledge that, in respect of any third party Intellectual Property Rights in any Information provided for use
        by us on our site or otherwise in connection with the Platform (including text, data, photographs and other materials),
        our use of any such Intellectual Property Rights requires you to have first obtained a written licence from the relevant
        licensor on such terms as will either entitle you to license such rights to us, or grant such rights direct to us. You
        warrant that you have obtained any and all such licences and shall provide copies of them to us on demand.
      </Text>
      <Text size={18}>
        10.4 You shall indemnify us against all costs, claims, damages, losses and expenses arising as a result of any claim or
        action that the Design(s) and/or the Information infringes Intellectual Property Rights belonging to a third party (IP
        Claim).
      </Text>
      <Header type="h2" margin="16px 0 0">
        11. LIMITATION OF LIABILITY: YOUR ATTENTION IS DRAWN TO THIS CLAUSE
      </Header>
      <Text size={18}>
        11.1 We do not warrant that our site, the Platform or the Content is virus free. You must take your own precautions in
        this respect as we accept no responsibility for any infection by virus or other contamination or by anything that has
        destructive properties.
      </Text>
      <Text size={18}>11.2 In particular, we disclaim all liabilities in connection with the following:</Text>
      <Text size={18}>
        11.2.1 incompatibility of the Content with any of your equipment, software or telecommunications links;
      </Text>
      <Text size={18}>11.2.2 technical problems including errors in or interruptions to the Platform; and</Text>
      <Text size={18}>11.2.3 unreliability or inaccuracy of the Content.</Text>
      <Text size={18}>11.3 Except as expressly and specifically provided in these Terms:</Text>
      <Text size={18}>
        11.3.1 we shall have no liability for any damage caused by errors or omissions in any information or instructions provided
        by you in connection with the Platform, or any actions taken by us at your direction; and
      </Text>
      <Text size={18}>
        11.3.2 all warranties, conditions and other terms implied by statute or common law are, to the fullest extent permitted by
        law, excluded from these Terms.
      </Text>
      <Text size={18}>11.4 Subject to clause 11.6, we are not responsible for any of the following:</Text>
      <Text size={18}>11.4.1 loss of income or revenue;</Text>
      <Text size={18}>11.4.2 loss of business;</Text>
      <Text size={18}>11.4.3 loss of profits or contracts</Text>
      <Text size={18}>11.4.4 loss of anticipated savings;</Text>
      <Text size={18}>11.4.5 loss of data, or</Text>
      <Text size={18}>
        11.4.6 waste of management or office time however arising and whether caused by tort (including negligence), breach of
        contract or otherwise;
      </Text>
      <Text size={18}>
        provided that this clause 11.4 shall not prevent claims for any other claims for direct financial loss that are not
        excluded by any of categories 11.4.1 to 11.4.6.
      </Text>
      <Text size={18}>
        11.5 Subject to paragraph 11.6, our liability for losses you suffer as a result of us breaking these Terms is strictly
        limited to the total amount of any Artist Fee paid by us to you over the preceding 12 months, or if none, to a total
        amount of £100.
      </Text>
      <Text size={18}>
        11.6 The exclusions and limitations set out in paragraphs 11.4 and 11.5 do not exclude or limit in any way our liability
        for
      </Text>
      <Text size={18}>11.6.1 death or personal injury caused by our negligence;</Text>
      <Text size={18}>11.6.2 fraud or fraudulent misrepresentation; or</Text>
      <Text size={18}>
        11.6.3 any matter for which it would be illegal for us to exclude, or attempt to exclude, our liability.
      </Text>
      <Text size={18}>11.7 This clause 11 will survive termination of the Contract for any reason.</Text>
      <Header type="h2" margin="16px 0 0">
        12. SUSPENSION AND CANCELLATION OF YOUR REGISTRATION
      </Header>
      <Text size={18}>
        12.1 You can cancel your Registration at any time by informing us in writing. If you do so, you must stop using the
        Platform immediately.
      </Text>
      <Text size={18}>
        12.2 These Terms for Artists set out a number of different grounds for us suspending, restricting or terminating the
        Contract or your Registration. We may also terminate your Registration or the Contract if we need to for commercial,
        reputational, technical or operational reasons connected with our business.
      </Text>
      <Text size={18}>
        12.3 We may terminate the Contract (or suspend or terminate your Registration) with any or no notice in the following
        circumstances. First, if you have breached these Terms for Artists. Second, if it appears to us that your Registration has
        been or may be used for dishonest, deceptive or unlawful activity. Third, your use of the Platform has damaged (or we
        determine in good faith that it may damage) legitimate interests of Buyers, other designers submitting designs on our
        site, or us. Fourth, if we consider that we are under a legal or regulatory obligation to terminate your access to the
        Platform.
      </Text>
      <Text size={18}>
        12.4 You may terminate your Registration at any time by ceasing all use of the Platform and contacting us by email at{" "}
        <TextLink href="mailto:hello@goodmoodprints.io">hello@goodmoodprints.io</TextLink> requesting us to deactivate your
        account. You acknowledge and agree that we may continue to fulfil sales of Products already concluded by us with Buyers
        prior to termination.
      </Text>
      <Text size={18}>
        12.5 We will promptly notify you of any termination or suspension of the Contract or your Registration. If we suspend your
        Registration due to you not having complied with your obligations under the Contract, this suspension will continue until
        we are reasonably satisfied that you have fixed any non-compliances that have breached this Contract.
      </Text>
      <Text size={18}>
        12.6 Once your Registration is terminated, you shall have no access to any data on your account, including any data
        regarding Products.
      </Text>
      <Text size={18}>
        12.7 Unless we specifically agree in writing otherwise, if we terminate your Registration, you are prohibited from using
        our Platform again by re-registering with a different User ID.
      </Text>
      <Text size={18}>
        12.8 In the event that either you or we cancel your Registration and/or the Contract for any reason, part way through a
        Collection, we will terminate your Product listings and remove it from our site.
      </Text>
      <Text size={18}>
        12.9 The suspension or cancellation of your Registration and/or the Contract and/or your right to use the Platform shall
        not affect any rights or remedies we may have.
      </Text>
      <Header type="h2" margin="16px 0 0">
        13. DISCONTINUATION OF PLATFORM
      </Header>
      <Text size={18}>
        We reserve the right to discontinue the Platform (or any part of it) without notice to you and shall not be liable to you
        if we exercise these rights. Where reasonably practicable, we will try to give you reasonable notice of any
        discontinuation of the Platform.
      </Text>
      <Header type="h2" margin="16px 0 0">
        14. HOW WE MAY USE YOUR PERSONAL INFORMATION
      </Header>
      <Text size={18}>
        We will only use your personal information as set out in our{" "}
        <TextLink openInNewTab to="/privacy">
          Privacy Policy
        </TextLink>
        .
      </Text>
      <Header type="h2" margin="16px 0 0">
        15. GENERAL
      </Header>
      <Text size={18}>15.1 Event Outside Our Control:</Text>
      <Text size={18}>
        15.1.1 For the purposes of this Contract, Event Outside Our Control means an event beyond our reasonable control including
        but not limited to strikes, lock-outs or other industrial disputes (whether involving our workforce or that of any other
        party), failure of a utility service or transport network, act of God, war, riot, civil commotion, malicious damage,
        compliance with any law or governmental order, rule, regulation or direction, accident, breakdown of plant or machinery,
        fire, flood, storm or default of suppliers or subcontractors.
      </Text>
      <Text size={18}>
        15.1.2 We will not be liable to you as a result of any delay or failure to perform our obligations under the Contract as a
        result of an Event Outside Our Control.
      </Text>
      <Text size={18}>
        15.1.3 If the Event Outside Our Control prevents us from providing all or part of the Platform for more than six weeks, we
        will, without limiting our other rights or remedies, have the right to terminate the Contract immediately by giving
        written notice to you.
      </Text>
      <Text size={18}>15.2 Assignment and subcontracting:</Text>
      <Text size={18}>
        5.2.1 We may at any time assign, transfer, charge, subcontract or deal in any other manner with all or any of our rights
        under the Contract and may subcontract or delegate in any manner any or all of our obligations under the Contract to any
        third party or agent.
      </Text>
      <Text size={18}>
        5.2.2 You shall not, without our prior written consent, assign, transfer, charge, subcontract or deal in any other manner
        with all or any of your rights or obligations under the Contract.
      </Text>
      <Text size={18}>15.3 Notices:</Text>
      <Text size={18}>
        15.3.1 To GoodMood Prints: Notice will be sent to the following address:{" "}
        <TextLink href="mailto:hello@goodmoodprints.io">hello@goodmoodprints.io</TextLink>.
      </Text>
      <Text size={18}>15.3.2 To you: your email address as provided in our account information for you.</Text>
      <Text size={18}>15.4 Waiver:</Text>
      <Text size={18}>
        15.4.1 A waiver of any right under the Contract is only effective if it is in writing and shall not be deemed to be a
        waiver of any subsequent breach or default. No failure or delay by a party in exercising any right or remedy under the
        Contract or by law shall constitute a waiver of that or any other right or remedy, nor preclude or restrict its further
        exercise. No single or partial exercise of such right or remedy shall preclude or restrict the further exercise of that or
        any other right or remedy.
      </Text>
      <Text size={18}>
        15.4.2 Unless specifically provided otherwise, rights arising under the Contract are cumulative and do not exclude rights
        provided by law.
      </Text>
      <Text size={18}>15.5 Severance:</Text>
      <Text size={18}>
        15.5.1 If a court or any other competent authority finds that any provision of the Contract (or part of any provision) is
        invalid, illegal or unenforceable, that provision or part-provision shall, to the extent required, be deemed deleted, and
        the validity and enforceability of the other provisions of the Contract shall not be affected.
      </Text>
      <Text size={18}>
        15.5.2 If any invalid, unenforceable or illegal provision of the Contract would be valid, enforceable and legal if some
        part of it were deleted, the provision shall apply with the minimum modification necessary to make it legal, valid and
        enforceable.
      </Text>
      <Text size={18}>
        15.6 Nothing in the Contract is intended to, or shall be deemed to, constitute a partnership or joint venture of any kind
        between any of the parties, nor constitute any party the agent of another party for any purpose. No party shall have
        authority to act as agent for, or to bind, the other party in any way.
      </Text>
      <Text size={18}>
        15.7 A person who is not a party to the Contract shall not have any rights under or in connection with it.
      </Text>
      <Text size={18}>
        15.8 Except as set out in these Terms, any variation, including the introduction of any additional terms and Terms, to the
        Contract, shall only be binding when agreed in writing and signed by us.
      </Text>
      <Text size={18}>
        15.9 This Contract, and any dispute or claim arising out of or in connection with it or its subject matter or formation
        (including non-contractual disputes or claims), shall be governed by, and construed in accordance with, English law, and
        the parties irrevocably submit to the exclusive jurisdiction of the courts of England and Wales.
      </Text>
    </>
  );
};

export default ArtistTerms;
