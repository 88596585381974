import { gql } from "@apollo/client";
import { AdminProductFragment, ProductForTagsFragment } from "../generated/graphql";
import { adminClient } from "./apolloClients";
import { generateTags } from "../services/API";

export const removeFrameOptionFromProducts = async (products: AdminProductFragment[]) => {
  const promises = products.map(async (product) => {
    const frameOption = product.options.find((option) => option.name === "frame");
    if (!frameOption) return;

    const framedOptionValueIds = frameOption.optionValues
      .filter((option) => option.name !== "unframed")
      .map((option) => option.id);

    const REMOVE_OPTION_VALUES = gql`
      mutation OptionValuesDelete($productId: ID!, $option: OptionUpdateInput!, $optionValuesToDelete: [ID!]) {
        productOptionUpdate(
          productId: $productId
          option: $option
          optionValuesToDelete: $optionValuesToDelete
          variantStrategy: MANAGE
        ) {
          product {
            options {
              values
            }
          }
          userErrors {
            field
            message
          }
        }
      }
    `;

    const updateVariables = {
      productId: product.id,
      option: { id: frameOption.id },
      optionValuesToDelete: framedOptionValueIds,
    };

    try {
      const { data: updateData, errors: updateErrors } = await adminClient.mutate({
        mutation: REMOVE_OPTION_VALUES,
        variables: updateVariables,
      });
      if (updateData.userErrors || updateErrors) console.log("productOptionUpdate Error", updateData.userErrors, updateErrors);

      const DELETE_OPTIONS = gql`
        mutation OptionsDelete($productId: ID!, $options: [ID!]!) {
          productOptionsDelete(productId: $productId, options: $options, strategy: POSITION) {
            deletedOptionsIds
            userErrors {
              field
              message
            }
          }
        }
      `;

      const variables = {
        productId: product.id,
        options: [frameOption.id],
      };

      const { data, errors } = await adminClient.mutate({ mutation: DELETE_OPTIONS, variables });
      if (data.userErrors || errors) console.log("productOptionsDelete Error", data.userErrors, errors);

      return updateData;
    } catch (error) {
      console.log("Error", error);
    }
  });

  console.log("promises", promises.length);

  return await Promise.all(promises);
};

export const addTagsToProducts = async (products: ProductForTagsFragment[]) => {
  const promises = products.map(async (product) => {
    if (product.tags.find((tag) => tag.includes("gmst"))) return;
    if (!product.featuredMedia?.preview?.image) return;
    const tags = await generateTags(product.featuredMedia.preview.image.url);
    console.log("Tags", tags);
    const tagsToAdd = tags.map((tag) => `gmst: ${tag}`);

    const ADD_TAGS = gql`
      mutation ProductTagsAdd($productId: ID!, $tags: [String!]!) {
        tagsAdd(id: $productId, tags: $tags) {
          node {
            id
          }
          userErrors {
            field
            message
          }
        }
      }
    `;

    const variables = {
      productId: product.id,
      tags: tagsToAdd,
    };

    try {
      const { data, errors } = await adminClient.mutate({ mutation: ADD_TAGS, variables });
      if (data.userErrors || errors) console.log("productTagsUpdate Error", data?.userErrors, errors);
      return data;
    } catch (error) {
      console.log("Error", error);
    }
  });

  return await Promise.all(promises);
};
