import { useState } from "react";
import { useDebounce } from "react-use";
import { getUsers, searchArtists } from "../../services/Firebase";
import { User } from "../../types/user";

const useAdmin = () => {
  const [users, setUsers] = useState<User[]>();
  const [loading, setLoading] = useState(false);
  const [searchedUsers, setSearchedUsers] = useState<User[] | null>(null);
  const [artistSearchValue, setArtistSearchValue] = useState("");

  const fetchUsers = async () => {
    setLoading(true);
    const { users } = await getUsers({ filters: { hasProducts: true } });
    setUsers(users);
    setLoading(false);
  };

  const searchUsers = async (searchValue: string) => {
    setLoading(true);
    if (searchValue) {
      const searchedUsers = await searchArtists(searchValue, true);
      setSearchedUsers(searchedUsers);
    } else {
      setSearchedUsers(null);
    }
    setLoading(false);
  };

  useDebounce(() => searchUsers(artistSearchValue), 500, [artistSearchValue]);

  return {
    users,
    searchedUsers,
    fetchUsers,
    loading,
    artistSearchValue,
    setArtistSearchValue,
  };
};

export default useAdmin;
