import { Dispatch, SetStateAction } from "react";
import { Header, Text } from "../../components/Text";
import TextLink from "../../components/TextLink";
import { Tab } from ".";

type Props = {
  setSelectedTab: Dispatch<SetStateAction<Tab>>;
};

const BuyerTerms = ({ setSelectedTab }: Props) => {
  return (
    <>
      <Text size={18}>
        We are GOODMOOD PRINTS LTD, a limited company registered in England and Wales. Our company registration number is 14180872
        and our registered office is Avins Farm, College Road, Ardingly, West Sussex, RH17 6SH.
      </Text>
      <Text size={18}>
        GoodMood is a platform where you can buy products from us, which include designs by independent artists. You can find
        everything you need to know about us and our product on our website www.goodmoodprints.com before you order.
      </Text>
      <Text size={18}>The below terms are applicable to your use of GoodMood and any purchase of product from us.</Text>
      <Text size={18}>In these Terms:</Text>
      <Text size={18}>'We', 'us' or 'our' means GOODMOOD PRINTS LTD, reg. number 14180872;</Text>
      <Text size={18}>
        GoodMood means GOODMOOD PRINTS LTD, reg. number 14180872; 'You' or 'your' means the person using our site to buy products;
      </Text>
      <Text size={18}>
        'Artist' means the independent artist who has provided us with the design reproduced on the product; 'product' means a
        print, framed print or other product sold by us on the GoodMood website;
      </Text>
      <Text size={18}>
        'Privacy Policy' -{" "}
        <TextLink openInNewTab to="/privacy">
          available here
        </TextLink>
      </Text>
      <Text size={18}>
        'Terms for Artists' - <TextLink onClick={() => setSelectedTab(1)}>available here</TextLink>
      </Text>
      <Text size={18}>
        'Delivery & Returns Policy' -{" "}
        <TextLink openInNewTab to="/delivery">
          available here
        </TextLink>
      </Text>
      <Text size={18}>
        'Website' -{" "}
        <TextLink openInNewTab href="https://www.goodmoodprints.com">
          www.goodmoodprints.com
        </TextLink>
      </Text>
      <Text size={18}>
        If you don't understand any of these Terms and want to talk to us about it, please contact us by email at{" "}
        <TextLink href="mailto:hello@goodmoodprints.io">hello@goodmoodprints.io</TextLink>
      </Text>
      <Header type="h2" margin="16px 0 0">
        1. INTRODUCTION
      </Header>
      <Text size={18}>1.1 If you buy products on our site you agree to be legally bound by these Terms.</Text>
      <Text size={18}>1.2 These Terms are only available in English. No other languages will apply to these Terms.</Text>
      <Header type="h2" margin="16px 0 0">
        2. YOUR PRIVACY AND PERSONAL INFORMATION
      </Header>
      <Text size={18}>
        2.1 Our Privacy Policy is available{" "}
        <TextLink openInNewTab to="/privacy">
          here
        </TextLink>
        .
      </Text>
      <Text size={18}>
        2.2 Your privacy and personal information are important to us. Any personal information that you provide to us will be
        dealt with in line with our{" "}
        <TextLink openInNewTab to="/privacy">
          Privacy Policy
        </TextLink>
        , which explains what personal information we collect from you, how and why we collect, store, use and share such
        information, your rights in relation to your personal information and how to contact us and supervisory authorities if you
        have a query or complaint about the use of your personal information.
      </Text>
      <Header type="h2" margin="16px 0 0">
        3. ORDERING PRODUCTS FROM US
      </Header>
      <Text size={18}>3.1 Below, we set out how a legally binding contract between you and us is made.</Text>
      <Text size={18}>
        3.2 Please read and check your order carefully before submitting it. However, if you need to correct any errors you can do
        so before submitting it to us.
      </Text>
      <Text size={18}>
        3.3 When you place your order at the end of the checkout process, we will acknowledge it by email. This acknowledgement
        does not, however, mean that your order has been accepted by us.
      </Text>
      <Text size={18}>
        3.4 We may contact you to say that we do not accept your order. This is typically for the following reasons:
      </Text>
      <Text size={18}>3.4.1 we cannot authorise your payment;</Text>
      <Text size={18}>3.4.2 you have ordered too many products; or</Text>
      <Text size={18}>3.4.3 there has been a mistake on the pricing or description of the products.</Text>
      <Text size={18}>
        3.5 We will only accept your order when we email you to confirm this (Confirmation Email). At this point a legally binding
        contract will be in place between you and us.
      </Text>
      <Header type="h2" margin="16px 0 0">
        4. RIGHT TO RETURN
      </Header>
      <Text size={18}>
        4.1 If you are in the UK or European Union, you have the right to return your order for products within 30 days without
        giving any reason.
      </Text>
      <Text size={18}>
        4.2 The cancellation period will expire after 30 days from the day on which you (or someone indicated by you) acquire
        physical possession of the product.
      </Text>
      <Text size={18}>
        4.3 To exercise the right to return, you must email us at{" "}
        <TextLink href="mailto:hello@goodmoodprints.io">hello@goodmoodprints.io</TextLink>. Please provide your name, home
        address, details of the order and, where available, your phone number and email address.
      </Text>
      <Text size={18}>
        4.4 To meet the return deadline, it is sufficient for you to send your communication concerning your exercise of the right
        to cancel before the cancellation period has expired.
      </Text>
      <Header type="h2" margin="16px 0 0">
        5. EFFECTS OF RETURNING AN ORDER
      </Header>
      <Text size={18}>
        5.1 If you chose to return your products, you must send them to our registered business address, without undue delay and
        in any event not later than 30 days from the day on which you communicate your cancellation to us. The deadline is met if
        you send back the products before the period of 30 days has expired. Please email us at{" "}
        <TextLink href="mailto:hello@goodmoodprints.io">hello@goodmoodprints.io</TextLink> to communicate that you will be
        returning the product.
      </Text>
      <Text size={18}>5.2 you will have to bear the direct cost of returning the products.</Text>
      <Text size={18}>
        5.3 If the product is then received undamaged, in its original condition and with original packaging, we will reimburse
        you the amount you paid for the item, excluding the costs of delivery we charged you, which is non refundable.
      </Text>
      <Text size={18}>5.4 We will make the reimbursement without unreasonable delay, and not later than:</Text>
      <Text size={18}>5.4.1 30 days after the day we received from you any products supplied; or</Text>
      <Text size={18}>
        5.4.2 if there were no products supplied, 30 days after the day on which we are informed about your decision to cancel
        these Terms.
      </Text>
      <Text size={18}>
        5.5 We will make the reimbursement using the same means of payment as you used for the initial transaction, unless you
        have expressly agreed otherwise; in any event, you will not incur any fees as a result of the reimbursement.
      </Text>
      <Header type="h2" margin="16px 0 0">
        6. DELIVERY
      </Header>
      <Text size={18}>6.1 We use third party delivery agents to deliver our products.</Text>
      <Text size={18}>
        6.2 Whilst we may facilitate any import formalities on your behalf, you will remain legally responsible for any import
        VAT/GST or customs duties payable in your country.
      </Text>
      <Text size={18}>6.3 The expected time for delivery, is outlined in our Delivery & Returns Policy</Text>
      <Text size={18}>
        6.4 We will arrange for the delivery of your products to the delivery address that you provided when placing your order.
      </Text>
      <Text size={18}>
        6.5 We deliver to the countries listed on our Delivery & Returns Policy. However please note the following:
      </Text>
      <Text size={18}>
        6.6.1 If you order products from our site for delivery to a destination outside the UK, EU, USA or Australia, your order
        may be subject to import duties and taxes which are applied when the delivery reaches that destination. Please note that
        neither we nor the Artist has any control over these charges, and we cannot predict their amount.
      </Text>
      <Text size={18}>
        6.6.2 You, not us or the Artist, will be responsible for payment of any such import duties and taxes. Please contact your
        local customs office for further information before placing your order.
      </Text>
      <Text size={18}>
        6.6.3 You must comply with all applicable laws and regulations of the country for which the products are destined. We will
        not be liable or responsible if you break any such law.
      </Text>
      <Text size={18}>
        6.7 You are responsible for the products when delivery has taken place. In other words, the risk in the products passes to
        you when you take possession of the products.
      </Text>
      <Header type="h2" margin="16px 0 0">
        7. PAYMENT
      </Header>
      <Text size={18}>
        7.1 We accept Visa, Mastercard, American Express, Discover, Diners Club, Maestro, Shop Pay, Apple Pay, Google Pay and
        Paypal payments. We do not accept cash.{" "}
      </Text>
      <Text size={18}>7.2 Your credit card or debit card will be charged at the time the order is placed. </Text>
      <Text size={18}>7.3 All payments by credit card or debit card need to be authorised by the relevant card issuer. </Text>
      <Text size={18}>
        7.4 The price of the products displayed to you during checkout includes any VAT/GST at the applicable rate.
      </Text>
      <Text size={18}>
        7.5 Depending on your location and the value of the order, your order may be inspected by customs authorities and may be
        subject to import duties and taxes. You will be responsible for the payment of any such import duties and taxes. Whilst we
        will do our best to facilitate the process for you, we do not have control over these charges and cannot predict their
        amount. We recommend contacting your local customs office for further information before placing your order.
      </Text>
      <Header type="h2" margin="16px 0 0">
        8. NATURE OF THE PRODUCTS
      </Header>
      <Text size={18}>
        8.1 Depending on where you live, mandatory local consumer laws will provide you with certain rights under your contract
        with us for supply of the products. The Consumer Rights Act 2015 in the UK gives you certain legal rights (also known as
        'statutory rights') under your contract with us, for example, the products:
      </Text>
      <Text size={18}>8.1.1 are of satisfactory quality;</Text>
      <Text size={18}>8.1.2 are fit for purpose;</Text>
      <Text size={18}>8.1.3 match the description on the GoodMood website.</Text>
      <Text size={18}>8.2 We must provide you with products that comply with your legal rights.</Text>
      <Text size={18}>8.3 While we try to make sure that:</Text>
      <Text size={18}>
        8.3.1 all weights, sizes and measurements set out on the GoodMood website are as accurate as possible, there may be a
        small tolerance in such weights, sizes and measurements in prints and framed prints; and
      </Text>
      <Text size={18}>
        8.3.2 the colours of the products are displayed accurately on the site, the actual colours that you see on your computer
        may vary depending on the monitor that you use.
      </Text>
      <Header type="h2" margin="16px 0 0">
        9. FAULTY PRODUCTS
      </Header>
      <Text size={18}>
        9.1 Nothing in these Terms affects your legal rights under the Consumer Rights Act 2015 (also known as 'statutory
        rights'). You may also have other rights in law.{" "}
      </Text>
      <Text size={18}>
        9.2 Please email us at <TextLink href="mailto:hello@goodmoodprints.io">hello@goodmoodprints.io</TextLink>, if the product
        you ordered from us is faulty and you want us to replace the products.
      </Text>
      <Header type="h2" margin="16px 0 0">
        10. DISPUTES
      </Header>
      <Text size={18}>
        10.1 We will try to resolve any disputes between you and us quickly and efficiently. If you are unhappy with the products
        please contact us as soon as possible and we will try and resolve the issue.
      </Text>
      <Text size={18}>
        10.2 The laws of England and Wales will apply to these Terms. These laws will apply no matter where in the world you live,
        but you will be entitled to the protection of the mandatory consumer protection provisions of your local consumer
        protection laws if you live outside England and Wales. Any disputes are subject to the exclusive jurisdiction of the
        English courts
      </Text>
    </>
  );
};

export default BuyerTerms;
