import { RefObject, useCallback, useEffect, useRef, useState } from "react";

const useLoadMoreOnScroll = (
  ref: RefObject<HTMLDivElement>,
  callback: () => void,
  hasMore: boolean,
  loading: boolean,
  scrollThreshold = 1000
) => {
  const [loadMore, setLoadMore] = useState(false);
  const debounceTimeout = useRef<number | null>(null);

  const onScroll = useCallback(() => {
    if (debounceTimeout.current) {
      window.clearTimeout(debounceTimeout.current);
    }

    debounceTimeout.current = window.setTimeout(() => {
      if (ref?.current) {
        const scrollPosition = window.scrollY + window.innerHeight;
        const targetPosition = ref.current.offsetTop + ref.current.scrollHeight - scrollThreshold;

        setLoadMore(scrollPosition > targetPosition);
      }
    }, 100);
  }, [ref, scrollThreshold]);

  useEffect(() => {
    if (hasMore && loadMore && !loading) {
      callback();
      setLoadMore(false);
    }
  }, [loadMore, hasMore, loading, callback]);

  useEffect(() => {
    window.addEventListener("scroll", onScroll, { passive: true });

    onScroll();

    return () => {
      window.removeEventListener("scroll", onScroll);
      if (debounceTimeout.current) {
        window.clearTimeout(debounceTimeout.current);
      }
    };
  }, [onScroll]);
};

export default useLoadMoreOnScroll;
