import { match } from "ts-pattern";
import { capitalize, lowerCase, uniqBy, upperCase } from "lodash";
import {
  collection,
  doc,
  getDoc,
  addDoc,
  updateDoc,
  getDocs,
  query,
  where,
  orderBy as firebaseOrderBy,
  startAt,
  endAt,
  limit as firebaseLimit,
  QueryDocumentSnapshot,
  DocumentData,
  startAfter,
  Timestamp,
} from "firebase/firestore";
import { db } from ".";
import { User } from "../../types/user";
import { Medium } from "../../types/product";

export const getUsers = async ({
  limit,
  orderBy,
  lastUser,
  filters = {},
}: {
  limit?: number;
  orderBy?: { field: string; direction: "asc" | "desc" };
  lastUser?: QueryDocumentSnapshot<DocumentData>;
  filters?: { [key: string]: any };
}) => {
  const usersRef = collection(db, "users");
  try {
    const customWhere = Object.entries(filters).map(([key, value]) => {
      if (key === "mediums") {
        const medium = match(value)
          .with(Medium.Graphic, () => "graphic")
          .with(Medium.Photography, () => "photography")
          .with(Medium.Mixed, () => "mixed")
          .with(Medium.Painting, () => "painting")
          .exhaustive();
        return where(key, "array-contains", medium);
      } else {
        return where(key, "==", value);
      }
    });
    const orderByQuery = orderBy ? [firebaseOrderBy(orderBy.field, orderBy.direction)] : [];
    const lastUserQuery = lastUser ? [startAfter(lastUser)] : [];
    const users = query(usersRef, ...customWhere, ...orderByQuery, ...lastUserQuery, firebaseLimit(limit || 96));
    const documentSnapshots = await getDocs(users);
    const docs: User[] = [];
    documentSnapshots.forEach((doc) => {
      docs.push({ id: doc.id, ...doc.data() } as User);
    });
    const lastVisible = documentSnapshots.docs[documentSnapshots.docs.length - 1];

    return { users: docs, lastVisible };
  } catch (error) {
    console.log("Error getting users: ", error);
    throw error;
  }
};

export const getUserById = async (id: string) => {
  try {
    const docRef = doc(db, "users", id);
    const docSnap = await getDoc(docRef);
    if (docSnap.data()) {
      return { id: docSnap.id, ...docSnap.data() } as User;
    }
    return null;
  } catch (error) {
    console.log("Error getting user by id: ", error);
    throw error;
  }
};

export const getUserByPermalink = async (permalink: string) => {
  try {
    const usersRef = collection(db, "users");
    const whereQuery = where("permalink", "==", permalink);
    const q = query(usersRef, whereQuery);
    const querySnapshot = await getDocs(q);
    const docs: User[] = [];
    querySnapshot.forEach((doc) => {
      docs.push({ id: doc.id, ...doc.data() } as User);
    });
    return docs[0];
  } catch (error) {
    console.log("Error getting user by permalink: ", error);
    throw error;
  }
};

export const getLoggedInUser = async (uid: string) => {
  try {
    const usersRef = collection(db, "users");
    const whereQuery = where("uid", "==", uid);
    const q = query(usersRef, whereQuery);
    const querySnapshot = await getDocs(q);
    const docs: User[] = [];
    querySnapshot.forEach((doc) => {
      docs.push({ id: doc.id, ...doc.data() } as User);
    });
    return docs[0];
  } catch (error) {
    console.log("Error getting logged in user: ", error);
    throw error;
  }
};

export const addUser = async (user: Omit<User, "id">) => {
  try {
    const usersRef = collection(db, "users");
    const newUser = await addDoc(usersRef, { ...user, createdAt: Timestamp.now(), updatedAt: Timestamp.now() });
    const doc = await getDoc(newUser);
    return { id: doc.id, ...doc.data() } as User;
  } catch (error) {
    console.error("Error adding user document: ", error);
    throw error;
  }
};

export const updateUser = async (userId: string, user: Partial<User>) => {
  try {
    const userRef = doc(db, "users", userId);
    await updateDoc(userRef, { ...user, updatedAt: Timestamp.now() });
    const docSnap = await getDoc(userRef);
    return { id: docSnap.id, ...docSnap.data() } as User;
  } catch (error) {
    console.error("Error updating user document: ", error);
    throw error;
  }
};

const getQuery = (field: string, qs: string) => {
  const usersRef = collection(db, "users");
  return query(usersRef, where("hasProducts", "==", true), firebaseOrderBy(field), startAt(qs), endAt(qs + "\uf8ff"));
};

export const searchArtists = async (qs: string, isAdmin?: boolean) => {
  const queryArray: string[] = qs.split(" ").filter(Boolean);
  if (!queryArray.length) return [];

  const [capitalizedQs, upperCaseQs, lowerCaseQs] = [
    capitalize(queryArray[0]),
    upperCase(queryArray[0]),
    lowerCase(queryArray[0]),
  ];

  const searchFields = [
    { field: "firstName", value: capitalizedQs },
    { field: "lastName", value: capitalizedQs },
    { field: "firstName", value: upperCaseQs },
    { field: "lastName", value: upperCaseQs },
    { field: "firstName", value: lowerCaseQs },
    { field: "lastName", value: lowerCaseQs },
    { field: "permalink", value: queryArray[0] },
  ];

  if (isAdmin) {
    searchFields.push(
      { field: "id", value: queryArray[0] },
      { field: "email", value: queryArray[0] },
      { field: "paypalEmail", value: queryArray[0] },
      { field: "city", value: capitalizedQs }
    );
  }

  try {
    const querySnapshots = await Promise.all(searchFields.map(({ field, value }) => getDocs(getQuery(field, value))));

    const docs = querySnapshots.flatMap((snapshot) => snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() } as User)));

    return uniqBy(docs, "id");
  } catch (error) {
    console.error("Error searching artists:", error);
    throw error;
  }
};
