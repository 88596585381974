import { useRef, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useToggle } from "react-use";
import { Badge, Box, Grid, MenuItem, Select, SelectChangeEvent, Stack, Typography } from "@mui/material";
import { ArrowDropDownRounded } from "@mui/icons-material";
import { colors } from "../../theme";
import { Medium, Frame, SortBy } from "../../types/product";
import { Icon } from "../../components/Icon";
import ProductCard from "../../components/ProductCard";
import { FiltersButton, StickyContainer } from "./styles";
import { useAppState } from "../../state";
import { Loader } from "../../components/Loader";
import Button from "../../components/Button";
import { useShop } from "./useShop";
import FrameBadges from "../../components/FrameBadges";
import { ProductSortKeys } from "../../generated/storefront";
import useLoadMoreOnScroll from "../../hooks/useLoadMoreOnScroll";
import FiltersDrawer from "./Filters/Drawer";
import { isNextMonth } from "../../helpers/time";
import AutoResizeText from "../../components/AutoResizeText";
import ReadMoreText from "../../components/ReadMoreText";
import FeaturedCollections from "./FeaturedCollections";
import Switch from "../../components/Switch";
import FrameBadge from "../../components/FrameBadges/FrameBadge";
import BottomSheet from "../../components/BottomSheet";
import FiltersDesktopDrawer from "./Filters/DesktopDrawer";
import useFilterOptions from "./Filters/useFilterOptions";

export type Filters = {
  mediums: Medium[];
  sortKey: ProductSortKeys;
  collection: boolean;
  reverse?: boolean;
  campaign?: string;
};

export type SearchParams = {
  mediums?: string;
  sortKey?: ProductSortKeys;
  collection?: boolean;
  reverse?: boolean;
  campaign?: string;
};

const Shop = () => {
  const ref = useRef<HTMLDivElement>(null);
  const { handle } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const { navHeight, isMobileScreen } = useAppState();
  const [selectedFrame, setSelectedFrame] = useState(Frame.Natural);
  const [showFilters, toggleShowFilters] = useToggle(false);
  const [openFrameBar, setOpenFrameBar] = useState(false);
  const { sortByOptions } = useFilterOptions();

  const {
    products,
    loadMore,
    clearFilters,
    loading,
    hasMore,
    collectionTitle,
    collectionCategory,
    collectionDescription,
    collectionDescriptionHtml,
  } = useShop({
    handle,
  });
  const filters = ["colour", "month", "medium", "size", "style", "subject", "orientation"];
  let filterCount = 0;
  searchParams.forEach((value, key) => filters.includes(key) && value && filterCount++);
  useLoadMoreOnScroll(ref, loadMore, hasMore, loading);

  const saveScrollPosition = () => {
    window.sessionStorage.setItem("scrollPosition", window.scrollY.toString());
  };

  const handleSortByChange = (event: SelectChangeEvent<string>) => {
    const { value } = event.target;
    searchParams.set("sort", value);
    setSearchParams(searchParams, { preventScrollReset: true });
    if (window.scrollY > 650) window.scrollTo(0, 650);
  };

  const onViewChange = (value: string) => {
    searchParams.set("view", value);
    setSearchParams(searchParams, { preventScrollReset: true });
  };

  const sortBy = sortByOptions.map((option) => ({
    ...option,
    disabled:
      (option.value === SortBy.BestSelling || option.value === SortBy.Newest) &&
      Boolean(searchParams.get("colour") || searchParams.get("style") || searchParams.get("subject")),
  }));

  return (
    <>
      <Helmet>
        <title>{handle ? `Shop ${collectionTitle}` : "Shop"} | GoodMood</title>
        <meta
          name="description"
          content={collectionDescription || "Fill your space with work that inspires, entertains and resonates with you"}
        />
      </Helmet>
      <div>
        <Box padding={2} paddingBottom={4} position="relative">
          <Stack gap={{ xs: 1, md: 2 }} alignItems="center">
            <AutoResizeText text={collectionTitle} align="center" maxSize={isMobileScreen ? 50 : 85} />
            {handle && collectionDescriptionHtml ? (
              <ReadMoreText width={{ xs: "100%", md: "50%" }} align="center" markup>
                {collectionDescriptionHtml}
              </ReadMoreText>
            ) : (
              <Typography width={{ xs: "100%", md: "50%" }} align="center">
                Fill your space with work that inspires, entertains and resonates with you
              </Typography>
            )}
          </Stack>
        </Box>

        {filterCount === 0 && collectionCategory && (
          <FeaturedCollections collectionCategory={collectionCategory} isMobileScreen={isMobileScreen} />
        )}

        <StickyContainer bgcolor={colors.white} padding={{ xs: "8px 16px", md: "8px 40px" }} navHeight={navHeight}>
          <Stack gap={1.5} direction="row" justifyContent="space-between" alignItems="center">
            <Stack gap={1.5} direction="row" alignItems="center" minWidth={{ xs: "none", md: 255 }}>
              {isMobileScreen ? (
                <Badge badgeContent={filterCount} color="secondary">
                  <FiltersButton onClick={toggleShowFilters}>
                    <Stack direction="row" alignItems="center" gap={1}>
                      <Icon icon="filters" fill={colors.black} size={20} />
                    </Stack>
                  </FiltersButton>
                </Badge>
              ) : (
                <FiltersButton onClick={toggleShowFilters}>
                  <Stack direction="row" alignItems="center" gap={1}>
                    <Typography fontSize={12}>Filters{filterCount > 0 ? ` (${filterCount})` : ""}</Typography>
                    <Icon icon="filters" fill={colors.black} size={20} />
                  </Stack>
                </FiltersButton>
              )}

              {!isMobileScreen && (
                <>
                  <Select
                    name="sort"
                    variant="filled"
                    size="small"
                    displayEmpty
                    onChange={handleSortByChange}
                    IconComponent={ArrowDropDownRounded}
                    renderValue={(selected) => sortByOptions.find((o) => o.value === selected)?.label || "Sort by"}
                  >
                    {sortBy.map((option) => (
                      <MenuItem key={option.value} value={option.value} disabled={option.disabled}>
                        <Typography fontSize={14}>{option.label}</Typography>
                      </MenuItem>
                    ))}
                  </Select>

                  {filterCount > 0 && (
                    <Button onClick={clearFilters} tertiary size="small" style={{ fontSize: 12 }}>
                      Clear
                    </Button>
                  )}
                </>
              )}
            </Stack>

            <Switch
              options={[
                {
                  label: "Artwork",
                  value: "artwork",
                },
                {
                  label: "Interior",
                  value: "interior",
                },
              ]}
              fullWidth={isMobileScreen}
              selected={searchParams.get("view") || "artwork"}
              onChange={onViewChange}
            />

            {isMobileScreen ? (
              <Stack
                onClick={() => setOpenFrameBar(true)}
                component="button"
                position="relative"
                bgcolor={colors.white}
                border={`2px solid ${colors.cardGrey}`}
                borderRadius={100}
                padding={0.25}
              >
                <FrameBadge frame={selectedFrame} size={30} />
              </Stack>
            ) : (
              <Stack direction="row" alignItems="center" justifyContent="flex-end" minWidth={255}>
                <FrameBadges selectedFrame={selectedFrame} setSelectedFrame={setSelectedFrame} size="medium" />
              </Stack>
            )}
          </Stack>
        </StickyContainer>

        {isMobileScreen ? (
          <FiltersDrawer
            isOpen={showFilters}
            onClose={toggleShowFilters}
            searchParams={searchParams}
            setSearchParams={setSearchParams}
          />
        ) : (
          <FiltersDesktopDrawer
            isOpen={showFilters}
            onClose={toggleShowFilters}
            filterCount={filterCount}
            searchParams={searchParams}
            setSearchParams={setSearchParams}
          />
        )}

        <Box
          ref={ref}
          padding={{ xs: "8px 0 40px", md: "8px 40px 40px" }}
          minHeight={!products || loading ? `${window.sessionStorage.getItem("scrollPosition")}px` : "auto"}
        >
          {products && !loading ? (
            <>
              {products.length === 0 ? (
                <Box padding={{ xs: "0 24px", md: "0" }}>
                  <Stack gap={2} alignItems="center">
                    <Typography>No pieces with selected filters</Typography>
                    <Button size="medium" onClick={clearFilters}>
                      Clear filters
                    </Button>
                  </Stack>
                </Box>
              ) : (
                <>
                  <Grid container spacing={{ xs: 1, md: 2 }} rowGap={{ xs: 4, md: 9 }}>
                    {products.map((product, index) => (
                      <Grid item xs={6} md={3} xl={3} xxl={2} xxxl={1.5} key={product.id} onClick={saveScrollPosition}>
                        <ProductCard
                          nextMonths={isNextMonth(product.productType)}
                          product={product}
                          indent={isMobileScreen}
                          frame={selectedFrame}
                          style={(searchParams.get("view") as "artwork" | "interior") || "artwork"}
                          index={index}
                        />
                      </Grid>
                    ))}
                  </Grid>
                  {loading ? (
                    <Loader />
                  ) : hasMore ? (
                    <Stack width="100%" alignItems="center" padding={5}>
                      <Button onClick={loadMore}>Load more</Button>
                    </Stack>
                  ) : null}
                </>
              )}
            </>
          ) : (
            <Loader />
          )}
        </Box>

        {isMobileScreen && (
          <BottomSheet open={openFrameBar} onClose={() => setOpenFrameBar(false)}>
            <Typography>Frame colour</Typography>
            <FrameBadges
              selectedFrame={selectedFrame}
              setSelectedFrame={(frame: Frame) => {
                setSelectedFrame(frame);
                setOpenFrameBar(false);
              }}
              size="large"
              showLabels
            />
          </BottomSheet>
        )}
      </div>
    </>
  );
};

export default Shop;
