import { CurrencyCode, DraftOrderLineItemFragment, DraftOrderLineItemInput } from "../../../../generated/graphql";
import { ArtworkItem, CustomItem } from "./AddOrUpdateItemModal";

export const getExVatPrice = (price: string, currencyCode: CurrencyCode) => {
  const taxable = currencyCode === "GBP";
  return taxable ? (Number(price) / 1.2).toFixed(2) : price;
};

export const getArtworkLineItems = (items: ArtworkItem[], currency: CurrencyCode): DraftOrderLineItemInput[] => {
  return items.map((item) => {
    const attributes = Object.entries(item.attributes).map(([key, value]) => {
      if (key === "color") {
        return { key: "frame", value };
      }
      return { key, value };
    });
    const baseAttributes = [
      ...attributes,
      { key: "_commissionValue", value: item.commissionValue.toString() },
      { key: "_commissionType", value: item.commissionType },
      { key: "_sizing", value: item.sizing },
    ];

    const taxable = currency === CurrencyCode.Gbp;
    const priceIncVat = item.price && taxable ? (Number(item.price) * 1.2).toFixed(2) : item.price;

    return item.productExists
      ? {
          variantId: item.variantId,
          quantity: item.quantity,
          priceOverride: priceIncVat !== undefined ? { amount: priceIncVat, currencyCode: currency } : undefined,
          requiresShipping: true,
          customAttributes: baseAttributes,
        }
      : {
          originalUnitPriceWithCurrency: {
            amount: priceIncVat,
            currencyCode: currency,
          },
          requiresShipping: true,
          quantity: item.quantity,
          title: item.title,
          sku: item.sku,
          customAttributes: [
            ...baseAttributes,
            { key: "_artworkUrl", value: item.artworkUrl },
            { key: "_artistId", value: item.artistId },
            { key: "_artistName", value: item.artistName },
          ],
        };
  });
};

export const getCustomLineItems = (items: CustomItem[], currency: CurrencyCode): DraftOrderLineItemInput[] => {
  return items.map((item) => {
    const taxable = currency === CurrencyCode.Gbp;
    const priceIncVat = item.price && taxable ? (Number(item.price) * 1.2).toFixed(2) : item.price;
    return {
      title: item.title,
      originalUnitPriceWithCurrency: {
        amount: priceIncVat,
        currencyCode: currency,
      },
      quantity: item.quantity,
      customAttributes: [
        { key: "_commissionValue", value: item.commissionValue.toString() },
        { key: "_commissionType", value: item.commissionType },
        { key: "_artistId", value: item.artistId },
        { key: "_artistName", value: item.artistName },
      ],
    };
  });
};

export const getLineItemInput = (lineItem: DraftOrderLineItemFragment): DraftOrderLineItemInput => {
  const customAttributes = lineItem.customAttributes?.map((attr) => ({ key: attr.key, value: attr.value })) || [];
  return {
    variantId: lineItem.variant?.id,
    uuid: lineItem.uuid,
    quantity: lineItem.quantity,
    title: lineItem.title,
    sku: lineItem.sku,
    customAttributes,
    originalUnitPriceWithCurrency: {
      amount: lineItem.originalUnitPriceSet.presentmentMoney.amount,
      currencyCode: lineItem.originalUnitPriceSet.presentmentMoney.currencyCode,
    },
    ...(lineItem.priceOverride
      ? {
          priceOverride: {
            amount: lineItem.priceOverride?.amount,
            currencyCode: lineItem.priceOverride?.currencyCode,
          },
        }
      : {}),
    requiresShipping: lineItem.requiresShipping,
  };
};
