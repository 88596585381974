import { match } from "ts-pattern";
import { compact, uniq } from "lodash";
import {
  RectangleSize,
  Ratio,
  Size,
  SquareSize,
  rectangleSizes,
  squareSizes,
  TShirtSize,
  tShirtSizeNumbers,
  SizeScale,
  Frame,
} from "../types/product";
import { OptionType, SelectedOption } from "../generated/graphql";
import {
  ArtistProductVariantFragment,
  CountryCode,
  ProductCardVariantFragment,
  ProductSortKeys,
  SearchArticleFragment,
  SearchProductFragment,
} from "../generated/storefront";

export const isSortKey = (sortKey: string | null): sortKey is ProductSortKeys =>
  Object.values(ProductSortKeys).includes(sortKey as ProductSortKeys);

export const getCampaignFromTags = (tags: string[]) => {
  if (tags.includes("artforgood")) return "Art For Good";
};

const gmTags = ["artforgood", "favourite", "pick", "community_favourites"];

export const getKeywordsFromTags = (tags: string[]) =>
  tags.filter((t) => !t.includes(".") && !t.includes(":") && !gmTags.includes(t));

export const getGmTagsFromTags = (tags: string[]) => tags.filter((t) => t.startsWith("gm.")).map((t) => t.replace("gm.", ""));

export const escapeQuotes = (string: string) => string.replace(/"/g, "&quot;");

export const getSizeAndFrame = (selectedOptions: SelectedOption[]) => {
  const size = selectedOptions.find((option) => option.name === OptionType.Size);
  const frame = selectedOptions.find((option) => option.name === OptionType.Frame);
  if (!size) throw new Error("Can't find size option");
  return { size: size.value as Size, frame: (frame?.value || Frame.Unframed) as Frame };
};

export const getSizeAndFrameFromVariantTitle = (title: string) => {
  const variants = title.split(" / ");
  const size = variants[0] as Size;
  const frame = variants.length > 1 ? (variants[1] as Frame) : Frame.Unframed;
  return { size, frame };
};

export const getSkuCode = (size: Size, framed: boolean, mounted?: boolean, boxFrame?: boolean) => {
  return match(framed)
    .with(false, () => `GLOBAL-FAP-${size}`)
    .with(true, () => `GLOBAL-${boxFrame ? "BOX" : "CFP"}${mounted ? "M" : ""}-${size}`)
    .exhaustive();
};

export const sizeToTShirtSize = (size: Size) => {
  return match(size)
    .with(RectangleSize.A3, () => TShirtSize.Small)
    .with(RectangleSize.A2, () => TShirtSize.Medium)
    .with(RectangleSize["20X28"], () => TShirtSize.Medium)
    .with(RectangleSize.A1, () => TShirtSize.Large)
    .with(RectangleSize["28X40"], () => TShirtSize.XLarge)
    .with(SquareSize["12X12"], () => TShirtSize.Small)
    .with(SquareSize["20X20"], () => TShirtSize.Medium)
    .with(SquareSize["28X28"], () => TShirtSize.Large)
    .exhaustive();
};

export const tShirtSizeToSize = (size: TShirtSize, ratio: Ratio) => {
  return match(ratio)
    .with(Ratio.Rectangle, () =>
      match(size)
        .with(TShirtSize.Small, () => RectangleSize.A3)
        .with(TShirtSize.Medium, () => RectangleSize["20X28"])
        .with(TShirtSize.Large, () => RectangleSize.A1)
        .with(TShirtSize.XLarge, () => RectangleSize["28X40"])
        .exhaustive()
    )
    .with(Ratio.Square, () =>
      match(size)
        .with(TShirtSize.Small, () => SquareSize["12X12"])
        .with(TShirtSize.Medium, () => SquareSize["20X20"])
        .with(TShirtSize.Large, () => SquareSize["28X28"])
        .with(TShirtSize.XLarge, () => SquareSize["28X28"])
        .exhaustive()
    )
    .exhaustive();
};

export const getAvailableSizes = (variants: ArtistProductVariantFragment[]) => {
  const sizeVariants = compact(variants.map((variant) => variant.selectedOptions.find((option) => option.name === "size")));
  const availableSizes = sizeVariants.map((variant) => variant.value as Size);
  return uniq(availableSizes).sort((a, b) => SizeScale[a] - SizeScale[b]);
};

export const getMaxSize = (variants: ArtistProductVariantFragment[]) => {
  const availableSizes = getAvailableSizes(variants).reverse();
  return sizeToTShirtSize(availableSizes[0]);
};

export const getRatioFromSize = (size: Size) => {
  return match(size)
    .with(RectangleSize.A3, () => Ratio.Rectangle)
    .with(RectangleSize.A2, () => Ratio.Rectangle)
    .with(RectangleSize["20X28"], () => Ratio.Rectangle)
    .with(RectangleSize.A1, () => Ratio.Rectangle)
    .with(RectangleSize["28X40"], () => Ratio.Rectangle)
    .with(SquareSize["12X12"], () => Ratio.Square)
    .with(SquareSize["20X20"], () => Ratio.Square)
    .with(SquareSize["28X28"], () => Ratio.Square)
    .exhaustive();
};

export const getRatioFromVariants = (variants: ProductCardVariantFragment[]): Ratio => {
  const sizeVariants = compact(variants.map((variant) => variant.selectedOptions.find((option) => option.name === "size")));
  return getRatioFromSize(sizeVariants[0].value as Size);
};

// ~ mm * 11.811
export const getResolutionDimensions = (maxSize: TShirtSize, ratio: Ratio) => {
  return match(ratio)
    .with(Ratio.Rectangle, () =>
      match(maxSize)
        .with(TShirtSize.Small, () => ({ width: 3507, height: 4960 }))
        .with(TShirtSize.Medium, () => ({ width: 6000, height: 8400 }))
        .with(TShirtSize.Large, () => ({ width: 7016, height: 9933 }))
        .with(TShirtSize.XLarge, () => ({ width: 8400, height: 12000 }))
        .exhaustive()
    )
    .with(Ratio.Square, () =>
      match(maxSize)
        .with(TShirtSize.Small, () => ({ width: 3600, height: 3600 }))
        .with(TShirtSize.Medium, () => ({ width: 6000, height: 6000 }))
        .with(TShirtSize.Large, () => ({ width: 8400, height: 8400 }))
        .with(TShirtSize.XLarge, () => ({ width: 12000, height: 12000 }))
        .exhaustive()
    )
    .exhaustive();
};

export const getMaxSizeFromResolutionDimensions = (longSide: number, shortSide: number, ratio: Ratio) => {
  return match(ratio)
    .with(Ratio.Rectangle, () => {
      if (shortSide >= 8400 && longSide >= 12000) return TShirtSize.XLarge;
      if (shortSide >= 7016 && longSide >= 9933) return TShirtSize.Large;
      if (shortSide >= 6000 && longSide >= 8400) return TShirtSize.Medium;
      if (shortSide >= 3507 && longSide >= 4960) return TShirtSize.Small;
    })
    .with(Ratio.Square, () => {
      if (shortSide >= 8400 && longSide >= 8400) return TShirtSize.Large;
      if (shortSide >= 6000 && longSide >= 6000) return TShirtSize.Medium;
      if (shortSide >= 3600 && longSide >= 3600) return TShirtSize.Small;
    })
    .exhaustive();
};

export const getDimensionsForCanvas = (ratio: Ratio) => {
  return match(ratio)
    .with(Ratio.Rectangle, () => ({ width: 1188, height: 1682 }))
    .with(Ratio.Square, () => ({ width: 1682, height: 1682 }))
    .exhaustive();
};

export const getDimensionsForMockUpTemplate = (ratio: Ratio) => {
  return match(ratio)
    .with(Ratio.Rectangle, () => ({ width: 377, height: 533.5 }))
    .with(Ratio.Square, () => ({ width: 533.5, height: 533.5 }))
    .exhaustive();
};

export const getSizesToSell = (maxSize: TShirtSize, ratio: Ratio) => {
  const maxSizeNumber = tShirtSizeNumbers[maxSize];
  return match(ratio)
    .with(Ratio.Rectangle, () => rectangleSizes.filter((size) => Number(SizeScale[size]) <= Number(maxSizeNumber)))
    .with(Ratio.Square, () => squareSizes.filter((size) => Number(SizeScale[size]) <= Number(maxSizeNumber)))
    .exhaustive();
};

export const shippingCostsForCountry = (countryCode: CountryCode) =>
  match(countryCode)
    .with(CountryCode.Gb, () => ({ unframed: "£4.95", framed: "£9.95" }))
    .with(CountryCode.Us, () => ({ unframed: "$5.95", framed: "$11.95" }))
    .with(CountryCode.Au, () => ({ unframed: "$9.95", framed: "$19.95" }))
    .with(CountryCode.At, () => ({ unframed: "€5.95", framed: "€11.95" }))
    .otherwise(() => ({ unframed: "£4.95", framed: "£9.95" }));

export const sizeLabelsForCountry = (countryCode?: CountryCode) =>
  match(countryCode)
    .with(CountryCode.Us, CountryCode.Au, () => ({
      [RectangleSize.A3]: {
        shortHand: "A3",
        printMeasurements: '11.7x16.5"',
        framedMeasurements: '13.3x18.1"',
      },
      [RectangleSize.A2]: {
        shortHand: "A2",
        printMeasurements: '16.5x23.4"',
        framedMeasurements: '18.1x25"',
      },
      [RectangleSize["20X28"]]: {
        shortHand: "20x28",
        printMeasurements: '20x28"',
        framedMeasurements: '21.6x29.6"',
      },
      [RectangleSize.A1]: {
        shortHand: "A1",
        printMeasurements: '23.4x33.1"',
        framedMeasurements: '25x34.7"',
      },
      [RectangleSize["28X40"]]: {
        shortHand: "28x40",
        printMeasurements: '28x40"',
        framedMeasurements: '29.6x41.6"',
      },
      [SquareSize["12X12"]]: {
        shortHand: "12x12",
        printMeasurements: '12x12"',
        framedMeasurements: '13.6x13.6"',
      },
      [SquareSize["20X20"]]: {
        shortHand: "20x20",
        printMeasurements: '20x20"',
        framedMeasurements: '21.6x21.6"',
      },
      [SquareSize["28X28"]]: {
        shortHand: "28x28",
        printMeasurements: '28x28"',
        framedMeasurements: '29.6x29.6"',
      },
    }))
    .otherwise(() => ({
      [RectangleSize.A3]: {
        shortHand: "A3",
        printMeasurements: "29.7x42.0cm",
        framedMeasurements: "33.7x46.0cm",
      },
      [RectangleSize.A2]: {
        shortHand: "A2",
        printMeasurements: "42.0x59.4cm",
        framedMeasurements: "46.0x63.4cm",
      },
      [RectangleSize["20X28"]]: {
        shortHand: "50x70",
        printMeasurements: "50x70cm",
        framedMeasurements: "54x74cm",
      },
      [RectangleSize.A1]: {
        shortHand: "A1",
        printMeasurements: "59.4x84.1cm",
        framedMeasurements: "63.4x88.1cm",
      },
      [RectangleSize["28X40"]]: {
        shortHand: "70x100",
        printMeasurements: "70x100cm",
        framedMeasurements: "74x104cm",
      },
      [SquareSize["12X12"]]: {
        shortHand: "30x30",
        printMeasurements: "30x30cm",
        framedMeasurements: "34x34cm",
      },
      [SquareSize["20X20"]]: {
        shortHand: "50x50",
        printMeasurements: "50x50cm",
        framedMeasurements: "54x54cm",
      },
      [SquareSize["28X28"]]: {
        shortHand: "70x70",
        printMeasurements: "70x70cm",
        framedMeasurements: "74x74cm",
      },
    }));

export const updateLocalStorageWishlist = (id: string) => {
  const existingIds = JSON.parse(localStorage.getItem("wishlist") || "[]");
  const ids = existingIds.includes(id) ? existingIds.filter((wishlistId: string) => wishlistId !== id) : [...existingIds, id];
  localStorage.setItem("wishlist", JSON.stringify(ids));
};

export const productSearchFilter = (node: SearchProductFragment | SearchArticleFragment | { __typename: "Page" }) => {
  return node.__typename === "Product" && node.title !== "Mounting";
};
