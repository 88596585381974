import { useState } from "react";
import { Box, Stack, Typography } from "@mui/material";
import { match } from "ts-pattern";
import {
  Brightness7Rounded,
  ConstructionRounded,
  FilterFramesRounded,
  FlareRounded,
  LayersRounded,
  PublicRounded,
  SecurityRounded,
  VerifiedUserRounded,
} from "@mui/icons-material";
import frameCloseup1Webp from "../../assets/images/frame-closeups-webp/01.webp";
import frameCloseup2Webp from "../../assets/images/frame-closeups-webp/02.webp";
import frameCloseup3Webp from "../../assets/images/frame-closeups-webp/03.webp";
import frameCloseup4Webp from "../../assets/images/frame-closeups-webp/04.webp";
import frameCloseup5Webp from "../../assets/images/frame-closeups-webp/05.webp";
import frameCloseup6Webp from "../../assets/images/frame-closeups-webp/06.webp";
import paperCloseup1 from "../../assets/images/paper-closeups-webp/01.webp";
import paperCloseup2 from "../../assets/images/paper-closeups-webp/02.webp";
import mount01 from "../../assets/images/mounting-closeups/mount01.webp";
import mount02 from "../../assets/images/mounting-closeups/mount02.webp";
import Switch from "../../components/Switch";
import { colors } from "../../theme";
import { CountryCode } from "../../generated/storefront";

type SwitchOption = "frames" | "paper" | "mounting";

const QualitySection = ({ isMobileScreen, selectedCountry }: { isMobileScreen: boolean; selectedCountry: CountryCode }) => {
  const [selectedSwitchOption, setSelectedSwitchOption] = useState<SwitchOption>("frames");
  const height = isMobileScreen ? "40vh" : "60vh";
  const mountLabel = match(selectedCountry)
    .with(CountryCode.Us, () => "matt")
    .otherwise(() => "mount");
  const mountingLabel = match(selectedCountry)
    .with(CountryCode.Us, () => "Matting")
    .otherwise(() => "Mounting");

  return (
    <Stack paddingX={{ xs: 2, md: 5 }} direction={{ xs: "column", md: "row" }} gap={6}>
      <Box width={{ xs: "100%", md: "30%" }}>
        <Typography variant="h2">Our Exceptional Quality</Typography>
        <Box paddingTop={3} paddingBottom={4}>
          <Switch
            options={[
              { label: "Frames", value: "frames" },
              { label: "Paper", value: "paper" },
              { label: mountingLabel, value: "mounting" },
            ]}
            selected={selectedSwitchOption}
            onChange={(value) => setSelectedSwitchOption(value as SwitchOption)}
            fullWidth
          />
        </Box>
        <Box>
          {match(selectedSwitchOption)
            .with("frames", () => (
              <Stack gap={3}>
                <Stack gap={1.5} direction="row">
                  <ConstructionRounded />
                  <Stack gap={0.2}>
                    <Typography>Handcrafted wooden frames</Typography>
                    <Typography fontSize={14} color={colors.grey60}>
                      Meticulous, gallery-grade craftsmanship
                    </Typography>
                  </Stack>
                </Stack>

                <Stack gap={1.5} direction="row">
                  <PublicRounded />
                  <Stack gap={0.2}>
                    <Typography>Sustainable, FSC certified wood</Typography>
                    <Typography fontSize={14} color={colors.grey60}>
                      Eco-friendly choice supporting responsible forestry
                    </Typography>
                  </Stack>
                </Stack>

                <Stack gap={1.5} direction="row">
                  <SecurityRounded />
                  <Stack gap={0.2}>
                    <Typography>UV-blocking, premium glaze</Typography>
                    <Typography fontSize={14} color={colors.grey60}>
                      Ensure your art remains vibrant for 100+ years
                    </Typography>
                  </Stack>
                </Stack>

                <Stack gap={1.5} direction="row">
                  <FilterFramesRounded />
                  <Stack gap={0.2}>
                    <Typography>Ready-to-hang, with archival sealing</Typography>
                    <Typography fontSize={14} color={colors.grey60}>
                      Acid-free materials protect from dust and moisture
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>
            ))
            .with("paper", () => (
              <Stack gap={3}>
                <Stack gap={1.5} direction="row">
                  <Brightness7Rounded />
                  <Stack gap={0.2}>
                    <Typography>We’re Giclée printing experts</Typography>
                    <Typography fontSize={14} color={colors.grey60}>
                      Industry leading 12-colour ultra-chrome HDR inks
                    </Typography>
                  </Stack>
                </Stack>

                <Stack gap={1.5} direction="row">
                  <LayersRounded />
                  <Stack gap={0.2}>
                    <Typography>Luxurious 200gsm, heavyweight paper</Typography>
                    <Typography fontSize={14} color={colors.grey60}>
                      Fine art paper with a sleek matte finish
                    </Typography>
                  </Stack>
                </Stack>

                <Stack gap={1.5} direction="row">
                  <FlareRounded />
                  <Stack gap={0.2}>
                    <Typography>Rich, vibrant colours</Typography>
                    <Typography fontSize={14} color={colors.grey60}>
                      Bright colours that pop, with rich, deep dark tones
                    </Typography>
                  </Stack>
                </Stack>

                <Stack gap={1.5} direction="row">
                  <VerifiedUserRounded />
                  <Stack gap={0.2}>
                    <Typography>Museum quality, made to order</Typography>
                    <Typography fontSize={14} color={colors.grey60}>
                      Ensuring only the finest, with zero waste
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>
            ))
            .with("mounting", () => (
              <Stack gap={3}>
                <Stack gap={1.5} direction="row">
                  <Brightness7Rounded />
                  <Stack gap={0.2}>
                    <Typography>Elevate your art with a gallery look</Typography>
                    <Typography fontSize={14} color={colors.grey60}>
                      Focus the attention with a sophisticated 2 inch {mountLabel} around your print
                    </Typography>
                  </Stack>
                </Stack>

                <Stack gap={1.5} direction="row">
                  <LayersRounded />
                  <Stack gap={0.2}>
                    <Typography>Conservation-grade, thick {mountLabel} board</Typography>
                    <Typography fontSize={14} color={colors.grey60}>
                      An acid-free, 2.4mm thick, snow-white board
                    </Typography>
                  </Stack>
                </Stack>

                <Stack gap={1.5} direction="row">
                  <FlareRounded />
                  <Stack gap={0.2}>
                    <Typography>Hand finished bevelled edges</Typography>
                    <Typography fontSize={14} color={colors.grey60}>
                      On acid-free board that protects your print
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>
            ))
            .exhaustive()}
        </Box>
      </Box>
      {match(selectedSwitchOption)
        .with("frames", () => (
          <Box width={{ xs: "100%", md: "70%" }}>
            <Stack
              gap={{ xs: 1.5, md: 2 }}
              overflow="auto"
              direction="row"
              marginRight={{ xs: -2, md: -5 }}
              marginLeft={{ xs: -2, md: 0 }}
              paddingRight={{ xs: 2, md: 5 }}
              paddingLeft={{ xs: 2, md: 0 }}
            >
              <img src={frameCloseup1Webp} alt="frame close-up" width="auto" height="auto" style={{ height }} />
              <img src={frameCloseup2Webp} alt="frame close-up" width="auto" height="auto" style={{ height }} />
              <img src={frameCloseup3Webp} alt="frame close-up" width="auto" height="auto" style={{ height }} />
              <img src={frameCloseup4Webp} alt="frame close-up" width="auto" height="auto" style={{ height }} />
              <img src={frameCloseup5Webp} alt="frame close-up" width="auto" height="auto" style={{ height }} />
              <img src={frameCloseup6Webp} alt="frame close-up" width="auto" height="auto" style={{ height }} />
            </Stack>
          </Box>
        ))
        .with("paper", () => (
          <Box width={{ xs: "100%", md: "70%" }}>
            <Stack
              gap={{ xs: 1.5, md: 2 }}
              overflow="auto"
              direction="row"
              marginRight={{ xs: -2, md: -5 }}
              marginLeft={{ xs: -2, md: 0 }}
              paddingRight={{ xs: 2, md: 5 }}
              paddingLeft={{ xs: 2, md: 0 }}
            >
              <img src={paperCloseup1} alt="paper close-up" width="auto" height="auto" style={{ height }} />
              <img src={paperCloseup2} alt="paper close-up" width="auto" height="auto" style={{ height }} />
            </Stack>
          </Box>
        ))
        .with("mounting", () => (
          <Box width={{ xs: "100%", md: "70%" }}>
            <Stack
              gap={{ xs: 1.5, md: 2 }}
              overflow="auto"
              direction="row"
              marginRight={{ xs: -2, md: -5 }}
              marginLeft={{ xs: -2, md: 0 }}
              paddingRight={{ xs: 2, md: 5 }}
              paddingLeft={{ xs: 2, md: 0 }}
            >
              <img src={mount01} alt="mounting close-up" width="auto" height="auto" style={{ height }} />
              <img src={mount02} alt="mounting close-up" width="auto" height="auto" style={{ height }} />
            </Stack>
          </Box>
        ))
        .exhaustive()}
    </Stack>
  );
};

export default QualitySection;
