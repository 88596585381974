import { AddRounded, DeleteOutlineRounded, RemoveRounded } from "@mui/icons-material";
import { IconButton, Stack, Typography } from "@mui/material";
import { colors, fonts } from "../../theme";

type Props = {
  updateQuantity: (qty: number) => void;
  value: number;
  disabled?: boolean;
  color?: string;
};

const QuantityPicker = ({ updateQuantity, value, disabled, color = colors.grey80 }: Props) => {
  const onAdd = () => {
    updateQuantity(value + 1);
  };

  const onSubtract = () => {
    updateQuantity(value - 1);
  };

  return (
    <Stack direction="row" alignItems="center" gap={0.5}>
      <IconButton onClick={onSubtract} size="small" disabled={disabled}>
        {value === 1 ? <DeleteOutlineRounded sx={{ fontSize: 16, color }} /> : <RemoveRounded sx={{ fontSize: 16, color }} />}
      </IconButton>
      <Typography fontSize={12} fontFamily={fonts.body} color={color}>
        {value}
      </Typography>

      <IconButton onClick={onAdd} size="small" disabled={disabled}>
        <AddRounded sx={{ fontSize: 16, color }} />
      </IconButton>
    </Stack>
  );
};

export default QuantityPicker;
