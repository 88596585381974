import { useParams, Link } from "react-router-dom";
import { match } from "ts-pattern";
import { Box, Stack, Typography } from "@mui/material";
import { ArrowBackRounded, IosShareRounded } from "@mui/icons-material";
import { colors, fonts } from "../../../theme";
import { Loader } from "../../../components/Loader";
import { useAppState } from "../../../state";
import StatusPill from "../StatusPill";
import TextLink from "../../../components/TextLink";
import Stats from "./Stats";
import { ProductStatus, useChangeProductStatusMutation, useGetDashboardProductQuery } from "../../../generated/graphql";
import ActionButtons from "./ActionButtons";
import { getIdNumber } from "../../../helpers/shopify";
import { useContext, useState } from "react";
import AuthContext from "../../../state/auth";
import ReadMoreText from "../../../components/ReadMoreText";
import ShareBar from "../ShareBar";
import ShareIcons from "../../../components/ShareIcons";
import Checkbox from "../../../components/Checkbox";
import { updateProduct } from "../../../services/API";

const ProductBreakdown = () => {
  const { isMobileScreen } = useAppState();
  const { id } = useParams();
  const { user } = useContext(AuthContext);
  const [shareBarOpen, setShareBarOpen] = useState(false);
  const [updatingProduct, setUpdatingProduct] = useState(false);
  const { data, loading, refetch } = useGetDashboardProductQuery({
    fetchPolicy: "network-only",
    variables: { id: `gid://shopify/Product/${id}` },
  });
  const [changeProductStatus, { loading: updating }] = useChangeProductStatusMutation();
  const product = data?.product;

  const updateStatus = async (status: ProductStatus) => {
    if (product) {
      await changeProductStatus({
        variables: {
          id: product.id,
          status,
        },
      });
      await refetch();
    }
  };

  if (!user) return null;

  if (loading) return <Loader />;

  if (!product)
    return (
      <Stack alignItems="center" justifyContent="center" width="100%" height="50vh">
        <Typography>Product not found</Typography>
      </Stack>
    );

  const handleExclusivityChange = async (event: any) => {
    setUpdatingProduct(true);
    const { checked } = event.target;
    await updateProduct(product.id, {
      metafields: [{ namespace: "custom", key: "exclusive_to_goodmood", value: checked.toString() }],
    });
    await refetch();
    setUpdatingProduct(false);
  };

  return (
    <Stack direction="row" gap={3} paddingX={{ xs: 2, md: 5 }} paddingY={2}>
      <Box width={{ xs: "100%", md: "50%" }}>
        <Stack gap={3}>
          <Stack direction="row" justifyContent="space-between">
            <Link to="/profile">
              <ArrowBackRounded />
            </Link>
            <Stack direction="row" gap={1} alignItems="center">
              {match(product)
                .when(
                  ({ status }) => status === ProductStatus.Archived,
                  () => <StatusPill status="archived" />
                )
                .when(
                  ({ status }) => status === ProductStatus.Draft,
                  () => <StatusPill status="draft" />
                )
                .when(
                  ({ status }) => status === ProductStatus.Active,
                  () => <StatusPill status="live" />
                )
                .otherwise(() => (
                  <StatusPill status="archived" />
                ))}
              <ActionButtons product={product} updateStatus={updateStatus} loading={updating} />
              {isMobileScreen && <IosShareRounded fontSize="small" onClick={() => setShareBarOpen(true)} />}
            </Stack>
          </Stack>

          <Stack direction="row" justifyContent={{ xs: "start", md: "space-between" }} gap={2}>
            {isMobileScreen && <img src={product.images.nodes[0]?.src} alt={product.title} width="auto" height={70} />}
            <Stack gap={{ xs: 0.5, md: 1 }}>
              <TextLink to={`/products/${id}`}>
                <Typography fontSize={{ xs: 16, md: 18 }}>{product.title}</Typography>
              </TextLink>

              {product.descriptionHtml && (
                <ReadMoreText markup fontFamily={fonts.body} fontSize={{ xs: 14, md: 16 }} align="left">
                  {product.descriptionHtml}
                </ReadMoreText>
              )}
            </Stack>
            {!isMobileScreen && <TextLink to={`/profile/dashboard/${getIdNumber(product.id)}/edit`}>Edit</TextLink>}
          </Stack>
          {!isMobileScreen && (
            <Stack alignItems="flex-start">
              <ShareIcons
                text={`Check out my piece on GoodMood - ${product.title}`}
                pageUrl={`https://www.goodmoodprints.com/products/${product.handle}`}
                isMobileScreen={isMobileScreen}
              />
            </Stack>
          )}

          <Stack gap={4}>
            <Stats user={user} product={product} updating={updatingProduct} />
            <Checkbox
              label="This piece is exclusive to GoodMood (30% commission, instead of 20%)"
              onChange={handleExclusivityChange}
              defaultChecked={product.exclusiveToGoodMood?.value === "true"}
              disabled={updatingProduct}
            />
          </Stack>
        </Stack>
      </Box>

      <Stack width="50%" bgcolor={colors.cardGrey} paddingY={10} alignItems="center" display={{ xs: "none", md: "flex" }}>
        <img src={product.images.nodes[0]?.src} alt={product.title} width="50%" height="auto" />
      </Stack>

      {shareBarOpen && (
        <ShareBar
          text={`Check out my piece on GoodMood - ${product.title}`}
          pageUrl={`https://www.goodmoodprints.com/products/${product.handle}`}
          setShareBarOpen={setShareBarOpen}
          isMobileScreen={isMobileScreen}
        />
      )}
    </Stack>
  );
};

export default ProductBreakdown;
