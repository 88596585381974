export const gmcIds = [
  8184651186390, 8744155709654, 8013173915862, 8132849926358, 8099174613206, 7938069954774, 7945849307350, 7903583830230,
  7903571083478, 8029997924566, 8029995958486, 7903582945494, 7903581044950, 7945849962710, 8099179299030, 7735542218966,
  8033719288022, 7938071101654, 8025505759446, 8079850045654, 8131230466262, 7945851568342, 7945850978518, 7970095857878,
  7903582257366, 7970518204630, 7903581602006, 7853998964950, 7723064000726, 7974925009110, 8054583918806, 8148169556182,
  8169800171734, 8148173422806, 8135859044566, 8087476306134, 8300357615830, 8300356862166, 8300355682518, 8299460821206,
  8299456037078, 8299445027030, 8299437228246, 8299433885910, 8299426775254, 8299414618326, 8299394072790, 8298834067670,
  8298701422806, 8298540335318, 8298538533078, 8298513236182, 8298452254934, 8298361356502, 8298344087766, 8297796108502,
  8297756819670, 8297753706710, 8297752527062, 8297749938390, 8297747644630, 8297718939862, 8296815067350, 8296760246486,
  8296759263446, 8296672592086, 8296669675734, 8296603222230, 8296582447318, 8296580808918, 8295819411670, 8295819215062,
  8295577845974, 8294134153430, 8294126878934, 8294108823766, 8294103843030, 8291295527126, 8291283599574, 8291270983894,
  8290604482774, 8290396471510, 8290198290646, 8290197536982, 8290070462678, 8289494892758, 8289487782102, 8289484767446,
  8289285472470, 8288670777558, 8288657244374, 8288449069270, 8287651135702, 8287427625174, 8286255579350, 8286245486806,
  8285437264086, 8285432873174, 8285421699286, 8285404823766, 8285195796694, 8285181378774, 8285178396886, 8285174530262,
  8284689301718, 8283513716950, 8283215560918, 8283121189078, 8283100676310, 8282921074902, 8282269679830, 8282268270806,
  8282267713750, 8281332187350, 8280147886294, 8280137269462, 8280106533078, 8280078483670, 8279092854998, 8279089840342,
  8278900670678, 8278895624406, 8278683091158, 8278682337494, 8278681223382, 8278680961238, 8278275457238, 8277709357270,
  8277696839894, 8277632057558, 8277630681302, 8277628223702, 8277624193238, 8277623341270, 8277622161622, 8277620457686,
  8277619081430, 8277385674966, 8277377286358, 8277369192662, 8277256306902, 8276348567766, 8276343193814, 8276337426646,
  8276330381526, 8276321009878, 8276317503702, 8276308656342, 8276039205078, 8276016333014, 8276002767062, 8276002406614,
  8276001685718, 8276000211158, 8275950600406, 8275942375638, 8275940475094, 8275925958870, 8275921043670, 8275917439190,
  8275447316694, 8274618319062, 8274617729238, 8274616975574, 8273515217110, 8273510858966, 8273083990230, 8271178399958,
  8271169749206, 8271168176342, 8271165751510, 8271146713302, 8271145271510, 8271133147350, 8270569570518, 8268661424342,
  8268661162198, 8266916462806, 8266912825558, 8266912235734, 8266903912662, 8266896408790, 8266893852886, 8266891821270,
  8266877173974, 8266774184150, 8266773725398, 8266773201110, 8265381511382, 8264155168982, 8263667810518, 8263667712214,
  8263667548374, 8263667384534, 8263200047318, 8263199949014, 8263199785174, 8263199719638, 8263199391958, 8262568050902,
  8261188255958, 8261187535062, 8261186715862, 8257807876310, 8257807057110, 8257403814102, 8257020559574, 8256535003350,
  8256533790934, 8254926815446, 8254925111510, 8254922752214, 8254921081046, 8254920196310, 8253907009750, 8253502030038,
  8253497147606, 8253493510358, 8252787491030, 8252785262806, 8252782870742, 8252780249302, 8249188712662, 8249155059926,
  8249106464982, 8249103155414, 8249097945302, 8249093554390, 8249085821142, 8249081725142, 8249076547798, 8249065472214,
  8248645910742, 8248642535638, 8248639422678, 8248634310870, 8248631263446, 8248619991254, 8247417864406, 8247402528982,
  8247396958422, 8247394631894, 8247386407126, 8245586854102, 8245084782806, 8245083504854, 8245081637078, 8244727447766,
  8244054524118, 8244054163670, 8244053508310, 8243633815766, 8243631259862, 8243566674134, 8243551699158, 8243527319766,
  8243519652054, 8243512279254, 8243510149334, 8243509002454, 8243492651222, 8243489439958, 8243434488022, 8243423412438,
  8243421741270, 8243418661078, 8242784108758, 8242775818454, 8242771558614, 8242770247894, 8242769330390, 8242765955286,
  8242760384726, 8242758680790, 8242747998422, 8242745213142, 8242662637782, 8242543755478, 8242309202134, 8242292261078,
  8242289049814, 8242259919062, 8242144641238, 8241814896854, 8241682219222, 8699858550998, 8699732590806, 8712905064662,
  8707411378390, 8699731017942, 8699332853974, 8699331412182, 8706544861398, 8707107389654, 8707412525270, 8699345699030,
  8699861205206, 8699733311702, 8699596341462, 8699596931286, 8699597619414, 8699598209238, 8699599356118, 8706593816790,
  8706594308310, 8706563899606, 8707103260886, 8707112304854, 8707415245014, 8699734491350, 8699343372502, 8699735965910,
  8699736817878, 8699740258518, 8699844165846, 8699844853974, 8699845574870, 8699859632342, 8700186067158, 8699861827798,
  8699861860566, 8699860943062, 8707414130902, 8699337507030, 8699340161238, 8706540241110, 8717303611606, 8716460261590,
  8712180728022, 8710383272150, 8709547524310, 8709542183126, 8705880916182, 8704076021974, 8702683087062, 8701444784342,
  8700647997654, 8699323187414, 8698304757974, 8691223396566, 8688710975702, 8687687631062, 8686011515094, 8684116967638,
  8682389930198, 8744163737814, 8743704854742, 8743690535126, 8743428784342, 8743387627734, 8742569312470, 8742567248086,
  8742564692182, 8742546243798, 8742378406102, 8741017125078, 8741014438102, 8741011062998, 8739016376534, 8738863415510,
  8738819866838, 8738815836374, 8738725855446, 8738723987670, 8738721693910, 8738719072470, 8738465906902, 8738320023766,
  8738319270102, 8738317992150, 8738317467862, 8738316812502, 8738315894998, 8738315174102, 8738314125526, 8738309603542,
  8738306261206, 8738304950486, 8738302132438, 8734569627862, 8734164517078, 8734159536342, 8733153755350, 8733153689814,
  8733147005142, 8733146874070, 8733146841302, 8733146808534, 8733146775766, 8733146546390, 8733146448086, 8733146251478,
  8733146054870, 8731644297430, 8731621949654, 8731608350934, 8731598291158, 8731589902550, 8731578958038, 8731552448726,
  8731521188054, 8731508474070, 8731498414294, 8731488911574, 8731479081174, 8731453259990, 8731445362902, 8731388641494,
  8731374354646, 8731370291414, 8731362361558, 8727705616598, 8727702634710, 8727564222678, 8724895760598, 8724893827286,
  8724010336470, 8723995459798, 8723989725398, 8723963314390, 8723954401494, 8723333021910, 8723330597078, 8722450645206,
  8722444157142, 8722440913110, 8722439700694, 8722439012566, 8722434162902, 8722433409238, 8721264115926, 8720924410070,
  8720388784342, 8241503076566, 8241500520662, 8241498947798, 8225517109462, 8225496793302, 8152039588054, 8135858553046,
  8135842300118, 14804273299834, 14804272644474, 14804156711290, 14804156612986, 14804156383610, 14804156285306, 14804156187002,
  14804156055930, 14766298726778, 14766298595706, 14766298104186, 14766298071418, 14766298038650, 14766297973114, 14766297940346,
  14766297481594, 14766297317754, 14766297252218, 14766297219450, 14766297055610, 14766297022842, 14766296957306, 14766296924538,
  14766296891770, 14766296793466, 14766296662394, 14766272184698, 14766271201658, 14751020712314, 14751020646778, 14751020614010,
  14751020351866, 14751020253562, 14751020220794, 14751019925882, 14751019893114, 14751019827578, 14751019565434, 14751019401594,
  14751019368826, 14751019303290, 14751018975610, 14751018221946, 14751017107834, 14751005049210, 14751001739642, 14750986830202,
  14750978474362, 14750594498938, 14750594236794, 14750572740986, 14750567334266, 14750559273338, 14750334419322, 14750333239674,
  14750331273594, 14750013292922, 14750013194618, 14750013161850, 14750013096314, 14750011654522, 14750011130234, 14750010573178,
  14750008738170, 14750008705402, 14750008672634, 14750008607098, 14750006051194, 14750004871546, 14750002315642, 14749996220794,
  14749994090874, 14749991010682, 14749986783610, 14749976822138, 14749682336122, 14749678895482, 14749676274042, 14749668999546,
  14749663691130, 14749642686842, 14749640294778, 14749599564154, 14749594321274, 14749526753658, 14749523771770, 14749520757114,
  14749516956026, 14749512925562, 14749511385466, 14749504209274, 14749497786746, 14749496934778, 14749459054970, 14749394043258,
  14749225386362, 14749219848570, 14749215588730, 14749212049786, 14749201695098, 14749201301882, 14749200908666, 14749192978810,
  14748736553338, 14748736061818, 14748735635834, 14748219539834, 14748218556794, 14748217803130, 14748215968122, 14748199682426,
  14747095662970, 14746876445050, 14746875789690, 14746875494778, 14746871988602, 14746871366010, 8766855381206, 8766829560022,
  8766829527254, 8766825693398, 8766691934422, 8766652285142, 8766520459478, 8766178001110, 8765662167254, 8765641326806,
  8765640933590, 8765639098582, 8765637787862, 8765635789014, 8765631889622, 8765630677206, 8765627957462, 8765622223062,
  8765621240022, 8765620256982, 8765619536086, 8765618815190, 8765616619734, 8765615800534, 8765615636694, 8765615309014,
  8765614653654, 8765612261590, 8765611180246, 8765610328278, 8765608526038, 8765606822102, 8765605707990, 8765603578070,
  8765455139030, 8765454581974, 8765454188758, 8765128638678, 8765124509910, 8765084762326, 8765078896854, 8765066117334,
  8765063561430, 8765063266518, 8765030006998, 8764912074966, 8764910665942, 8764321693910, 8763571175638, 8763473821910,
  8763469529302, 8763463663830, 8763458879702, 8763456979158, 8763454685398, 8763453604054, 8763444592854, 8763404091606,
  8763403272406, 8761654771926, 8761247727830, 8761247203542, 8761246974166, 8760043307222, 8759768187094, 8759768023254,
  8759766810838, 8759483859158, 8759465771222, 8756402487510, 8755753353430, 8755719864534, 8753601544406, 8753217339606,
  8752949002454, 8752930128086, 8752475930838, 8752468623574, 8752396435670, 8752388309206, 8752275423446, 8752261693654,
  8752260874454, 8752111091926, 8752097001686, 8752068329686, 8752052568278, 8752050307286, 8751962292438, 8751620423894,
  8750759510230, 8750758559958, 8750743093462, 8750295679190, 8749834600662, 8749780435158, 8749529891030, 8750758559958,
  8750743093462, 8750295679190, 8749834600662, 8749780435158, 8749529891030, 14869108261242, 14869107278202, 14869104820602,
  14869104329082, 14869102264698, 14869100265850, 14869099774330, 14869099250042, 14869096530298, 14869096071546, 14869095678330,
  14867877593466, 14867877429626, 14866738741626, 14866734907770, 14866441929082, 14866441077114, 14866333892986, 14866324095354,
  14865821401466, 14865659724154, 14865642324346, 14865164304762, 14865138024826, 14865129767290, 14864464281978, 14864462152058,
  14863013773690, 14863008301434, 14863005385082, 14861945602426, 14861774979450, 14861592002938, 14861547929978, 14861387628922,
  14861330940282, 14860898173306, 14860886638970, 14859410243962, 14858497425786, 14858479698298, 14858030350714, 14857966190970,
  14857965568378, 14857964487034, 14857687859578, 14848415826298, 14848414581114, 14848413532538, 14848412254586, 14848411238778,
  14848410255738, 14848409043322, 14848408486266, 14848239534458, 14848236061050, 14848202473850, 14848196837754, 14848193134970,
  14848186876282, 14848177734010, 14839219716474, 14834904138106, 14834893324666, 14834845188474, 14834795708794, 14834786042234,
  14830491140474, 14829578289530, 14829573669242, 14811690566010, 14811043529082, 14810996572538, 14810992312698, 14810903019898,
  14810844234106, 14810831356282, 14810585596282, 14810583105914, 14809634242938, 14809565200762, 14808804557178, 14807709352314,
  14807546528122, 14807145480570, 14807139025274, 14807112974714, 14807110320506, 14807105372538, 14807102685562, 14807100031354,
  14807088759162, 14807084204410, 14806946152826, 14806943334778, 14806940516730, 14806939959674, 14806647832954, 14806526951802,
  14806074196346, 14806069805434, 14805603123578, 14805525660026, 14804157170042, 14804156580218, 14804156547450, 14804156514682,
  14804156449146, 14804156350842, 14804156154234, 14900967932282, 14900958560634, 14900954857850, 14900952564090, 14900913799546,
  14900911997306, 14900911538554, 14900501807482, 14900480508282, 14899902284154, 14899768295802, 14899745849722, 14899744080250,
  14899743981946, 14899640893818, 14899638829434, 14898337546618, 14897987748218, 14897985945978, 14897976672634, 14897315053946,
  14896888316282, 14896888217978, 14896888185210, 14895958950266, 14893276037498, 14892176212346, 14890057924986, 14890044424570,
  14889712025978, 14889419047290, 14889415901562, 14889380282746, 14889372877178, 14887204028794, 14886894502266, 14886894371194,
  14886894338426, 14886894305658, 14886894240122, 14886298878330, 14886292259194, 14886290719098, 14886094012794, 14885769150842,
  14885761483130, 14885753422202, 14885746508154, 14885665833338, 14885663637882, 14885663080826, 14885654167930, 14885650760058,
  14885650301306, 14885648138618, 14885647614330, 14885646958970, 14885644861818, 14885644403066, 14885643846010, 14885641650554,
  14885641126266, 14885637390714, 14885635096954, 14885628707194, 14885628117370, 14885625823610, 14885625201018, 14885622448506,
  14885472043386, 14885466472826, 14885462311290, 14885460181370, 14885410144634, 14885407752570, 14885403656570, 14885400805754,
  14885391106426, 14885385830778, 14885378163066, 14885344739706, 14885340086650, 14885336285562, 14884873666938, 14883977658746,
  14880939934074, 14880301875578, 14878558585210, 14878330126714, 14878327865722, 14878326456698, 14878323933562, 14878322950522,
  14878319346042, 14877681385850, 14877681025402, 14877639246202, 14876330131834, 14876320006522, 14869842592122, 14869836366202,
  14869665481082, 14869614920058, 14869349499258, 14867699695994, 14867502104954, 14915598909818, 14915598221690, 14915597828474,
  14915595600250, 14915594944890, 14915594518906, 14915592585594, 14901199077754, 14900967932282, 14897985945978, 14878300406138,
  14877681385850, 14918951338362, 14914130837882, 14911448482170, 14907979366778, 14868745650554, 14867670892922, 14867654246778,
  14867370443130, 14864292446586, 14864289857914, 14864289563002, 14860805767546, 14860794167674, 14860791185786, 14858896540026,
  14858894508410, 14858892444026, 14858891559290, 14858889560442,
];

export const usdGmcIds = [
  7723064000726, 7735542218966, 7903581044950, 7903582945494, 7903583830230, 7938069954774, 7938071101654, 7945849307350,
  7945849962710, 7945850978518, 7945851568342, 7946331914454, 7970095857878, 7970518204630, 7970523250902, 8013173915862,
  8029995958486, 8029997924566, 8033719288022, 8099174613206, 8132849926358, 8135842300118, 8135859044566, 8184651186390,
  8744155709654,
];

export const bestsellingIds = [
  7945849307350, 7903581044950, 8029995958486, 7903582945494, 7903583830230, 8029997924566, 7970518204630, 7970095857878,
  8744155709654, 7938069954774, 7945849962710, 8135842300118, 7945851568342, 8099174613206, 7945850978518, 8033719288022,
  7735542218966, 8013173915862, 7938071101654, 8135859044566, 7970523250902, 8184651186390, 7723064000726, 7946331914454,
  8709542183126,
];
