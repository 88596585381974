import { format } from "date-fns";
import { uniqueId } from "lodash";
import { IconButton, Stack, Typography } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { ContentCopyRounded } from "@mui/icons-material";
import momentData from "moment-timezone/data/meta/latest.json";
import { User } from "../../../types/user";
import TextLink from "../../../components/TextLink";
import Search from "../Search";
import { fonts } from "../../../theme";
import { dataGridStyles } from "../styles";

type Props = {
  users?: User[];
  loading: boolean;
  artistSearchValue: string;
  setArtistSearchValue: (value: string) => void;
};

const Artists = ({ users, loading, artistSearchValue, setArtistSearchValue }: Props) => {
  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
      renderCell: ({ row }) => (
        <Stack
          direction="row"
          alignItems="center"
          onClick={() => navigator.clipboard.writeText(row.email)}
          sx={{ cursor: "pointer" }}
        >
          <Typography fontSize={14} fontFamily={fonts.body}>
            {row.email}
          </Typography>
          <IconButton>
            <ContentCopyRounded color="primary" sx={{ fontSize: 16 }} />
          </IconButton>
        </Stack>
      ),
    },
    {
      field: "paypalEmail",
      headerName: "Paypal Email",
      flex: 1,
      renderCell: ({ row }) => (
        <Stack
          direction="row"
          alignItems="center"
          onClick={() => navigator.clipboard.writeText(row.paypalEmail)}
          sx={{ cursor: "pointer" }}
        >
          <Typography fontSize={14} fontFamily={fonts.body}>
            {row.paypalEmail}
          </Typography>
          <IconButton>
            <ContentCopyRounded color="primary" sx={{ fontSize: 16 }} />
          </IconButton>
        </Stack>
      ),
    },
    {
      field: "city",
      headerName: "City",
    },
    {
      field: "country",
      headerName: "Country",
    },
    {
      field: "vatNumber",
      headerName: "VAT/GST Number",
    },
    {
      field: "lastUploaded",
      headerName: "Last Uploaded",
      renderCell: (params) => (
        <Typography fontSize={14} fontFamily={fonts.body}>
          {params.row.lastUploaded ? format(params.row.lastUploaded.toDate(), "do MMM yyyy") : ""}
        </Typography>
      ),
    },
    {
      field: "artistPage",
      headerName: "Profile Url",
      renderCell: (params) => (
        <TextLink openInNewTab href={`${process.env.REACT_APP_SITE_URL}/artists/${params.row.permalink}`}>
          <Typography fontSize={14} fontFamily={fonts.body}>
            View
          </Typography>
        </TextLink>
      ),
    },
    {
      field: "ghost",
      headerName: "Ghost",
      renderCell: (params) => (
        <TextLink to={`/?ghost=${params.row.artistId}`} openInNewTab>
          <Typography fontSize={14} fontFamily={fonts.body}>
            Ghost
          </Typography>
        </TextLink>
      ),
    },
  ];

  const formattedUsers = (users || []).map(
    ({ id, permalink, firstName, lastName, email, paypalEmail, city, country, vatRegistered, lastUploaded }) => ({
      id: uniqueId(),
      artistId: id,
      permalink,
      name: firstName + " " + lastName,
      email,
      paypalEmail,
      city,
      // @ts-ignore
      country: country && momentData.countries[country]?.name,
      vatNumber: vatRegistered?.vatNumber,
      lastUploaded,
      artistPage: `${process.env.REACT_APP_SITE_URL}/artists/${id}`,
    })
  );

  return (
    <Stack gap={2}>
      <Stack direction="row" justifyContent="flex-end">
        <Search inputValue={artistSearchValue} setInputValue={setArtistSearchValue} />
      </Stack>
      <DataGrid
        loading={loading}
        rows={formattedUsers}
        columns={columns}
        pageSizeOptions={[]}
        style={{ height: loading ? "70vh" : "auto", minHeight: "70vh" }}
        sx={dataGridStyles}
      />
    </Stack>
  );
};

export default Artists;
