import { useContext, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Box, Drawer, IconButton, Stack, Typography } from "@mui/material";
import { CheckRounded, CloseRounded, ContentCopyRounded, LockRounded } from "@mui/icons-material";
import { currencyToLocale, formattedPrice } from "../../../helpers/money";
import { useAppState } from "../../../state";
import { colors, fonts } from "../../../theme";
import Button from "../../Button";
import { Icon } from "../../Icon";
import { Container } from "../../Layout";
import { Text } from "../../Text";
import { trackEvent } from "../../../helpers/analytics";
import AuthContext from "../../../state/auth";
import { BasketFragment } from "../../../generated/storefront";
import BasketDrawerItem from "./BasketDrawerItem";
import TextLink from "../../TextLink";

type ContentProps = {
  basket?: BasketFragment;
  onClose: () => void;
  viewOnly?: boolean;
};

type Props = {
  open: boolean;
  basket?: BasketFragment;
  onClose: () => void;
  viewOnly?: boolean;
};

const StickyBottom = styled.div`
  position: sticky;
  width: 100%;
  bottom: 0;
  background-color: ${colors.white};
`;

const Bar = styled.div<{ color: string; opacity: number }>`
  width: 100%;
  height: 4px;
  border-radius: 100px;
  background-color: ${({ color }) => color};
  opacity: ${({ opacity }) => opacity};
`;

const FreeDeliveryBar = styled.div<{ percent: number }>`
  width: 100%;
  height: 4px;
  border-radius: 100px;
  background-color: ${colors.grey10};

  &::after {
    content: "";
    display: block;
    width: ${({ percent }) => percent}%;
    height: 100%;
    border-radius: 100px;
    background-color: ${({ percent }) => (percent === 100 ? colors.green : colors.red)};
  }
`;

const BasketContent = ({ basket, onClose, viewOnly }: ContentProps) => {
  const { freeDeliveryThreshold, inFreeDeliveryCountry } = useAppState();
  const { isAdmin } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const itemCount = basket?.lines?.nodes.length || 0;
  const disableEdit = viewOnly && !isAdmin;

  if (basket && itemCount > 0) {
    const items = basket.lines.nodes;
    const basketMainItems =
      basket?.lines.nodes.filter(
        (l) => l.merchandise.product.handle !== "mounting" && l.merchandise.product.handle !== "frame"
      ) || [];
    const basketCount = basketMainItems.reduce((total, i) => total + i.quantity, 0);
    const basketSubtotal = basket.cost.subtotalAmount;
    const basketSubtotalAmount = basket.lines.nodes.reduce((total, i) => total + Number(i.cost.subtotalAmount.amount), 0);
    const basketTotalAmount = basket.lines.nodes.reduce((total, i) => total + Number(i.cost.totalAmount.amount), 0);
    const basketTotal = basket.cost.totalAmount;
    const basketSubtotalPrice = formattedPrice(basketSubtotalAmount, basketSubtotal.currencyCode);
    const discountApplied = basketSubtotalAmount > basketTotalAmount;
    const freeDeliveryDifference = freeDeliveryThreshold - basketTotalAmount;
    const freeDeliveryPercentage = Math.min((basketTotalAmount / freeDeliveryThreshold) * 100, 100);

    const onCheckout = () => {
      if (basket) {
        setLoading(true);
        trackEvent("InitiateCheckout");
        setTimeout(() => {
          setLoading(false);
        }, 2000);
      }
    };

    return (
      <>
        <Stack height="calc(100% - 76px)">
          <Box flex={1}>
            {items.map((item) =>
              item.merchandise.product.handle === "mounting" || item.merchandise.product.handle === "frame" ? null : (
                // @ts-ignore
                <BasketDrawerItem item={item} basket={basket} disableEdit={disableEdit || false} onClose={onClose} />
              )
            )}
          </Box>
          <StickyBottom>
            <Link to="/shop" style={{ width: "100%" }}>
              <Box paddingX={2} paddingY={1.5} borderTop={`1px solid ${colors.grey10}`}>
                <Stack direction="row" gap={1.5} alignItems="center">
                  <Stack sx={{ opacity: basketCount > 1 ? 0.2 : 1 }}>
                    <Icon icon="checkmark" size={16} width={22.84} fill={colors.green} />
                  </Stack>
                  <Bar color={basketCount > 1 ? colors.green : colors.grey10} opacity={basketCount > 2 ? 0.2 : 1} />
                  {basketCount > 1 ? (
                    <Stack sx={{ opacity: basketCount > 2 ? 0.2 : 1 }}>
                      <Icon icon="checkmark" size={16} fill={colors.green} />
                    </Stack>
                  ) : (
                    <LockRounded fontSize="small" color="disabled" />
                  )}
                  <Bar color={basketCount > 2 ? colors.green : colors.grey10} opacity={1} />
                  {basketCount > 2 ? (
                    <Stack>
                      <Icon icon="checkmark" size={16} fill={colors.green} />
                    </Stack>
                  ) : (
                    <LockRounded fontSize="small" color="disabled" />
                  )}
                </Stack>
                <Stack direction="row" alignItems="start" justifyContent="space-between" gap={2} paddingTop={0.5}>
                  <Stack flex={1} sx={{ opacity: basketCount > 1 ? 0.2 : 1 }}>
                    <Typography align="left" fontSize={12} fontFamily={fonts.disclaimer} color={colors.green} whiteSpace="nowrap">
                      1 item
                    </Typography>
                  </Stack>

                  <Stack flex={1} sx={{ opacity: basketCount > 2 ? 0.2 : 1 }}>
                    <Typography
                      align="center"
                      fontSize={12}
                      fontFamily={fonts.disclaimer}
                      color={basketCount >= 2 ? colors.green : colors.grey40}
                      whiteSpace="nowrap"
                    >
                      2 items
                    </Typography>
                    <Typography
                      align="center"
                      fontSize={12}
                      fontFamily={fonts.disclaimer}
                      color={basketCount >= 2 ? colors.green : colors.black}
                      whiteSpace="nowrap"
                      flex={1}
                    >
                      15% off
                    </Typography>
                  </Stack>

                  <Stack flex={1}>
                    <Typography
                      align="right"
                      fontSize={12}
                      fontFamily={fonts.disclaimer}
                      color={basketCount >= 3 ? colors.green : colors.grey40}
                      whiteSpace="nowrap"
                    >
                      3 items
                    </Typography>
                    <Typography
                      align="right"
                      fontSize={12}
                      fontFamily={fonts.disclaimer}
                      color={basketCount >= 3 ? colors.green : colors.black}
                      whiteSpace="nowrap"
                      flex={1}
                    >
                      20% off
                    </Typography>
                  </Stack>
                </Stack>
              </Box>
            </Link>

            {inFreeDeliveryCountry && (
              <Box paddingX={2} paddingY={1.5} borderTop={`1px solid ${colors.grey10}`}>
                <FreeDeliveryBar percent={freeDeliveryPercentage} />
                <Typography
                  align="center"
                  fontSize={12}
                  fontFamily={fonts.disclaimer}
                  lineHeight="20px"
                  paddingTop={1}
                  color={freeDeliveryPercentage === 100 ? colors.green : colors.black}
                >
                  {freeDeliveryDifference > 0
                    ? `Spend ${formattedPrice(freeDeliveryDifference, basketSubtotal.currencyCode)} more for free delivery`
                    : `Free delivery applied (${formattedPrice(freeDeliveryThreshold, basketSubtotal.currencyCode, false)})`}
                </Typography>
              </Box>
            )}

            <Box
              paddingX={{ xs: 2, md: 3 }}
              paddingY={1.5}
              borderTop={`1px solid ${colors.grey10}`}
              borderBottom={`1px solid ${colors.grey10}`}
            >
              <Stack direction="row" alignItems="center" justifyContent="space-between">
                <Text size={18}>Subtotal</Text>
                <Stack direction="row">
                  {discountApplied && (
                    <Text margin="0 6px 0 0">{formattedPrice(basketTotalAmount, basketTotal.currencyCode)}</Text>
                  )}
                  <Text linethrough={discountApplied}>{basketSubtotalPrice}</Text>
                </Stack>
              </Stack>
            </Box>
            <Box padding={{ xs: 2, md: 3 }} marginBottom={{ xs: window.innerHeight === window.outerHeight ? 0 : 4, md: 0 }}>
              <Stack gap={2}>
                <a href={basket.checkoutUrl}>
                  <Button onClick={onCheckout} fullWidth loading={loading}>
                    Checkout
                  </Button>
                </a>

                {/* @ts-ignore */}
                <klarna-placement
                  id="klarna-placement-credit-promotion-badge"
                  data-key="credit-promotion-badge"
                  data-locale={currencyToLocale(basketTotal.currencyCode)}
                  data-purchase-amount={`${basketTotalAmount * 100}`}
                >
                  {/* @ts-ignore */}
                </klarna-placement>
              </Stack>
            </Box>
          </StickyBottom>
        </Stack>
      </>
    );
  } else {
    return (
      <Container padding="145px 24px">
        <Stack justifyContent="center">
          <Typography fontSize={18} margin="0 0 16px" align="center">
            Your cart is empty
          </Typography>
          <Link to="/shop" style={{ width: "100%" }}>
            <Button onClick={onClose} fullWidth>
              Shop
            </Button>
          </Link>
        </Stack>
      </Container>
    );
  }
};

const BasketDrawer = ({ open, basket, onClose, viewOnly }: Props) => {
  const { setBasket } = useAppState();
  const [copied, setCopied] = useState(false);
  const basketMainItems =
    basket?.lines.nodes.filter((l) => l.merchandise.product.handle !== "mounting" && l.merchandise.product.handle !== "frame") ||
    [];
  const basketCount = basketMainItems.reduce((total, i) => total + i.quantity, 0);

  const shareBasketLink = () => {
    if (basket) {
      const url = `${window.location.origin}?basket=${basket.id}`;
      navigator.clipboard.writeText(url);
      setCopied(true);
      setTimeout(() => setCopied(false), 3000);
    }
  };

  const onClearBasket = () => {
    localStorage.removeItem("basketId");
    setBasket();
    onClose();
  };

  return (
    <Drawer anchor="right" open={open} onClose={onClose}>
      <Box width={{ xs: "90vw", md: "25vw" }} height="100vh" overflow="auto" minWidth={{ xs: "auto", md: "450px" }}>
        <Stack
          paddingY={2}
          paddingX={{ xs: 2, md: 3 }}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          borderBottom={`1px solid ${colors.grey10}`}
        >
          <Stack direction="row" gap={1.5} alignItems="center">
            <Typography variant="h4">Cart ({basketCount})</Typography>
            {basketCount > 0 && (
              <TextLink onClick={onClearBasket}>
                <Typography fontSize={14} variant="body2">
                  Clear
                </Typography>
              </TextLink>
            )}
          </Stack>
          <Stack direction="row" gap={1}>
            <IconButton
              onClick={shareBasketLink}
              disabled={!basket?.lines.nodes.length}
              style={{ backgroundColor: colors.grey02 }}
            >
              {copied ? (
                <CheckRounded color="primary" />
              ) : (
                <ContentCopyRounded color={basket?.lines.nodes.length ? "primary" : "disabled"} />
              )}
            </IconButton>
            <IconButton onClick={onClose} style={{ backgroundColor: colors.grey02 }}>
              <CloseRounded color="primary" />
            </IconButton>
          </Stack>
        </Stack>
        <BasketContent basket={basket} onClose={onClose} viewOnly={viewOnly} />
      </Box>
    </Drawer>
  );
};

export default BasketDrawer;
