import { useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { match } from "ts-pattern";
import { Box, Grid, Stack } from "@mui/material";
import { colors } from "../../theme";
import PageHeader from "../../components/PageHeader";
import galleryWallsImage from "../../assets/images/Inspiration.png";
import galleryWallsWebp from "../../assets/images/Inspiration.webp";
import { useGetGalleryWallCollectionsQuery } from "../../generated/storefront";
import CollectionCarousel from "./CollectionCarousel";
import { Loader } from "../../components/Loader";
import Switch from "../../components/Switch";
import { useAppState } from "../../state";
import CollectionCard from "../../components/Card/Collection";
import useLoadMoreOnScroll from "../../hooks/useLoadMoreOnScroll";
import Button from "../../components/Button";

const GalleryWalls = () => {
  const ref = useRef<HTMLDivElement>(null);
  const { isMobileScreen } = useAppState();
  const [selectedTab, setSelectedTab] = useState<"interiors" | "artwork">("interiors");
  const { data, loading, fetchMore } = useGetGalleryWallCollectionsQuery();
  const galleryWalls = data?.collections.nodes || [];
  const collections = galleryWalls.filter((collection) => !/\d/.test(collection.title));
  const pageInfo = data?.collections.pageInfo;
  const hasMore = pageInfo?.hasNextPage || false;

  const loadMore = async () => {
    if (!pageInfo?.hasNextPage || !pageInfo.endCursor) return;
    await fetchMore({
      variables: {
        afterCursor: pageInfo.endCursor,
      },
    });
  };

  useLoadMoreOnScroll(ref, loadMore, hasMore, loading);

  return (
    <>
      <Helmet>
        <title>Gallery Walls | GoodMood</title>
        <meta
          name="description"
          content="Explore our diverse collection of curated walls. Whether you choose to shop them as they are or seek inspiration to craft your own unique collection, we've got you covered."
        />
      </Helmet>
      <PageHeader
        header="Gallery Walls"
        background={colors.beige}
        image={galleryWallsImage}
        webpImage={galleryWallsWebp}
        imageLink="/collections/434403770582"
      >
        Explore our diverse collection of curated walls. Whether you choose to shop them as they are or seek inspiration to craft
        your own unique collection, we've got you covered.
      </PageHeader>
      <Box paddingY={3} ref={ref}>
        <Stack gap={3}>
          <Stack paddingX={{ xs: 2, md: 5 }} alignItems="center">
            <Switch
              fullWidth={isMobileScreen}
              options={[
                { label: "View interiors", value: "interiors" },
                { label: "View artwork", value: "artwork" },
              ]}
              selected={selectedTab}
              onChange={(tab) => setSelectedTab(tab as "interiors" | "artwork")}
            />
          </Stack>

          {loading ? (
            <Loader />
          ) : (
            match(selectedTab)
              .with("interiors", () => (
                <Box paddingX={{ xs: 2, md: 5 }}>
                  <Grid container spacing={3} rowSpacing={{ xs: 3, md: 9 }}>
                    {galleryWalls.map((collection) => (
                      <Grid item key={collection.id} xs={12} md={4}>
                        <CollectionCard collection={collection} imageHeight={isMobileScreen ? "90vw" : "30vw"} />
                      </Grid>
                    ))}
                  </Grid>
                  {loading ? (
                    <Loader />
                  ) : hasMore ? (
                    <Stack width="100%" alignItems="center" padding={5}>
                      <Button onClick={loadMore}>Load more</Button>
                    </Stack>
                  ) : null}
                </Box>
              ))
              .with("artwork", () => (
                <Stack gap={{ xs: 6, md: 9 }}>
                  {collections.map((collection) => (
                    <CollectionCarousel key={collection.id} collection={collection} />
                  ))}
                  {loading ? (
                    <Loader />
                  ) : hasMore ? (
                    <Stack width="100%" alignItems="center" padding={5}>
                      <Button onClick={loadMore}>Load more</Button>
                    </Stack>
                  ) : null}
                </Stack>
              ))
              .exhaustive()
          )}
        </Stack>
      </Box>
    </>
  );
};

export default GalleryWalls;
