import { Stack, Typography } from "@mui/material";
import styled from "styled-components";
import { colors } from "../../theme";

type Props = {
  name: string;
  value: string;
  checked: boolean;
  label: string;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
};

const Checkbox = styled.input`
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  margin: 0;
  cursor: pointer;

  &:hover + div {
    background-color: ${colors.grey10};
  }

  &:checked + div {
    background-color: ${colors.black};
    color: ${colors.white};
  }
`;

const StyledCheckboxButton = styled.div`
  width: 100%;
  padding: 13px 16px;
  color: ${colors.black};
  background-color: ${colors.cardGrey};
  border-radius: 100px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  line-height: 1;
  white-space: nowrap;
  text-align: center;
`;

const CheckboxButton = ({ name, value, checked, label, onChange }: Props) => {
  return (
    <Stack position="relative" alignItems="center" justifyContent="center" width="auto" minWidth={70}>
      <Checkbox type="checkbox" name={name} value={value} checked={checked} onChange={onChange} />
      <StyledCheckboxButton>
        <Typography fontSize={14} lineHeight={1}>
          {label}
        </Typography>
      </StyledCheckboxButton>
    </Stack>
  );
};

export default CheckboxButton;
