import { useCallback, useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet";
import { useSearchParams } from "react-router-dom";
import { compact } from "lodash";
import { Box, Stack, Typography } from "@mui/material";
import {
  CollectionSortKeys,
  SetProductFragment,
  useGetArtistProductsLazyQuery,
  useGetPrintSetCollectionsQuery,
  useGetProductByHandleQuery,
  useGetProductsForSetLazyQuery,
} from "../../generated/storefront";
import { Loader } from "../../components/Loader";
import { getAvailableSizes, getSizeAndFrame } from "../../helpers/product";
import CollectionItem from "./CollectionItem";
import Button from "../../components/Button";
import useBasketActions from "../../hooks/useBasketActions";
import { colors } from "../../theme";
import { Frame } from "../../types/product";
import { formattedPrice } from "../../helpers/money";
import { useAppState } from "../../state";
import { Carousel, CarouselItem } from "../../components/SnapCarousel";
import { PlainMockUp } from "../../components/MockUps";
import FrameMockUp from "../../components/FrameMockUp";
import sofaMockUp from "../../assets/images/mock-ups/sofa.webp";
import bedMockUp from "../../assets/images/mock-ups/bed.webp";
import ScrollCarousel from "../../components/ScrollCarousel";
import ProductCard from "../../components/ProductCard";
import { ArtistProductFragment } from "../../generated/storefront";
import PrintSetCard from "../PrintSets/PrintSetCard";
import { getIdNumber } from "../../helpers/shopify";

const PrintSet = () => {
  const [searchParams] = useSearchParams();
  const productIds = useMemo(() => searchParams.get("products")?.split(",") || [], [searchParams]);
  const { isMobileScreen, selectedCountry } = useAppState();
  const { addOrCreateBasket, loading: loadingBasket, error } = useBasketActions();
  const [selectedVariants, setSelectedVariants] = useState<Record<string, { variantId: string; frame: Frame }>>({});
  const [getProducts, { data, loading }] = useGetProductsForSetLazyQuery();
  const products = useMemo(() => data?.products?.nodes || [], [data]);
  const [getArtistProducts] = useGetArtistProductsLazyQuery();
  const [firstArtistProducts, setFirstArtistProducts] = useState<ArtistProductFragment[]>([]);
  const [secondArtistProducts, setSecondArtistProducts] = useState<ArtistProductFragment[]>([]);
  const { data: printSetsData } = useGetPrintSetCollectionsQuery({
    variables: {
      sortKey: CollectionSortKeys.Title,
      limit: 12,
      country: selectedCountry,
    },
  });
  const { data: frameProductData } = useGetProductByHandleQuery({
    fetchPolicy: "cache-and-network",
    variables: { handle: "frame", country: selectedCountry },
  });
  const frameProduct = useMemo(() => frameProductData?.product, [frameProductData]);

  const printSets = printSetsData?.collections?.nodes || [];

  const setSelectedVariant = (productId: string, variantId: string, frame: Frame) =>
    setSelectedVariants({ ...selectedVariants, [productId]: { variantId, frame } });

  const addAllItemsToBasket = () => {
    const items = Object.keys(selectedVariants).map((productId) => {
      const selectedVariantId = selectedVariants[productId].variantId;
      const product = products.find((p) => p.id === productId);
      if (!product) return null;
      const selectedVariant = product.variants.nodes.find((variant) => variant.id === selectedVariantId);
      if (!selectedVariant) return null;
      const { size } = getSizeAndFrame(selectedVariant.selectedOptions);
      return {
        merchandiseId: selectedVariantId,
        quantity: 1,
        frame: selectedVariants[product.id].frame,
        size,
        mounted: false,
        isGiftCard: false,
      };
    });
    addOrCreateBasket(compact(items));
  };

  const setArtistProducts = useCallback(async () => {
    const firstVendorId = products[0]?.vendor;
    const secondVendorId = products[1]?.vendor;
    const { data } = await getArtistProducts({
      variables: {
        limit: 10,
        query: `vendor:${firstVendorId}`,
        country: selectedCountry,
      },
    });
    const firstArtistProducts = data?.products?.nodes || [];
    setFirstArtistProducts(firstArtistProducts);

    if (firstVendorId !== secondVendorId) {
      const { data } = await getArtistProducts({
        variables: {
          limit: 10,
          query: `vendor:${secondVendorId}`,
          country: selectedCountry,
        },
      });
      const secondArtistProducts = data?.products?.nodes || [];
      setSecondArtistProducts(secondArtistProducts);
    }
  }, [getArtistProducts, products, selectedCountry]);

  useEffect(() => {
    setArtistProducts();
  }, [setArtistProducts]);

  useEffect(() => {
    getProducts({
      variables: {
        query: `id:${productIds.join(" OR ")}`,
        country: selectedCountry,
      },
    });
  }, [productIds, selectedCountry, getProducts]);

  useEffect(() => {
    const defaultVariants: Record<string, { variantId: string; frame: Frame }> = {};
    products.forEach((product) => {
      const defaultVariant = product.variants.nodes.find((variant) => {
        const { size, frame } = getSizeAndFrame(variant.selectedOptions);
        return size === getAvailableSizes(product.variants.nodes)[0] && frame === Frame.Unframed;
      });
      if (defaultVariant) defaultVariants[product.id] = { variantId: defaultVariant.id, frame: Frame.Black };
    });
    setSelectedVariants(defaultVariants);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products]);

  if (!data || loading)
    return (
      <Box padding={2}>
        <Loader />
      </Box>
    );

  const subtotal = products.reduce((acc, product) => {
    const variant = product.variants.nodes.find((v) => v.id === selectedVariants[product.id]?.variantId);
    const framePrice = Number(
      frameProduct?.variants.nodes.find(
        (variant) =>
          variant.selectedOptions[0].value === getSizeAndFrame(variant.selectedOptions).size &&
          variant.selectedOptions[1].value === selectedVariants[product.id]?.frame
      )?.price.amount || 0
    );
    return acc + (variant ? Number(variant.priceV2.amount) + framePrice : 0);
  }, 0);
  const currencyCode = products[0]?.variants.nodes[0].priceV2.currencyCode;
  const discountPct = 0.15;

  const getFrameColour = (product: SetProductFragment) => selectedVariants[product.id]?.frame || Frame.Black;

  const SofaSetMockUp = () => (
    <Stack width="100%" height={{ xs: "100%", md: "auto" }} position="relative">
      <img src={sofaMockUp} alt="plain wall" width="100%" height="100%" style={{ objectFit: "cover" }} />
      <Stack
        direction="row"
        gap={2}
        position="absolute"
        top="30%"
        left="50%"
        width="60%"
        padding={{ xs: 2, md: 5 }}
        sx={{ transform: "translate(-50%, -50%)" }}
      >
        <Box width="50%">
          <FrameMockUp frame={getFrameColour(products[0])} image={products[0].images.nodes[0]} shadow />
        </Box>

        <Box width="50%">
          <FrameMockUp frame={getFrameColour(products[1])} image={products[1].images.nodes[0]} shadow />
        </Box>
      </Stack>
    </Stack>
  );

  const BedSetMockUp = () => (
    <Stack width="100%" height={{ xs: "100%", md: "auto" }} position="relative">
      <img src={bedMockUp} alt="plain wall" width="100%" height="100%" style={{ objectFit: "cover" }} />
      <Stack
        direction="row"
        gap={2}
        position="absolute"
        top="30%"
        left="50%"
        width="60%"
        padding={{ xs: 2, md: 5 }}
        sx={{ transform: "translate(-50%, -50%)" }}
      >
        <Box width="50%">
          <FrameMockUp frame={getFrameColour(products[0])} image={products[0].images.nodes[0]} shadow />
        </Box>

        <Box width="50%">
          <FrameMockUp frame={getFrameColour(products[1])} image={products[1].images.nodes[0]} shadow />
        </Box>
      </Stack>
    </Stack>
  );

  return (
    <>
      <Helmet>
        <title>Print Set | GoodMood</title>
      </Helmet>
      <Stack direction={{ xs: "column", md: "row" }} width="100%">
        {products.length > 0 ? (
          <>
            <Stack flexBasis={{ xs: "100%", md: "50%" }}>
              {isMobileScreen ? (
                products.length > 0 && (
                  <Box height="50vh">
                    <Carousel
                      items={[
                        {
                          key: "sofa-mockup",
                          component: <SofaSetMockUp />,
                        },
                        {
                          key: "bed-mockup",
                          component: <BedSetMockUp />,
                        },
                        ...products.map((product) => {
                          return {
                            key: product.id,
                            component: <PlainMockUp image={product.images.nodes[0]} frame={getFrameColour(product)} isMobile />,
                          };
                        }),
                      ]}
                      renderItem={({ item, isSnapPoint }) => {
                        return (
                          <CarouselItem key={item.key} isSnapPoint={isSnapPoint}>
                            {item.component}
                          </CarouselItem>
                        );
                      }}
                    />
                  </Box>
                )
              ) : (
                <Stack gap={0.2}>
                  <SofaSetMockUp />
                  <BedSetMockUp />
                </Stack>
              )}
            </Stack>
            <Stack flexBasis={{ xs: "100%", md: "50%" }}>
              <Box overflow={{ xs: "initial", md: "auto" }} paddingTop={2}>
                <Stack gap={3}>
                  <Typography variant="h2" component="h1" paddingX={{ xs: 2, md: 5 }}>
                    Print Set
                  </Typography>
                  <Stack gap={2}>
                    {products.map((product, index) => (
                      <CollectionItem
                        product={product}
                        setSelectedVariant={setSelectedVariant}
                        lastChild={index === products.length - 1}
                      />
                    ))}
                  </Stack>
                </Stack>
                <Box
                  paddingY={1.5}
                  paddingX={{ xs: 2, md: 5 }}
                  bgcolor={colors.white}
                  position="sticky"
                  bottom={0}
                  borderTop={`1px solid ${colors.grey10}`}
                >
                  <Stack gap={{ xs: 1, md: 2 }} alignItems="center">
                    {products.length && (
                      <Stack gap={{ xs: 0.5, md: 1 }} direction="row" alignSelf="start">
                        <Typography
                          variant="h4"
                          alignSelf="start"
                          style={{ textDecoration: "line-through" }}
                          color={colors.grey60}
                        >
                          {formattedPrice(subtotal, currencyCode)}
                        </Typography>
                        <Typography variant="h4" color={colors.red}>
                          {formattedPrice(subtotal * (1 - discountPct), currencyCode)} · {discountPct * 100}% OFF
                        </Typography>
                      </Stack>
                    )}

                    <Button fullWidth onClick={addAllItemsToBasket} loading={loadingBasket}>
                      Add all to basket
                    </Button>
                    {error && <Typography color={colors.error}>{error}</Typography>}
                    <Typography variant="caption" align="center" color="text.secondary" fontSize={{ xs: 12, md: 14 }}>
                      No import fees for UK, EU, AUS & USA
                    </Typography>
                  </Stack>
                </Box>
              </Box>
            </Stack>
          </>
        ) : (
          <Box padding={2} width="100%">
            <Loader />
          </Box>
        )}
      </Stack>
      <Stack gap={{ xs: 6, md: 9 }} paddingY={{ xs: 6, md: 9 }}>
        {firstArtistProducts.length > 0 && firstArtistProducts[0].artistName?.value && (
          <Box>
            <ScrollCarousel header={`More from ${firstArtistProducts[0].artistName.value}`}>
              {firstArtistProducts?.map((product) => <ProductCard product={product} key={product.id} frame={Frame.Natural} />) ||
                []}
            </ScrollCarousel>
          </Box>
        )}
        {secondArtistProducts.length > 0 && secondArtistProducts[0].artistName?.value && (
          <Box>
            <ScrollCarousel header={`More from ${secondArtistProducts[0].artistName.value}`}>
              {secondArtistProducts?.map((product) => <ProductCard product={product} key={product.id} frame={Frame.Natural} />) ||
                []}
            </ScrollCarousel>
          </Box>
        )}
        {/* PRINT SETS */}
        <ScrollCarousel
          header="More Print Sets"
          minHeight={isMobileScreen ? "60%" : "450px"}
          cta={{
            link: "/print-sets",
            text: "View all",
          }}
          slideWidth={isMobileScreen ? "60%" : "450px"}
        >
          {printSets.map((printSet) => (
            <PrintSetCard
              key={printSet.id}
              printSet={{
                link: `/print-set?products=${getIdNumber(printSet.products.nodes[0].id)},${getIdNumber(
                  printSet.products.nodes[1].id
                )}`,
                product: printSet.products.nodes[0],
                complimentaryProduct: printSet.products.nodes[1],
              }}
              selectedFrame={Frame.Black}
              imageStyle="sofa"
            />
          )) || []}
        </ScrollCarousel>
      </Stack>
    </>
  );
};

export default PrintSet;
