import { BasketFragment, ProductPageProductFragment, ProductPageVariantFragment } from "../../generated/storefront";
import { getIdNumber } from "../../helpers/shopify";
import { User } from "../../types/user";

export const identifyCustomer = ({
  email,
  firstName,
  lastName,
}: {
  email: string;
  firstName?: string | null;
  lastName?: string | null;
}) => {
  // @ts-ignore
  const klaviyo = window.klaviyo || [];
  klaviyo.identify({
    email,
    $first_name: firstName,
    $last_name: lastName,
  });
};

export const trackViewedProduct = (
  product: ProductPageProductFragment,
  selectedVariant?: ProductPageVariantFragment,
  artist?: User | null
) => {
  // @ts-ignore
  const klaviyo = window.klaviyo || [];
  let item = {
    Name: product.title,
    ProductID: product.id.substring(product.id.lastIndexOf("/") + 1),
    ImageURL: product.images.nodes[0].src,
    Handle: product.handle,
    Brand: artist ? artist.firstName + " " + artist.lastName : "",
    Price: selectedVariant ? selectedVariant.price.amount : product.variants.nodes[0].price.amount,
    Metadata: {
      Brand: artist ? artist.firstName + " " + artist.lastName : "",
      Price: selectedVariant ? selectedVariant.price?.amount : product.variants.nodes[0].price?.amount,
    },
  };
  klaviyo.push(["track", "Viewed Product", item]);
  klaviyo.push(["trackViewedItem", item]);
};

export const trackAddedToCart = async ({ basket, addedItemId }: { basket: BasketFragment; addedItemId: string }) => {
  const lineItems = basket.lines.nodes.map((line) => {
    const { product } = line.merchandise;
    return {
      merchandiseId: line.merchandise.id,
      ProductID: product.id,
      SKU: line.merchandise.sku || "",
      ProductName: product.title,
      Quantity: line.quantity,
      ItemPrice: Number(line.cost.totalAmount.amount),
      RowTotal: Number(line.cost.totalAmount.amount),
      ProductURL: `https://www.goodmoodprints.com/products/${getIdNumber(product.id)}`,
      ImageURL: product.images.nodes[0]?.src || "",
    };
  });

  const addedItem = lineItems.find((line) => line.merchandiseId === addedItemId);

  if (!addedItem) {
    throw new Error("Could not find added item in basket");
  }
  // @ts-ignore
  window.klaviyo.push([
    "track",
    "Added to Cart",
    {
      $value: basket.cost.subtotalAmount.amount,
      AddedItemProductName: addedItem.ProductName,
      AddedItemProductID: addedItem.ProductID,
      AddedItemSKU: addedItem.SKU,
      AddedItemImageURL: addedItem.ImageURL,
      AddedItemURL: addedItem.ProductURL,
      AddedItemPrice: addedItem.ItemPrice,
      AddedItemQuantity: addedItem.Quantity,
      ItemNames: lineItems.map((line) => line.ProductName),
      CheckoutURL: basket.checkoutUrl,
      Items: lineItems,
    },
  ]);
};
