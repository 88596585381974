import { useEffect, useRef, useState } from "react";
import { useDebounce } from "react-use";
import { format, formatDate, parseISO } from "date-fns";
import { Stack, Box, Typography, Grid } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import Search from "../../Search";
import TextLink from "../../../../components/TextLink";
import { Image as ImageComp } from "../../../../components";
import { getUserById } from "../../../../services/Firebase";
import { getMonthsDate, getNextMonthsDate } from "../../../../helpers/time";
import { Loader } from "../../../../components/Loader";
import useLoadMoreOnScroll from "../../../../hooks/useLoadMoreOnScroll";
import {
  AdminProductFragment,
  ProductSortKeys,
  ProductStatus,
  useGetAdminProductsLazyQuery,
  useGetAdminProductsQuery,
} from "../../../../generated/graphql";
import ScrollCarousel from "../../../../components/ScrollCarousel";
import { Frame } from "../../../../types/product";
import Button from "../../../../components/Button";
import AdminProductCard from "../../../../components/ProductCard/AdminProductCard";
import Switch from "../../../../components/Switch";
import { match } from "ts-pattern";
import MonthPicker from "../../../../components/MonthPicker";
import { fonts } from "../../../../theme";
import { dataGridStyles } from "../../styles";
import FirebaseActions from "./FirebaseActions";

type Row = {
  id: string;
  title: string;
};

type Props = {
  month: Date;
  setMonth: (value: Date) => void;
  goToNextMonth: () => void;
  goToPreviousMonth: () => void;
};

const Products = ({ month, setMonth, goToNextMonth, goToPreviousMonth }: Props) => {
  const ref = useRef<HTMLDivElement>(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const [searchedProducts, setSearchedProducts] = useState<AdminProductFragment[]>();
  const [rows, setRows] = useState<Row[]>([]);
  const [selectedView, setSelectedView] = useState<"list" | "image">("list");
  const [getAdminProducts] = useGetAdminProductsLazyQuery();
  const {
    data,
    loading: loadingNextMonths,
    fetchMore,
    refetch,
  } = useGetAdminProductsQuery({
    variables: {
      query: `product_type:${format(month, "yyyy-MM") || getNextMonthsDate()} status:${ProductStatus.Active}`,
      sortKey: ProductSortKeys.CreatedAt,
      reverse: true,
      limit: 24,
    },
  });
  const nextMonthsProducts = data?.products?.nodes || [];
  const afterCursor = data?.products?.pageInfo?.endCursor;
  const hasMore = data?.products?.pageInfo?.hasNextPage || false;
  const { data: nextMonthsPicks } = useGetAdminProductsQuery({
    variables: {
      query: `product_type:${format(month, "yyyy-MM") || getNextMonthsDate()} tag:pick`,
      limit: 24,
    },
  });
  const nextMonthsPicksProducts = nextMonthsPicks?.products?.nodes || [];

  const loadMore = async () => {
    fetchMore({
      variables: {
        afterCursor,
      },
    });
  };

  useLoadMoreOnScroll(ref, loadMore, hasMore, loadingNextMonths);

  const columns: GridColDef[] = [
    {
      field: "image",
      headerName: "Image",
      flex: 1,
      renderCell: (params) => <ImageComp height="50px" width="auto" src={params.row.image} />,
    },
    {
      field: "title",
      headerName: "Title",
      flex: 1,
      renderCell: (params) => (
        <TextLink href={`/products/${params.row.handle}`} openInNewTab>
          <Typography fontSize={14} fontFamily={fonts.body}>
            {params.row.title}
          </Typography>
        </TextLink>
      ),
    },
    {
      field: "artist",
      headerName: "Artist",
      flex: 1,
      renderCell: (params) => (
        <TextLink href={`/artists/${params.row.artistPermalink}`} openInNewTab>
          <Typography fontSize={14} fontFamily={fonts.body}>
            {params.row.artist}
          </Typography>
        </TextLink>
      ),
    },
    {
      field: "collection",
      headerName: "Collection",
      flex: 1,
    },
    {
      field: "firebaseUrl",
      headerName: "Firebase Url",
      flex: 1,
      renderCell: (params) => <FirebaseActions id={params.row.id} />,
    },
  ];

  const searchProducts = async (search: string) => {
    setLoading(true);
    const { data } = await getAdminProducts({
      variables: {
        query: search ? `title:${search}*` : `product_type:${getMonthsDate(month)}`,
        limit: 20,
        sortKey: ProductSortKeys.CreatedAt,
        reverse: true,
      },
    });
    if (data?.products?.nodes) setSearchedProducts(data.products.nodes);
    setLoading(false);
  };

  const getRows = async (products: AdminProductFragment[]) => {
    const productPromises = products.map(async ({ id, title, handle, vendor, images, productType }) => {
      const artist = vendor ? await getUserById(vendor) : null;
      const image = images.nodes?.[0]?.src;
      const date = parseISO(`${productType}-01`);
      const collection = format(date, "MMMM yyyy");
      return {
        id,
        title,
        handle,
        image,
        collection,
        artist: `${artist?.firstName} ${artist?.lastName}`,
        artistPermalink: artist?.permalink || artist?.id,
      };
    });

    const rowsToBeSet = await Promise.all(productPromises);
    setRows(rowsToBeSet);
    setLoading(false);
  };

  useDebounce(() => searchProducts(searchQuery), 500, [searchQuery, month]);

  useEffect(() => {
    if (searchedProducts) {
      getRows(searchedProducts);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchedProducts]);

  return (
    <Stack gap={1}>
      <Switch
        selected={selectedView}
        options={[
          {
            label: "List",
            value: "list",
          },
          {
            label: "Image",
            value: "image",
          },
        ]}
        onChange={(value) => setSelectedView(value as "list" | "image")}
      />
      <Stack direction="row" gap={2} justifyContent="space-between">
        <MonthPicker
          month={month}
          setMonth={setMonth}
          loading={loading}
          goToNextMonth={goToNextMonth}
          goToPreviousMonth={goToPreviousMonth}
          showNextMonth
        />
        <Search inputValue={searchQuery} setInputValue={setSearchQuery} />
      </Stack>
      {match(selectedView)
        .with("list", () => (
          <DataGrid
            loading={loading}
            rows={rows}
            columns={columns}
            pageSizeOptions={[]}
            style={{ height: loading ? "70vh" : "auto", minHeight: "70vh" }}
            sx={dataGridStyles}
          />
        ))
        .with("image", () => (
          <>
            <ScrollCarousel
              header={month ? `${formatDate(month, "MMMM")}'s Picks` : "Next month's picks"}
              cta={{
                link: "/shop?collection=true",
                text: "Shop all",
              }}
              noPadding
              slideWidth="20%"
            >
              {nextMonthsPicksProducts?.map((product) => (
                <AdminProductCard product={product} key={product.id} frame={Frame.Unframed} />
              )) || []}
            </ScrollCarousel>

            <Box paddingY={2} ref={ref}>
              <Stack gap={2}>
                <Typography variant="h3">{month ? `${formatDate(month, "MMMM")}` : "Next month"}</Typography>
                <Box padding="0 0 72px">
                  <Grid container spacing={{ xs: 1, md: 2 }} rowGap={{ xs: 4, md: 9 }}>
                    {nextMonthsProducts.map((product) => (
                      <Grid item xs={6} md={3} xl={2.4} xxl={2} xxxl={1.5} key={product.id}>
                        <AdminProductCard product={product} frame={Frame.Unframed} refetch={refetch} />
                      </Grid>
                    ))}
                  </Grid>
                  {loadingNextMonths ? (
                    <Loader />
                  ) : hasMore ? (
                    <Stack width="100%" alignItems="center" padding={5}>
                      <Button onClick={loadMore}>Load more</Button>
                    </Stack>
                  ) : null}
                </Box>
              </Stack>
            </Box>
          </>
        ))
        .exhaustive()}
    </Stack>
  );
};

export default Products;
