import { match } from "ts-pattern";
import { breakpoints } from "../theme";
import { RectangleSize, Size, SquareSize } from "../types/product";

export const media = {
  s: `(max-width: ${breakpoints.s}px)`,
  m: `(max-width: ${breakpoints.m}px)`,
  l: `(max-width: ${breakpoints.l}px)`,
} as const;

export const mmDimensions = {
  [RectangleSize.A3]: { width: 297, height: 420 },
  [RectangleSize.A2]: { width: 420, height: 594 },
  [RectangleSize["20X28"]]: { width: 500, height: 700 },
  [RectangleSize.A1]: { width: 594, height: 841 },
  [RectangleSize["28X40"]]: { width: 700, height: 1000 },
  [SquareSize["12X12"]]: { width: 300, height: 300 },
  [SquareSize["20X20"]]: { width: 500, height: 500 },
  [SquareSize["28X28"]]: { width: 700, height: 700 },
};

export const mountingWidth = 50.8;

export const getFixedDimensions = (size: Size, isLandscape: boolean, isMobile: boolean) => {
  return match(isMobile)
    .with(true, () =>
      match(isLandscape)
        .with(true, () => ({ width: mmDimensions[size].height / 5, height: mmDimensions[size].width / 5 }))
        .with(false, () => ({ width: mmDimensions[size].width / 5, height: mmDimensions[size].height / 5 }))
        .exhaustive()
    )
    .with(false, () =>
      match(isLandscape)
        .with(true, () => ({ width: mmDimensions[size].height / 2.5, height: mmDimensions[size].width / 2.5 }))
        .with(false, () => ({ width: mmDimensions[size].width / 2.5, height: mmDimensions[size].height / 2.5 }))
        .exhaustive()
    )
    .exhaustive();
};
