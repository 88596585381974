import { getIdNumber } from "./shopify";
import { AdminOrderFragment, ArtistOrderFragment } from "../generated/graphql";

export const filterVendorItemsInOrders = (orders: AdminOrderFragment[] | ArtistOrderFragment[], vendorId: string) => {
  const vendorOrders = orders.map((x) => {
    return {
      ...x,
      lineItems: {
        nodes: x.lineItems.nodes.filter((i) => i.vendor === vendorId),
      },
    };
  });
  return vendorOrders;
};

export const getProductItemsFromOrders = (orders: AdminOrderFragment[] | ArtistOrderFragment[], productId: string) => {
  const productOrders = orders.map((x) => {
    return {
      ...x,
      lineItems: {
        nodes: x.lineItems.nodes.filter((i) => i.product && getIdNumber(i.product.id) === productId),
      },
    };
  });
  return productOrders.flatMap((order) => {
    const commissionValues = order.commission ? JSON.parse(order.commission.value) : {};
    return order.lineItems.nodes.map((i) => ({
      ...i,
      commissionValue: commissionValues[getIdNumber(i.id, "LineItem")],
      orderCreatedAt: order.createdAt,
    }));
  });
};
