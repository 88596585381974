import { useState } from "react";
import { Link } from "react-router-dom";
import { Box } from "@mui/material";
import { NavItem } from "../styles";
import ShopMegaMenu from "./ShopMegaMenu";

const styles = {
  ":hover": {
    ".shop-mega-menu:not(.close)": {
      display: "flex",
    },
    "+ .shop-mega-menu-veil:not(.close)": {
      display: "flex",
    },
  },
};

const ShopNavItem = ({ navHeight }: { navHeight: number }) => {
  const [closeMegaMenu, setCloseMegaMenu] = useState(false);

  const closeMegaMenuHandler = () => {
    setCloseMegaMenu(true);
    setTimeout(() => {
      setCloseMegaMenu(false);
    }, 500);
  };

  return (
    <>
      <Box sx={styles} position="relative" paddingX={4} paddingY={2} zIndex={1} onClick={closeMegaMenuHandler}>
        <Link to="/shop">
          <NavItem fontSize={14}>Shop</NavItem>
        </Link>
        <ShopMegaMenu closeMegaMenu={closeMegaMenu} />
      </Box>
      <Box
        width="100vw"
        height={`calc(100vh - ${navHeight}px)`}
        position="fixed"
        top={`${navHeight}px`}
        left={0}
        bgcolor="rgba(0,0,0,0.4)"
        display="none"
        className={`shop-mega-menu-veil` + (closeMegaMenu ? " close" : "")}
      />
    </>
  );
};

export default ShopNavItem;
