import { Link, useNavigate } from "react-router-dom";
import { Box, Drawer, IconButton, Stack } from "@mui/material";
import { BookmarkBorderOutlined, CloseRounded, MenuRounded, SearchRounded } from "@mui/icons-material";
import { colors } from "../../../theme";
import { Icon } from "../../Icon";
import { NavItem } from "../styles";
import BasketNavItem from "../Basket/BasketNavItem";
import CountrySelector from "../CountrySelector";
import { BasketFragment } from "../../../generated/storefront";
import Account from "../Account";
import ShopMenu from "./ShopMenu";
import CollectionMenu from "./CollectionMenu";
import { useContext } from "react";
import AuthContext from "../../../state/auth";
import { authSignOutUser } from "../../../services/Firebase";

type Props = {
  showNav: boolean;
  toggleNav: () => void;
  basketQuantity: number;
  closeBasket: () => void;
  basket?: BasketFragment;
  showBasket: boolean;
  setShowBasket: (value: boolean) => void;
  setSearchOpen: (value: boolean) => void;
  viewOnly?: boolean;
};

const MobileNav = ({
  showNav,
  toggleNav,
  closeBasket,
  basketQuantity,
  basket,
  showBasket,
  setShowBasket,
  setSearchOpen,
  viewOnly,
}: Props) => {
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();

  const navItems = [
    { title: "Gallery Walls", link: "/gallery-walls" },
    { title: "Artists", link: "/artists" },
    { title: "About", link: "/about" },
    { title: "Trade", link: "/trade" },
    { title: "Blog", link: "/goodnews" },
  ];

  const footerNavItems = [
    { title: "Contact", link: "/contact" },
    { title: "FAQs", link: "/faqs" },
  ];

  const openSearch = () => {
    toggleNav();
    setSearchOpen(true);
  };

  const logout = () => {
    authSignOutUser(navigate);
    window.location.reload();
  };

  return (
    <>
      <Stack justifyContent="space-between" direction="row" width="100%">
        <Stack gap={1.5} direction="row" alignItems="center">
          <NavItem onClick={toggleNav}>
            <MenuRounded />
          </NavItem>
          <NavItem onClick={() => setSearchOpen(true)}>
            <SearchRounded />
          </NavItem>
        </Stack>
        <Link to="/">
          <NavItem>
            <Icon icon="logo" width={48} height={30} />
          </NavItem>
        </Link>
        <Stack gap={1.5} direction="row" alignItems="center">
          <Link to="/wishlist">
            <NavItem style={{ marginTop: "2px" }}>
              <BookmarkBorderOutlined />
            </NavItem>
          </Link>
          <BasketNavItem
            basket={basket}
            showBasket={showBasket}
            setShowBasket={setShowBasket}
            closeBasket={closeBasket}
            basketQuantity={basketQuantity}
            isMobileScreen={true}
            viewOnly={viewOnly}
          />
        </Stack>
      </Stack>
      <Drawer
        open={showNav}
        onClose={toggleNav}
        anchor="left"
        sx={{
          top: 40,
          ".MuiModal-backdrop": { position: "absolute" },
          ".MuiDrawer-paper": { position: "absolute", boxShadow: "none" },
        }}
      >
        <Box width="90vw" height="100%" paddingTop={2} paddingBottom={3}>
          <Stack gap={2} height="100%">
            <Stack justifyContent="space-between" alignItems="center" width="100%" direction="row" paddingX={2}>
              <Stack gap={1.5} direction="row" alignItems="center">
                <IconButton onClick={toggleNav} style={{ padding: 0 }}>
                  <CloseRounded color="primary" />
                </IconButton>
                <NavItem onClick={openSearch}>
                  <SearchRounded />
                </NavItem>
              </Stack>
              <Link to="/">
                <NavItem onClick={toggleNav}>
                  <Icon icon="logo" width={48} height={22} />
                </NavItem>
              </Link>
              <div style={{ width: "62.84px" }}></div>
            </Stack>

            <Stack justifyContent="space-between" gap={2} width="100%" height="100%">
              <Stack gap={0.25}>
                <ShopMenu onCloseNav={toggleNav} />
                <CollectionMenu onCloseNav={toggleNav} />
                {navItems.map((item) => (
                  <Link to={item.link} key={item.title}>
                    <NavItem fontSize={18} paddingX={2} paddingY={1.5} onClick={toggleNav}>
                      {item.title}
                    </NavItem>
                  </Link>
                ))}
              </Stack>

              <Stack
                direction="row"
                flexWrap="wrap"
                rowGap={1.5}
                width="100%"
                padding={2}
                borderTop={`1px solid ${colors.grey05}`}
                sx={{ "> *": { flex: "1 1 30%" } }}
              >
                <div onClick={toggleNav}>
                  <Account isMobile />
                </div>
                {user ? (
                  <NavItem fontSize={14} onClick={logout}>
                    Logout
                  </NavItem>
                ) : (
                  <Link to="/sell" onClick={toggleNav}>
                    <NavItem fontSize={14}>Sell</NavItem>
                  </Link>
                )}
                {footerNavItems.map((item) => (
                  <Link to={item.link} key={item.title} onClick={toggleNav}>
                    <NavItem fontSize={14}>{item.title}</NavItem>
                  </Link>
                ))}
                <a href="https://www.instagram.com/goodmoodprints/" target="_blank" rel="noreferrer">
                  <NavItem fontSize={14}>Instagram</NavItem>
                </a>
                <CountrySelector fontSize={14} />
              </Stack>
            </Stack>
          </Stack>
        </Box>
      </Drawer>
    </>
  );
};

export default MobileNav;
