import { Helmet } from "react-helmet";
import { colors } from "../../theme";
import PageHeader from "../../components/PageHeader";
import contact from "../../assets/images/contact.png";
import { Container, Flex } from "../../components/Layout";
import { Header, Text } from "../../components/Text";
import TextLink from "../../components/TextLink";
import { useAppState } from "../../state";

const Privacy = () => {
  const { isMobileScreen } = useAppState();
  return (
    <>
      <Helmet>
        <title>Privacy Policy | GoodMood</title>
        <meta name="description" content="GoodMood Prints Privacy Policy" />
      </Helmet>
      <PageHeader header="Privacy Policy" background={colors.blue} image={contact} />
      <Container padding="40px 0 300px">
        <Flex gap={72} justifyContent="center" alignItems="center" direction="column">
          <Container width={isMobileScreen ? 100 : 50} padding={isMobileScreen ? "0 24px" : ""}>
            <Flex gap={16} direction="column">
              <Text size={20}>
                This Privacy Policy describes how your personal information is collected, used, and shared when you visit or make
                a purchase from www.goodmoodprints.com (the “Site”).
              </Text>
              <Header type="h2" margin="16px 0 0">
                PERSONAL INFORMATION WE COLLECT
              </Header>
              <Text size={20}>
                When you visit the Site, we collect certain information about your device, including information about your web
                browser, IP address, time zone, and some of the cookies that are installed on your device. Additionally, as you
                browse the Site, we collect information about the individual web pages or products that you view, what websites or
                search terms referred you to the Site, and information about how you interact with the Site.
              </Text>
              <Text size={20}>We collect Device Information using the following technologies:</Text>
              <ul>
                <li>
                  <Text size={20}>
                    “Cookies” are data files that are placed on your device or computer and often include an anonymous unique
                    identifier. For more information about cookies, and how to disable cookies, visit www.allaboutcookies.org.uk
                  </Text>
                </li>
                <li>
                  <Text size={20}>
                    “Log files” track actions occurring on the Site, and collect data including your IP address, browser type,
                    Internet service provider, referring/exit pages, and date/time stamps.
                  </Text>
                </li>
                <li>
                  <Text size={20}>
                    “Web beacons,” “tags,” and “pixels” are electronic files used to record information about how you browse the
                    Site.{" "}
                  </Text>
                </li>
              </ul>
              <Text size={20}>
                Additionally, when you make a purchase or attempt to make a purchase through the Site, we collect certain
                information from you, including your name, billing address, delivery address, payment information (including
                credit card numbers, email address, and phone number. We refer to this information as “Order Information.”
              </Text>
              <Text size={20}>
                When we talk about “Personal Information” in this Privacy Policy, we are talking both about Device Information and
                Order Information.
              </Text>
              <Header type="h2" margin="16px 0 0">
                HOW DO WE USE YOUR PERSONAL INFORMATION?
              </Header>
              <Text size={20}>
                We use the information that we collect generally to communicate with you and fulfil marketing activities. Order
                information collected will be used for any orders placed through the Site (including processing your payment
                information, arranging for delivery, and providing you with invoices and/or order confirmations). In addition, we
                screen orders for potential risk or fraud; and
              </Text>
              <Text size={20}>
                When in line with the preferences you have shared with us, provide you with information or advertising relating to
                our products or services.
              </Text>
              <Text size={20}>
                We use the Device Information that we collect to help us screen for potential risk and fraud (in particular, your
                IP address), and more generally to improve and optimize our Site (for example, by generating analytics about how
                our customers browse and interact with the Site,and to assess the success of our marketing and advertising
                campaigns).{" "}
              </Text>
              <Header type="h2" margin="16px 0 0">
                SHARING YOUR PERSONAL INFORMATION
              </Header>
              <Text size={20}>
                We share your Personal Information with third parties to help us use your Personal Information, as described
                above. For example, we use google analytics to help us understand how our customers use the Site–you can read more
                about how Google uses your Personal Information here:{" "}
                <TextLink openInNewTab href="https://www.google.com/intl/en/policies/privacy/">
                  https://www.google.com/intl/en/policies/privacy/
                </TextLink>
                . You can also opt-out of Google Analytics here:{" "}
                <TextLink openInNewTab href="https://tools.google.com/dlpage/gaoptout">
                  https://tools.google.com/dlpage/gaoptout
                </TextLink>
                .
              </Text>
              <Text size={20}>
                Finally, we may also share your personal information to comply with applicable laws and regulations, to respond to
                a subpoena, search warrant or other lawful requests for information we receive, or to otherwise protect our
                rights.
              </Text>
              <Header type="h2" margin="16px 0 0">
                BEHAVIOURAL ADVERTISING
              </Header>
              <Text size={20}>
                As described above, we use your personal information to provide you with targeted advertisements or marketing
                communications we believe may be of interest to you. For more information about how targeted advertising works,
                you can visit the Network Advertising Initiative’s (“NAI”) educational page at{" "}
                <TextLink openInNewTab href="http://www.networkadvertising.org/understanding-online-advertising/how-does-it-work">
                  http://www.networkadvertising.org/understanding-online-advertising/how-does-it-work
                </TextLink>
                .
              </Text>
              <Text size={20}>You can opt out of targeted advertising by contacting Facebook and Google directly.</Text>

              <Header type="h2" margin="16px 0 0">
                DO NOT TRACK
              </Header>
              <Text size={20}>
                Please note that we do not alter our Site’s data collection and use practices when we see a Do Not Track signal
                from your browser.
              </Text>
              <Text size={20}>
                If you are a European resident, you have the right to access the personal information we hold about you and to ask
                that your personal information is corrected, updated, or deleted. If you would like to exercise this right, please
                contact us.
              </Text>
              <Text size={20}>
                Additionally, if you are a European resident we note that we are processing your information in order to fulfil
                contracts we might have with you (for example if you make an order through the site), or otherwise to pursue our
                legitimate business interests listed above. Additionally, please note that your information will be transferred
                outside of Europe, including to Canada and the United States.
              </Text>

              <Header type="h2" margin="16px 0 0">
                DATA RETENTION
              </Header>
              <Text size={20}>
                When you place an order through the Site, we will maintain your Order Information for our records unless and until
                you ask us to delete this information.
              </Text>

              <Header type="h2" margin="16px 0 0">
                CHANGES
              </Header>
              <Text size={20}>
                We may update this privacy policy from time to time in order to reflect, for example, changes to our practices or
                for other operational, legal or regulatory reasons.
              </Text>
            </Flex>
          </Container>
        </Flex>
      </Container>
    </>
  );
};

export default Privacy;
