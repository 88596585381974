import styled from "styled-components";
import { capitalize } from "lodash";
import { InfoOutlined } from "@mui/icons-material";
import { Stack, Tooltip, TooltipProps, Typography, tooltipClasses } from "@mui/material";
import DragAndDrop from "../../components/DragAndDrop";
import { Text, Header } from "../../components/Text";
import { colors } from "../../theme";
import Button from "../../components/Button";
import Accordion from "../../components/Accordion";
import Checkbox from "../../components/Checkbox";
import Range from "../../components/Form/Range";
import { Ratio, UploadState, TShirtSize, tShirtSizeNumbers, tShirtSizes } from "../../types/product";
import { getResolutionDimensions, sizeLabelsForCountry } from "../../helpers/product";
import Switch from "../../components/Switch";
import { useAppState } from "../../state";
import { ProgressBar, ProgressBarContainer } from "../../components/ProgressBar";

type Props = {
  state: UploadState;
  setState: (state: Partial<UploadState>) => void;
  onStepChange: (step: number) => void;
  uploadImage: (files: File[]) => void;
  imageUploaded: boolean;
  uploading: boolean;
  progress: number;
  uploadError: string;
  uploadWarning: string;
  stepComplete: boolean;
  reuploadRequired: boolean;
  setConfirmedRgb: (value: boolean) => void;
  editMode?: boolean;
};

const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    minWidth: 356,
  },
});

const StepOne = ({
  state,
  setState,
  onStepChange,
  uploadImage,
  imageUploaded,
  uploading,
  progress,
  uploadError,
  uploadWarning,
  stepComplete,
  reuploadRequired,
  setConfirmedRgb,
  editMode,
}: Props) => {
  const { countryOfUser } = useAppState();
  const options = [
    {
      value: 1,
      label: capitalize(TShirtSize.Small),
    },
    {
      value: 2,
      label: capitalize(TShirtSize.Medium),
    },
    {
      value: 3,
      label: capitalize(TShirtSize.Large),
    },
  ];
  if (state.ratio === Ratio.Rectangle) {
    options.push({
      value: 4,
      label: "X-Large",
    });
  }
  const selectedSizeValue = options.find((option) => option.value === tShirtSizeNumbers[state.maxSize])?.value || 3;
  const { width, height } = getResolutionDimensions(state.maxSize, state.ratio);
  const sizeLabels = sizeLabelsForCountry(countryOfUser);

  const handleSelectRatio = (ratio: Ratio) => {
    const maxSize = ratio === Ratio.Square && state.maxSize === TShirtSize.XLarge ? TShirtSize.Large : state.maxSize;
    setState({ ratio, maxSize });
  };

  const handleMaxSizeChange = (event: any) => {
    const { name, value } = event.target;
    const selectedSize = options.find((option) => option.value === Number(value));
    const index = selectedSize ? selectedSize.value - 1 : 2;
    setState({ [name]: tShirtSizes[index] });
  };

  return (
    <>
      <Stack gap={2} paddingX={3} paddingY={2} width="100%">
        {uploading ? (
          <Header type="h3">Drum roll please...</Header>
        ) : (
          <Typography variant="h3" component="h1">
            Upload your design
          </Typography>
        )}
        <Stack gap={1}>
          <Typography>Please ensure:</Typography>
          <ul style={{ margin: 0, paddingLeft: "16px" }}>
            <li>
              <Typography fontSize={14}>This is your own design</Typography>
            </li>
            <li>
              <Typography fontSize={14}>It is an RGB file in PNG or JPEG format</Typography>
            </li>
            <li>
              <Typography fontSize={14}>There is no important detail within 3mm of the edge</Typography>
            </li>
          </ul>
        </Stack>
        {!editMode && (
          <Switch
            fullWidth
            options={[
              {
                label: capitalize(Ratio.Rectangle),
                value: Ratio.Rectangle,
              },
              { label: capitalize(Ratio.Square), value: Ratio.Square },
            ]}
            selected={state.ratio}
            onChange={(value) => handleSelectRatio(value as Ratio)}
          />
        )}
        <Stack gap={0.5} direction="row">
          <Typography>I want to sell in sizes up to:</Typography>
          <CustomWidthTooltip
            title={
              <Stack gap={2}>
                <Typography variant="body2">
                  We recommend uploading in larger sizes as long as the quality meets the outlined requirements and is of suitable
                  resolution. Hover over the image to view the print quality at the selected size.
                </Typography>
                <Typography variant="body2">
                  You can upload in A-ratio, "{sizeLabels["20X28"].shortHand}", "{sizeLabels["28X40"].shortHand}" or square.
                </Typography>
                <Typography variant="body2">The sizes are as follows:</Typography>
                <Stack>
                  <Typography variant="body2" fontWeight={500}>
                    X-Large:
                  </Typography>
                  <Typography variant="body2">{sizeLabels["28X40"].printMeasurements}</Typography>
                </Stack>
                <Stack>
                  <Typography variant="body2" fontWeight={500}>
                    Large:
                  </Typography>
                  <Typography variant="body2">
                    {sizeLabels.A1.printMeasurements} ({sizeLabels.A1.shortHand}) / {sizeLabels["28X28"].printMeasurements}
                  </Typography>
                </Stack>
                <Stack>
                  <Typography variant="body2" fontWeight={500}>
                    Medium:
                  </Typography>
                  <Typography variant="body2">
                    {sizeLabels.A2.printMeasurements} ({sizeLabels.A2.shortHand}) / {sizeLabels["20X28"].printMeasurements} /{" "}
                    {sizeLabels["20X20"].printMeasurements}
                  </Typography>
                </Stack>
                <Stack>
                  <Typography variant="body2" fontWeight={500}>
                    Small:
                  </Typography>
                  <Typography variant="body2">
                    {sizeLabels.A3.printMeasurements} ({sizeLabels.A3.shortHand}) / {sizeLabels["12X12"].printMeasurements}
                  </Typography>
                </Stack>
              </Stack>
            }
            placement="left"
            arrow
          >
            <InfoOutlined fontSize="small" />
          </CustomWidthTooltip>
        </Stack>
        <Range
          min={1}
          max={options.length}
          step={1}
          name="maxSize"
          onChange={handleMaxSizeChange}
          value={selectedSizeValue}
          valueLabel={state.maxSize}
          options={options}
          width="100%"
          disabled={uploading}
        />
        {reuploadRequired && <Typography>Please reupload your artwork to sell at this size</Typography>}
        {uploadWarning && <Typography>{uploadWarning}</Typography>}
        {uploading ? (
          <ProgressBarContainer>
            <ProgressBar
              role="progressbar"
              aria-valuenow={50}
              aria-valuemin={0}
              aria-valuemax={100}
              width={progress}
            ></ProgressBar>
          </ProgressBarContainer>
        ) : (
          <DragAndDrop
            onImageDrop={uploadImage}
            label={
              <Stack gap={1}>
                <Typography fontSize={18} align="center" margin="16px 0 0">
                  Upload RGB design
                </Typography>
                <Typography align="center" color="text.secondary">
                  Minimum dimensions: {height}px x {width}px (300 dpi)
                </Typography>
              </Stack>
            }
            error={uploadError}
          />
        )}
        {!imageUploaded && uploading ? (
          <Text size={14} color={colors.grey60} margin="16px 0">
            Uploading masterpiece...
          </Text>
        ) : null}
      </Stack>
      <Accordion
        items={[
          {
            label: "What happens to my file?",
            content: (
              <Stack gap={2} paddingX={{ xs: 3, md: 4 }} paddingBottom={4}>
                <Text>
                  We store your work securely, and use a low-resolution version (10% of the original file) on our site, so no one
                  can steal your work.
                </Text>
              </Stack>
            ),
          },
          {
            label: "Why use an RGB file?",
            content: (
              <Stack gap={2} paddingX={{ xs: 3, md: 4 }} paddingBottom={4}>
                <Text>
                  We only accept RGB files. This may come as a surprise but this allows us to maximise colour accuracy and quality
                  of the finished product. Our printers can print up to 12 colours, massively enhancing your colour gamut compared
                  to the traditional 4, and this also means the finished print is closely matched to what customers see on screen.
                </Text>
              </Stack>
            ),
          },
          {
            label: "Want to add a border?",
            content: (
              <Stack gap={2} paddingX={{ xs: 3, md: 4 }} paddingBottom={4}>
                <Text>We don't currently offer mounting, so we suggest adding a border to your file if you want one.</Text>
              </Stack>
            ),
          },
        ]}
      />
      <Stack gap={2} paddingX={3} paddingY={5} width="100%">
        <Checkbox
          name="rgb"
          label="I confirm this is an RGB file"
          onChange={({ target }) => setConfirmedRgb(target.checked)}
          checked={state.confirmedRgb}
        />
        <Typography color="text.secondary">
          Have you checked the quality of your file? Hover over the image once uploaded to see what the resolution will look like
        </Typography>
        <Stack alignSelf="end">
          <Button secondary onClick={() => onStepChange(2)} disabled={!stepComplete} size="medium">
            Next
          </Button>
        </Stack>
      </Stack>
    </>
  );
};

export default StepOne;
